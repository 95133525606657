@font-face {
  font-family: metropolisSemiBold;
  src: url(Metropolis-SemiBold.otf);
}

@font-face {
  font-family: metropolisRegular;
  src: url(Metropolis-Regular.otf);
}

@font-face {
  font-family: metropolisBold;
  src: url(Metropolis-Bold.otf);
}


input {
  font-family: metropolisRegular !important;
  padding-left: 10px;
}

.topheader {
  padding: 20px;
  height: 100px;
  width: 101%;
  background-color: #fff;
  box-shadow: 2px 0px 12px rgba(92, 138, 152, 0.075);
}

.bottom-section .input-field  {
  margin-top: 12px;
  font-size: 14px;
}
.btn-acc {
  width: 35px;
  height: 27px;
}
.acc-btn-text {
  font-size: 10pt !important;
  height: 50px !important;
}

.admin {
  /* background-image: url('../../../assets/icons/dropdown_icon.png') ; */
  background-position: right 0rem center;
  background-repeat: no-repeat;
}
.panel_copy{
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #EBEBEB;
  margin-top: 10px;
}
.panel_links{
  color:#000000;
  font-size: 10pt !important
}
.removeBtn{
  height: 14px;
  margin-left: 20px !important;
}

.increase_limit {
  /* position: absolute; */
  width: 637px;
  height: 24px;
  left: 280px;
  top: 220px;

  font-family: metropolisSemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 100% */


  color: #000000;
}

.file_css {
  width: 5%;
  margin-right: 2px;
  margin-bottom: 4px;
}

.upload_button_1 {
  /* position: absolute; */
  width: 250px;
  height: 30px;
  left: 320px;
  top: 277px;

  font-family: metropolisRegular;
  font-style: normal;
  font-weight: 500;
  color: #2F2F2F;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height, or 150% */

  /* Text-Color */

  color: #2F2F2F;

}

.top_section {
  margin-left: 20px;
}

.review-document {
  position: absolute;
  width: 344px;
  height: 48px;
  margin-left: 480px;
  margin-bottom: 10px;

  font-family: metropolisRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* or 150% */

  text-align: center;

  /* Icon/Primary */

  color: #757575;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.two_crore_green {
  color: #04B23D;
}

.upload_btn {
  /* position: absolute; */
  width: 228px;
  height: 24px;
  left: 320px;
  top: 277px;
  font-family: Metropolis-Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 150% */


  /* Text-Color */

  color: #2F2F2F;
}

.limit-1 {
  width: 400px !important;
  margin-bottom: 15px;
  height: 45px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  padding: 0px 20px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='6'%3E%3Cpath d='M1 1l5 4 5-4' stroke='%23333' fill='none'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 12px 6px;
  background-position: right 20px center;
}

.pagination-section {
  display: flex;
}

.pagination-left {
  width: 60%;
}

.pagination-right {
  width: 70%;
  margin-left: 580px;
}

.e-cash-css {
  height: 85vh;
  /* bottom: 5%; */
  width: 88vw;
  margin: 0 auto;
  /* border-radius: 15px; */
  /* display: flex; */
}

.right-arrow {
  width: 58%;
}

.arrowBtn {
  width: 24px;
  height: 24px;
}


.left-vector {}

.pic {
  float: right;
  width: 59px;
  padding-right: 10px;
  margin: 6px;
}

.headerLabel {
  font-family: metropolisSemiBold;
  float: right;
  margin-top: 20px;
}

.input-search {
  width: 246px;
}

.btnAdd {
  border-radius: 4px;
}

.select {
  width: 40%;
}

.ol {
  margin-top: 14px;
}

.navbar-css {
  display: flex;
}

.row-topheader {
  margin-left: 30px !important;
  width: 97%;
  margin-top: 17px !important;
  /* padding: 20px; */
  height: 88px;
  background-color: #fff;
  box-shadow: 2px 0px 12px rgba(92, 138, 152, 0.075);
  padding-top: 20px
}

.viewAllLink {
  margin-top: 0px;
}

.body-css {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 3%;
  border-radius: 10px;
}

.p-inputtext {
  width: 90%;
  border: 0px;
}

.top-section {
  background: rgba(253, 233, 108, 0.2);
  height: 60px;
}

.bottom-section {
  margin-top: 50px;
  margin-left: 30px;

}

.legend-css {
  margin-top: 20px;
  height: 60px;
  width: 300px;
  box-sizing: border-box;

  border: 1px solid #D3D3D3;
  border-radius: 8px;
}

.input-css {
  margin-top: 20px;
  height: 50px;
  width: 300px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #D3D3D3;
  border-radius: 8px;
}

.input-css-1 {
  position: absolute;
  padding-bottom: -13px;
  height: 40px;
  width: 285px;
  margin-top: 7px;
  margin-left: 0px;
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 8px;
}

.accordion-item .input-css-2 {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #D3D3D3;
  border-radius: 8px;
}

.accordion-item .input-css-class {
  margin-left: 20px;
  width: 95%;
}

.input-css-3 {
  display: flex;
  justify-content: center;
  width: 30px;
  padding: 1px;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px solid #D3D3D3;
  border-radius: 8px;
  margin-right: 10px;
  padding-left: 10px;
}

.otp-section {
  display: flex;
  margin-top: 15px;
}

.btn-upload {
  float: right;
  background-color: #04B23D !important;
  outline: none;
  border: none;
  float: right;
  width: 110px;
  margin-left: 35px;
  font-size: 11pt !important;
}

.footer-btns-container {
  font-family: metropolisRegular;
}

.input-css-1 :focus :after :before {
  position: absolute;
  padding-bottom: 27px;
  height: 40px;
  width: 285px;
  margin-top: -8px;
  margin-left: -6px;
  box-sizing: border-box;
  border: 1px solid white;
  border-radius: 8px;
  outline: none;
}

.input-outline:focus {
  border-color: white;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* 
.input-outline:focus{
  outline: none;
} */

.continue-button-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  grid-gap: 10px;
  gap: 10px;
  position: absolute;
  width: 278px;
  height: 40px;
  left: 525px;
  margin-top: 90px;
  color: #fff;
  border: 1px solid;
  background: #0DB442;
  border-radius: 8px;
  margin-left: 140px;
  font-size: 13px;
}

.container-css {
  font-size: 13px;
}

.continue-topheader {
  padding: 11px;
  height: 103px;
  width: 103%;
  background-color: #fff;
  box-shadow: 2px 0px 12px rgba(92, 138, 152, 0.075);
  margin-top: 250px;
  margin-left: -29px;
  /* font-size: 20px; */
}
.continue-topheader-css {
  padding: 11px;
  height: 103px;
  width: 100%;
  background-color: #fff;
  box-shadow: 2px 0px 12px rgba(92, 138, 152, 0.075);
  margin-top: 250px;
  /* margin-left: -29px; */
}
.upload-button {
  position: absolute;
  color: #fff;
  border: 1px solid;
  background: #0DB442;
  border-radius: 8px;
  margin-top: 70px;
  margin-bottom: 70px;
}
.css-nav-twocrore {
  font-size: 13px !important;
}
.upload-button-1 {
  position: absolute;
  color: #fff;
  border: 1px solid;
  background: #0DB442;
  border-radius: 8px;
  /* margin-top: 70px; */
  /* margin-bottom: 70px; */
}

.continue-class {
  padding: 20px;
  height: 100px;
  width: 101%;
  background-color: #fff;
  box-shadow: 2px 0px 12px rgba(92, 138, 152, 0.0);
}

.entity-css {

  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 18px;
  /* identical to box height, or 133% */


  color: #2F2F2F;
}

.fieldset {
  border: 1px solid #000;
}

.accordion {
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 50%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */


/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}

.accordion-button:not(.collapsed) {
  color: 'black';
  background-color: 'white';
  border: '0px';
}

/* .accordion-button:not(.collapsed):after {
  background-image: '';
} */
.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: white !important;
}

.accordion-button:not(.collapsed):after {
  background-image: '';
}

.accordion-button:not(.collapsed):after {
  background-image: '' !important;
}

.accordion-button:after {
  background-image: '' !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: '' !important;
  border: none !important
}

.limit-css-1 {
  font-size: 16px;
  width: 358px;
}

.limit-1 {
  width: 271px;
  height: 45px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  padding: 0px 20px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* background-image: url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='6'%3E%3Cpath d='M1 1l5 4 5-4' stroke='%23333' fill='none'/%3E%3C/svg%3E); */
  background-repeat: no-repeat;
  background-size: 12px 6px;
  background-position: right 20px center;
}

.accordion-item {
  margin-bottom: 30px !important;
  

}

.eCashAccordian .accordion-header {
  border-bottom: none !important;
  height: 50px !important;
}

.accordion-item:not(:first-of-type) {
  border-top: 1px solid #D3D3D3 !important;
}

.accordion-button:after {
  background-image: none !important;
}

.accordion-button {
  border: 1px solid #DDDDDD;
  position: relative;
  font-family: metropolisSemiBold;
}

.gap {
  height: 5px;
}

.info-class {
  /* padding: 5px; */
  color: #D3D3D3;
  font-weight: bold;
  margin-left: -30px;
}

.your-request {
  font-family: metropolisSemiBold;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */
  margin-top: 100px;
  text-align: center;
  color: #2F2F2F;

}

.greenLabel {
  color: #0DB442;
}

.image-css {
  border-radius: 100%;
}

.doc-css {
  margin-top: 50px;
  font-family: metropolisSemiBold;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */

  text-align: center;

  /* Icon/Primary */

  color: #757575;
}

.our-team {
  font-family: metropolisRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  margin-top: 15px;
  text-align: center;
  color: #AEAEAE;
}

.increase-limit {

  font-family: metropolisSemiBold;
  font-weight: 600;
  font-size: 20px;
  /* or 110% */
  margin-top: 45px;
  text-align: center;

  color: #5B5B5B;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.yes-btn {

  width: 112px;
  height: 34px;
  margin-top: 20px;
  background: #0DB442;
  border-radius: 8px;
  color: #fff;
  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.yes-btn:hover {
  background: #0DB442 !important;
  color: #fff !important;
  font-family: metropolisSemiBold;
}

.breadcrumbsLabel {
  font-family: metropolisSemiBold;
  margin-left: 5px;
  /* font-size: 12px !important; */
  margin-top: 2px
}

.continue-button-1:hover {
  background-color: #0DB442 !important;
  outline-color: #0DB442;
  border: none;
}

.btn {
  font-family: metropolisRegular !important;
}

.image-style {
  margin: 90px;
}

.yes_button {
  margin-bottom: 30px;
}
.btn-font {
  font-size: 14px !important;
}

.btn:disabled {
  background-color: #757575 !important;
  border-color: #757575 !important;
}