@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

a {
    color: #fff;
    text-decoration: none
}

.bg {
    /* position: absolute; */
    width: 100%;
    height: 74px;
    left: 0px;
    top: 160px;
    display: flex;
    background: #FFF5EA;

}



@font-face {
    font-family: MetropolisRegular;
    src: url('Metropolis-Regular.otf')
}

@font-face {
    font-family: MetropolisMedium;
    src: url('Metropolis-Medium.otf')
}

@font-face {
    font-family: MetropolisLight;
    src: url('Metropolis-Light.otf')
}

@font-face {
    font-family: MetropolisBold;
    src: url('Metropolis-Bold.otf')
}
.btn-disclaimer{
    background-color: #04B23D !important;
    width: 367px;
    height: 48px;
    border-radius: 8px !important;
    border:"none" !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 134% !important;
    color: #FFFFFF !important;
    letter-spacing: 1px;
    font-family: 'MetropolisRegular' !important;
    margin-top: 12px !important;
    border: none !important;
}
.bd{
    padding: 3% 7%;
    height: 100%;
}
.disclaimer-btn-div{
    display: flex;
    width: 100%;
    justify-content: center;
}
.bd > h3{
    color:gray !important;
    font-size:17pt !important;
    font-family: MetropolisMedium;
}
.paragraph{
    font-weight: normal;
    font-size: 11pt;
    font-family: MetropolisLight !important;
    margin-bottom: 25px;
}

.title{
    font-family: MetropolisMedium;
}

.head-title {
    font-family: MetropolisBold;
}

.bg .container {
    display: flex;
    align-items: center;
    max-width: 85% !important;
}

body {
    height: 100vh;
    // background: #3e3232;
    font-family: 'Poppins', sans-sherif;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    // overflow: hidden;
}
.marquee-container{
    // width:890px;
}

.ticker {
    display: flex;
    // flex-wrap: wrap;
    width: 100%;
    height: 50px;
    /* margin: 0 auto; */
}
.news {
    width: 71%;
    display: flex;
    /* background: #cc4444; */
    /* padding: 0 2% */



    .ticker-warpper {
        width: fit-content;
        font-family: 'Poppins', sans-serif;
        padding: 0.5em 1em;
        color: #2F2F2F;
        display: flex;
        margin-right: 40px;

        .ticker-header {
            margin-right: 0.5em;

            >p {
                margin: 0;
                font-size: 15px;
            }

            .commodity-name {
                font-family: MetropolisMedium;
                // font-weight: bold;
                font-size: 16px;
            }


            .location {
                font-size: 12px;
                color: #757575;
            }
        }

        .ticker-footer {
            text-align: right;

            >p {
                margin: 0;
            }

            .source {
                font-family: MetropolisMedium;
                font-size: 16px;
            }

            .upDown {
                font-size: 12px;
            }

            .pi {
                margin-right: 0.5em;
            }

            .green {
                color: #40e905;
            }

            .red {
                color: #FF4848;
            }


        }
    }
}

.icon {
    // margin-left: 170px;
    display: flex;
    align-items: center;
    // font-size: 25px;
    // background: linear-gradient(90deg, #FFF5EA 70.83%, rgba(255, 245, 234, 0.68) 100%);
    // transform: matrix(-1, 0, 0, 1, 0, 0);

}

.title {
    // width: 16%;
    // text-align: center;
    // position: relative;
    // background: linear-gradient(90deg, #FFF5EA 70.83%, rgba(255, 245, 234, 0.68) 100%);
    // padding-right: 40px;
}

.title:after {
    position: absolute;
    content: "";
    right: -22%;
    /* border-left: 20px solid #c81c1c; */
    border-top: 28px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 21px solid transparent;
    top: 0px
}

.title h5 {
    font-size: 18px;
    margin: 14.5% 0
}

.news marquee {
    font-size: 18px;
    margin-top: 12px
}

.news-content p {
    margin-right: 41px;
    display: inline
}

// ---------------------------
.marqueDiv{
    margin-top: 20px;
    width: 100vw;
    height: 74px;
    display: flex;
    background: #FFF5EA;
    display: flex;
    align-items: center;
    padding-left: 7%;
    // max-width: 85% !important;
}
.innerMarqueDiv{
    display: flex;
    width: 92%;
    justify-content: space-between;
    gap: 4%;
    // height: 50px;
}
.modal{
    z-index: 20 !important;
}
.modal-backdrop {
    z-index: 4 !important;
}
.modal-content {
    border-radius: 12px !important;
}
.modal-header {
    border-bottom: none !important;
    padding: 20px !important;
    font-size: 14px !important;
    justify-content: flex-end !important;
}
.disclaimer-content{
    padding-bottom: 30px !important;
    z-index: 99999;
    background-color: white !important;
}
.disclaimer-modal-hide{
    display: none !important;
}
.modal-dialog-centered {
    /* display: flex; */
    /* align-items: center; */
    /* min-height: calc(100% - var(--bs-modal-margin) * 2); */
}
@media only screen and (max-width: 7680px) and (min-width: 5747px){
    .modal-lg, .modal-xl {
        --bs-modal-width: 268rem !important;
    }
    .modal-header {
        border-bottom: none !important;
        padding: 69px !important;
        font-size: 56px !important;
    }
    .bd > h3 {
        color: gray !important;
        font-size: 74pt !important;
        margin-bottom: 6.5rem !important;
    }
    .bd {
        padding: 1% 7%;
    }
    .paragraph {
        font-size: 36pt;
        margin-bottom: 72px;
    }
    .btn-disclaimer {
        width: 104rem;
        height: 16rem;
        font-size: 56px !important;
        margin-top: 86px !important;
    }
    .marqueDiv {
        margin-top: 20px;
        width: 100vw;
        height: 212px;
    }
    .title h5 {
        font-size: 50px;
    }
    .news {
        width: 83%;
        display: flex;
    }
    .news .ticker-warpper .ticker-header .commodity-name , .news .ticker-warpper .ticker-footer .source{
        font-size: 44px;
    }
    .news .ticker-warpper .ticker-header .commodity-name{
        margin-right: 252px !important;
    }
    .news .ticker-warpper {
        margin-right: 137px;
    }
    .news .ticker-warpper .ticker-header .location , .news .ticker-warpper .ticker-footer .upDown{
        font-size: 33px;
    }
    .iconI{
        margin-top: 32px;
        height: 100px;
    }
    }
@media only screen and (max-width: 5746px) and (min-width: 5465px){
    .modal-lg, .modal-xl {
        --bs-modal-width: 143rem !important;
    }
    .iconI{
        height: 82px !important;
    }
    .modal-header {
        border-bottom: none !important;
        padding: 45px !important;
        font-size: 31px !important;
    }
    .bd {
        padding: 1% 7%;
        height: 100%;
    }
    .modal-content {
        height: 25vh;
    }
    .bd > h3 {
        color: gray !important;
        font-size: 37pt !important;
        margin-bottom: 34px !important;
        font-family: MetropolisMedium;
    }
    .paragraph {
        font-weight: normal;
        font-size: 20pt;
        font-family: MetropolisLight !important;
        margin-bottom: 40px;
    }
    .btn-disclaimer {
        width: 533px;
        height: 77px;
        font-size: 28px !important;
    }
    .marqueDiv {
        margin-top: 20px;
        width: 100vw;
        height: 212px;
    }
    .title h5 {
        font-size: 50px;
    }
    .news {
        width: 80%;
        display: flex;
    }
    .news .ticker-warpper .ticker-header .commodity-name , .news .ticker-warpper .ticker-footer .source{
        font-size: 44px;
    }
    .news .ticker-warpper .ticker-header .commodity-name{
        margin-right: 252px !important;
    }
    .news .ticker-warpper {
        margin-right: 137px;
    }
    .news .ticker-warpper .ticker-header .location , .news .ticker-warpper .ticker-footer .upDown{
        font-size: 33px;
    }
    }
@media only screen and (max-width: 5464px) and (min-width: 4099px){
    .modal-lg, .modal-xl {
        --bs-modal-width: 153rem !important;
    }
    .iconI{
        height: 90px !important;
    }
    .modal-header {
        border-bottom: none !important;
        padding: 32px !important;
        font-size: 28px !important;
    }
    .bd > h3 {
        color: gray !important;
        font-size: 34pt !important;
        margin-bottom: 27px;
        font-family: MetropolisMedium;
    }
    .paragraph {
        font-weight: normal;
        font-size: 20pt;
        font-family: MetropolisLight !important;
        margin-bottom: 33px;
    }
    .btn-disclaimer {
        width: 576px;
        height: 83px;
        font-size: 31px !important;
    }
    .marqueDiv {
        margin-top: 20px;
        width: 100vw;
        height: 212px;
    }
    .title h5 {
        font-size: 50px;
    }
    .news {
        width: 81%;
        display: flex;
    }
    .news .ticker-warpper .ticker-header .commodity-name , .news .ticker-warpper .ticker-footer .source{
        font-size: 44px;
    }
    .news .ticker-warpper .ticker-header .commodity-name{
        margin-right: 252px !important;
    }
    .news .ticker-warpper {
        margin-right: 137px;
    }
    .news .ticker-warpper .ticker-header .location , .news .ticker-warpper .ticker-footer .upDown{
        font-size: 33px;
    }
    }
@media only screen and (max-width: 4098px) and (min-width: 3842px){
    .modal-lg, .modal-xl {
        --bs-modal-width: 90rem !important;
    }
    .iconI{
        height: 80px !important;
    }
    .marqueDiv {
        margin-top: 20px;
        width: 100vw;
        height: 212px;
    }
    .title h5 {
        font-size: 50px;
    }
    .news {
        width: 83%;
        display: flex;
    }
    .innerMarqueDiv {
        gap: 2%;
    }
    .news .ticker-warpper .ticker-header .commodity-name , .news .ticker-warpper .ticker-footer .source{
        font-size: 44px;
    }
    .news .ticker-warpper .ticker-header .commodity-name{
        margin-right: 252px !important;
    }
    .news .ticker-warpper {
        margin-right: 137px;
    }
    .news .ticker-warpper .ticker-header .location , .news .ticker-warpper .ticker-footer .upDown{
        font-size: 33px;
    }
    
    }
@media only screen and (max-width: 3841px) and (min-width: 2561px){
    .news .ticker-warpper {
        margin-right: 143px;
    }
    .news {
        width: 79%;
    }
    .marqueDiv{
        height: 217px;
        margin-top: 45px;
    }
    .title h5{
        font-size: 57px;
    }
    .news .ticker-warpper .ticker-header .commodity-name {
        font-size: 48px;
        margin-right: 265px !important;
    }
    .news .ticker-warpper .ticker-header .location {
        font-size: 36px;
    }
    .news .ticker-warpper .ticker-footer .source {
        font-size: 48px;
    }
    .news .ticker-warpper .ticker-footer .upDown {
        font-size: 36px;
    }
    .innerMarqueDiv{
        gap: 2%;
    }
    .iconI{
        height: 79px !important;
    }
    .modal-header {
        padding: 38px !important;
        font-size: 38px !important;
    }
    .bd > h3 {
        font-size: 7rem!important;
    }
    .paragraph {
        font-size: 36px;
    }
    .btn-disclaimer {
        width: 89rem;
        height: 13rem;
        border-radius: 8px !important;
        font-size: 43px !important;
        margin-top: 61px !important;
    }
}
@media only screen and (max-width: 3200px) and (min-width: 3073px){
    .title h5 {
        font-size: 49px;
    }
    .iconI {
        height: 67px !important;
    }
    .news {
        width: 80%;
    }
    .carousel-body .carousel-caption .formText {
      font-size: 42px;
    }
    
}
@media only screen and (max-width: 3051px) and (min-width: 2733px){
    .news {
        width: 78%;
    }
    .title h5 {
        font-size: 50px;
    }
    .news .ticker-warpper .ticker-header .commodity-name , .news .ticker-warpper .ticker-footer .source{
        font-size: 41px;
    }
    .news .ticker-warpper .ticker-header .location ,.news .ticker-warpper .ticker-footer .upDown{
        font-size: 31px;
    }
    .iconI {
        height: 70px !important;
    }
}
@media only screen and (max-width: 2732px) and (min-width: 2561px){
    .news {
        width: 77%;
    }
    .title h5 {
        font-size: 47px;
    }
    .news .ticker-warpper .ticker-header .commodity-name , .news .ticker-warpper .ticker-footer .source{
        font-size: 38px;
    }
    .news .ticker-warpper .ticker-header .location , .news .ticker-warpper .ticker-footer .upDown{
        font-size: 29px;
    }
    .news .ticker-warpper .ticker-header .commodity-name {
        margin-right: 218px !important;
    }
    .marqueDiv {
        height: 168px;
        margin-top: 43px;
    }
    .iconI {
        height: 60px !important;
    }
}
@media only screen and (max-width: 2560px) and (min-width: 1921px) and (max-height:1920px){
    .modal-header {
        padding: 23px !important;
        font-size: 27px !important;
    }
    .bd > h3 {
        font-size: 5rem!important;
    }
    .paragraph {
        font-size: 24px;
    }
    .btn-disclaimer {
        width: 69rem;
        height: 9rem;
        border-radius: 8px !important;
        font-size: 34px !important;
        margin-top: 24px !important;
    }
    
}
@media only screen and (max-width: 2560px) and (min-width: 1921px) and (max-height: 1600px){
    .modal-header {
        padding: 34px !important;
        font-size: 24px !important;
    }
    .bd {
        padding: 3% 7%;
        height: 100%;
    }
    .bd > h3 {
        font-size: 5rem!important;
    }
    .paragraph {
        font-size: 24px;
    }
    .btn-disclaimer {
        width: 69rem;
        height: 9rem;
        border-radius: 8px !important;
        font-size: 34px !important;
        margin-top: 24px !important;
    }
    
}
@media only screen and (max-width: 2560px) and (min-width: 1921px){
    .news {
        width: 78%;
    }
    .news .ticker-warpper {
        margin-right: 81px;
    }
    .title h5 {
        font-size: 44px;
    }
    .news .ticker-warpper .ticker-header .commodity-name {
        font-size: 34px;
        margin-right: 171px !important;
    }
    .news .ticker-warpper .ticker-header .location {
        font-size: 26px;
    }
    .news .ticker-warpper .ticker-footer .source {
        font-size: 34px;
    }
    .news .ticker-warpper .ticker-footer .upDown {
        font-size: 26px;
    }
    .marqueDiv {
        height: 145px;
        margin-top: 30px;
    }
    .innerMarqueDiv {
        gap: 2%;
    }
    .iconI {
        height: 57px !important;
    }
}
@media only screen and (max-width: 2300px) and (min-width: 2049px){
    .news {
        width: 80%;
    }
    .title h5 {
        font-size: 31px;
    }
    .marqueDiv {
        height: 117px;
        margin-top: 30px;
    }
    .news .ticker-warpper .ticker-footer .source , .news .ticker-warpper .ticker-header .commodity-name{
        font-size: 26px;
    }
    .news .ticker-warpper .ticker-header .location, .news .ticker-warpper .ticker-footer .upDown {
        font-size: 21px;
    }
    .iconI {
        height: 51px !important;
    }
    .news {
        display: flex;
    }
}
@media only screen and (max-width: 2048px) and (min-width: 1601px){
    .news {
        width: 77%;
    }
    .navbarDiv {
        padding-left: 23%;
    }
    .title h5 {
        font-size: 36px;
    }
    .news .ticker-warpper .ticker-header .commodity-name {
        font-size: 30px;
        margin-right: 160px !important;
    }
    .news .ticker-warpper .ticker-footer .source {
        font-size: 30px;
    }
    .news .ticker-warpper .ticker-header .location {
        font-size: 23px;
    }
    .news .ticker-warpper .ticker-footer .upDown {
        font-size: 23px;
    }
    .iconI {
        height: 59px !important;
    }
    .modal-header {
        padding: 28px !important;
    font-size: 25px !important;
    }
    .bd > h3 {
        font-size: 5rem!important;
    }
    .paragraph {
        font-size: 24px;
    }
    .btn-disclaimer {
        width: 69rem;
        height: 9rem;
        border-radius: 8px !important;
        font-size: 34px !important;
        margin-top: 24px !important;
    }
    
    .disclaimer-modal {
        // --bs-modal-width: 800px;
        width: 68vw !important;
        max-width: 999999999px !important; 
    }
    // .modal-dialog {
    //      max-width: 999999999px !important; 
    // }
}
@media only screen and (max-width: 1920px) and (max-height: 1200px){
    .modal-header {
        padding: 17px !important;
        font-size: 20px !important;
    }
    .bd > h3 {
        font-size: 4rem!important;
    }
    .paragraph {
        font-size: 20px;
    }
    .btn-disclaimer {
        width: 62rem;
        height: 8rem;
        border-radius: 10px !important;
        font-size: 29px !important;
        margin-top: 18px !important;
    }
    
    .disclaimer-modal {
        // --bs-modal-width: 800px;
        width: 61vw !important;
        max-width: 999999999px !important; 
    }
}
@media only screen and (max-width: 1920px) and (max-height: 1080px){
    .modal-header {
        padding: 23px !important;
        font-size: 20px !important;
    }
    .bd > h3 {
        font-size: 4rem!important;
    }
    .paragraph {
        font-size: 20px;
    }
    .btn-disclaimer {
        width: 62rem;
        height: 8rem;
        border-radius: 10px !important;
        font-size: 29px !important;
        margin-top: 18px !important;
    }
    
    .disclaimer-modal {
        // --bs-modal-width: 800px;
        width: 61vw !important;
        max-width: 999999999px !important; 
    }
}
@media only screen and (max-width: 1920px)  and (min-width: 1441px){
    .navbarDiv {
        padding-left: 22%;
    }
    .marqueDiv {
        height: 104px;
    }
    .title h5 {
        font-size: 25px;
    }
    .iconI {
        height: 50px !important;
    }
    .news {
        width: 81%;
    }
    .innerMarqueDiv {
        gap: 2%;
    }
    .news .ticker-warpper {
        margin-right: 65px;
    }
    .news .ticker-warpper .ticker-header .commodity-name {
        font-size: 24px;
        margin-right: 142px !important;
    }
    .news .ticker-warpper .ticker-footer .source {
        font-size: 24px;
    }
    .news .ticker-warpper .ticker-header .location {
        font-size: 19px;
    }
    .news .ticker-warpper .ticker-footer .upDown {
        font-size: 19px;
    }
}
@media only screen and (max-width: 1680px) and (min-width: 1441px){
    .iconI {
        height: 38px !important;
    }
    .news {
        width: 80%;
    }
    .navbarDiv {
        padding-left: 23%;
    }
    .news .ticker-warpper {
        margin-right: 59px;
    }
    .news .ticker-warpper .ticker-header .commodity-name {
        font-size: 22px;
        margin-right: 117px !important;
    }
    .news .ticker-warpper .ticker-footer .source {
        font-size: 22px;
    }
    .news .ticker-warpper .ticker-header .location {
        font-size: 16px;
    }
    .news .ticker-warpper .ticker-footer .upDown {
        font-size: 16px;
    }
    .modal-header {
        padding: 21px !important;
        font-size: 17px !important;
    }
    .bd > h3 {
        font-size: 3rem!important;
    }
    .paragraph {
        font-size: 17px;
    }
    .btn-disclaimer {
        width: 51rem;
        height: 6rem;
        border-radius: 10px !important;
        font-size: 24px !important;
        margin-top: 6px !important;
    }
    .bd {
        padding: 2% 7%;
        height: 100%;
    }
    
    .disclaimer-modal {
        // --bs-modal-width: 800px;
        width: 58vw !important;
        max-width: 999999999px !important; 
    }
}

@media only screen and (max-width: 1600px) and (min-width: 1441px){
    .news {
        width: 79%;
    }
    .marqueDiv {
        height: 82px;
    }
    .title h5 {
        font-size: 24px;
        margin-top: 3rem;
    }
    .innerMarqueDiv {
        gap: 2%;
    }
    .news .ticker-warpper .ticker-header .commodity-name {
        font-size: 22px;
    }
    .news .ticker-warpper .ticker-footer .source {
        font-size: 22px;
    }
    .news .ticker-warpper .ticker-header .location {
        font-size: 16px;
    }
    .news .ticker-warpper .ticker-footer .upDown {
        font-size: 16px;
    }
    
    
}
@media only screen and (max-width: 1536px){
    
}
@media only screen and (max-width: 1518px){
    
}
@media only screen and (max-width: 1440px){
    .iconI{
        height: 36px !important;
    }
    .innerMarqueDiv {
        gap: 2%;
    }
    .news {
        width: 81%;
    }
    .news .ticker-warpper .ticker-header .commodity-name {
        font-size: 18px;
        margin-right: 117px !important;
    }
    .news .ticker-warpper .ticker-footer .source {
        font-size: 18px;
    }
    .news .ticker-warpper .ticker-header .location {
        font-size: 14px;
    }
    .news .ticker-warpper .ticker-footer .upDown {
        font-size: 14px;
    }
    .modal-header {
        padding: 18px !important;
    font-size: 13px !important;
    }
    .bd > h3 {
        font-size: 2rem!important;
    }
    .paragraph {
        font-size: 15px;
    }
    .btn-disclaimer {
        width: 46rem;
        height: 5rem;
        border-radius: 7px !important;
        font-size: 19px !important;
        margin-top: 11px !important;
    }
    .bd {
        padding: 1% 7%;
        height: 100%;
    }
    
    .disclaimer-modal {
        // --bs-modal-width: 800px;
        width: 51vw !important;
        max-width: 999999999px !important; 
    }
}
@media screen and (max-width: 1366px){
    .news {
        width: 81%;
    }
    .news .ticker-warpper .ticker-header .commodity-name {
        margin-right: 93px !important;
    }
    .title h5 {
        font-size: 18px;
    }
    .innerMarqueDiv {
        gap: 2%;
    }
    .disclaimer-modal {
        width: 51vw !important;
    }
    .bd {
        padding: 1% 4%;
        height: 100%;
    }
    .paragraph {
        font-size: 14px;
    }
    .btn-disclaimer {
        width: 43rem;
        height: 5rem;
        border-radius: 7px !important;
        font-size: 18px !important;
        margin-top: 11px !important;
    }
    
}
@media screen and (max-width: 1280px) and (max-height: 1024px){
    .news .ticker-warpper .ticker-footer .upDown {
        font-size: 12px;
    }
    .news .ticker-warpper .ticker-header .location {
        font-size: 12px;
    }
    .news .ticker-warpper .ticker-footer .source {
        font-size: 16px;
        font-weight: 500;
    }
    .news .ticker-warpper .ticker-header .commodity-name {
        font-size: 16px;
        font-weight: 500;
    }
    .title h5 {
        font-size: 16px;
    }
    .bd {
        padding: 2% 7%;
        height: 100%;
    }
    .paragraph {
        font-size: 13px;
    }
    .btn-disclaimer {
        width: 43rem;
        height: 5rem;
        border-radius: 7px !important;
        font-size: 17px !important;
        margin-top: 4px !important;
    }
    .modal-header {
        padding: 12px !important;
        font-size: 14px !important;
    }
    
    .disclaimer-modal {
        width: 59vw !important;
    }
}
@media screen and (max-width: 1280px) and (max-height: 800px){
    .iconI {
        height: 31px !important;
    }
    .bd {
        padding: 2% 7%;
        height: 100%;
    }
    .news {
        width: 82%;
    }
    .paragraph {
        font-size: 13px;
    }
    .btn-disclaimer {
        width: 37rem;
        height: 5rem;
        border-radius: 7px !important;
        font-size: 17px !important;
        margin-top: 4px !important;
    }
    .modal-header {
        padding: 15px !important;
        font-size: 12px !important;
    }
    
    .disclaimer-modal {
        width: 53vw !important;
    }
}
@media screen and (max-width: 1093px){
    .news {
        width: 72%;
    }
    .iconI{
        height: 28px !important;
    }
}
@media screen and (max-width: 1024px){
    .iconI{
        height: 28px !important;
    }
    .bg .container{
        margin-right: 0px !important;
        margin-left: 0px !important;
        padding-left: 7%;
    }
    .news{
        width: 78%;
    }
    .icon{
        margin-left: 10px;
    }
    .innerMarqueDiv{
        gap: 1%;
    }
    .paragraph {
        font-size: 12px;
    }
    .btn-disclaimer {
        width: 39rem;
        height: 4rem;
        border-radius: 7px !important;
        font-size: 15px !important;
        margin-top: 3px !important;
    }
    
    .disclaimer-modal {
        width: 64vw !important;
    }
}
@media screen and (max-width: 955px){
    .news {
        width: 71%;
    }
}
@media screen and (max-width: 780px){
    .news {
        width: 68%;
    }
}
@media screen and (max-width: 680px){
    .news {
        width: 65%;
    }
}
@media screen and (max-width: 598px){
    .news {
        width: 62%;
    }
}
@media screen and (max-width: 576px){
    #disclaimer {
        --bs-modal-margin: 17vw;
        --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }
}
@media screen and (max-width: 526px){
    .news {
        width: 58%;
    }
}
@media screen and (max-width: 465px){
    .news {
        width: 53%;
    }
}
@media screen and (max-width: 431px){
    .disclaimer-modal {
        width: 75vw !important;
    }
    #disclaimer {
        --bs-modal-margin: 12vw;
        --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }
}
@media screen and (max-width: 395px){
    .news {
        width: 49%;
    }
}
@media screen and (max-width: 360px){
    .news {
        width: 43%;
    }
}
@media screen and (max-width: 342px){
    .disclaimer-modal {
        width: 90vw !important;
    }
    #disclaimer {
        --bs-modal-margin: 5vw;
        --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }
}
@media screen and (max-width: 315px){
    .news {
        width: 35%;
    }
}
@media screen and (max-width: 263px){
    .news {
        width: 25%;
    }
}
@media screen and (max-width: 231px){
    .news {
        width: 15%;
    }
}