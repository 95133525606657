@font-face {
    font-family: MetropolisRegular;
    src: url('Metropolis-Regular.otf')
}

@font-face {
    font-family: MetropolisMedium;
    src: url('Metropolis-Medium.otf')
}


/* new-------------- */
.Footer{
    background-color: #312B25;
    height: 100vh;
    overflow: hidden;
}
/* 
.lowerinnerDiv {
} */

.div1 {
    font-family: MetropolisRegular;

}

.headings {
    font-family: MetropolisRegular;

}

.upperFooter{
    height: 40%;
    padding-top: 2%;
    margin-bottom: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #413F3E;
    padding-left: 7%;
}
.buy{
    font-family: MetropolisRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 134%;
    color: #ECECEC;
    margin-top: 1.8%;
    margin-bottom: 0px;
}
.lowerFooter{
    height: 60%;
    padding-left: 7%;
    padding-right: 7%;
    padding-top: 5%;
}
.lowerinnerDiv{
    display: flex;
    color: #D3D3D3;
    justify-content: space-between;
}

.headings {
    font-family: MetropolisRegular;
}

.div1,.div2,.div3{
    flex: 1;
}
.div4{
    flex: 1.5;
}
.links{
    font-family: MetropolisRegular;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    color: #a9a3a3;
    /* color: #979797; */
    text-decoration: none;
    cursor: pointer;
}
/* .links:hover{
    color: #979797;
} */
.address{
    font-family: MetropolisRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #979797;
}
.headings{
    font-family: MetropolisRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #FFFFFF;
}
.links1, .links2,.links3{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 17%;
}
.availableOnSection{
    display: flex;
    gap: 5%;
    margin-bottom: 9%;
}
.googleImages{
    width: 32px;
    height: 32px;
}
.scanner{
    width:130px;
    height:130px;
}
.lowerFooter a:hover {
    color: #a9a3a3;
}
@media only screen and (max-width: 7680px) and (min-width: 5747px){
    .upperFooter img{
        height: 12rem;
    }
    .buy {
        font-size: 48px;
    }
    .lowerFooter {
        height: 60%;
        padding-left: 9%;
        padding-right: 0%;
        padding-top: 2%;
    }
    .headings {
        font-size: 58px;
    }
    a:hover {
        color: #a9a3a3;
    }
    .links {
        font-size: 44px;
    }
    .links1, .links2, .links3 {
        gap: 7rem;
    }
    .googleImages {
        width: 124px;
        height: 124px;
    }
    .scanner {
        width: 300px;
        height: 300px;
    }
    .address {
        font-size: 46px;
    }
}
@media only screen and (max-width: 5746px) and (min-width: 5465px){
    .upperFooter img{
        height: 12rem;
    }
    .buy {
        font-size: 48px;
    }
    .lowerFooter {
        height: 60%;
        padding-left: 9%;
        padding-right: 9%;
        padding-top: 2%;
    }
    .headings {
        font-size: 58px;
    }
    a:hover {
        color: #a9a3a3;
    }
    .links {
        font-size: 44px;
    }
    .links1, .links2, .links3 {
        gap: 7rem;
    }
    .googleImages {
        width: 124px;
        height: 124px;
    }
    .scanner {
        width: 300px;
        height: 300px;
    }
    .address {
        font-size: 46px;
    }
}
@media only screen and (max-width: 5464px) and (min-width: 4099px){
    .upperFooter img{
        height: 12rem;
    }
    .buy {
        font-size: 48px;
    }
    .lowerFooter {
        height: 60%;
        padding-left: 9%;
        padding-right: 9%;
        padding-top: 2%;
    }
    .headings {
        font-size: 58px;
    }
    a:hover {
        color: #a9a3a3;
    }
    .links {
        font-size: 44px;
    }
    .links1, .links2, .links3 {
        gap: 7rem;
    }
    .googleImages {
        width: 124px;
        height: 124px;
    }
    .scanner {
        width: 300px;
        height: 300px;
    }
    .address {
        font-size: 46px;
    }
}
@media only screen and (max-width: 4098px) and (min-width: 3842px){
    .upperFooter img{
        height: 12rem;
    }
    .buy {
        font-size: 48px;
    }
    .lowerFooter {
        height: 60%;
        padding-left: 9%;
        padding-right: 9%;
        padding-top: 2%;
    }
    .headings {
        font-size: 58px;
    }
    a:hover {
        color: #a9a3a3;
    }
    .links {
        font-size: 44px;
    }
    .links1, .links2, .links3 {
        gap: 7rem;
    }
    .googleImages {
        width: 124px;
        height: 124px;
    }
    .scanner {
        width: 300px;
        height: 300px;
    }
    .address {
        font-size: 46px;
    }
}
@media only screen and (max-width: 3841px) and (min-width: 2561px){
    .buy {
        font-size: 59px;
    }
    .upperinnerFooterDiv img {
        width: 279px;
    }
    .Footer {
        height: 126vh;
    }
    .upperFooter {
        height: 32%;
    }
    .headings {
        font-size: 61px;
    }
    .links {
        font-size: 50px;
    }
    .links1, .links2, .links3 {
        gap: 11rem;
    }
    .address {
        font-size: 47px;
    }
    .googleImages {
        width: 81px;
        height: 81px;
    }
    .scanner {
        width: 243px;
        height: 243px;
    }
}
@media only screen and (max-width: 3491px) and (min-width: 2733px){
.headings {
    font-size: 50px;
}
.upperinnerFooterDiv img {
    width: 233px;
}
.links {
    font-size: 43px;
}
.buy {
    font-size: 51px;
}
.links1, .links2, .links3 {
    grid-gap: 11rem;
    gap: 8rem;
}
.address {
    font-size: 40px;
}
}
@media only screen and (max-width: 2732px) and (min-width: 2561px){
    .buy {
        font-size: 41px;
    }
    .upperinnerFooterDiv img {
        width: 241px;
    }
    .headings {
        font-size: 36px;
    }
    .links {
        font-size: 35px;
    }
    .links1, .links2, .links3 {
        gap: 7rem;
    }
    .address {
        font-size: 34px;
    }
}
@media only screen and (max-width: 3051px) and (min-width: 2733px){
    .buy {
        font-size: 43px;
    }
    .upperinnerFooterDiv img {
        width: 233px;
    }
    .headings {
        font-size: 49px;
    }
    .links {
        font-size: 39px;
    }
}
@media only screen and (max-width: 2560px) and (min-width: 1921px){
    .upperinnerFooterDiv img {
        width: 241px;
    }
    .buy {
        font-size: 30px;
    }
    .headings {
        font-size: 36px;
    }
    .links {
        font-size: 30px;
    }
    .links1, .links2, .links3 {
        gap: 5rem;
    }
    .googleImages {
        width: 57px;
        height: 57px;
    }
    .scanner {
        width: 159px;
        height: 159px;
    }
    .address {
        font-size: 28px;
    }
    .Footer {
        height: 112vh;
    }
    .upperFooter {
        height: 29%;
    }
}
@media only screen and (max-width: 2300px) and (min-width: 1921px){
    .headings {
        font-size: 32px;
    }
    .links {
        font-size: 26px;
    }
    .address {
        font-size: 25px;
    }
}
@media only screen and (max-width: 2048px) and (min-width: 1921px){
    .upperinnerFooterDiv img {
        width: 241px;
    }
    .buy {
        font-size: 30px;
    }
    .headings {
        font-size: 32px;
    }
    .links {
        font-size: 29px;
    }
    .links1, .links2, .links3 {
        gap: 7rem;
    }
    .googleImages {
        width: 57px;
        height: 57px;
    }
    .scanner {
        width: 159px;
        height: 159px;
    }
    .address {
        font-size: 26px;
    }
    .Footer {
        height: 120vh;
    }
    .upperFooter {
        height: 29%;
    }
}
@media only screen and (max-width: 1920px) and (min-width: 1601px){
    .upperinnerFooterDiv img {
        width: 195px;
    }
    .buy {
        font-size: 23px;
    }
    .headings {
        font-size: 28px;
    }
    .links {
        font-size: 25px;
    }
    .googleImages {
        width: 57px;
        height: 57px;
    }
    .scanner {
        width: 159px;
        height: 159px;
    }
    .address {
        font-size: 22px;
    }
    .links1, .links2, .links3 {
        gap: 4rem;
    }
    .Footer {
        height: 124vh;
    }
    .upperFooter {
        height: 34%;
    }
}
@media only screen and (max-width: 1600px) and (min-width: 1441px){
    .Footer{
        height: 114vh;
    }
    .upperFooter {
        height: 35%;
    }
}
@media only screen and (max-width: 1440px) and (min-width: 1367px){
    .Footer{
        height: 119vh;
    }
    .upperFooter {
        height: 36%;
    }
}
@media only screen and (max-width: 1366px) and (min-width: 1025px){
    .Footer{
        height: 146vh;
    }
    .upperFooter {
        height: 34%;
    }
}
@media only screen and (max-width: 1280px) and (max-height: 1024px) and (max-height: 800px){
    .Footer{
        height: 139vh;
    }
    .upperFooter{
        height: 27%;
    }
}
@media only screen and (max-width: 1280px) and (max-height: 1024px) and (max-height: 1024px){
    .Footer{
        height: 117vh;
    }
}
@media only screen and (max-width: 1024px){
    .Footer{
        height: 123vh;
    }
    .upperFooter {
        height: 33%;
    }
    .address{
        font-size: 14px;
    }
    .lowerFooter {
        height: 60%;
        padding-left: 8%;
        padding-right: 13%;
        padding-top: 5%;
    }
    .links {
        font-size: 16px;
    }

.upperFooter {
    padding-left: 8%;
}
}
@media screen and (max-width: 756px) {
    .headings {
        font-family: MetropolisRegular;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
    }
}
@media screen and (max-width: 676px) {
    .lowerinnerDiv {
      flex-wrap: wrap;
      justify-content: center;
    }
    
    .lowerinnerDiv>.div4 {
      margin-top: 20px; 
    }
    .links {
        font-size: 16px;
    }
}
@media screen and (max-width: 647px) {
    .lowerinnerDiv>.div4 {
      margin-top: 20px; 
    }
}
@media screen and (max-width: 647px) {
    .scanner {
        width: 109px;
        height: 109px;
    }
}
@media screen and (max-width: 620px) {
    .upperinnerFooterDiv img {
        height: 3rem;
    }
    .buy {
        font-size: 14px;
        margin-top: 2.8%;
    }
    .upperFooter {
        height: 22%;
    }
}
@media screen and (max-width: 388px) {
    .scanner {
        width: 83px;
        height: 83px;
    }
    .headings {
        font-size: 17px;
    }
    .upperinnerFooterDiv>img{
        height: 3rem;
    }
    .buy {
        font-size: 12px;
    }
    .upperFooter {
        height: 18%;
    }
}
@media screen and (max-width: 320px){
    .headings {
        font-size: 15px;
    }
}
@media screen and (max-width: 303px) {
    .div3{
        margin-top: 20px;
    }
    .Footer {
        height: 158vh;
    }
    .links3 {
        margin-top: 11%;
    }
    .availableOnSection {
        flex-wrap: wrap;
        justify-content: center;
    }
    .googleImages {
        width: 45px;
        height: 42px;
    }
    .scanner {
        margin-top: 20px;
    }
    .address {
        font-size: 7px;
    }
}
@media screen and (max-width: 206px) {
    .div2{
        margin-top: 20px;
        margin-right: 20px;
    }
    .div3{
        margin-right: 20px;
    }
}