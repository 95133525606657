@font-face {
    font-family: metropolisLight;
    src: url(Metropolis-Light.otf);
}

@font-face {
    font-family: metropolisRegular;
    src: url(Metropolis-Regular.otf);
}


@font-face {
    font-family: metropolisSemiBold;
    src: url(Metropolis-SemiBold.otf);
}

@font-face {
    font-family: metropolisBold;
    src: url(Metropolis-Black.otf);
}

.details-home p {
    margin: 0px !important;
}

.msg {
    font-family: metropolisSemiBold;
}

.breadcrumb>li+li:before {
    color: #979797 !important;
    content: "> " !important;
    font-weight: bold !important;
}

.details-home nav {
    font-family: metropolisRegular;
    padding-left: 35px;
    padding-top: 22px;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 18px;
    display: flex;
    align-items: center;
}

.checkRight {
    margin-left: 25%;
}

.reqPara {
    text-align: center;
    font-family: metropolisRegular;
}

.breadcrumb {
    margin: 0px !important;
}

.breadcrumb-item>a {
    text-decoration: none !important;
    color: #757575 !important;
    font-family: metropolisRegular !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 14px !important;
}

.details-home .active {
    color: #04B23D !important;
}

.data-outer-container {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.data-container {
    width: 93%;
    background-color: #FFFFFF;
    border-radius: 12px 12px 0px 0px;
}

.tabsDiv {
    height: 6rem;
    padding-left: 40px;
    /* border-bottom: 1px solid #F2F2F2; */
    border-bottom: 1px solid #e7e0e0;
    box-shadow: 0px 4px 14px rgba(169, 178, 187, 0.07);
}

.tabsWrapper {
    display: flex;
    height: 100%;
    justify-content: space-between;
    width: 100% !important;
}

.tab {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 10pt !important;
}

.tab>p {
    font-family: metropolisSemiBold;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #979797;
}

.activeTab>p {
    color: #2F2F2F;
    margin-top: 7px !important;
}

.activeTab {
    border-bottom: 5px solid #2F2F2F;
}

.details-outer-div {
    display: flex;
    padding-left: 40px;
    padding-top: 30px;
    gap: 6%;
}

.details-inner-div {
    width: 40%;
    display: flex;
    justify-content: space-between;
}

#detailtable>tr>th>p {
    font-family: metropolisRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #757575;
    text-align: left;
    margin-bottom: 20px !important;
}

#detailtable>tr>td>p {
    font-family: metropolisRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #2F2F2F;
    margin-bottom: 20px !important;
    text-align: left;
    padding-left: 50px;
    width: 90%;

}


.left-properties>p {
    font-family: metropolisRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #757575;
    margin-bottom: 20px !important;
}

.rightValues>p {
    font-family: metropolisRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #2F2F2F;
    margin-bottom: 20px !important;
}

.table-container {
    padding-left: 40px;
    margin-top: 33px;
}

.ecash-document-table tbody,
.ecash-document-table td,
.ecash-document-table tfoot,
.ecash-document-table th,
.ecash-document-table thead,
.ecash-document-table tr {
    /* border: 1px solid #F2F2F2 !important; */

    text-align: start !important;
    padding-left: 27px;
    font-family: metropolisRegular;
}

.ecash-document-table>thead>tr>th {
    background-color: #FEFCFC !important;
}
.details-home .row img{
    height: 20px !important;
}

.remarks {
    max-height: 150px;
    overflow: auto;
}

.ecash-document-table {
    width: 94%;
    border-left: 1px solid #F2F2F2 !important;
    border-right: 1px solid #F2F2F2 !important;
    border-top: 1px solid #F2F2F2 !important;

}

.Column1 {
    width: 20%;
}

.Column2 {
    width: 20%;
}

.document-table-row {
    height: 49px !important;
}

.document-table-row1 {
    background-color: #FEFCFC;
}

.documents-actions-div {
    width: 85%;
    display: flex;
    justify-content: space-between;
}

.limit-css {
    font-family: metropolisRegular !important;
    color: #757575 !important;
}

.select-limit {
    color: #757575 !important;
    font-family: metropolisRegular !important;
}

.edit-download-div {
    display: flex;
    gap: 13px;
}

.pdfIcon {
    margin-right: 10px;
}

.document-name {
    font-size: 12px;
}

.supply-limit-container {
    padding-left: 40px;
    margin-top: 57px;
}

.supply-limit-wrapper {
    width: 63%;
    display: flex;
    justify-content: space-between;
    font-family: metropolisRegular;
}

.supply-limit-div>p,
.available-limit-div>p {
    font-family: metropolisRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #2F2F2F;
    margin-bottom: 11px !important;
}

.supply-limit-div>select,
.update-div>.update-left {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #757575;
}

#limit {
    width: 271px;
    height: 45px;
    color: #757575 !important;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 6px;
    padding-left: 10px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='6'%3E%3Cpath d='M1 1l5 4 5-4' stroke='%23333' fill='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 12px 6px;
    background-position: right 20px center;
}

.modal-header {
    border-bottom: none !important;
}

.modal {
    --bs-modal-margin: 9.75rem;
}

.update-div {
    display: flex;
    width: 274px;
    height: 45px;
}

.update-left {
    width: 60%;
    height: 100%;
    border: 1px solid #EBEBEB;
    border-radius: 6px 0px 0px 6px;
    display: flex;
    align-items: center;
    padding-left: 17px;
}

.update-right {
    width: 40%;
    height: 100%;
    border: 1px solid #EBEBEB;
    background: #D3D3D3;
    border-radius: 0px 6px 6px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    cursor: pointer;
}

.sent-reject-div {
    font-family: metropolisRegular;
}

.discrepent-btn {
    font-family: metropolisRegular;
}

.mid-edit-section {
    display: flex;
    flex-direction: column;
    border: 1px dashed #000000;
    border-radius: 12px;
    box-sizing: border-box;
    border-width: 2px;
    margin: 2px;

}
.mid-edit-section > label{
    height: 230px !important;
    position: absolute !important;
    width: 100% !important;
    border: none !important;
    opacity: 0;
}
.btnSave {
align-items: center;
padding: 12px 24px;
width: 187px;
height: 3 5px;
margin-left: auto;
margin-right: auto;
margin-top: 30px;
background-color: #04B23D ;
border-radius: 8px;
font-size: 11pt !important;
}

.drag-drop {
    margin: 15px;
    color: #2F2F2F;
    font-size: 24px;
}

.your-files {
    margin-bottom: 15px;
    color: #2F2F2F;
    font-size: 14px;
}

.only-jpeg {
    margin-bottom: 32px;
    color: #1147B2;
    font-size: 14px;
}
.editModal {
    text-align: center;
}

.documents-img {
    width: 10%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
}

.footer-btns-container {
    display: flex;
    margin-top: 71px;
    padding-left: 40px;
    justify-content: space-between;
    padding-right: 40px;
    height: 108px;
    background: #FFFFFF;
    align-items: center;
    box-shadow: 0px -4px 8px rgba(92, 138, 152, 0.075);
}

.discrepent-btn {
    width: 169.26px;
    height: 39.94px;
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    font-family: metroPolisRegular;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    background: #FFFFFF;
}

.sent-to-lender-btn {
    width: 169.26px;
    height: 39.94px;
    background: #0DB442;
    border-radius: 8px;
    border: none;
    font-family: metropolisRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
}

.reject-btn {
    width: 122.94px;
    height: 39.94px;
    background: #F11C1C;
    border-radius: 8px;
    border: none;
    font-family: metroPolisRegular;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
}

.reasonText {
    font-family: metropolisRegular;
    font-size: 12pt;
}

.sent-reject-div {
    display: flex;
    gap: 28px;
}

.continue-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 10px;

    position: absolute;
    width: 293px;
    height: 40px;
    left: 690px;
    top: 750px;
    color: #fff;
    border: 1px solid;
    /* Brand/Green
  
  CTAs, Active Links, Primary elements
  */
    background: #0DB442;
    border-radius: 8px;
    margin-left: 140px;
}

.h4-css {
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    font-family: metropolisRegular;
    text-align: left !important;
}
.details-home .modal-header{
    justify-content: left !important;
}

.details-home .modal-body{
    font-size: 11pt !important;
}

.text-css {
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    width: 465px;
    height: 130px;
}

.submit-button {
    width: 516px;
    height: 45px;
    left: 0px;
    position: relative;
    color: #fff;
    border: 1px solid;
    font-family: metropolisRegular !important;
    /* Brand/Green
    CTAs, Active Links, Primary elements
    */
    background: #0DB442
}

.details-home .btn-secondary{
    height: 40px !important;
    margin-top: 10px;
    font-size: 11pt !important;
    
}
.footer-btns-container{
    display: flex;
    flex-direction: row;
}

.footer-btns-container .btn-secondary{
    height: 40px !important;
    margin-top: 0px;
    width: 130px !important;
    font-size: 10pt !important;
    border-radius: 7px;
}


.recommendationLetter{
    background-color: #FFFBE6;
    height: 120px;
    width:634px;
    margin-left: 40px;
    margin-top: 30px;
    border-radius: 15px;
    

}
.msg{
    padding-top:"20px";
}
.recommendationLetter label{
    height: 120px;
    width:634px;
    position: absolute;
    opacity: 0;
}
.recommendationLetter h3{
    padding-top: 20px;
    margin-left: 40px;
    text-align: center;
}
.recLetterText{
    text-align: center;
    padding-top: 20px;
}
.uploadIcon{
    float: left;
    width:150px;
    margin-top:15px
}

#txtRemarks {
    box-sizing: border-box;
    margin-top: 0px;
    width: 510px;
    height: 155px;
    margin-left: 12px;

    /* Primary Palette/White */

    background: #FFFFFF;
    /* Divider */
    padding: 10px;
    outline-color: #D3D3D3;
    border: 1px solid #D3D3D3;
    border-radius: 8px;

}

.modal-close {
    float: right;
    position: absolute;
    outline: none;
    margin-right: -45px;
    border: none;
    margin-top: -24px;
}

.modal-content {

    width: 585px;
    padding: 20px;
    border-radius: 20px;
}

.btnAddMore {

    font-family: metropolisregular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    margin-right: 5px;

    /* identical to box height, or 186% */
    color: #29BD5A;
}

.btn-close {
    right: 50px;
    position: absolute
}
.enhance-limit {
    margin-top: 30px !important;
    margin-left: 250px;
    width: 200px !important;
}