@font-face {
    font-family: MetropolisMedium;
    src: url('Metropolis-Medium.otf')
}

@font-face {
    font-family: MetropolisBold;
    src: url('Metropolis-Bold.otf')
}

@font-face {
    font-family: MetropolisRegular;
    src: url('Metropolis-Regular.otf')
} 


.modal-body{
    padding: 0px !important;
}

.text-css-1 {
    font-family: MetropolisRegular;
}
.grid-item{
    width:49% !important;
    display:inline-block;
    vertical-align: top;
    height:353px !important;
}
.modal-body{
    padding: 0px !important;
}
.crossDiv{
    padding: 1em 1em 1em 2.5em;
    margin-left: 30px;
}
@media only screen and (max-width: 3841px) and (min-width: 1681px){
    .modal-lg, .modal-xl {
        --bs-modal-width: 54vw !important;
    }
    /* .modal-content {
        height: 42vh;
    } */
    .modal-body ,.div-header ,.modal-card ,.grid-container ,.grid-item{
        height: 100% !important;
    }
    .eImage{
        width: 36px !important;
    }
    .mandiImage{
        width: 236px;
    }
    .join-text p{
        font-size: 45px !important;
    }
    .grpPeopleImage{
        width: 23rem;
    }
    .crossP{
        font-size: 36px;
    }
    .crossP img{
        width: 3rem !important;
        height: 3rem !important;
    }
    .callUsHere{
        font-size: 45px !important;
    }
    .number{
        font-size: 54px !important;
    }
    .copyImg{
        width: 5rem;
        margin-bottom: 5%;
        margin-left: 1%;
    }
    .numberImagePara{
        height: 103px;
        margin-top: 30px;
    }
    .orScan{
        font-size: 4rem;
    }
    .scannerCallUs{
        width: 200px;
        height: 200px;
    }
    .orscanFlex{
        gap: 50px !important;
    }
}
@media only screen and (max-width: 2560px) and (min-width: 1681px) and (max-height: 1920px){
    .mandiImage {
        width: 179px;
    }
    .eImage {
        width: 30px !important;
    }
    .imagePara{
        margin-top: 13px !important;
    }
    .rightInnerDiv{
        padding: 6% !important;
    }
    .join-text p {
        font-size: 33px !important;
    }
    .grpPeopleImage {
        width: 21rem;
    }
    .callUsHere {
        font-size: 31px !important;
    }
    .number {
        font-size: 41px !important;
    }
    .copyImg {
        width: 4rem;
    }
    .orScan {
        font-size: 2rem;
    }
    .orscanFlex{
        margin-top: -3em !important;
    }
    .crossP img {
        width: 2rem !important;
        height: 2rem !important;
    }
    /* .modal-content {
        height: 36vh;
    } */
}
@media only screen and (max-width: 2560px) and (min-width: 1681px)and (max-height: 1600px){
    .mandiImage {
        width: 179px;
    }
    .eImage {
        width: 30px !important;
    }
    .imagePara{
        margin-top: 13px !important;
    }
    .rightInnerDiv{
        padding: 6% !important;
    }
    .join-text p {
        font-size: 33px !important;
    }
    .grpPeopleImage {
        width: 21rem;
    }
    .callUsHere {
        font-size: 31px !important;
    }
    .number {
        font-size: 41px !important;
    }
    .copyImg {
        width: 4rem;
    }
    .orScan {
        font-size: 2rem;
    }
    .orscanFlex{
        margin-top: -3em !important;
    }
    .crossP img {
        width: 2rem !important;
        height: 2rem !important;
    }
    /* .modal-content {
        height: 41vh;
    } */
}
@media only screen and (max-width: 2048px) and (min-width: 1921px){
    .modal-lg, .modal-xl {
        --bs-modal-width: 63vw !important;
    }
    /* .modal-content{
        height: 38vh;
    } */
    .mandiImage {
        width: 175px;
    }
    .join-text p {
        font-size: 28px !important;
    }
    .grpPeopleImage {
        width: 14rem;
    }
    .callUsHere {
        font-size: 27px !important;
    }
    .crossDiv{
        margin-left: 7px !important;
    }
    .number {
        font-size: 37px !important;
    }
    .scannerCallUs {
        width: 122px;
        height: 122px;
    }
    .numberImagePara {
        height: 107px;
        margin-top: 19px;
    }
}
@media only screen and (max-width: 1920px) and (min-width: 1681px) and (max-height: 1200px){
    .mandiImage {
        width: 123px;
    }
    .join-text p {
        font-size: 27px !important;
    }
    .grpPeopleImage {
        width: 11rem;
    }
    .imagePara{
        margin-top: 22px !important;
    }
    .rightInnerDiv{
        padding: 3%;
    }
    .callUsHere {
        font-size: 27px !important;
    }
    .number {
        font-size: 31px !important;
    }
    .orscanFlex {
        margin-top: -2em !important;
        gap: 20px !important;
    }
    .copyImg {
        width: 2.5rem;
        margin-bottom: 3%;
    }
    .scannerCallUs {
        width: 122px;
        height: 122px;
    }
    .numberImagePara {
        height: 67px;
        margin-top: 19px;
    }
    /* .modal-content{
        height: 43vh;
    } */
}
@media only screen and (max-width: 1920px) and (min-width: 1681px)and (max-height: 1080px){
    .mandiImage {
        width: 123px;
    }
    .join-text p {
        font-size: 27px !important;
    }
    .grpPeopleImage {
        width: 11rem;
    }
    .imagePara{
        margin-top: 22px !important;
    }
    .rightInnerDiv{
        padding: 3%;
    }
    .callUsHere {
        font-size: 27px !important;
    }
    .number {
        font-size: 31px !important;
    }
    .orscanFlex {
        margin-top: -2em !important;
        gap: 20px !important;
    }
    .copyImg {
        width: 2.5rem;
        margin-bottom: 3%;
    }
    .scannerCallUs {
        width: 122px;
        height: 122px;
    }
    .numberImagePara {
        height: 67px;
        margin-top: 19px;
    }
    /* .modal-content{
        height: 47vh;
    } */
}