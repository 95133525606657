@font-face {
    font-family: metropolis-medium;
    src: url('../assets/Metropolis_Font_family/Metropolis-Medium.otf');
}

@font-face {
    font-family: metropolis-light;
    src: url('../assets/Metropolis_Font_family/Metropolis-Light.otf');
}

@font-face {
    font-family: metropolis-regular;
    src: url('../assets/Metropolis_Font_family/Metropolis-Regular.otf');
}

@font-face {
    font-family: metropolis-semiBold;
    src: url('../assets/Metropolis_Font_family/Metropolis-SemiBold.otf');
}

* {
    box-sizing: border-box;
}

body {
    position: relative;
    margin: 0;
    font-family: metropolis-medium, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    /* max-width: 100vw; */
    min-height: 100vh;
}
.toast-content {
    font-size: initial;
  }

  .Toastify__toast {
    font-size: initial !important;
}

.mainContainer button:not(.section3 button, .section4 button, .section6 button , .eresearch-daily-reports button, .marketPrice-container button , .kyc-div button, .eCashPostReg button) {
    position: relative;
    color: white;
    background-color: rgba(4, 178, 61, 1);
    border-radius: 8px;
    outline: none;
    border: none;
    font-family: metropolis-medium, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.8rem;
    cursor: pointer;
}


.mainContainer {
    position: relative;
    margin: 0;
}

.innerContainer {
    position: relative;
    margin: 0;
}

.section,
.navOuter {
    position: relative;
    display: block;
    /* max-width: 100vw; */
}

.navOuter {
    /* min-width: 100vw; */
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #FEFEFE;
    box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
}

.navInner {
    position: relative;
    padding: 30px 50px;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-items: center;
    align-items: center;
    /* display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly; */
}

.main-logo {
    cursor: pointer;
}

.navInner>div {
    justify-self: center;
}

.navInner>button {
    justify-self: center;
    padding: 14px 22px;
    font-family: Metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 600;
}

.nav-links {
    position: relative;
    padding: 0 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, auto);
    align-items: center;
    justify-items: center;
    font-size: 16px;
}

.nav-links>a,
.nav-links>div {
    color: #2F2F2F;
    text-decoration: none;
    cursor: pointer;
    padding: 0 10px;
    font-family: metropolis-medium, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 400;
}

.nav-links>a {
    transition: color 0.3s ease;
}

.nav-links>a:hover,
.nav-links>div:hover {
    color: #04B23D;
}


.mob-nav>div {
    position: relative;
    transition: all 0.5s ease;
}

.mob-nav>div>div {
    height: 0;
    overflow: hidden;
    background-color: transparent;
    text-align: center;
    transition: all 0.5s ease;
}

.mobOpen>div {
    height: 70px !important;
}

.nav-links>div {
    position: relative;
    transition: all 0.5s ease;
}

.nav-links>div>div {
    position: absolute;
    height: 0;
    overflow: hidden;
    transition: height 1s ease;
    background-color: transparent;
    text-align: center;
    width: 100%;
    left: 0;
}

.nav-links>div>div>div {
    background-color: white;
    margin-top: 20px;
    height: auto;
    width: 100%;
    box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
    border-radius: 8px;
    transition: color 0.3s ease;
    color: rgba(47, 47, 47, 1);
    padding: 24px 0;
}


.nav-links>div>div>div>div:first-of-type {
    height: 1px;
    background: #ECECEC;
    width: 70%;
    margin: 0 auto;
}

.nav-links>div>div>div>p {
    margin: 0;
    padding: 0;
    color: #2F2F2F;
}

.nav-links>div>div>div>p:first-of-type {
    padding-bottom: 16px;
}

.nav-links>div>div>div>p:last-of-type {
    padding-top: 16px;
}

.nav-links>div>div>div>p:hover {
    color: #04B23D;
}

.open>div {
    height: 150px !important;
}

.nav-links>a:last-of-type {
    display: grid;
    grid-template-columns: auto auto;
    justify-items: center;
    align-items: center;
}

.mainContainer .mob-nav>a:last-of-type {
    display: flex;
    align-items: flex-start;
    justify-content: center;

}

.mainContainer  .mob-nav>a:last-of-type>span {
    padding-left: 8px;
}



.nav-links>a:last-of-type>span {
    padding-left: 8px;
    font-weight: 600;
}

.mainContainer .active {
    color: #04B23D !important;
}

/* Overlay */

.tcOverlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(47, 47, 47, 0.5);
    backdrop-filter: blur(3px);
    z-index: 3;
    overflow: auto;
    /* display: none; */
    opacity: 0;
    pointer-events: none;
}

.tcDisplay {
    /* display: block; */
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.3s ease;

}

.tcContainer {
    position: relative;
    margin: 100px 20vw;
    margin-bottom: 0;
    padding: 30px 50px;
    background-color: white;
    border-radius: 12px;
}

.tcContainer>svg {
    display: block;
    margin: 20px 0 0 auto;
    cursor: pointer;
}

.tcContainer h3 {
    color: #2F2F2F;
    text-align: center;
    padding: 0px 0 20px 0;
    font-size: 18px;
    font-weight: 600;
}

.tcContainer>div:first-of-type {
    position: relative;
    color: #2F2F2F;
    font-family: metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    max-height: 250px;
    overflow: auto;
    margin: 30px 0 32px 0;
    line-height: 20px;
}



.tcContainer>div:last-of-type {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 12px;

    -webkit-box-shadow: inset 0px -200px 57px -3px rgba(255, 255, 255, 1);
    -moz-box-shadow: inset 0px -200px 57px -3px rgba(255, 255, 255, 1);

    /* Disable click events */
    pointer-events: none;
    box-shadow: inset 0px -200px 57px -3px rgb(255, 255, 255);

}

.tcContainer>button {
    position: relative;
    display: block;
    padding: 12px 100px;
    font-size: 18px;
    margin: 20px auto;
    margin-top: 10px;
    font-family: metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
}


.chatContent {
    position: relative;
    padding-left: 20px;
}

.toaster {
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    background: #3F3B3B;
    box-shadow: 0px 4px 12px rgba(64, 82, 88, 0.12);
    border-radius: 8px;
    padding: 12px 30px;
    font-family: metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
    opacity: 0;
    animation: popup 3s ease;
    z-index: 3;
    width: max-content;
}


@keyframes popup {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}



.chatContent>p {
    font-size: 14px;
    color: #2F2F2F;
    font-weight: 600;
    margin: 24px 0;
}

.chatContent>div:first-of-type {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #ECECEC;
    padding: 8px;
    width: fit-content;
    color: #2F2F2F;
    cursor: pointer;
    font-size: 18px;
}

.chatContent>div:first-of-type>svg {
    margin-left: 60px;
}

.chatContent>div:last-of-type {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 36px 0;
    margin-bottom: 20px;
}

.chatContent>div:last-of-type>img {
    max-width: 120px;
    margin-left: 24px;
}

.loginOverlay,
.chatOverlay, .disclaimerOverlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(47, 47, 47, 0.5);
    backdrop-filter: blur(3px);
    z-index: 11;
    overflow: auto;
    opacity: 0;
    pointer-events: none;
}

.loginDisplay,
.chatDisplay, .disclaimerDisplay {
    /* display: block; */
    opacity: 1;
    transition: opacity 0.3s ease;
    pointer-events: all;
}
.disclaimerDisplay {
    display: flex;
    align-items: center;
    justify-content: center;
}
.disclaimerModalDisplay{
    display: flex !important;
}

.loginContainer {
    position: relative;
    margin: 160px 25vw;
    margin-bottom: 0;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: flex-start;
    /* justify-items: center; */
    background-color: white;
    border-radius: 12px;
}

.loginLeft {
    position: relative;
    background: url('./img/loginBackground.svg') center center no-repeat no-repeat;
    padding: 70px 80px;
    padding-bottom: 100px;
    background-size: cover;
    border-radius: 12px 0 0 12px;
    height: 100%;
    width: 100%;
}

.loginLeft>p:first-of-type {
    margin-top: 50px;
}

.loginLeft>p {
    font-size: 20px;
    margin: 10px 0;
    font-weight: 600;
}

.loginLeft>p>span {
    color: #04B23D;
}

.loginLeft>img {
    margin-top: 10px;
    max-width: 70px;
}

.loginRight {
    position: relative;
    padding: 30px 30px;
    padding-bottom: 70px;
    height: 100%;
    width: 100%;
}

.loginRight>div:first-of-type {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    justify-items: right;
    align-items: center;
    margin-bottom: 10px;
}

.loginRight>div:first-of-type>svg:first-of-type {
    opacity: 0;
    pointer-events: none;
    justify-self: left;
    margin-left: 20px;
    transition: opacity 0.5s ease-in-out;
}


.loginRight>div:first-of-type>svg {
    cursor: pointer;
}

.processContainer {
    display: grid;
    grid-template-columns: 100% 100%;
    overflow: hidden;
}

.processContainer>div {
    padding: 20px;
    transition: transform 0.5s ease-in-out;
    will-change: transform;
}

.otpActive>.processContainer>div {
    transform: translateX(-100%);
}

.otpActive>div:first-of-type>svg:first-of-type {
    opacity: 1;
    pointer-events: all;
}

.numberContainer>p:first-of-type {
    font-size: 14px;
    margin: 16px 0;
    font-weight: 600;
}

.numberContainer>div:first-of-type {
    padding: 16px 24px;
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    font-size: 16px;
    color: #979797;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 40px;
}

.numberContainer>div:first-of-type>span {
    padding-right: 10px;
    border-right: 1px #979797 solid;
}

.numberContainer>div:first-of-type>input {
    
    border: none;
    font-size: 16px !important;
    outline: none;
    width: 100%;
    font-family: metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-bottom: unset !important;
    border-radius: unset !important;
    padding: unset !important;
    padding-left: 10px !important;

}

.numberContainer>p>a {
    cursor: pointer;
}

.numberContainer>button,
.otpContainer>button {
    font-size: 18px;
    width: 100%;
    padding: 16px 0;
    font-family: Metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
}

.numberContainer>p:last-of-type {
    font-size: 12px;
    font-weight: 100;
    color: #979797;
}

.numberContainer>p:last-of-type>a {
    text-decoration: none;
    color: #2F2F2F;
}

.otpContainer>p:first-of-type {
    font-size: 14px;
    color: #2F2F2F;
    margin: 16px 0;
}

.otpContainer>p:last-of-type {
    font-size: 11px;
    color: #2F2F2F;
}

.otpContainer>p:last-of-type>span:first-of-type {
    font-size: 12px;
    color: #2F2F2F;
    padding: 0 10px;
}

.otpContainer>p:last-of-type>span:last-of-type {
    color: #04B23D;
    font-size: 14px;
    font-size: 600;
    cursor: pointer;
}

.otpContainer>div {
    margin-bottom: 42px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-flow: wrap;
}

.otpContainer>div>input {
    width: 48px;
    height: 50px;
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    font-size: 18px;
    margin-right: 16px;
    text-align: center;
    outline: none;
}

.otpContainer>div>input:last-of-type {
    margin-right: 0;
}

.otpContainer>div>input::placeholder {
    text-align: center;
    color: #757575;
}

.otpContainer>div>input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    text-align: center;
    color: #757575;

}

.otpContainer>div>input::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
    color: #757575;
}

.otpContainer>div>input:-ms-input-placeholder {
    /* IE 10+ */
    text-align: center;
    color: #757575;
}

.otpContainer>div>input:-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
    color: #757575;
}



/* section 1 */

.sectionBg {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
    background: url('/src/pre-reg/img/section1Bg.png') no-repeat no-repeat center center;
    background-size: cover;
    margin-top: -50px;
}

.sectionBg .section1 {
    position: relative;
    margin: 0;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    justify-items: center;
    align-items: center;
    color: white;
    text-align: center;
    font-family: Metropolis-medium, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.sectionBg .section1Inner {
    position: relative;
    display: block;
    padding: 130px 0;
    letter-spacing: 1px;
}

.sectionBg .section1Inner>h1 {
    max-width: 90vw;
    margin: 15px auto;
    font-size: 44px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.674);
    font-weight: 500;
    text-align: center;
    color: white;
}

.sectionBg .section1Inner>h3 {
    text-align: center;
    max-width: 90vw;
    font-size: 24px;
    padding: 0;
    /* padding-bottom: 50px; */
    font-weight: 500;
    margin: 25px auto;
}

.sectionBg .section1Inner>div {
    position: relative;
    display: grid;
    grid-template-columns: 55% 45%;
    width: 46%;
    margin: 0 auto;
}

.sectionBg .section1Inner input {
    outline: none;
    height: 100%;
    border-radius: 8px 0 0 8px !important;
    border: none !important;
    font-size: 18px !important;
    padding: 0 10px !important;
    font-family: Metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: black;
    margin-bottom: unset !important;
}

.sectionBg .section1Inner>div>div:first-of-type {
    padding: 16px 24px;
    padding-right: 0;
    border-radius: 8px 0 0 8px;
    font-size: 18px;
    color: #979797;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.sectionBg .section1Inner>div>div:first-of-type>span {
    padding-right: 5px;
    border-right: 1px #979797 solid;
    color: #B6B6B6;

}

.sectionBg .section1Inner>div>div:first-of-type>input {
    border: none;
    outline: none;
    width: 160px;
    padding-left: 10px;
}


.sectionBg .section1Inner input::placeholder {
    color: #B6B6B6;
}

.sectionBg .section1Inner input:-ms-input-placeholder {
    color: #B6B6B6;
}

.sectionBg .section1Inner input::-webkit-input-placeholder {
    color: #B6B6B6;

}

.sectionBg .section1Inner input::-moz-placeholder {
    color: #B6B6B6;
}

.sectionBg .section1Inner input:-ms-input-placeholder {
    color: #B6B6B6;
}

.sectionBg .section1Inner input:-moz-placeholder {
    color: #B6B6B6;
}
.sub-sub-text{
    padding-bottom: 50px;
    font-size: 16px;
}
.sectionBg .section1Inner>div>button {
    padding: 16px 0px;
    font-size: 24px;
    border-radius: 0 8px 8px 0;
    font-family: Metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 600;
    margin: 0;
}



.sectionBg .section1Inner>h1>span {
    color: rgba(4, 178, 61, 1);
}



/* Section 2 */

.section2 {
    position: relative;
    display: block;
    margin: 0;
}

.section2Inner {
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
}


.section2 .eCash,
.section2 .eHedge { 
    position: relative;
    padding: 60px 0;
    padding-right: 60px;
    display: grid;
    grid-template-columns: 100%;
    justify-items: right;
    padding-left: 18vw;
}

.eResearch,
.eSpot {
    position: relative;
    padding: 60px 0;
    display: grid;
    grid-template-columns: 100%;
    justify-items: left;
    padding-left: 60px;
    padding-right: 18vw;
}

.section2 .eCash,
.section2 .eSpot {
    background: rgba(253, 233, 108, 0.15);
}

.eContent {
    cursor: pointer;
}

.eContent>div:first-of-type {
    position: relative;
    display: flex;
    align-items: center;
}

.eContent>div:first-of-type>p {
    margin: 0;
    margin-left: 8px;
    font-size: 12px;
    color: #757575;
}

.dent {
    position: relative;
    width: 4px;
    height: 20px;
    background: #FDE96C;
    border-radius: 0px 4px 4px 0px;
}

.eGrid {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.eContent {
    position: relative;
    width: 100%;
}

.eGrid h1 {
    font-size: 2.7rem;
    text-align: unset;
    color: unset;
    padding: unset;
    margin: unset;
    margin: 22px 0;
    font-weight: bold;
}

.eGrid>div:first-of-type>div {
    display: flex;
    align-items: flex-end;
    height: 50px;
}

.eGrid>div:first-of-type>div>svg {
    margin-right: 10px;
}

.eGrid img {
    max-width: 33px;
    margin-right: 10px;
}

.eGrid>div:last-of-type>button {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    padding: 0;
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    justify-items: center;
}

.eResearch {
    background: url('./img/Stonks.svg') 80% bottom no-repeat no-repeat;
}

.eGrid>div>h1>svg {
    position: relative;
    margin-right: 3px;
}

.eGrid p {
    max-width: 70%;
    color: #2F2F2F;
    font-size: 15px;
}


/* Section 3 */

.section3 {
    position: relative;
    display: block;
    background: rgba(255, 249, 244, 1);
}

.section3Inner {
    position: relative;
    padding: 100px 0;
}

.section3Inner>h1 {
    text-align: center;
    color: #2F2F2F;
    font-size: 32px;
    /* font-weight: unset; */
    padding: unset;
    margin: 20px 0px;
}

.client {
    position: relative;
    height: 120px;
    width: 120px;
    background-color: white;
    border-radius: 100%;
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    justify-items: center;
    margin: 0 auto;
    transition: all 0.3s ease;
    box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
}
.ourClients-subheading{
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: #2F2F2F;
    font-family: metropolis-medium;
}
.client>img {
    max-height: 70px;
    max-width: 70px;
}

.glide__slide--active>.client {
    transform: scale(1.1);
}

.glide__track {
    width: 70%;
    margin: 0 auto;
}

.glide__arrow--left {
    position: absolute;
    left: 5%;
    top: 41% !important;
    transform: translate(-50%, -50%);
}

.glide__arrow--right {
    position: absolute;
    left: 95%;
    top: 41% !important;
    transform: translate(-50%, -50%);
}

.glide__arrow {
    display: grid !important;
    grid-template-columns: 100% !important;
    align-items: center !important;
    justify-items: center !important;
    height: 43px !important;
    width: 43px !important;
    background-color: white !important;
    border-radius: 100% !important;
    box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075) !important;
    border: none !important;
    z-index: unset !important;
    transform: unset !important;
}
.glide__arrow--left {
    left: 4em !important; 
}

.glide__slides {
    padding: 40px 0 !important;
}


/* Section 4  */

 .section4 {
    position: relative;
    display: block;
}

.section4Inner {
    position: relative;
    padding: 100px 0;
}

.section4Inner>h1 {
    text-align: center;
    color: rgba(47, 47, 47, 1);
    margin-bottom: 20px;
    font-size: 32px;
}

.chain {
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    justify-items: center;
    margin: 0 auto;
    transition: all 0.3s ease;
}

.chain>img {
    border-radius: 10px;
    margin-bottom: 14px;
    max-height: 95px;
    max-width: 95px;
    width: 95px;
    height: 95px;
}

.chain>span {
    font-size: 2rem;
    text-align: center;
} 

/* 
.glide__slide--active>.chain {
    transform: scale(1.1);
} */

/* Section 5  */

.section5 {
    position: relative;
    display: block;
    background: rgba(255, 249, 244, 1);
}

.section5Inner {
    position: relative;
    padding: 100px 0;
}

.section5Inner>h1 {
    text-align: center;
    color: rgba(47, 47, 47, 1);
    margin-bottom: 20px;
    font-size: 32px;
}

.partnersGrid {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    width: fit-content;
    margin: 0 auto;
    padding: 30px 0;
}

.partner {
    position: relative;
    height: 120px;
    width: 120px;
    background-color: white;
    border-radius: 100%;
    box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    justify-items: center;
    margin: 10px 30px;
}

.partner>img {
    max-width: 90px;
    max-height: 90px;
}

/* Section 6 */

.section6 {
    position: relative;
    display: block;
}

.section6Inner {
    position: relative;
    padding: 100px 0;
    padding-bottom: 200px;
}

.section6Inner>h1 {
    text-align: center;
    color: #2F2F2F;
    margin-bottom: 60px;
    font-size: 32px;
}

.section6Inner>h1>span {
    color: rgba(4, 178, 61, 1);
}

.glide3 .glide__track {
    width: 100%;
}

.glide3 .card {
    position: relative;
    display: block;
    box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
    border-radius: 16px;
    padding: 50px 70px;
    transition: all 0.3s ease;
    transform-origin: center;
    border: none;
}
.person {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    justify-items: left;
    align-items: center;
    width: fit-content;
}

.person>div:first-of-type {
    position: relative;
    margin-right: 10px;
    overflow: hidden;
    max-height: 75px;
    max-width: 75px;
    width: 75px;
    height: 75px;
}

.person>div:first-of-type>img {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.person>div:last-of-type {
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    justify-items: left;
    height: 80%;
}

.loc {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    justify-items: left;
    align-items: center;
    color: rgba(117, 117, 117, 1);
}

.loc>span {
    margin-left: 8px;
    transform: translate(0, 1px);
}

.glide3 .card>p {
    margin: 0;
    padding: 28px 0;
    color: #2F2F2F;
    font-family: metropolis-regular, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
}

.glide__slide--active>.card {
    transform: scale(1.1);
}



.glide__slide:not(.glide__slide--active) p:first-of-type {
    color: #757575;
}

.glide3 .glide__arrow {
    top: -14% !important;
}
.glide3{
    font-size: initial !important;
}


/* Section 7 */

/* .section7 {
    position: relative;
    display: block;
    background: #312B25;
}

.section7Inner {
    position: relative;
    display: block;
    padding: 50px 150px;
    color: white;
    font-weight: bold;
    font-family: metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.footerUpper {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    padding: 30px 0;
    padding-bottom: 70px;
    border-bottom: 1px solid #413F3E;
}

.footerUpper>div:first-of-type>p {
    position: relative;
    margin: 20px 0;
}

.footerUpper>div:last-of-type {
    position: relative;
    justify-self: right;
}

.footerLower {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    padding: 30px 0;
    padding-top: 70px;
}


.aboutApps {
    position: relative;
    justify-self: right;
}

.aboutApps>div:first-of-type {
    position: relative;
    display: grid;
    grid-template-columns: auto auto auto auto;
    align-items: flex-start;
    justify-items: center;
    width: fit-content;
}

.aboutApps>div:first-of-type>img {
    margin: 0 10px;
}

.aboutApps>div:first-of-type>img:first-of-type {
    max-width: 32px;
    max-height: 32px;
}

.aboutApps>div:first-of-type>img:nth-of-type(2) {
    max-width: 32px;
    max-height: 32px;
}

.aboutApps>div:first-of-type>img:last-of-type {
    max-width: 150px;
    max-height: 150px;
}

.aboutApps>div:first-of-type>span {
    transform: translate(0, 7px);
    padding: 0 10px;
}

.aboutApps>div:last-of-type {
    position: relative;
    margin-top: 60px;
    color: #979797;
    display: flex;
    align-items: center;
}

.aboutApps>div:last-of-type>svg {
    align-self: flex-start;
    margin-right: 10px;
}

.aboutLinks {
    position: relative;
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: flex-start;
    justify-items: left;
}

.aboutLinks>div>h3 {
    position: relative;
    margin-top: 0;
    margin-bottom: 35px;
}

.aboutLinks>div>a {
    position: relative;
    display: block;
    margin: 20px 0;
    color: #D3D3D3;
    font-weight: 100;
    text-decoration: none;
    cursor: pointer;
}

.aboutLinks>div>a:hover {
    color: #04B23D;
}

.cgp {
    max-width: 80%;
    text-decoration: none;
}

.footerUpper>div:last-of-type>h1 {
    font-weight: 500;
    font-size: 24px;
    margin: 30px 0;
    margin-top: 0;
}

.footerUpper>div:last-of-type>div {
    position: relative;
    display: grid;
    grid-template-columns: 60% 40%;
}

.footerUpper>div:last-of-type>div>input {
    outline: none;
    height: 100%;
    border-radius: 8px 0 0 8px;
    border: none;
    font-size: 0.9rem;
    padding: 0 33px;
    font-family: Metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: black;
    text-align: left;
}

.footerUpper>div:last-of-type>div>input::placeholder {
    text-align: center;
    color: #757575;
}

.footerUpper>div:last-of-type>div>input:-ms-input-placeholder {
    text-align: center;
    color: #757575;
}

.footerUpper>div:last-of-type>div>input::-webkit-input-placeholder {
    text-align: center;
    color: #757575;

}

.footerUpper>div:last-of-type>div>input::-moz-placeholder {
    text-align: center;
    color: #757575;
}

.footerUpper>div:last-of-type>div>input:-ms-input-placeholder {
    text-align: center;
    color: #757575;
}

.footerUpper>div:last-of-type>div>input:-moz-placeholder {
    text-align: center;
    color: #757575;
}

.footerUpper>div:last-of-type>div>button {
    padding: 15px 24px;
    font-size: 1.1rem;
    border-radius: 0 8px 8px 0;
    font-family: Metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-family: 400;
} */

@media(min-width: 2000px) {
    .loginContainer {
        margin: 160px 27vw;
    }
}

@media(min-width: 2400px) {
    .loginContainer {
        margin: 160px 30vw;
    }
}


@media(min-width: 2600px) {

    .loginContainer {
        margin: 160px 32vw;
    }

    .section7Inner {
        padding: 50px 15vw;
    }
    .aboutUs .section1, .eCash > .section1{
        padding: 80px 15vw;
    }

    .eHedge > .section1 .comingSoonContainer{
        padding: 44px 15vw;
        padding-bottom: 150px;
    }

    .section7Inner{
        padding: 50px 15vw;
    }

    .eCashLeft{
        justify-self: right;
        padding: 0;
        padding-right: 250px;
    }

    .eCashRight{
        justify-self: left;
        padding: 0;
        padding-left: 250px;
    }

    .loginContainer{
        margin: 160px 32vw;
    }
}

@media(min-width: 3000px) {
    .loginContainer {
        margin: 160px 35vw;
    }
}

@media(min-width: 3400px) {
    .loginContainer {
        margin: 160px 37vw;
    }
}


@media(min-width: 3800px) {

    .section7Inner {
        padding: 50px 13vw;
    }
}

@media(min-width: 5500px) {
    .loginContainer {
        margin: 160px 41vw;
    }
}


@media(min-width: 5700px) {

    .section7Inner {
        padding: 50px 20vw;
    }

    .navInner {
        padding: 30px 10vw;
    }
}

@media(min-width: 7000px) {

    .loginContainer {
        margin: 160px 43vw;
    }

    .section7Inner {
        padding: 50px 20vw;
    }
}




@media(max-width: 1533px) {
    .chain>span {
        font-size: 1.8rem;
        text-align: center;
    }

    .loginContainer {
        margin: 160px 20vw;
        margin-bottom: 0;
    }
}

@media(max-width: 1319px) and (min-width: 1232px) {
    .chain>span {
        font-size: 1.7rem;
        text-align: center;
    }
}


@media(max-width: 1237px) {
    .loginContainer {
        margin: 160px 15vw;
        margin-bottom: 0;
    }

    .tcContainer {
        margin: 100px 15vw;
        margin-bottom: 0;
    }
}


@media(max-width: 1200px) {

    .section2 .eCash,
    .section2 .eHedge {
        padding-left: 15vw;
    }

    .eResearch,
    .eSpot {
        padding-right: 15vw;
    }

    .eGrid p {
        max-width: 90%;
    }


    .section2 .eHedge {
        background-color: rgba(253, 233, 108, 0.15);
    }

    .eSpot {
        background-color: white !important;
    }

    .section7Inner {
        padding: 50px 70px;
    }

}
@media(max-width: 1049px) {
    .loginContainer {
        margin: 160px 10vw;
        margin-bottom: 0;
    }

    .loginLeft {
        padding: 50px 40px;
        padding-bottom: 50px;
    }

    .loginRight {
        padding: 20px;
        padding-bottom: 30px;
    }

    .loginLeft>svg {
        margin-top: 20px;
    }

    .loginLeft>p:first-of-type {
        margin-top: 30px;
    }
    .tcContainer {
        margin: 100px 10vw;
        margin-bottom: 0;
    }
}


@media(max-width: 1048px) and (min-width: 1000px) {
    .chain>span {
        font-size: 1.7rem;
        text-align: center;
    }
}


@media(max-width: 1025px) {

    .nav-links>a,
    .nav-links>div {
        font-size: 1.6rem;
    }
}


@media(max-width: 990px) {

    .nav-links>a,
    .nav-links>div {
        font-size: 1.3rem;
    }

    .footerUpper {
        grid-template-columns: 100%;
        justify-items: center;
    }

    .footerUpper>div:last-of-type {
        justify-self: center;
    }

    .footerUpper>div:first-of-type {
        margin-bottom: 40px;
    }

    .footerUpper>div:first-of-type>svg {
        display: block;
        margin: 0 auto;
    }

    .footerUpper>div:first-of-type>p {
        text-align: center;
    }

    .footerUpper>div:last-of-type>h1 {
        text-align: center;
    }

    .footerLower {
        display: block;
    }

    .aboutLinks {
        justify-items: center;
        margin-bottom: 50px;
        text-align: center;
    }

    .aboutApps>div:first-of-type {
        width: 100%;
    }

    .aboutApps>div:last-of-type {
        text-align: center;
        justify-content: center;
    }

    .cgp {
        max-width: 80%;
        margin: 20px auto !important;
        text-decoration: none;
    }
}


@media(max-width: 976px) {

    .section2 .eCash,
    .section2 .eHedge {
        padding-left: 13vw;
    }

    .eResearch,
    .eSpot {
        padding-right: 13vw;
    }
}

@media(max-width: 914px) {

    .section2 .eCash,
    .section2 .eHedge {
        padding-left: 12vw;
    }

    .eResearch,
    .eSpot {
        padding-right: 12vw;
    }
}

.hamContainer {
    display: none;
}

.mob-nav {
    display: none;
}

@media(max-width: 903px) {

    .section2Inner {
        display: block;
    }

    .register {
        display: none;
    }

    .nav-links {
        display: none;
    }

    .navInner {
        grid-template-columns: auto auto;
        justify-items: left;
    }

    .navInner>.hamContainer {
        justify-self: right;
    }

    .mainContainer  .hamContainer {
        display: block;
    }

    .mainContainer  .checkbox {
        position: absolute;
        display: block;
        height: 32px;
        width: 32px;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        user-select: none;
        -webkit-touch-callout: none
    }


    .mainContainer  .hamburger-lines {
        display: block;
        height: 26px;
        width: 32px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .mainContainer .hamburger-lines .line {
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #04B23D;
    }

    .mainContainer .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.4s ease-in-out;
    }

    .mainContainer .hamburger-lines .line2 {
        transition: transform 0.2s ease-in-out;
    }

    .mainContainer .hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.4s ease-in-out;
    }

    .mainContainer .mob-nav {
        position: fixed;
        padding: 120px 0;
        left: 100%;
        display: block;
        transform: translate(0);
        transition: transform 0.5s ease-in-out;
        text-align: center;
        background-color: #2F2F2F;
        min-width: 40vw;
        max-width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        font-family: metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-weight: 600;
        top: 0px;
    }

    .mainContainer .mob-nav>a,
    .mainContainer .mob-nav>div,
    .mainContainer .mob-nav>button {
        display: block;
        color: white;
        text-decoration: none;
        margin: 0px 20px;    
        font-size: 16px;
    }
    .mainContainer .mob-nav>button {
        font-size: 17px;
    }

    .mainContainer .mob-nav>button {
        padding: 12px 24px;
        height: max-content;
        font-family: metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-weight: 600;
    }



    .mainContainer .navOuter input[type="checkbox"]:checked~.hamburger-lines .line1 {
        transform: rotate(45deg);
    }

    .mainContainer .navOuter input[type="checkbox"]:checked~.hamburger-lines .line2 {
        transform: scaleY(0);
    }

    .mainContainer .navOuter input[type="checkbox"]:checked~.hamburger-lines .line3 {
        transform: rotate(-45deg);
    }

    .show-nav-side {
        transform: translateX(-100%) !important;
    }
}

@media(max-width: 903px) and (max-height: 631px) {
    .mainContainer .mob-nav {
        padding: 20px;
    }
}




@media(max-width: 903px) and (max-height: 496px) {

    .mainContainer .mob-nav {
        display: flex;
        justify-content: space-around;
        padding: 100px 40px;
        width: 100vw;
        max-width: 100vw;
        flex-flow: wrap;
        height: auto;
        top: 0px;
    }

    .mainContainer .mob-nav>a,
    .mainContainer .mob-nav>div,
    .mainContainer .mob-nav>button {
        margin: 10px;
    }
}

@media(max-width: 903px) and (max-height: 242px) {
    .innerContainer {
        overflow: hidden;
    }

    .navOuter {
        position: relative;
    }

    .mainContainer .mob-nav {
        position: absolute;
    }
}


@media(max-width: 800px) and (min-width: 774px) {
    .otpContainer>div>input {
        height: 40px;
        width: 38px;
        font-size: 16px;
    }

    .otpContainer>div {
        margin-bottom: 40px;
    }
}

@media(max-width: 711px) and (min-width: 665px) {
    .otpContainer>div>input {
        height: 40px;
        width: 38px;
        font-size: 16px;
    }
}


@media(max-width: 356px) {
    .otpContainer>div {
        justify-content: flex-start;
        align-items: center;
    }

    .otpContainer>div>input {
        height: 40px;
        width: 38px;
        font-size: 16px;
        margin-right: 5px;
    }
}



@media(max-width: 800px) {
    .navInner {
        padding: 30px 20px;
    }
}

@media(max-width: 775px) {
    .loginContainer {
        margin: 160px 5vw;
        margin-bottom: 0;
    }

    .tcContainer {
        margin: 60px 5vw;
        margin-bottom: 0;
    }

    .tcContainer>div:first-of-type {
        max-height: 300px;
    }
}




@media(max-width: 750px) and (min-width: 590px) {
    .person {
        display: block;
        text-align: center;
        width: fit-content;
        margin: 0 auto;
    }

    .person>div:last-of-type {
        margin: 15px 0;
    }

    .glide3 .card {
        padding: 50px 30px;
    }

    .loc {
        margin: 5px auto;
    }
}

@media(max-width: 700px) {

    .section2 .eCash,
    .section2 .eSpot,
    .section2 .eHedge,
    .section2 .eResearch {
        justify-items: center;
    }

    .chain>span {
        font-size: 1.7rem;
    }

    .eResearch {
        background: url('./img/Stonks.svg') 100% bottom no-repeat no-repeat;
    }

    .section7Inner {
        padding: 50px;
    }

}

@media(max-width: 775px) and (min-width: 470px) {
    .chain>span {
        font-size: 1.8rem;
        text-align: center;
    }
}

@media(min-width: 665px) and (max-height: 650px) {
    .tcContainer {
        margin: 90px 10vw;
        margin-bottom: 0;
    }

    .tcContainer h3 {
        padding: 0;
        margin: 10px 0;
    }

    .tcContainer>div:first-of-type {
        max-height: 180px;
    }
}


@media(min-width: 665px) and (max-height: 570px) {

    .toaster {
        top: 50px;
    }

    .loginContainer {
        margin: 100px 10vw;
        margin-bottom: 0;
    }

    .loginLeft {
        padding: 30px 40px;
        padding-bottom: 20px;
    }

    .loginRight {
        padding: 20px;
        padding-bottom: 30px;
    }

    .otpContainer>div>input {
        height: 40px;
        width: 38px;
        margin-right: 10px;
    }

}

@media(min-width: 665px) and (max-height: 534px) {
    .tcContainer {
        margin: 50px 10vw;
        margin-bottom: 0;
    }

    .tcContainer h3 {
        padding: 0;
        margin: 10px 0;
    }

    .tcContainer>div:first-of-type {
        max-height: 180px;
    }
}



@media(min-width: 665px) and (max-height: 508px) {

    .toaster {
        top: initial;
        bottom: 50px;
    }

    .loginContainer {
        margin: 30px 10vw;
    }


    .loginLeft {
        padding: 20px 40px;
        padding-bottom: 20px;
    }

    .loginRight {
        padding: 20px;
        padding-bottom: 10px;
    }

    .loginRight>svg {
        margin: 10px 10px 0 auto;
    }

    .tcContainer {
        margin: 50px 10vw;
        margin-bottom: 0;
    }

    .tcContainer h3 {
        padding: 0;
        margin: 10px 0;
    }

    .tcContainer>div:last-of-type {
        box-shadow: none;
    }

    .tcContainer>div:first-of-type {
        max-height: 100px;
    }

    .tcContainer>button {
        margin: 0 auto;
    }
}

@media(max-width: 665px) and (max-height: 733px) {
    .loginLeft {
        padding: 10px 40px;
        padding-bottom: 20px;
    }

    .loginRight {
        padding: 10px;
        padding-bottom: 10px;
    }

    .loginRight>svg {
        margin: 10px 10px 0 auto;
    }

    .processContainer>div {
        padding: 10px 20px;
    }

}




@media(max-width: 665px) {

    .toaster {
        top: initial;
        bottom: 70px;
    }

    .loginContainer {
        margin: 50px 5vw;
        display: flex;
        flex-direction: column-reverse;
    }

    .loginLeft {
        border-radius: 0 0 12px 12px;
    }

    .chatContent {
        width: fit-content;
        margin: 0 auto;
        padding: 0;
    }

}

@media(max-width: 625px) {
    .sectionBg .section1Inner>div>button {
        font-size: 1.6rem;
    }
}

@media(max-width: 600px) {
    .sectionBg .section1Inner>div>button {
        font-size: 1.6rem;
    }

    .sectionBg .section1Inner>div {
        width: 90%;
    }

    .section7Inner {
        padding: 50px 20px;
    }

    .aboutApps>div:last-of-type {
        flex-direction: column;
    }

    .aboutApps>div:last-of-type>svg {
        align-self: center;
        margin-bottom: 15px;
        height: 22px;
        width: 26px;
    }

    .loginContainer {
        margin: 40px 5vw;
    }

    .chatOverlay>.loginContainer {
        margin: 35px 5vw;
    }

    .numberContainer>div:first-of-type {
        margin-bottom: 20px;
    }

    .otpContainer>div {
        margin-bottom: 22px;
    }
}

@media(max-width: 665px) and (min-width:444px) and (min-height: 570px) {
    .loginContainer {
        margin: 50px 10vw;
    }
}


@media(max-width: 590px) {
    .glide__track {
        width: 100%;
    }

    .glide3 .card {
        width: 80%;
        margin: 0 auto;
        padding: 50px 20px;
    }

    .glide__slide--active>.card {
        transform: none;
    }

    .section2 .eCash,
    .section2 .eSpot,
    .section2 .eHedge,
    .section2 .eResearch{
        padding: 60px;
    }

    .sectionBg .section1Inner>div>input {
        font-size: 16px;
    }

}


@media(max-width: 645px) and (min-width: 580px) {
    .chain>span {
        font-size: 1.8rem;
        text-align: center;
    }
}


@media(max-width: 540px) {
    .mainContainer .mob-nav {
        min-width: 50vw;
    }

    .glide__slides {
        padding: 70px 0;
    }

}

.glide__bullets {
    position: unset !important;
    z-index: unset !important;
    bottom: unset !important;
    left: unset !important;
    display: flex !important;
    list-style: unset !important;
    transform: unset !important;
}
.glide__bullets {
    display: none !important;
}


@media(max-width: 497px) and (min-width: 470px) {
    .chain>span {
        font-size: 1.8rem;
        text-align: center;
    }
}

@media(max-width: 470px) {

    .glide__arrows {
        display: none;
    }

    .glide__bullets {
        display: block !important;
    }

    .glide__bullets {
        margin: 0 auto;
        width: fit-content;
    }
    .glide__bullet {
        position: relative;
        height: 15px;
        width: 15px;
        /* min-width: 15px; */
        padding: 0;
        /* padding: 15px; */
        border-radius: 100%;
        background-color: rgba(117, 117, 117, 0.303);
        transition: all 0.1s ease;
    }

    .glide__bullet--active {
        background-color: #04B23D !important;
    }

    .sectionBg .section1Inner>div>input {
        font-size: 14px;
    }

    .sectionBg .section1Inner>div>div:first-of-type>input {
        font-size: 14px;
        width: 122px;
    }

    .sectionBg .section1Inner>div>div:first-of-type>span {
        font-size: 14px;
    }

    .tcContainer {
        margin: 100px 5vw;
        margin-bottom: 0;
        padding: 30px;
    }

    .tcContainer>svg {
        margin: 0 0 0 auto;
    }
}


@media(max-width: 450px) {

    .nav-links>a,
    .nav-links>div {
        font-size: 0.5rem;
    }

    .aboutLinks>div>h3 {
        font-size: 0.9rem;
    }

    .aboutLinks>div>a {
        font-size: 0.8rem;
    }

    .aboutApps>div:first-of-type {
        display: grid;
        grid-template-columns: none;
        grid-template-areas: "a a a" "b c d";
        justify-items: flex-start;
        align-items: start;
    }


    .aboutApps>div:first-of-type>span {
        transform: none;
        grid-area: a;
        margin-bottom: 20px;
    }

    .aboutApps>div:first-of-type>img:nth-of-type(1) {
        grid-area: b;
    }

    .aboutApps>div:first-of-type>img:nth-of-type(2) {
        grid-area: c;
    }

    .aboutApps>div:first-of-type>img:nth-of-type(3) {
        grid-area: d;
    }

    .section2 .eCash,
    .section2 .eSpot,
    .section2 .eHedge,
    .section2 .eResearch {
        padding: 60px 30px;
    }


    .tcContainer>button {
        padding: 12px 50px;
    }

}

@media(max-width: 390px) {
    .partnersGrid {
        display: block;
    }

    .sectionBg .section1 {
        display: block;
    }

    .sectionBg .section1Inner>h1 {
        font-size: 2rem;
    }

    .sectionBg .section1Inner>h3 {
        font-size: 1rem;
    }



}

@media(max-width: 374px) {

    .toaster {
        font-size: 1rem;
        /* width: auto; */
    }

    .chatOverlay>.loginContainer {
        margin: 30px 5vw;
    }

    .chatContent>p {
        margin: 18px 0;
    }
}


@media(max-width: 356px) {
    .sectionBg .section1Inner>div>input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        white-space: pre-line;
        position: relative;
        transform: translateY(-20%);

    }

    .sectionBg .section1Inner>div>input::-moz-placeholder {
        /* Firefox 19+ */
        white-space: pre-line;
        position: relative;
        transform: translateY(-20%);
    }

    .sectionBg .section1Inner>div>input:-ms-input-placeholder {
        /* IE 10+ */
        white-space: pre-line;
        position: relative;
        transform: translateY(-20%);
    }

    .sectionBg .section1Inner>div>input:-moz-placeholder {
        /* Firefox 18- */
        white-space: pre-line;
        position: relative;
        transform: translateY(-20%);
    }
}

@media(max-width: 345px) and (min-height: 496px) {
    .mainContainer .mob-nav {
        width: 100vw;
    }
}


@media(max-width: 350px) {
    .sectionBg .section1Inner>div>div:first-of-type>input {
        font-size: 12px;
        width: 106px;
    }

    .sectionBg .section1Inner>div>div:first-of-type>span {
        font-size: 12px;
    }

    .sectionBg .section1Inner>div>button {
        font-size: 14px;
    }
}


@media(max-width: 324px) {
    .tcContainer>button {
        padding: 12px 20px;
    }

    .tcContainer {
        padding: 30px;
    }

    .tcContainer>svg {
        display: block;
        margin: 0px 0 20px auto;
    }

    .tcContainer h3 {
        padding: 20px;
    }
}


@media(max-width: 305px) {
    .footerUpper>div:last-of-type>div {
        display: block;
    }

    .footerUpper>div:last-of-type>div>input {
        outline: none;
        height: 100%;
        border-radius: 8px;
        border: none;
        font-size: 16px;
        padding: 12px 10px;
        font-family: Metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        color: black;
        text-align: center;
        display: block;
        width: 100%;
    }

    .footerUpper>div:last-of-type>div>button {
        display: block;
        margin-top: 20px;
        border-radius: 8px;
        width: 100%;
    }

    .aboutLinks {
        display: flex;
        flex-direction: column;
        flex-flow: wrap;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 100%;
    }

    .aboutLinks>div {
        margin-bottom: 30px;
    }

    .aboutApps>div:first-of-type {
        display: grid;

        grid-template-areas: "a" "b" "c" "d";
        justify-items: center;
        align-items: start;
    }

    .aboutApps>div:first-of-type>span,
    .aboutApps>div:first-of-type>img {
        margin: 10px;
    }

}

@media(max-width: 324px) {
    .sectionBg .section1Inner>div>div:first-of-type>span {
        border: none;
    }

    .sectionBg .section1Inner>div>div:first-of-type {
        flex-direction: column;
    }
}

@media(max-width: 295px) {
    .otpContainer>div {
        justify-content: center;
        align-items: center;
    }

    .otpContainer>div>input {
        height: 40px;
        width: 38px;
        font-size: 16px;
        margin-right: 5px;
    }

    .otpContainer>p:last-of-type>span:first-of-type {
        padding: 0;
    }

    .chatContent>div:last-of-type {
        flex-direction: column;
        justify-content: flex-start;
    }

    .chatContent>div:last-of-type>img {
        margin: 0;
        margin-top: 16px;
    }

    .loginLeft {
        padding: 30px 40px;
    }

    .chatRight {
        padding: 20px;
    }

}

@media(max-width: 275px) {
    .otpContainer>div {
        justify-content: center;
        align-items: center;
    }

    .otpContainer>div>input {
        margin: 5px;
    }

    .processContainer>div {
        padding: 10px;
    }
}

@media(max-width: 288px) {
    .toaster {
        font-size: 0.9rem;
        width: auto;
    }
}

@media(max-width: 264px) {
    .chatContent>div:first-of-type>svg {
        margin-left: 20px;
    }
}

@media(max-width: 231px) {
    .eGrid>div:last-of-type>button {
        display: none;
    }
}