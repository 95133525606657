.Emandi-news-wrapper-cls{
    font-family: "Poppins", sans-serif;

.header-cls {
display: flex;
margin: 0.5em 3em;
justify-content: start;
img {
    width: 2em;
    height: 2em;
    // border: 2px solid bisque;
    border-radius: 50%;
    padding: 4px;
    box-shadow: 1px 1px 6px;
}
p {
    margin: 0.5em;
    font-size: 16px;
}

.pi-angle-left {
    margin: 0.5em 0em;
    font-size: 16px;
    box-shadow: 0px 0px 20px #00000029;
    border-radius: 50%;
    height: 1.5em;
    width: 1.5em;
    text-align: center;
    line-height: 1.5em;
    cursor: pointer;
}
}
.content-view-cls{
display: flex;
margin: 0 3em;
>div{
    >p{
        margin: 0.5em 0.8em;
    }
}

>img{
    margin: 0.5em;
}
.heading{
    color: black;
    font-size: 14px;
    font-weight: bold;
    width: 30em;
    text-transform: capitalize;
    font-family: "Poppins", sans-serif;
    }
    .heading{
        color: black;
        font-size: 14px;
        font-weight: bold;
        width: 30em;
        text-transform: capitalize;
        font-family: "Poppins", sans-serif;
        }
}
}