.App {
  height: 100vh;
  width: 100vw;
  background: #ffffff;
  /* overflow: hidden; */
}

.app-header {
  height: 16vh;
  width: 100vw;
  background: black;
  display: flex;
  box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
}

.app-header .eauctionHeader {
  flex: 1;
  display: flex;
}

.app-header .eauctionHeader p {
  color: #ffffff;
  background: #031100;
  margin: 0;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 23px;
  position: relative;
  left: 7%;
  padding-top: 0.5em;
}

.app-header .eauctionHeader p span {
  font-size: 1.4em;
}

.app-header .profileBlock-cls {
  margin-right: 6em;
  /* background: #aedc94; */
}

.app-header .profileBlock-cls p {
  /* background: #aedc94; */
  color: #fff;
  font-weight: bold;
  text-align: center;
  padding: 0 0.5em;
  margin-top: 1.25em;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;

}

/* .app-header .eauctionHeader .header-div{
border: 2px solid #ffffff;
height: 57px;
margin: 0.2em;
margin-left: 0.5em;
} */

.app-header .eauctionHeader .header-logo {
  /* width: 110px; */
  height: 4.3em;
  margin-left: 7.4em;
  border-radius: 2px;

  /* margin-left: .2em;
  width: 9%;
  height: 5em; */
  margin-top: 3em;
  cursor: pointer;
  min-width: 87px;
}

.app-header .eauctionHeader .header-logo:hover {
  /* background: #e1e1e1; */
  /* box-shadow: 0 0 0 2px #d0d0d0; */
}

.app-header .eauctionHeader .menu-header {
  width: 70%;
  display: flex;
  justify-content: space-between;
}

.app-header .eauctionHeader .menu-header p {
  font-size: 16px;
  padding-top: 5vh;
  cursor: pointer;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;

}

.app-header .eauctionHeader .menu-header p:hover {
  color: #FFD900;
  text-decoration: underline;
}

.app-header .profileBlock-cls .landing-page-top-right-wrapper {
  display: flex;
  color: #ffffff;
  cursor: pointer;
  height: 100%;

}

.call-us-cls {
  display: block;
  color: #fff;
  font-weight: bold;
  padding: 0 0.5em;
  margin-top: 0.5em;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  margin-right: 0.5em;
}

.login-reg-label {
  background: #aedc94;
  color: #333333;
  font-weight: bold;
  text-align: center;
  padding: 12px;
  /* margin-top: .25em; */
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  padding-top: 1.3em;

}

.phone-icon-cls {
  width: 2em;
  height: 2em;
  margin-top: 0.7em;
  margin-right: 0.2em;
}

.call-us-label {
  display: block;
  color: #7DE08A;
}

.app-containers {
  position: relative;
  height: 85vh;
  bottom: 5%;
  width: 88vw;
  margin: 0 auto;
  box-shadow: 0px 2px 30px #00000026;
  border-radius: 15px;
  background: #ffffff;
  display: flex;

}

.landing-body-page-cls {
  bottom: 0;
  border-radius: 0;
  margin-top: 7px;

}

.landing-header-page-cls {
  /* height: 12.4vh; */
}

.load-mask-cls {
  position: inherit;
}

.app-containers .app-menu-list {
  width: 10%;
}

.app-containers .container-view {
  width: 90%;
  overflow: auto;
}

.app-containers .container-view-full-screen {
  width: 100%;
  position: relative;
  /* overflow-y: scroll; */
}

.app-containers .container-view .center-region-cls {
  padding: 1.5em;
  padding-left: 4rem;
  height: 81vh;
  /* overflow: auto; */
}

.landing-header-page-cls .profileBlock-cls .landing-page-top-right-wrapper .call-us-cls {
  padding: 0.8em;
}

.landing-header-page-cls .profileBlock-cls .phone-icon-cls {
  padding-top: 0.8em;
}


.landing-header-page-cls .login-reg-label {
  padding-top: 2.2em;
}