@font-face {
  font-family: MetropolisRegular;
  src: url('../../../containers/HomeNew/Metropolis-Regular.otf')
}

@font-face {
  font-family: MetropolisMedium;
  src: url('../../../containers/HomeNew/Metropolis-Medium.otf')
}
*{
  font-family: MetropolisRegular;
}
.postInnerContainer{
  height: 100vh;
  overflow-y: hidden;
}
/* heading and tabs */

.eResearch-heading{
    display: flex;
    margin-bottom: 4rem;
}
.eresearch-container, .marketPrice-container{
    width: 100%;
    padding-left: 7%;
    padding-right: 7%;
    padding-top: 7rem;
}
.ereaseach-tabs {
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 2px solid #ECECEC;
    grid-gap: 3rem;
    gap: 3rem;
  }
  
  .ereaseach-tab {
    flex: 1;
    padding: 0px;
    text-align: left;
    cursor: pointer !important;
    color:  #979797;
    font-size: 16px;
    white-space: nowrap;
    padding-bottom: 15px;
  }
  .eresearch-tabs-container{
    margin-bottom: 23px;
  }
  .ereaseach-tab.active-eresearch-tab {
    color: black;
    border-bottom: 2px solid black;
  }
  
  .line-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .eresearch-line {
    flex: 1;
    height: 2px;
    background-color: #ECECEC;
    transition: background-color 0.3s;
  }
  
  .eresearch-line.active-eresearch-tab {
    background-color: black;
  }
  .eresearch-daily-reports{
    padding: 20px 0px;
  }
  .eresearch-daily-reports p{
    font-size: 16px;
    margin: 0px;
    color: #757575
  }

  /* filters */

  .multiselect-commodity-dropdown , .custom-time-dropdown{
    position: relative;
    display: inline-block;
  }
  
  .selected-items , .selected-item{
    position: relative;
    border: 1px solid #D3D3D3;
    background-color: #fff;
    cursor: pointer !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 2px 0px 12px rgba(92, 138, 152, 0.075);
    border-radius: 8px;   
    padding: 9px 24px 9px 22px;
    gap: 1rem;
  }
  .commodity-placeholder{
    font-size: 14px;
    color: #979797;
    max-width: 33rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: MetropolisRegular;
  }
  .commodity-placeholder span{
    font-size: 14px;
    color: #2F2F2F;
  }
  
  .selected-items .placeholder , .selected-item .placeholder {
    flex-grow: 1;
  }
  
  .selected-items .arrow , .selected-item .arrow {
    /* width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #000 transparent transparent transparent;
    margin-left: 5px; */
  }
  .dropdown-list {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 100%;
    background-color: #fff;
    display: none;
    z-index: 1;
    text-align: center;
    box-shadow: 0px 0px 5px rgba(64, 81, 81, 0.14);
    border-radius: 12px;
  }
  
  .scrollable-list {
    padding: 10px;
    overflow-y: auto;
    max-height: 262px;
  }
  
  .dropdown-itemm {
    padding: 14px 0;
    border-bottom: 1px solid #F2F2F2 !important;
    display: flex;
    justify-content: space-between;
    color: #2F2F2F;
    font-size: 14px;
    font-weight: 500;
    font-family: MetropolisMedium;
  }
  .time-dropdown-item{
    justify-content: center;
  }
  
  .dropdown-itemm:last-child {
    border-bottom: none;
  }
  
  .dropdown-itemm label {
    display: flex;
    align-items: center;
    /* white-space: nowrap; */
    max-width: 84%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  
  .dropdown-itemm label input[type="checkbox"] {
    margin-right: 5px;
    border:  1px solid #D3D3D3;
  }
  
  .dropdown-itemm label:hover {
    background-color: #f2f2f2;
  }
  .eresearch_filters{
    display: flex;
    margin-top: 31px;
    gap: 2rem;
    flex-wrap: wrap;
  }
 .search-box{
  border: 1px solid #D3D3D3;
  border-radius: 8px;
  /* color: #979797; */
  padding: 10px 5px 10px 13px;
  margin-top: 16px;
  width: 83%;
  font-size: 12px;
 }
 .harvest-filters{
  display: flex;
  gap: 1rem;
 }
 .harvest-filters button{
  padding: 9px 19px 9px 19px;
  border: 1px solid #979797;
  border-radius: 22px;
  background: none;
  color: #979797;
  letter-spacing: -0.01em;
  font-size: 14px;
  font-family: MetropolisMedium;
 }
 .activeFilter{
  background: #04B23D !important;
  color: #FFFFFF !important;
  border: none !important;
 }
 .separator {
  width: 0.5px;
  border: 0.5px solid #ECECEC;
}

 /* cards */
.eresearch-cards-div{
  margin-top: 4rem;
  display: flex;
  gap: 2.5%;
  flex-wrap: wrap;
}
.eresearch-card{
  width: 18%;
  margin-bottom: 29px;
}
.eresearch-card-upper-div{
  padding: 22px;
  box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
  border-radius: 8px 8px 0px 0px; 
}
.eResearch-commodity-img{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.eResearch-commodityName-view-download{
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.commodity-name{
  letter-spacing: -0.01em;
  color: #2F2F2F;
  font-size: 16px;
  font-family: MetropolisMedium;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.eresearch-download{
  margin-left: 15px;
}
.eresearch-card-lower-div{
  padding: 14px 22px;
  background: #F5F5F5;
  box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
  border-radius: 0px 0px 8px 8px;
}
.eresearch-date{
  letter-spacing: -0.01em;
  color: #979797;
  font-size: 12px;
}
.eresearch-Language{
  color: #04B23D;
  font-size: 12px;
  letter-spacing: -0.01em;
  font-family: MetropolisMedium;
}
.weekly-lower-div{
  display: flex;
  gap: 5px;
  align-items: center;
}
.weekly-lower-div-separator{
  width: 0.5px;
  height: 10px;
  border: 0.5px solid #979797;
}
.no-reports{
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  color: #979797;
  font-size: 19px;
}
.eresearch-download{
  cursor: pointer !important;
}
/* pagination */
.pagination-container{
  display: flex;
  justify-content: center;
  margin: 30px 0px 100px 0px;
}
.paginationList{
  display: flex;
  gap: 2rem;
  list-style: none;
}
.paginationList .active-page{
  color: #04B23D;
}
.paginationList li{
  font-size: 18px;
  color: #979797;
  font-family: MetropolisMedium;
  cursor: pointer !important;
}
.eResearch-heading .big {
  display: flex;
  align-items: center;
}

/* breadcrumbs */
.eresearch-breadcrumbs{
  padding-left: 7%;
    padding-right: 7%;
    gap: 3rem;
    display: flex;
    padding-top: 4%;
}
.eresearch-breadcrumbs img{
  cursor: pointer !important;
}
.breads{
  cursor: pointer !important;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #2F2F2F;
}

/* market price  */
.marketPrice-btn-container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 4rem;
}
.marketPrice-commodityBtn{
  border: 1px solid #D3D3D3;
    background-color: #fff;
    cursor: pointer !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 2px 0px 12px rgba(92, 138, 152, 0.075);
    border-radius: 8px;
    padding: 9px 24px 9px 22px;
    gap: 1rem;
    font-size: 15px;
    color: #979797;
    /* max-width: 33rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    font-family: MetropolisMedium;
    font-weight: 600;
}
.marketPrice-heading{
  margin-bottom: 2rem !important;
}
.marketPrice-compareBtn{
  border: 1px solid #D3D3D3;
  background-color: #fff;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 2px 0px 12px rgba(92, 138, 152, 0.075);
  border-radius: 8px;
  padding: 9px 24px 9px 22px;
  gap: 1rem;
  font-size: 15px;
  color: #2F2F2F;
  background: #F5F5F5;
  font-family: MetropolisMedium;
  font-weight: 600;
}

/* market price commodity popup */
.circle-cross{
  position: absolute;
  top: -46px;
  left: 50%;
  cursor: pointer !important;
}
.marketPrice-commodity-modal-header , .marketPrice-compare-modal-header
{
  padding: 0px !important;
}
.marketPrice-container .popup-content{
  padding: 6rem 6rem 0rem 6rem;
  width: 40%;
  border-radius: 12px;
}

.searchIconFront{
  display: block;
}
.afterInputDiv{
  display: none;
  gap: 2rem;
}
.crossIconBack{
  cursor: pointer !important;
}
.searchIconBack{
  width: 16px;
}
.search-div-marketPrice{
  display: flex;
  width: 100%;
  border: 1px solid #D3D3D3;
  box-shadow: 2px 0px 12px rgba(92, 138, 152, 0.075);
  border-radius: 8px;
  padding: 10px 20px;
  gap: 2rem;
  margin-bottom: 46px;
}
.marketPrice-commodity-search-input{
  border: none;
  width: 100%;
  outline: none;
}
.marketPrice-commodity-search-input , .marketPrice-commodity-search-input::placeholder{
  font-size: 18px;
  font-weight: 500;
  color: #757575;
  font-family: MetropolisMedium;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.small-heading-commodities{
  color: #757575;
  font-family: MetropolisMedium;
  font-size: 11px;
}
.scrollable-commodities-list-div{
  overflow-y: auto;
  max-height: 360px;
}
.marketprice-commodityName{
  font-size: 16px;
  color: #2F2F2F;
  font-family: MetropolisMedium;
}
.marketPrice-active-commodity{
  color: #0DB442;
  font-weight: 600;
}
.marketPrice-dropdown-item{
  cursor: pointer !important;
  width: 95%;
}
.show-dot-commodity{
  display: flex;
  gap: 3rem;
  align-items: center;
}
.hide-dot-commodity{
  display: none;
}
.text-selected{
  font-size: 10px;
  color: #757575; 
  font-family: MetropolisMedium;
  letter-spacing: 0.4px;
}

/* marketPrice mandis tabs  */
.marketPrice-tabs-container{
  margin-bottom: 4rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow-x: hidden;
  gap: 1rem;
}
.wrapped{
  width: 100% !important;
  justify-content: space-between;
  overflow-x: auto;
  scrollbar-color: transparent transparent;
}
.marketPrice-manidis-tabs{
  display: flex;
  width: fit-content;
  border-bottom: 2px solid #ECECEC;
  gap: 3rem;
  max-width: 100%;
  overflow-x: auto;
}
.marketPrice-manidis-tabs::-webkit-scrollbar {
  display: none; /* Hides the scrollbar */
}
.marketPrice-tab{
  cursor: pointer !important;
    color: #979797;
    font-size: 16px;
    white-space: nowrap;
    padding-bottom: 15px;
}
.active-marketPrice-mandi-tab{
  color: #2F2F2F;
  border-bottom: 2px solid #2F2F2F;
}
.chart-types-div{
  display: flex;
  gap: 1rem;
}
.chart-type{
  cursor: pointer !important;
}

/* market price line/candle chart  */
.marketprice-lineChart{
  position: relative;
  margin-top: 20px;
}
.marketprice-lineChart-timePeriods, .marketprice-candleChart-timePeriods, .marketprice-lineChart-compare-timePeriods{
  display: flex;
  justify-content: center;
  gap: 10rem;
  position: relative;
}
.info-popup{
  position: absolute;
  bottom: 10%;
  left: 0;
  width:fit-content;
  background: #2F2F2F;
  box-shadow: 0px 0px 6px rgba(92, 107, 121, 0.11);
  border-radius: 8px;
  line-height: 20px;
  text-align: center;
  left: 50%;
  padding: 6px 25px;
  transform: translateX(-50%);
  font-size: 16px;
  color: #FFFFFF;
  max-width: 50%;
  display: flex;
  align-items: center;
}
.infoCross{
  margin-left: 10px;
  cursor: pointer;
}
.linechart-timePeriod, .candlechart-timePeriod , .linechart-compare-timePeriod{
  font-size: 14px;
  color: #757575;
  font-family: MetropolisMedium;
  cursor: pointer !important;
}
.active-lineChart-timePeriod, .active-candleChart-timePeriod{
  color: #0DB442;
  background: rgba(13, 180, 66, 0.1);
  border-radius: 8px;
  padding: 0px 10px;
}
.canvasjs-chart-canvas{
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(251, 235, 139, 0.29) 100%) !important;
}
.line-chart-div, .candle-chart-div{
  margin-bottom: 3rem !important;
  position: relative;
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(251, 235, 139, 0.29) 100%) !important;
  border-bottom: 1px dashed #D3D3D3;
}
.candle-chart-div > div:first-child {
  margin-left: 25% !important;
  width: 75% !important;
}
.candle-chart-div .canvasjs-chart-canvas{
  width: 100% !important;
  height: 400px !important;
}
.line-chart-div .canvasjs-chart-canvas{
  width: 100% !important;
  height: 400px !important;
}

.divider {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: lightgray;
  pointer-events: none;
}

.button-container {
  background-color: transparent;
  position: absolute;
  bottom: 10px;
}

.data-display {
  margin-top: 10px;
}
.lineChart_divider_move_btn{
  cursor: pointer !important;
  height: 55px;
}
.divider-top{
  position: absolute;
  top: 20px;
  transform: translateX(-50%);
  background: #FFFFFF;
  box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
  border-radius: 8px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer !important;
}
.candle-chart-div .divider-top{
  top: -30px;
}
.divider-top-first {
  color: #2F2F2F;
  font-size: 12px;
  width: fit-content;
  white-space: nowrap;
  font-family: MetropolisMedium;
}
.opensize-divider-top-first{
  font-size: 14px !important;
}
.divider-top-second p{
  margin: 0px !important;
  font-size: 14px;
  color: #757575;
  font-family: MetropolisMedium;
}
.todaysPrice-container{
  display: flex;
  gap: 1rem;
  align-items: self-start;
}
.yellowLine-todaysPrice{

}
.text-todaysPrice{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.uppertext-todaysPrice{
  font-size: 20px;
  color: #2F2F2F;
  font-family: MetropolisBold;
  margin-top: -3px;
}
.rupee-bold-img{
  margin-top: -3px;
}
.lowertext-todaysPrice{
  font-size: 14px;
 
  font-family: MetropolisMedium;
}
.redUpdownChangeText{
  color: red;
}
.greenUpdownChangeText{
  color: #04B23D;
}
.text-today{
  font-size: 12px;
  color: #757575;
  font-family: MetropolisMedium;
  margin-left: 10px;

}
.updownChangeArrow{
  margin-left: 5px;
}
.candle-chart-div .canvasjs-chart-container .canvasjs-chart-canvas .canvasjs-candlestick {
  pointer-events: none;
}
.canvasjs-chart-canvas path:hover {
  stroke-width: 1px !important;
  stroke: transparent !important;
}

/* compare functionality */
.mandis-heading, .commodity-heading{
  font-size: 16px;
  color: #2F2F2F;
  font-family: MetropolisMedium;
  margin-bottom: 3.5rem;
}
.mandis-for-commodity-selected{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 5rem;
}
.mandi-name-to-compare{
  border: 1px solid #D3D3D3;
  border-radius: 22px;
  padding: 5px 24px;
  font-size: 16px;
  color: #2F2F2F;
  cursor: pointer !important;
}
.commodity-heading{
  margin-bottom: 2rem;
}
.selected-commodity-change{
  display: flex;
  gap: 1rem;
  align-items: baseline;
  margin-bottom: 5rem;
}
.commodity-selected{
  font-size: 18px;
  color: #2F2F2F;
  font-family: MetropolisMedium;
}
.change-btn{
  font-size: 14px;
  color: #04B23D;
  font-family: MetropolisBold;
  cursor: pointer !important;  
}
.compare-inner-btn{
  background: #D3D3D3;
  border-radius: 8px;
  border: none;
  color: #FFFFFF;
  font-family: MetropolisMedium;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 6rem;
}
.green-bg-compare-mandi , .green-compare-inner-btn{
  background: #04B23D;
  color: #FFFFFF; 
}
.wider-popup-overlay{
  overflow-y: auto;
}
.wider-popup-content{
  width: 75% !important;
  padding: 5rem !important;
  margin-top: 8rem !important;
}
.inner-compare-popup-header-cross{
  cursor: pointer !important;
}
.comparison-for{
  display: flex;
  gap: 2rem;
  align-items: center;
}
.yellow-vertical-line{
  background: #FDE96C;
  width: 7px;
  /* height: 36px; */
  align-self: stretch;
  border-radius: 0px 5px 5px 0px;
}
.comaperison-heading{
  font-size: 24px;
  font-family: MetropolisRegular;
  color: #2F2F2F;
  font-weight: 600;
  cursor: pointer;
}
.commodity-name-comparison-for{
  font-size: 24px;
  color: #04B23D;
  font-family: MetropolisRegular;
  font-weight: 600;
  margin-left: 10px;
}
.selcted-mandis-label{
  margin-top: 4rem;
  font-size: 16px;
  margin-bottom: 3rem;
  color: #2F2F2F;
  font-family: MetropolisMedium;
}
.selected-mandis-for-innerComparison{
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
.green-bg-selected-compare-mandi{
  border-radius: 22px;
  padding: 5px 24px;
  font-size: 16px;
  background: #04B23D;
  color: #FFFFFF; 
}
.addMore-compare-mandi{
  border: 1px solid #D3D3D3;
  border-radius: 22px;
  padding: 5px 24px;
  font-size: 16px;
  display: flex;
  gap: 1rem;  
  cursor: pointer;
}
.mandi-table{
  border: 1px solid #ECECEC;
  border-radius: 4px;
  margin-top: 3rem;
}
.mandi-table td {
  text-align: left;
  vertical-align: middle;
  padding-left: 4% !important;
  /* width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.mandi-name-in-table{
  font-size: 14px;
  color: #2F2F2F;
}
.price-in-table{
  font-size: 14px;
  font-family: MetropolisMedium;
  color: #2F2F2F;
}
.updownChange-in-table{
  font-size: 14px;
  font-family: MetropolisRegular;
  font-weight: 600;
}
.chart-container{
  border-bottom: 1px dashed #D3D3D3;
  margin-bottom: 27px;
  position: relative;
  margin-top: 27px;
}
.chart-container .canvasjs-chart-canvas{
  height: 312px !important;
}
.chart-container>div:first-child{
  height: 312px !important;
}
.chart-container .canvasjs-chart-canvas:first-child{
  width: 100% !important;
}
.canvasjs-chart-credit{
  display: none;
}
.dividerTopOuterDiv{
  /* left: 85% !important; */
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    background: #FFFFFF;
    box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
    border-radius: 8px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    cursor: pointer !important;
}
.dividertop-innerDiv{
  color: #2F2F2F;
  font-size: 14px;
  width: -moz-fit-content;
  width: fit-content;
  white-space: nowrap;
  font-family: MetropolisMedium;
}
.color-circle{
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
.dot-mandi-div{
  display: flex;
  align-items: center;
  gap: 0.8rem;
}
.addMore-text{
  cursor: pointer !important;
}
.dot-mandi-div>span{
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
}
@media only screen and (max-width:5464px) and (min-width:2561px){
  .eResearch-heading .small-box {
    width: 14px;
    height: 96px;
  }
  .eResearch-heading {
    margin-bottom: 11rem !important;
}
  .eResearch-heading .big {
    font-size: 66px !important;
    margin-left: 31px !important;
  }
  .ereaseach-tab {
    font-size: 50px;
  }
  .eresearch-daily-reports p {
    font-size: 55px;
    margin-top: 5rem;
    margin-bottom: 10rem;
  }
  .commodity-placeholder {
    font-size: 52px;
  }
  .commodity-placeholder span {
    font-size: 52px;
  }
  .selected-items, .selected-item {
    padding: 27px 76px 30px 76px;
    gap: 4rem;
    border-radius: 24px;
  }
  .commodity-placeholder {
    max-width: 77rem;
  }
  .selected-items .arrow img, .selected-item .arrow img{
    width: 55px;
  }
  .eresearch-card-upper-div {
    padding: 48px;
  }
  .eResearch-commodity-img {
    width: 100px;
    height: 100px;
  }
  .commodity-name {
    font-size: 47px;
  }
  .eResearch-commodityName-view-download img{
    width: 51px;
    height: 48px;
  }
  .eresearch-card-lower-div {
    padding: 40px 48px;
  }
  .eresearch-date {
    font-size: 39px ;
  }
  .eresearch-cards-div {
    margin-top: 18rem;
  }
  .eResearch-commodityName-view-download {
    display: flex;
    justify-content: space-between;
    margin-top: 8rem;
  }
  .eresearch-card {
    margin-bottom: 10rem;
  }
  .paginationList {
    gap: 6rem;
  }
  .paginationList li {
    font-size: 62px;
  }
  .paginationList li img{
    width: 48px;
  }
  .harvest-filters button {
    font-size: 53px;
    border-radius: 61px;
    padding: 24px 53px 24px 53px;
  }
  .harvest-filters {
    gap: 4rem;
  }
  .eresearch_filters {
    gap: 7rem;
  }
  .separator {
    width: 4.5px;
  }
  .dropdown-itemm label {
    font-size: 52px;
  }
  .dropdown-itemm input{
    width: 3rem;
    height: 5rem;
  }
  .scrollable-list {
    max-height: 53rem;
}
.search-box {
  padding: 23px 27px 23px 27px;
}
.search-box {
  font-size: 29px;
}
.breads {
  font-size: 40px;
}
.eresearch-breadcrumbs img {
  width: 38px;
}
.marketPrice-commodityBtn {
  font-size: 78px;
  padding: 27px 60px 27px 60px;
  border-radius: 32px;
}
.marketPrice-commodityBtn img{
  width: 7rem;
}
.marketPrice-tab {
  font-size: 79px;
}
.marketPrice-manidis-tabs {
  gap: 12rem;
}
.marketPrice-btn-container {
  margin-bottom: 16rem;
}
.marketPrice-compareBtn {
  gap: 5rem;
  font-size: 72px;
  padding: 27px 60px 27px 60px;
  border-radius: 32px;
}
.marketPrice-compareBtn img{
  width: 9rem;
}
.marketPrice-tabs-container {
  margin-bottom: 20rem;
}
.chart-type {
  cursor: pointer !important;
  width: 15rem;
}
.uppertext-todaysPrice {
  font-size: 57px;
}
.yellowLine-todaysPrice {
  height: 7rem;
}
.todaysPrice-container {
  gap: 3rem;
}
.text-today {
  font-size: 29px;
}
.lowertext-todaysPrice {
  font-size: 37px;
}
.canvasjs-chart-canvas{
  height: 80rem !important;
}
.line-chart-div>div:first-child, .candle-chart-div>div:first-child{
  height: 80rem !important;
}
.divider-top-first {
  font-size: 33px;
}
.divider-top-first img{
  height: 19px;
}
.divider-top {
  padding: 23px 31px;
}
.lineChart_divider_move_btn {
  height: 128px;
}
.linechart-timePeriod, .candlechart-timePeriod, .linechart-compare-timePeriod {
  font-size: 40px;
}
.marketprice-lineChart-timePeriods, .marketprice-candleChart-timePeriods, .marketprice-lineChart-compare-timePeriods {
  gap: 45rem;
}
.line-chart-div, .candle-chart-div {
  margin-bottom: 10rem !important;
}
.info-popup {
  border-radius: 23px;
  padding: 32px 75px;
  font-size: 46px;
  max-width: 60%;
  line-height: 70px;
}
.info-popup img{
  height: 4rem;
}
.search-div-marketPrice {
  padding: 28px 40px;
}
.marketPrice-commodity-search-input, .marketPrice-commodity-search-input::placeholder {
  font-size: 38px;
}
.searchIconBack {
  width: 43px;
}
.crossIconBack {
  width: 4rem;
}
.small-heading-commodities {
  font-size: 28px;
}
.marketPrice-container .popup-content {
  padding: 13rem 11rem 0rem 11rem;
}
.circle-cross {
  width: 9rem;
  top: -138px;
}
.show-dot-commodity img{
  width: 20px;
}
.text-selected {
  font-size: 28px;
}
.dropdown-itemm {
  padding: 28px 0;
}
.scrollable-commodities-list-div {
  max-height: 80rem;
}
.mandis-heading, .commodity-heading {
  font-size: 36px;
}
.mandi-name-to-compare {
  font-size: 32px;
}
.commodity-selected {
  font-size: 26px;
}
.change-btn {
  font-size: 23px;
}
.compare-inner-btn {
  font-size: 38px;
}
.comaperison-heading {
  font-size: 45px;
}
.yellow-vertical-line {
  border-radius: 0px 10px 10px 0px;
  width: 17px;
}
.commodity-name-comparison-for {
  font-size: 37px;
}
.comparison-for-dropdown-img{
  width: 6rem;
  cursor: pointer;
}
.selcted-mandis-label {
  font-size: 31px;
}
.green-bg-selected-compare-mandi {
  font-size: 34px;
}
.green-bg-selected-compare-mandi {
  border-radius: 33px;
  padding: 7px 46px;
}
.addMore-compare-mandi {
  padding: 7px 46px;
  font-size: 39px;
}
.addMore-compare-mandi img{
  width: 3rem;
}
.updownChange-in-table , .price-in-table, .dot-mandi-div{
  font-size: 36px;
}
.color-circle {
  width: 20px;
  height: 20px;
}
.chart-container .canvasjs-chart-canvas {
  height: 80rem !important;
}
.chart-container>div:first-child {
  height: 80rem !important;
}
.dividertop-innerDiv {
  font-size: 35px;
}
.line-chart-div .canvasjs-chart-canvas, .candle-chart-div .canvasjs-chart-canvas {
  width: 100% !important;
  height: 80rem !important;
}
.rupee-bold-img{
  width: 28px;
}
}
@media only screen and (max-width:1143px) {
  .eresearch-card {
    width: 23%;
  }
}
@media only screen and (max-width:1020px) {
  .marketPrice-container .popup-content {
    padding: 6rem 6rem 0rem 6rem;
    width: 60%;
  }
  .wider-popup-content {
    width: 85% !important;
  }
}

@media only screen and (max-width:853px) {
  .eresearch-card {
    width: 31%;
  }
  .eresearch-cards-div {
    gap: 3.5%;
  }
  .candle-chart-div .canvasjs-chart-canvas {
    width: 100% !important;
  }
  .candle-chart-div > div:first-child {
    margin-left: 23% !important;
  }
  .comaperison-heading, .commodity-name-comparison-for {
    font-size: 19px;
  }
  .comparison-for {
    gap: 1rem
  }
}
@media only screen and (max-width: 700px){
  .marketPrice-container .popup-content {
      width: 80%;
  }
  .wider-popup-content {
    width: 95% !important;
  }
  .divider-top {
    padding: 10px 4px;
  }
  .opensize-divider-top-first {
    font-size: 11px !important;
  }
  .divider-top-second p {
    font-size: 11px;
  }
}
@media only screen and (max-width:619px) {
  .eresearch-card {
    width: 48%;
  }
  .ereaseach-tabs {
    display: flex;
    width: -moz-fit-content;
    width: 100%;
    gap: 9%;
  }
  .ereaseach-tab {
    font-size: 15px;
  }
  .info-popup {
    width: 76vw;
    max-width: 100%;
  }
  .divider-top {
    padding: 10px 8px;
}
.divider-top-first {
  font-size: 9px;
}
}
@media only screen and (max-width:583px) {
  .ereaseach-tabs {
    gap: 6%;
  }
  .candle-chart-div .canvasjs-chart-canvas {
    width: 100% !important;
  }
  .candle-chart-div > div:first-child {
    margin-left: 23% !important;
  }
  .chart-container .canvasjs-chart-canvas {
    width: 100% !important;
}
}
@media only screen and (max-width:547px) {
  .ereaseach-tab {
    font-size: 15px;
  }
  .ereaseach-tabs {
    grid-gap: 6%;
    gap: 3%;
}
}
@media only screen and (max-width:506px) {
  .ereaseach-tab {
    font-size: 14px;
  }
  .ereaseach-tabs {
    gap: 3%;
}
.line-chart-div .canvasjs-chart-canvas {
  width: 100% !important;
}
.chart-container .canvasjs-chart-canvas:first-child {
  width: 100% !important;
}
.marketPrice-container .popup-content {
  width: 95%;
}
.wider-popup-content {
  padding: 2rem !important;
}
}
@media only screen and (max-width:500px) {
  .dividerTopOuterDiv {
    padding: 8px 8px;
  }
  .dividertop-innerDiv {
    font-size: 11px;
  }
  }
@media only screen and (max-width:475px) {
  .ereaseach-tab {
    font-size: 13px;
  }
}
@media only screen and (max-width:467px) {
  .ereaseach-tabs {
    border: none;
    flex-direction: column;
    gap: 1rem;
  }
  .ereaseach-tab.active-eresearch-tab {
    color: black;
    box-shadow: 2px 0px 12px rgba(92, 138, 152, 0.075);
}
.ereaseach-tab {
  font-size: 18px;
  border-radius: 8px;
  border: 1px solid #D3D3D3;
  padding-bottom: 0px;
  padding: 5px 24px 5px 22px;
}
.marketprice-lineChart-timePeriods, .marketprice-candleChart-timePeriods, .marketprice-lineChart-compare-timePeriods {
  display: flex;
  gap: 0rem;
  justify-content: space-between;
}
.candle-chart-div .canvasjs-chart-canvas {
  width: 100% !important;
}
.candle-chart-div > div:first-child {
  margin-left: 23% !important;
}
}
@media only screen and (max-width:440px) {
.commodity-placeholder {
  max-width: 21rem;
}
}
@media only screen and (max-width:440px) {
  .eresearch-tabs-container {
    margin-bottom: 7px;
  }
}
@media only screen and (max-width:426px) {
  .eresearch-card {
    width: 100%;
}
.marketprice-commodityName {
  font-size: 14px;
}
.marketPrice-commodity-search-input, .marketPrice-commodity-search-input::placeholder {
  font-size: 15px;
}
.searchIconBack {
  width: 13px;
}
.crossIconBack {
  width: 11px;
}
.searchIconFront {
  width: 17px;
}
}
@media only screen and (max-width:415px) {
  .eresearch_filters {
    flex-direction: column;
  }
  .harvest-filters {
    gap: 0rem;
    justify-content: space-between;
}
}
@media only screen and (max-width:402px) {
  .divider-top{
    padding: 8px 8px;
  }
  .show-dot-commodity {
    gap: 1rem;
  }
  .marketPrice-container .popup-content {
    padding: 4rem 4rem 0rem 4rem;
  }
}
@media only screen and (max-width:370px) {
  .seasonal-report .harvest-filters button {
  padding: 8px 15px 8px 14px;
}
.eresearch-daily-reports p {
  font-size: 14px;
}
}
@media only screen and (max-width:341px) {
.selected-items, .selected-item {
  padding: 9px 9px 9px 9px;
}
}
@media only screen and (max-width:339px) {
  .seasonal-report .harvest-filters button {
    padding: 8px 9px 8px 9px;
  }
}
@media only screen and (max-width:327px) {
  .eresearch-daily-reports p {
    font-size: 13px;
  }
}
@media only screen and (max-width:309px) {
  .eResearch-heading .big{
      font-size: 15pt !important;
      margin-bottom: 0px !important;
  }
  .eResearch-heading .small-box {
    width: 6px;
    height: 25px !important;
}
}
@media only screen and (max-width:301px) {
  .seasonal-report .harvest-filters button {
    padding: 8px 9px 8px 9px;
    font-size: 12px;
  }
  .eresearch-daily-reports p {
    font-size: 12px;
}
.mandi-table td {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
}
@media only screen and (max-width:297px) {
  .commodity-placeholder {
    max-width: 18rem;
}
}
@media only screen and (max-width:276px) {
  .eResearch-heading .big {
    font-size: 12pt !important;
    margin-bottom: 0px !important;
    margin-top: 6px !important;
}
.seasonal-report .harvest-filters button {
  font-size: 12px;
}
}
@media only screen and (max-width:297px) {
  .commodity-placeholder {
    max-width: 16rem;
}
}
@media only screen and (max-width:230px) {
  .eResearch-heading .big {
    font-size: 11pt !important;
}
}