.custom-btn-cls {
    outline-style: none;
    &.p-disabled {
        color: rgba(23, 1, 1, 0.3) !important;
        border-color: rgba(118, 118, 118, 0.3) !important;
        background-color: rgba(80, 60, 60, 0.3) !important; 
             
    }
    .p-button{
        outline-style: none !important;    
    }
}