.session-time-out{
    .p-dialog{
        width: 35vw !important;
        .p-dialog-titlebar{
            .p-dialog-titlebar-icons{
                display: none;
            }
        }


    }

    .p-dialog-content{
        .time-out-alert{
            display: flex;
            padding: 1em;
        }

        .pi-exclamation-triangle{
            font-size: 2em;
            margin-right: 0.5em;
        }

        .wrapper{
            p{
                margin: 0;
                font-size: 14px;
            }

            svg{
                display: none;
            }

            .value{
                position: relative;
                top: 0.61em;
                // right: 0.3em;
                margin: 0 1em;
                
            }

            .warp-timer{
                display: flex;

                >div{
                    position: relative;
                    width: 0 !important;
                    height: 0 !important;
                }

                p{
                    margin-right: 0.5em;
                }

                .seconds{
                    margin-left: 1em;
                }
            }
            
        }
    }
}