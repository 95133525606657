.dropdown-content-section {
  display: flex;

  .dropdown-label {
    > label {
      top: 20%;
      position: relative;
      padding: 0.5em;
      padding-left: 0;
    }

    > span {
      color: red;
    }
  }

  .p-dropdown {
    min-width: 80px;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: none;
    

    .p-dropdown-items {
      text-transform: capitalize;
    }

    li:hover {
      background: #ecefe9;
      border-radius: 4px;
      box-shadow: 0px 1.5px 3px #4b652e4D;
    }
  }
}

.multiselect-content-section {
  .p-multiselect {
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #d2d2d2;
    border-radius: 4px;
    height: 2em;
    margin-top: 0.5em;
    background: #fff;
    opacity: 1;
    width: 100%;

    .p-multiselect-panel {
      background: #fff;
      border: 0.1em solid #7070704D;

      .p-multiselect-header {
        display: none;

        .p-checkbox {
          border-radius: 5px;
          border: 2px solid;
        }

        .p-multiselect-filter-container {
          width: 75%;
        }
      }

      .p-multiselect-items {
        display: contents;
        padding: 0.4em;

        .p-highlight {
          // background: #009d45;
        }

        .p-multiselect-item {
          font-weight: normal;
          border: 0 none;
          cursor: pointer;
          margin: 1px 0;
          padding: .125em .25em;
          text-align: left;

          .p-checkbox {
            border-radius: 5px;
            border: 2px solid;
          }
        }
      }

      .p-multiselect-items :hover {
        background: #8dd0ab;
      }
    }

    .p-multiselect-label-container {
      .p-multiselect-label {
        padding: .35em 2em 0 1em;
      }
    }
  }
}
.p-multiselect .p-multiselect-label {
  width: 11em !important;
}