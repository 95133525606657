.naveList {
    height: 85vh;

    .nav-tree-list-cls {
        width  : 80%;
        margin : 0 auto;
        padding: 0.2em;
        cursor : pointer;

        .renderMenu {
            text-align: center;
            // margin-top: 10px;

            >p {
                margin        : 0;
                text-align    : center;
                text-transform: uppercase;
                color         : #00000099;
                font-weight   : bold;
                font-size     : 12px;
            }

            .nav-icon-image {
                width : 2em;
                margin: 0 auto;

                img {
                    width : 21px;
                    height: 21px;
                }
            }
        }
    }

    .active-cls {
        box-shadow   : 0px 3px 10px #00000029;
        border-radius: 10px;
        background   : #00000029;
        width        : 100px;
        height       : 60px;
    }

    .naveList-scrollbar {
        >div {
            display        : flex;
            flex-direction : column;
            justify-content: space-evenly;
        }
    }
}