

@font-face {
  font-family: MetropolisMedium;
  src: url('Metropolis-Medium.otf')
}

@font-face {
  font-family: MetropolisBold;
  src: url('Metropolis-Bold.otf')
}

@font-face {
  font-family: MetropolisRegular;
  src: url('Metropolis-Regular.otf')
} 
 
.form-css{
  font-family: MetropolisRegular;
}

select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option:first {
  color: #979797;
}
.formCard{
  margin-top: 70px;
}
.form-control{
  font-size: medium;
}

.input-text-css {
  font-family: MetroPolisExtraLight;
}

.form-commodity-css {
  background-image: url('../../../assets/icons/select_icon.png');
  background-position: 290px 13px;
  background-repeat: no-repeat;
}

.formCard input{
  margin-bottom: 6px !important;
  border-radius: 8px !important;
  padding: 0.375rem 2.5rem !important;
  font-size: medium !important;
}
.formCard select{
  margin-bottom: 32px !important;
  border-radius: 8px !important;
  padding: 0.375rem 2.5rem !important;
  font-size: medium !important;
}
.formCard .formCardBody .btn {
  padding: 16px;
  font-size: medium;
  border: none;
  letter-spacing: 1px;
  font-family: MetropolisMedium;
  border-radius: 8px;
}
.formCard .formCardBody {
  margin-top: 75px;
  padding: 19px;
}
.form-label {
  margin-bottom: 1rem;
  font-weight: 600;
}
.inner-input{
  margin-bottom: 0px !important;
  border: none;
  outline: none;
    /* display: block;
    width: 100%;
    padding: 0.375rem 0.75rem; */
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    font-family: MetropolisRegular;
}
.enterMobileLabel{
  font-weight: 'bold';
   font-size: 'Medium';
}

::-webkit-input-placeholder {
    text-align: left;
  }
  
  :-moz-placeholder {
    text-align: left;
  }
  .dropdown {
    position: relative;
    -webkit-appearance: none;
  
  }
  .dropdown:before {
    content: "";
    position: absolute;
    right: 10px;
    top: 8px;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #f00;
  }
  .dropdown:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 3px;
    width: 0; 
    height: 0; 
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #333;
  }
  .btn-css-3 {
    position: relative;   
    background: url("./arrow_down_icon.png") 96% / 7% no-repeat #eee;
  }
  .form-css-1 {
    padding: 0.6rem 2.75rem !important;
    border-radius: 8px !important;
    margin-bottom: 6px !important;
  }
  .card-body form .mb-3 input::placeholder{
    color: #979797;
  }
  .card-body form .mb-3 input , .card-body form .mb-3 select{
    font-family: MetroPolisExtraLight !important;
  }
  @media only screen and (max-width: 7680px) and (min-width: 5747px){
    .redValidation small {
      font-size: 25px !important;
  }
    .formCard .formCardBody .form-label, .enterMobileLabel, .whichCommodityLabel {
      font-size: 37px !important;
      margin-bottom: 31px;
      margin-top: 5rem;
  }
  .card-body form .mb-3 input {
    height: 50px !important;
    padding: 51px !important;
    font-size: 37px !important;
    border-radius: 19px !important;
}
.formCard .formCardBody {
  width: 998px;
    padding: 5rem !important;
    border-radius: 36px;
    margin-top: 17rem;
}
.input-box-1 .prefix-1 {
  font-size: 38px !important;
}
.card-body form .mb-3 select {
  height: 120px !important;
  padding: 34px !important;
  font-size: 38px !important;
  border-radius: 29px !important;
  margin-bottom: 96px !important;
}
.form-commodity-css {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
  background-position: 680px 41px;
  background-repeat: no-repeat;
  background-size: 84px 35px;
}
.card-body form .mb-3>input, .form-css-1 {
  border-radius: 22px !important;
}
.formCard .formCardBody .btn {
  padding: 24px;
  font-size: 47px;
  border: none;
  letter-spacing: 1px;
  font-family: MetropolisRegular;
  border-radius: 26px;
}
  }
  @media only screen and (max-width: 5746px) and (min-width: 5465px){
    .redValidation small {
      font-size: 25px !important;
  }
    .formCard .formCardBody .form-label, .enterMobileLabel, .whichCommodityLabel {
      font-size: 37px !important;
      margin-bottom: 31px;
      margin-top: 5rem;
  }
  .card-body form .mb-3 input {
    height: 50px !important;
    padding: 51px !important;
    font-size: 37px !important;
    border-radius: 19px !important;
}
.formCard .formCardBody {
  width: 998px;
    padding: 5rem !important;
    border-radius: 36px;
    margin-top: 17rem;
}
.input-box-1 .prefix-1 {
  font-size: 38px !important;
}
.card-body form .mb-3 select {
  height: 120px !important;
  padding: 34px !important;
  font-size: 38px !important;
  border-radius: 29px !important;
  margin-bottom: 96px !important;
}
.form-commodity-css {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
  background-position: 680px 41px;
  background-repeat: no-repeat;
  background-size: 84px 35px;
}
.card-body form .mb-3>input, .form-css-1 {
  border-radius: 22px !important;
}
.formCard .formCardBody .btn {
  padding: 24px;
  font-size: 47px;
  border: none;
  letter-spacing: 1px;
  font-family: MetropolisRegular;
  border-radius: 26px;
}
  }
  @media only screen and (max-width: 5464px) and (min-width: 4099px){
    .redValidation small {
      font-size: 25px !important;
  }
    .formCard .formCardBody .form-label, .enterMobileLabel, .whichCommodityLabel {
      font-size: 37px !important;
      margin-bottom: 31px;
      margin-top: 5rem;
  }
  .card-body form .mb-3 input {
    height: 50px !important;
    padding: 51px !important;
    font-size: 37px !important;
    border-radius: 19px !important;
}
.formCard .formCardBody {
  width: 998px;
    padding: 5rem !important;
    border-radius: 36px;
    margin-top: 17rem;
}
.input-box-1 .prefix-1 {
  font-size: 38px !important;
}
.card-body form .mb-3 select {
  height: 120px !important;
  padding: 34px !important;
  font-size: 38px !important;
  border-radius: 29px !important;
  margin-bottom: 96px !important;
}
.form-commodity-css {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
  background-position: 680px 41px;
  background-repeat: no-repeat;
  background-size: 84px 35px;
}
.card-body form .mb-3>input, .form-css-1 {
  border-radius: 22px !important;
}
.formCard .formCardBody .btn {
  padding: 24px;
  font-size: 47px;
  border: none;
  letter-spacing: 1px;
  font-family: MetropolisRegular;
  border-radius: 26px;
}
  }
  @media only screen and (max-width: 4098px) and (min-width: 3842px){
    .redValidation small {
      font-size: 30px !important;
  }
    .formCard .formCardBody .form-label, .enterMobileLabel, .whichCommodityLabel {
      font-size: 37px !important;
      margin-bottom: 31px;
      margin-top: 5rem;
  }
  .card-body form .mb-3 input {
    height: 50px !important;
    padding: 51px !important;
    font-size: 37px !important;
    border-radius: 19px !important;
}
.formCard .formCardBody {
  width: 998px;
    padding: 5rem !important;
    border-radius: 36px;
    margin-top: 17rem;
}
.input-box-1 .prefix-1 {
  font-size: 38px !important;
}
.card-body form .mb-3 select {
  height: 120px !important;
  padding: 34px !important;
  font-size: 38px !important;
  border-radius: 29px !important;
  margin-bottom: 96px !important;
}
.form-commodity-css {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
  background-position: 680px 41px;
  background-repeat: no-repeat;
  background-size: 84px 35px;
}
.card-body form .mb-3>input, .form-css-1 {
  border-radius: 22px !important;
}
.formCard .formCardBody .btn {
  padding: 24px;
  font-size: 47px;
  border: none;
  letter-spacing: 1px;
  font-family: MetropolisRegular;
  border-radius: 26px;
}
  }
 @media only screen and (max-width: 3841px) and (min-width: 1441px){
  .formCard .formCardBody .form-label , .enterMobileLabel, .whichCommodityLabel {
    font-size: 49px !important;
    margin-bottom: 44px;
  }
  .redValidation small{
    font-size: 42px !important;
  }
    .formCard .formCardBody {
      padding: 95px;
      border-radius: 29px !important;
      margin-top: 110px;
    }
    .card-body form .mb-3 input {
      height: 50px;
      padding: 71px !important;
      font-size: 53px !important;
      border-radius: 29px !important;
    }
    .card-body form .mb-3>input , .form-css-1{
      margin-bottom: 0px !important;
      border-radius: 29px !important;
    }
    .enterMobileLabel{
      margin-top: 96px !important;
    }
    .whichCommodityLabel{
      margin-top: 71px !important;
      font-weight: bold;
    }
     .card-body form .mb-3 select{
      height: 153px !important;
      padding: 45px !important;
      font-size: 49px !important;
      border-radius: 29px !important;
      margin-bottom: 96px !important;
     }
    .input-box-1 .prefix-1{
      font-size: 49px !important;
    }
    .formCard .formCardBody .btn {
      padding: 37px;
      font-size: 59px;
      border-radius: 40px;
    }
    .form-commodity-css {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
      background-position: 863px 63px;
      background-repeat: no-repeat;
      background-size: 108px 45px;
  }
  .formCard .formCardBody .imageBody img {
    width: 44%;
  }
  .Thanks{
    margin-left: 30px !important;
    font-size: 47px !important;
    margin-top: 59px !important;
  }
  .getBackToU{
    font-weight: bold;
    margin-left: 30px !important;
    margin-top: 83px !important;
    font-size: 53px !important;
  }
  .backToTopBtn{
    padding: 37px !important;
    font-size: 59px !important;
    border-radius: 40px !important;
    margin-top: 24rem !important;
    width: 97% !important;
  }
}
@media only screen and (max-width: 2732px) and (min-width: 2561px){
  .redValidation small {
    font-size: 28px !important;
}
  .formCard .formCardBody .form-label, .enterMobileLabel, .whichCommodityLabel {
    font-size: 31px !important;
    margin-bottom: 19px;
}
.card-body form .mb-3 input {
  height: 83px !important;
    padding: 50px !important;
    font-size: 35px !important;
    border-radius: 19px !important;
}
.form-css-1 {
  padding: 0rem 2.75rem !important;
}
.form-css-1 {
  margin-bottom: 0px !important;
  border-radius: 16px !important;
}
.input-box-1 .prefix-1 {
  font-size: 38px !important;
}
.card-body form .mb-3 select {
  height: 107px !important;
  padding: 33px !important;
  font-size: 33px !important;
  border-radius: 19px !important;
  margin-bottom: 89px !important;
}
.enterMobileLabel {
  margin-top: 70px !important;
}
.whichCommodityLabel {
  margin-top: 46px !important;
}
.form-commodity-css {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
    background-position: 600px 44px;
    background-repeat: no-repeat;
    background-size: 74px 26px;
}
.formCard .formCardBody {
  padding: 59px;
}
.formCard .formCardBody .btn {
  padding: 32px;
  font-size: 33px;
  border-radius: 23px;
}
.formCard .formCardBody {
  width: 85rem;
}
}
@media only screen and (max-width: 2560px) and (min-width: 1441px){
  .formCard .formCardBody {
    width: 88rem;
  }
  .redValidation small {
    font-size: 24px !important;
  }
  .formCard .formCardBody {
    padding: 65px;
    border-radius: 24px !important;
    margin-top: 75px;
  }
  .formCard .formCardBody .form-label, .enterMobileLabel, .whichCommodityLabel {
    font-size: 35px !important;
    margin-bottom: 27px;
  }
  .input-box-1 .prefix-1 {
    font-size: 35px !important;
  }
  .card-body form .mb-3>input, .form-css-1 {
    margin-bottom: 0px !important;
    border-radius: 20px !important;
    padding: 0rem 2.75rem !important;
  }
  .enterMobileLabel{
    margin-top: 71px !important;
  }
  .whichCommodityLabel{
    margin-top: 45px !important;
    font-weight: bold;
  }
  .card-body form .mb-3 input {
    height: 50px !important;
    padding: 52px !important;
    font-size: 35px !important;
    border-radius: 20px !important;
  }
  .card-body form .mb-3 select {
    padding-left: 17px;
    height: 108px !important;
    padding: 25px 49px!important;
    font-size: 35px !important;
    border-radius: 20px !important;
    margin-bottom: 78px !important;
  }
  .formCard .formCardBody .btn {
    padding: 17px;
    font-size: 43px;
    border-radius: 27px;
  }
  .form-commodity-css {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
    background-position: 617px 42px;
    background-repeat: no-repeat;
    background-size: 70px 30px;
  }
  .formCard .formCardBody .imageBody img {
    width: 44%;
  }
  .Thanks{
    margin-left: 30px !important;
    font-size: 37px !important;
    margin-top: 37px !important;
  }
  .getBackToU{
    font-weight: bold;
    margin-left: 30px !important;
    margin-top: 67px !important;
    font-size: 38px !important;
  }
  .backToTopBtn{
    padding: 22px !important;
    font-size: 46px !important;
    border-radius: 22px !important;
    margin-top: 16rem !important;
    width: 97% !important;
  }
}
@media only screen and (max-width: 2300px) and (min-width: 2049px){
  .redValidation small {
    font-size: 25px !important;
}
  .formCard .formCardBody .form-label, .enterMobileLabel, .whichCommodityLabel {
    font-size: 25px !important;
    margin-bottom: 14px;
}
.card-body form .mb-3 input {
  height: 50px !important;
  padding: 42px !important;
  font-size: 27px !important;
  border-radius: 15px !important;
}
.input-box-1 .prefix-1 {
  font-size: 27px !important;
}
.card-body form .mb-3 select {
  padding-left: 17px;
  height: 87px !important;
  padding: 17px 49px!important;
  font-size: 28px !important;
  border-radius: 16px !important;
  margin-bottom: 78px !important;
}
.form-commodity-css {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
  background-position: 615px 32px;
  background-repeat: no-repeat;
  background-size: 59px 24px;
}
.formCard .formCardBody .btn {
  padding: 18px;
  font-size: 33px;
  border-radius: 20px;
}
.formCard .formCardBody {
  width: 72rem;
}
.form-css-1 {
  padding: 0rem 2.75rem !important;
}
}
@media only screen and (max-width: 2048px) and (min-width: 1441px){
  .formCard .formCardBody {
    width: 71rem !important;
  }
  .formCard .formCardBody {
      padding: 55px !important;
      border-radius: 24px !important;
      margin-top: 52px !important;
  }
  .formCard .formCardBody .form-label, .enterMobileLabel, .whichCommodityLabel {
    font-size: 28px !important;
    margin-bottom: 17px !important;
  }
  .card-body form .mb-3 input {
      padding: 44px !important;
      font-size: 31px !important;
  }
  .card-body form .mb-3>input, .form-css-1 {
      margin-bottom: 0px !important;
  }
  .enterMobileLabel{
    margin-top: 72px !important;
  }
  .whichCommodityLabel{
    margin-top: 50px !important;
    font-weight: bold;
  }
  .card-body form .mb-3 input {
    height: 50px;
    padding: 46px !important;
    font-size: 29px !important;
    border-radius: 13px !important;
  }
  .input-box-1 .prefix-1 {
    font-size: 29px !important;
  }
  .card-body form .mb-3 select {
    font-size: 29px !important;
  }
  .form-commodity-css {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
    background-position: 485px 39px;
    background-repeat: no-repeat;
    background-size: 60px 30px;
  }
  .formCard .formCardBody .imageBody img {
    width: 55%;
  }
  .Thanks{
    margin-left: 30px !important;
    font-size: 27px !important;
    margin-top: 37px !important;
  }
  .getBackToU{
    font-weight: bold;
    margin-left: 30px !important;
    margin-top: 55px !important;
    font-size: 29px !important;
  }
  .backToTopBtn{
    padding: 22px !important;
    font-size: 37px !important;
    border-radius: 21px !important;
    margin-top: 17rem !important;
    width: 91% !important;
  }
}
@media only screen and (max-width: 1920px) and (min-width: 1441px){
  .formCard .formCardBody {
    width: 61rem !important;
  }
  .redValidation small {
    font-size: 17px !important;
  }
  .formCard .formCardBody {
    padding: 44px !important;
    border-radius: 21px !important;
    margin-top: 92px !important;
  }
  .formCard .formCardBody .form-label, .enterMobileLabel, .whichCommodityLabel {
    font-size: 24px !important;
    margin-bottom: 27px !important;
  }
  .card-body form .mb-3 input {
    height: 79px !important;
    padding: 31px !important;
    font-size: 25px !important;
    border-radius: 14px !important;
  }
  #exampleInputEmail1{
    height: 79px !important;
    /* font-weight: bold; */
  }
  .input-box-1 .prefix-1 {
    font-size: 23px !important;
  }
  .card-body form .mb-3 select {
    height: 79px !important;
    padding: 24px !important;
    font-size: 23px !important;
    border-radius: 11px !important;
    margin-bottom: 60px !important;
  }
  .formCard .formCardBody .btn {
    padding: 17px;
    font-size: 27px;
    border-radius: 16px;
  }
  .card-body form .mb-3>input {
    margin-bottom: 0px !important;
   
  }
  .form-css-1 {
    margin-bottom: 0px !important;
    border-radius: 14px !important;
  }
  .enterMobileLabel{
    margin-top: 50px !important;
  }
  .whichCommodityLabel{
    margin-top: 23px !important;
    font-weight: bold;
  }
  .form-commodity-css {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
    background-position: 406px 29px;
    background-repeat: no-repeat;
    background-size: 56px 26px;
  }
  .formCard .formCardBody .imageBody img {
    width: 48%;
  }
  .Thanks{
    margin-left: 30px !important;
    font-size: 19px !important;
    margin-top: 26px !important;
  }
  .getBackToU{
    font-weight: bold;
    margin-left: 30px !important;
    margin-top: 40px !important;
    font-size: 24px !important;
  }
  .backToTopBtn{
    padding: 22px !important;
    font-size: 30px !important;
    border-radius: 21px !important;
    margin-top: 15rem !important;
    width: 91% !important;
  }
}
@media only screen and (max-width: 1822px) and (min-width:1709px){
  .redValidation small {
    font-size: 20px !important;
}
  .formCard .formCardBody {
    padding: 44px !important;
    border-radius: 21px !important;
    margin-top: 74px !important;
}
.formCard .formCardBody .form-label, .enterMobileLabel, .whichCommodityLabel {
  font-size: 20px !important;
  margin-bottom: 23px !important;
}
#exampleInputEmail1 {
  height: 70px !important;
  /* font-weight: bold; */
}
.card-body form .mb-3 input {
  height: 70px !important;
  padding: 28px !important;
  font-size: 22px !important;
  border-radius: 11px !important;
}
.card-body form .mb-3 select {
  height: 73px !important;
  padding: 21px !important;
  font-size: 21px !important;
  border-radius: 11px !important;
  margin-bottom: 61px !important;
}
.form .formBG {
  height: 77rem;
}
.formCard .formCardBody {
  width: 57rem !important;
}
.form-commodity-css {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
  background-position: 383px 22px;
  background-repeat: no-repeat;
  background-size: 50px 24px;
}
}
@media only screen and (max-width: 1708px) and (min-width:1681px){
  .redValidation small {
    font-size: 19px !important;
}
  .formCard .formCardBody {
    padding: 44px !important;
    border-radius: 21px !important;
    margin-top: 74px !important;
}
.formCard .formCardBody .form-label, .enterMobileLabel, .whichCommodityLabel {
  font-size: 20px !important;
  margin-bottom: 23px !important;
}
#exampleInputEmail1 {
  height: 70px !important;
  /* font-weight: bold; */
}
.card-body form .mb-3 input {
  height: 70px !important;
  padding: 28px !important;
  font-size: 22px !important;
  border-radius: 11px !important;
}
.card-body form .mb-3 select {
  height: 73px !important;
  padding: 21px !important;
  font-size: 21px !important;
  border-radius: 11px !important;
  margin-bottom: 61px !important;
}
.form .formBG {
  height: 77rem;
}
.formCard .formCardBody {
  width: 57rem !important;
}
.form-commodity-css {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
  background-position: 383px 22px;
  background-repeat: no-repeat;
  background-size: 50px 24px;
}
}
@media only screen and (max-width: 1680px) and (min-width: 1601px){
  .formCard .formCardBody {
    padding: 44px !important;
    border-radius: 21px !important;
    margin-top: 54px !important;
  }
  .form-commodity-css {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
    background-position: 429px 28px;
    background-repeat: no-repeat;
    background-size: 48px 26px;
  }
  .Thanks {
    margin-left: 30px !important;
    font-size: 19px !important;
    margin-top: 35px !important;
  }
  .backToTopBtn {
    padding: 18px !important;
    font-size: 25px !important;
    border-radius: 21px !important;
    margin-top: 15rem !important;
    width: 91% !important;
  }
  .formCard .formCardBody .imageBody img {
    width: 50%;
  }
}
@media only screen and (max-width: 1600px) and (min-width: 1441px){
  .formCard .formCardBody {
    width: 54rem !important;
    border-radius: 28px !important;
    margin-top: 66px !important;
  }
  .formCard .formCardBody .form-label, .enterMobileLabel, .whichCommodityLabel {
    font-size: 20px !important;
    margin-bottom: 13px !important;
  }
  .card-body form .mb-3 input {
    height: 50px !important;
    padding: 31px !important;
    font-size: 20px !important;
    border-radius: 11px !important;
  }
  #exampleInputEmail1{
    height: 50px !important;
  }
  .input-box-1 .prefix-1 {
    font-size: 19px !important;
  }
  .card-body form .mb-3>input{
    margin-bottom: 0px !important;
    border-radius: 11px !important;
  }
  .form-css-1 {
    margin-bottom: 0px !important;
    border-radius: 11px !important;
  }
  .enterMobileLabel{
    margin-top: 48px !important;
  }
  .whichCommodityLabel{
    margin-top: 20px !important;
    font-weight: bold;
  }
  .card-body form .mb-3 select {
    height: 72px !important;
    padding: 21px !important;
    font-size: 21px !important;
    border-radius: 11px !important;
    margin-bottom: 51px !important;
  }
  .formCard .formCardBody .btn {
    padding: 13px;
    font-size: 25px;
    border-radius: 11px !important;
  }
  .form-commodity-css {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
    background-position: 358px 25px;
    background-repeat: no-repeat;
    background-size: 43px 26px;
  }
.getBackToU {
  font-weight: bold;
  margin-left: 30px !important;
  margin-top: 40px !important;
  font-size: 22px !important;
}
.backToTopBtn {
  padding: 19px !important;
  font-size: 23px !important;
  border-radius: 12px !important;
  margin-top: 10rem !important;
  width: 94% !important;
}
  
}
@media only screen and (max-width: 1536px) and (min-width: 1519px){
  .card-body form .mb-3 select {
    height: 66px !important;
    font-size: 20px !important;
  }
  .form-commodity-css {
    background-position: 358px 20px;
  }
}
@media only screen and (max-width: 1440px){
  .redValidation small {
    font-size: 14px !important;
  }
  .enterMobileLabel{
    margin-top: 36px !important;
  }
  .whichCommodityLabel{
    margin-top: 4px !important;
    font-weight: bold;
  }
  .formCard .formCardBody .form-label, .enterMobileLabel, .whichCommodityLabel {
    font-size: 16px !important;
    margin-bottom: 13px !important;
  }
  .formCard .formCardBody .imageBody img {
    width: 36%;
  }
  .Thanks{
    margin-left: 30px !important;
    margin-top: 17px !important;
    font-size: 14px !important;
  }
  .getBackToU{
    font-weight: bold;
    margin-left: 30px !important;
    margin-top: 26px !important;
    font-size: 16px !important;
  }
  .backToTopBtn{
    padding: 15px !important;
    font-size: 19px !important;
    border-radius: 11px !important;
    margin-top: 8rem !important;
    width: 88% !important;
  }
}
@media only screen and (max-width: 992px){
  .formCard .formCardBody .form-label, .enterMobileLabel, .whichCommodityLabel {
    font-size: 12px !important;
    margin-bottom: 7px !important;
}
.form-commodity-css {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
  background-position: 191px 13px;
}
.enterMobileLabel {
  margin-top: 24px !important;
}
.whichCommodityLabel {
  margin-top: 0px !important;
  font-weight: bold;
}
.formCard .card-body select{
  margin-bottom: 23px !important;
}
.formCard .card-body input {
  height: 44px !important;
}
.formCard .card-body .inner-input {
  width: 157px;
  height: 100% !important;
}
.card-body form .mb-3 input, .card-body form .mb-3 input::placeholder, .card-body form .mb-3 select, .card-body form .mb-3 select::placeholder{
    font-size: 14px !important;
}
.prefix-1{
  font-size: 13px !important;
}
.form-control {
  height: 46px !important;
  display: flex !important;
}
}
@media only screen and (max-width: 775px){
  .form-css-1 {
      padding: 0.6rem 2rem !important;
  }
}
@media only screen and (max-width: 720px){
.form-commodity-css {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
    background-position: 168px 13px;
}
}
@media only screen and (max-width: 664px){
  .form-commodity-css {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
      background-position: 145px 13px;
      background-size: 34px 18px;
  }
  }
@media screen and (max-width: 630px) {
  .form-commodity-css {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
      background-position: 320px 13px;
  }
  .formCard .formCardBody .form-label, .enterMobileLabel, .whichCommodityLabel {
    font-size: 14px !important;
}
.card-body form .mb-3 input, .card-body form .mb-3 input::placeholder, .card-body form .mb-3 select, .card-body form .mb-3 select::placeholder {
    font-size: 15px !important;
}
}

@media screen and (max-width: 597px){
  .form-commodity-css {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
    background-position: 298px 13px;
}
}
@media screen and (max-width: 570px){
  .form-commodity-css {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
    background-position: 271px 13px;
}
}
@media screen and (max-width: 533px){
  .form-commodity-css {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
    background-position: 241px 13px;
    background-size: 32px 18px;
}
}
@media screen and (max-width: 477px){
  .formCard .formCardBody {
    margin-top: 66px;
  }
}
@media screen and (max-width: 490px){
  .form-commodity-css {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
    background-position: 213px 14px;
    background-size: 31px 15px;
}
}
@media screen and (max-width: 454px) {
  .form-commodity-css {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
    background-position: 191px 14px;
    background-size: 24px 15px;
  }
}
@media screen and (max-width: 403px) {
  .formCard .formCardBody {
      margin-top: 75px;
  }
}
@media screen and (max-width: 418px){
.form-commodity-css {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
    background-position: 163px 14px;
    background-size: 24px 15px;
}
.card-body form .mb-3 input, .card-body form .mb-3 input::placeholder, .card-body form .mb-3 select, .card-body form .mb-3 select::placeholder {
  font-size: 12px !important;
}
.formCard .formCardBody .form-label, .enterMobileLabel, .whichCommodityLabel {
  font-size: 13px !important;
}
}
@media screen and (max-width: 387px){
.form-commodity-css {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
    background-position: 140px 14px;
    background-size: 24px 15px;
}
}
@media only screen and (max-width: 369px){
  .form-css-1 {
      padding: 0.6rem 1.5rem !important;
  }
}

@media screen and (max-width: 358px){
  .form-commodity-css {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
    background-position: 129px 14px;
    background-size: 24px 15px;
}
}
@media only screen and (max-width: 357px){
  .form-css-1 {
      padding: 0.6rem 1rem !important;
  }
}
@media only screen and (max-width: 344px){
  .formCard .card-body .inner-input {
    width: 79% !important;
}
}
@media screen and (max-width: 334px){
.formCard .formCardBody {
  width: 111%;
  margin-left: -23px;
  margin-top: 50px;
}
}
@media screen and (max-width: 305px){
.form-commodity-css {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
    background-position: 120px 14px;
    background-size: 24px 15px;
}
.formCard .card-body .inner-input {
  padding: 0.375rem 0.5rem !important;
  width: 75% !important;
}
}
@media screen and (max-width: 283px){
.form-commodity-css {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
    background-position: 103px 14px;
    background-size: 24px 15px;
}
}
@media screen and (max-width: 275px){
  .formCard .card-body .inner-input {
    width: 70% !important;
  }
}
@media screen and (max-width: 263px){
  .form-commodity-css {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=);
    background-position: 86px 14px;
    background-size: 24px 15px;
}
.home_page-css {
  height: 519px;
}
}
@media only screen and (max-width: 357px){
  .form-css-1 {
      padding: 0.6rem 0.8rem !important;
  }
}




