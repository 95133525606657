@font-face {
    font-family: metropolis-medium;
    src: url('../assets/Metropolis_Font_family/Metropolis-Medium.otf');
}

@font-face {
    font-family: metropolis-light;
    src: url('../assets/Metropolis_Font_family/Metropolis-Light.otf');
}

@font-face {
    font-family: metropolis-regular;
    src: url('../assets/Metropolis_Font_family/Metropolis-Regular.otf');
}

@font-face {
    font-family: metropolis-semiBold;
    src: url('../assets/Metropolis_Font_family/Metropolis-SemiBold.otf');
}

.mainContainer {
    position: relative;
    margin: 0;
}

.innerContainer {
    position: relative;
    margin: 0;
}

.section,
.navOuter {
    position: relative;
    display: block;
    /* max-width: 100vw; */
}

.navOuter {
    /* min-width: 100vw; */
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #FEFEFE;
    box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
}

.navInner {
    position: relative;
    padding: 30px 50px;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-items: center;
    align-items: center;
    /* display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly; */
}

.main-logo{
    cursor: pointer;
}

.navInner>div {
    justify-self: center;
}

.navInner>button {
    justify-self: center;
    padding: 14px 22px;
    font-family: Metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
}

.nav-links {
    position: relative;
    padding: 0 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, auto);
    align-items: center;
    justify-items: center;
    font-size: 16px;
}


.nav-links>a,
.nav-links>div {
    color: #2F2F2F;
    text-decoration: none;
    cursor: pointer;
    padding: 0 10px;
    font-family: metropolis-medium, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 400;
}

.nav-links>a {
    transition: color 0.3s ease;
}

.nav-links>a:hover,
.nav-links>div:hover {
    color: #04B23D;
}

.mob-nav>div {
    position: relative;
    transition: all 0.5s ease;
}

.mob-nav>div>div {
    height: 0;
    overflow: hidden;
    background-color: transparent;
    text-align: center;
    transition: all 0.5s ease;
}

.mobOpen>div {
    height: 70px !important;
}

.nav-links>div {
    position: relative;
    transition: all 0.5s ease;
}

.nav-links>div>div {
    position: absolute;
    height: 0;
    overflow: hidden;
    transition: height 1s ease;
    background-color: transparent;
    text-align: center;
    width: 100%;
    left: 0;
}

.nav-links>div>div>div {
    background-color: white;
    margin-top: 20px;
    height: auto;
    width: 100%;
    box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
    border-radius: 8px;
    transition: color 0.3s ease;
    color: rgba(47, 47, 47, 1);
    padding: 24px 0;
}


.nav-links>div>div>div>div:first-of-type {
    height: 1px;
    background: #ECECEC;
    width: 70%;
    margin: 0 auto;
}

.nav-links>div>div>div>p {
    margin: 0;
    padding: 0;
    color: #2F2F2F;
}

.nav-links>div>div>div>p:first-of-type{
    padding-bottom: 16px;
}

.nav-links>div>div>div>p:last-of-type{
    padding-top: 16px;
}

.nav-links>div>div>div>p:hover{
    color: #04B23D;
}

.open>div {
    height: 150px !important;
}


.nav-links>a:last-of-type {
    display: grid;
    grid-template-columns: auto auto;
    justify-items: center;
    align-items: center;
}

.mob-nav>a:last-of-type {
    display: flex;
    align-items: flex-start;
    justify-content: center;

}

.mob-nav>a:last-of-type>span {
    padding-left: 8px;
}

.nav-links>a:last-of-type>span {
    padding-left: 8px;
    font-weight: 600;
}

.active {
    color: #04B23D !important;
}


/* Overlay */

.tcOverlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(47, 47, 47, 0.5);
    backdrop-filter: blur(3px);
    z-index: 12;
    overflow: auto;
    /* display: none; */
    opacity: 0;
    pointer-events: none;
}

.tcDisplay {
    /* display: block; */
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.3s ease;
}

.tcContainer {
    position: relative;
    margin: 100px 20vw;
    margin-bottom: 0;
    padding: 30px 50px;
    background-color: white;
    border-radius: 12px;
}

.tcContainer>svg {
    display: block;
    margin: 20px 0 0 auto;
    cursor: pointer;
}

.tcContainer h3 {
    color: #2F2F2F;
    text-align: center;
    padding: 0px 0 20px 0;
    font-size: 18px;
    font-weight: 600;
}

.tcContainer>div:first-of-type {
    position: relative;
    color: #2F2F2F;
    font-family: metropolis-regular, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    max-height: 200px;
    overflow: auto;
    margin: 30px 0 32px 0;
    line-height: 20px;
    font-size: initial;
}



.tcContainer > div:last-of-type{
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 12px;

    -webkit-box-shadow: inset 0px -200px 57px -3px rgba(255, 255, 255, 1);
    -moz-box-shadow: inset 0px -200px 57px -3px rgba(255, 255, 255, 1);

    /* Disable click events */
    pointer-events: none;
    box-shadow: inset 0px -200px 57px -3px rgb(255, 255, 255);

}

.tcContainer > button{
    position: relative;
    display: block;
    padding: 12px 100px;
    font-size: 18px;
    margin: 20px auto;
    margin-top: 10px;
    font-family: metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
}








.chatContent{
    position: relative;
    padding-left: 20px;
}

.toaster {
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    background: #3F3B3B;
    box-shadow: 0px 4px 12px rgba(64, 82, 88, 0.12);
    border-radius: 8px;
    padding: 12px 30px;
    font-family: metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
    opacity: 0;
    animation: popup 3s ease;
    z-index: 3;
}


@keyframes popup{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}


.chatContent > p{
    font-size: 14px;
    color: #2F2F2F;
    font-weight: 600;
    margin: 24px 0;
}

.chatContent > div:first-of-type{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #ECECEC;
    padding: 8px;
    width: fit-content;
    color: #2F2F2F;
    cursor: pointer;
    font-weight: 18px;

}

.chatContent > div:first-of-type > svg{
    margin-left: 60px;
}

.chatContent > div:last-of-type{
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 36px 0;
    margin-bottom: 20px;
}

.chatContent > div:last-of-type > img{
    max-width: 120px;
    margin-left: 24px;
}





.loginOverlay, .chatOverlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(47, 47, 47, 0.5);
    backdrop-filter: blur(3px);
    z-index: 11;
    overflow: auto;
    /* display: none; */
    opacity: 0;
    pointer-events: none;
}

.loginDisplay, .chatDisplay {
    /* display: block; */
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.3s ease;
}

.loginContainer {
    position: relative;
    margin: 160px 25vw;
    margin-bottom: 0;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: flex-start;
    /* justify-items: center; */
    background-color: white;
    border-radius: 12px;
}

.loginLeft {
    position: relative;
    background: url('./img/loginBackground.svg') center center no-repeat no-repeat;
    padding: 70px 80px;
    padding-bottom: 100px;
    background-size: cover;
    border-radius: 12px 0 0 12px;
    height: 100%;
    width: 100%;
}

.loginLeft>p:first-of-type {
    margin-top: 50px;
}

.loginLeft>p {
    font-size: 20px;
    margin: 10px 0;
    font-weight: 600;
}

.loginLeft>p>span {
    color: #04B23D;
}

.loginLeft>img {
    margin-top: 10px;
    max-width: 70px;
}

.loginRight {
    position: relative;
    padding: 30px 30px;
    padding-bottom: 70px;
    height: 100%;
    width: 100%;
}

.loginRight>div:first-of-type {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    justify-items: right;
    align-items: center;
    margin-bottom: 10px;
}

.loginRight>div:first-of-type>svg:first-of-type {
    opacity: 0;
    pointer-events: none;
    justify-self: left;
    margin-left: 20px;
    transition: opacity 0.5s ease-in-out;
}



.loginRight>div:first-of-type > svg{
    cursor: pointer;
}

.processContainer{
    display: grid;
    grid-template-columns: 100% 100%;
    overflow: hidden;
}

.processContainer>div {
    padding: 20px;
    transition: transform 0.5s ease-in-out;
    will-change: transform;
}

.otpActive>.processContainer>div {
    transform: translateX(-100%);
}

.otpActive>div:first-of-type>svg:first-of-type {
    opacity: 1;
    pointer-events: all;
}

.numberContainer>p:first-of-type {
    font-size: 14px;
    margin: 16px 0;
    font-weight: 600;
}

.numberContainer>div:first-of-type {
    padding: 16px 24px;
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    font-size: 16px;
    color: #979797;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 40px;
}

.numberContainer>div:first-of-type>span {
    padding-right: 10px;
    border-right: 1px #979797 solid;
}

.numberContainer>div:first-of-type>input {
    border: none;
    font-size: 16px;
    outline: none;
    width: 100%;
    padding-left: 10px;
    font-family: metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.numberContainer > p > a{
    cursor: pointer;
}

.numberContainer>button,
.otpContainer>button {
    font-size: 18px;
    width: 100%;
    padding: 16px 0;
    font-family: Metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
}

.numberContainer>p:last-of-type {
    font-size: 12px;
    font-weight: 100;
    color: #979797;
}

.numberContainer>p:last-of-type>a {
    text-decoration: none;
    color: #2F2F2F;
}

.otpContainer>p:first-of-type {
    font-size: 14px;
    color: #2F2F2F;
    margin: 16px 0;
}

.otpContainer>p:last-of-type {
    font-size: 11px;
    color: #2F2F2F;
}

.otpContainer>p:last-of-type>span:first-of-type {
    font-size: 12px;
    color: #2F2F2F;
    padding: 0 10px;
}

.otpContainer>p:last-of-type>span:last-of-type {
    color: #04B23D;
    font-size: 14px;
    font-size: 600;
    cursor: pointer;
}

.otpContainer>div {
    margin-bottom: 42px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-flow: wrap;
}

.otpContainer>div>input {
    width: 48px;
    height: 50px;
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    font-size: 18px;
    margin-right: 16px;
    text-align: center;
    outline: none;
}

.otpContainer > div > input:last-of-type{
    margin-right: 0;
}


.otpContainer>div>input::placeholder {
    text-align: center;
    color: #757575;
}

.otpContainer>div>input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    text-align: center;
    color: #757575;

}

.otpContainer>div>input::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
    color: #757575;
}

.otpContainer>div>input:-ms-input-placeholder {
    /* IE 10+ */
    text-align: center;
    color: #757575;
}

.otpContainer>div>input:-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
    color: #757575;
}




/* Sections */

.eCash .section1 {
    position: relative;
    display: block;
    padding: 80px 6vw;
    padding-bottom: 150px;
}

.eCash .section1Inner {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    justify-items: center;
    align-items: center;
}

.dentContainer {
    position: relative;
    display: flex;
    align-items: center;
}


.dent {
    position: relative;
    width: 4px;
    height: 20px;
    background: #FDE96C;
    border-radius: 0px 4px 4px 0px;
}

.dentContainer>p {
    margin: 0;
    margin-left: 8px;
    font-size: 12px;
    color: #757575;
}

.eCashLeft {
    padding-left: 100px;
}

.eCashRight>img {
    position: relative;
    width: 45vw;
    max-width: 700px;
}

.eCashLeft>button {
    width: 100%;
    padding: 12px 24px 12px 24px;
    margin: 40px 0;
    font-family: Metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
}

.eCashLeft>div:not(.dentContainer) {
    position: relative;
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.eCashLeft>div:not(.dentContainer)>span {
    margin-left: 20px;
    font-size: 18px;
}

.eCashLeft>p:first-of-type {
    font-size: 14px;
    color: #2F2F2F;
    margin: 20px 0;
    margin-bottom: 35px;
}

.eCashLeft>p:last-of-type {
    font-size: 12px;
    color: #757575;
}

.eCashRight {
    justify-self: right;
}
.eresearch-heading{
    font-size: 3.2rem;
    margin-top: 2rem;
}



/* eHedge */

.eHedge .section1Inner {
    position: relative;
    display: block;
}


.launchingSoon {
    position: relative;
    width: 100%;
    background-color: #FDE96C;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.launchingSoon>h2 {
    align-self: center;
    margin: 15px 0;
    color: #2F2F2F;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
}


.launchingSoon>svg:first-of-type {
    padding-right: 20px;
    transform: translateY(-30px) rotate(1.19deg);
}

.launchingSoon>svg:last-of-type {
    padding-left: 20px;
    transform: translateY(-28px) rotate(-0.19deg);
}

.comingSoonContainer {
    position: relative;
    display: grid;
    padding: 44px 6vw;
    padding-bottom: 150px;
    grid-template-columns: auto auto;
    align-items: flex-start;
    justify-items: center;
}

.comingSoonLeft>div:not(.dentContainer) {
    position: relative;
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.comingSoonLeft>div:not(.dentContainer)>span {
    margin-left: 20px;
    font-size: 18px;
}

.comingSoonLeft>div:not(.dentContainer)>span:last-of-type {
    max-width: 90%;
}

.comingSoonLeft>p:first-of-type {
    font-size: 14px;
    color: #2F2F2F;
    margin: 20px 0;
    margin-bottom: 35px;
}

.comingSoonLeft>p:last-of-type {
    font-size: 12px;
    color: #757575;
}

.comingSoonLeft>div:last-of-type {
    position: relative;
    width: 100%;
    margin: 40px 0;
    justify-content: flex-start;
}

.comingSoonLeft>div:last-of-type>button {
    border: 1px solid #04B23D;
    border-radius: 8px;
    padding: 12px 40px;
    color: #04B23D;
    font-size: 18px;
    background-color: white;
}

.comingSoonLeft>div:last-of-type>button:first-of-type {
    margin-right: 20px;
}

.comingSoonLeft>div:last-of-type>button:last-of-type {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.comingSoonLeft>div:last-of-type>button:last-of-type>svg {
    margin-right: 10px;
}

.comingSoonRight {
    position: relative;
    padding: 46px 0;
    box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
    border-radius: 12px;
    width: 39rem;
}

.comingSoonRight>h3 {
    margin: 0;
    margin-bottom: 10px;
    color: #2F2F2F;
    font-size: 18px;
    font-weight: 500;
    padding: 0 50px;

}

.comingSoonRight>span {
    color: #04B23D;
    font-size: 18px;
    font-weight: 500;
    padding: 0 50px;

}

.comingSoonRight>div:first-of-type {
    border: 1px solid #ECECEC;
    margin: 36px 0;
}

.comingSoonRight>div:nth-of-type(2) {
    position: relative;
    padding: 0 50px;
}

.formContainer>p {
    font-size: 16px;
    color: #2F2F2F;
    margin-bottom: 16px;
    margin-top: 0;
    font-weight: 600;
}

.formContainer>div,
.formContainer>input,
.formContainer>select {
    margin: 0;
    margin-bottom: 36px;
    width: 100%;
    font-family: metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.formContainer>input {
    padding: 16px 24px;
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    font-size: 16px;
}

.formContainer>input:focus {
    outline: none;
}

.formContainer input::placeholder {
    font-size: 16px;
    color: #979797;
}

.formContainer input:-ms-input-placeholder {
    font-size: 16px;
    color: #979797;
}

.formContainer>div:first-of-type {
    padding: 16px 24px;
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    font-size: 16px;
    color: #979797;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.formContainer>div:first-of-type>span {
    padding-right: 10px;
    border-right: 1px #979797 solid;
    font-weight: 500;
    font-family: metropolis-medium, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.formContainer>div:first-of-type>input {
    border: none;
    font-size: 16px;
    outline: none;
    width: 100%;
    padding-left: 10px;
    font-family: metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.formContainer>select {
    padding: 16px 24px;
    outline: none;
    border: 1px solid #D3D3D3;
    color: black;
    display: block;
    border-radius: 8px;
    font-size: 16px;
    background-color: white;
    font-family: 'metropolis-regular';
}
.default-buying-commodity{
    color: #979797;
}

.formContainer>button {
    width: 100%;
    padding: 12px 16px;
    font-weight: 100;
    font-family: metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 500;
}






/* About Us */


.aboutUs .section1 {
    position: relative;
    display: block;
    padding: 80px 10vw;
    padding-bottom: 150px;
}

.aboutUs .section1Inner {
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
    align-items: flex-start;
}

.aboutUsLeft {
    font-size: 18px;
    line-height: 30px;
    padding: 0 50px;
}

.aboutUsLeft>p {
    margin-bottom: 50px;
}

.aboutUsRight {
    justify-self: flex-end;
    align-self: center;
}

.aboutUsRight>img {
    padding-left: 50px;
    width: 100%;
    max-width: 45vw;
    margin: 0 0 20px auto;
    display: block;
}

/* Policy */


.policy .section1 {
    position: relative;
    display: block;
    padding: 80px 10vw;
    padding-bottom: 150px;
}

.policy .section1Inner {
    position: relative;
    display: block;
}

.policy h1 {
    text-align: center;
    margin: 70px 0;
    margin-top: 10px;
    font-weight: 500;
    font-size: 32px;
    color: #464646;
}

.downloadsContainer {
    position: relative;
    border: 1px solid #F2F2F2;
}

.file {
    position: relative;
    display: grid;
    grid-template-columns: 8fr auto auto auto;
    align-items: center;
    justify-items: center;
    border-bottom: 1px solid #F2F2F2;
    padding: 20px 10px;
}

.file>div {
    padding: 0 20px;
    display: flex;
    align-items: center;
}

.file svg {
    margin: 0 20px;
    min-width: 24px;
}

.file>div:first-of-type {
    justify-self: left;
}

.file>div:first-of-type>svg {
    min-width: 18px;

}

.file>div:nth-of-type(2)>svg {
    min-width: 16px;

}

.file>div:nth-of-type(3)>svg {
    min-width: 20px;

}

.file>div>svg {
    margin-right: 10px;
}


.file>div>span {
    font-weight: 500;
    font-size: 16px;
    color: #757575;
    transform: translateY(2px);
}


.file>div:first-of-type>span {
    color: #2F2F2F;
}










.section7 {
    position: relative;
    display: block;
    background: #312B25;
}

.section7Inner {
    position: relative;
    display: block;
    padding: 50px 150px;
    color: white;
    font-weight: bold;
    font-family: metropolis-regular, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.footerUpper {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    padding: 30px 0;
    padding-bottom: 70px;
    border-bottom: 1px solid #413F3E;
}

.footerUpper>div:first-of-type>p {
    position: relative;
    margin: 20px 0;
    font-size: initial;
}

.footerUpper>div:last-of-type {
    position: relative;
    justify-self: right;
}

.footerLower {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    padding: 30px 0;
    padding-top: 70px;
}


.aboutApps {
    position: relative;
    justify-self: right;
}

.aboutApps>div:first-of-type {
    position: relative;
    display: grid;
    grid-template-columns: auto auto auto auto;
    align-items: flex-start;
    justify-items: center;
    width: fit-content;
}

.aboutApps>div:first-of-type>img {
    margin: 0 10px;
}

.aboutApps>div:first-of-type>img:first-of-type {
    max-width: 32px;
    max-height: 32px;
}

.aboutApps>div:first-of-type>img:nth-of-type(2) {
    max-width: 32px;
    max-height: 32px;
}

.aboutApps>div:first-of-type>img:last-of-type {
    max-width: 150px;
    max-height: 150px;
}

.aboutApps>div:first-of-type>span {
    transform: translate(0, 7px);
    padding: 0 10px;
    font-size: initial;
}

.aboutApps>div:last-of-type {
    position: relative;
    margin-top: 60px;
    color: #979797;
    display: flex;
    align-items: center;
    font-size: initial;
}

.aboutApps>div:last-of-type>svg {
    align-self: flex-start;
    margin-right: 10px;
}

.aboutLinks {
    position: relative;
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: flex-start;
    justify-items: left;
}

.aboutLinks>div>h3 {
    position: relative;
    margin-top: 0;
    margin-bottom: 35px;
    font-size: 19px;
}

.aboutLinks>div>a {
    position: relative;
    display: block;
    margin: 20px 0;
    color: #D3D3D3 !important;
    font-weight: 100;
    text-decoration: none;
    cursor: pointer;
    font-size: initial;
}

.aboutLinks>div>a:hover{
    color:#04B23D;
}

.cgp {
    max-width: 80%;
    /* margin: 20px auto !important; */
    text-decoration: none;
}

.footerUpper>div:last-of-type>h1 {
    font-weight: 500;
    font-size: 24px;
    margin: 30px 0;
    margin-top: 0;
}

.footerUpper>div:last-of-type>div {
    position: relative;
    display: grid;
    grid-template-columns: 60% 40%;
}

.footerUpper>div:last-of-type>div>input {
    font-size: 14px;
    outline: none;
    height: 100%;
    border-radius: 8px 0 0 8px;
    border: none;
    padding: 0 33px;
    font-family: Metropolis-regular, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: black;
    text-align: left;
}

.footerUpper>div:last-of-type>div>input::placeholder {
    text-align: center;
    color: #757575;
}

.footerUpper>div:last-of-type>div>input::-ms-input-placeholder {
    text-align: center;
    color: #757575;
}

.footerUpper>div:last-of-type>div>button {
    padding: 15px 24px;
    font-size: 1.1rem;
    border-radius: 0 8px 8px 0;
    font-family: Metropolis-regular, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-family: 400;
    font-size: 19px;
}


@media(min-height: 1371px){
    .innerContainer{
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    .innerContainer > .section:last-of-type{
        margin: auto 0 0 0;
    }

}

@media(min-width: 2000px){
    .loginContainer{
        margin: 160px 27vw;
    }
}

@media(min-width: 2400px){  
    .loginContainer{
        margin: 160px 30vw;
    }
}



@media(min-width: 2600px){
    .aboutUs .section1, .eCash > .section1{
        padding: 80px 15vw;
    }

    .eHedge > .section1 .comingSoonContainer{
        padding: 44px 15vw;
        padding-bottom: 150px;
    }

    .section7Inner{
        padding: 50px 15vw;
    }

    .eCashLeft{
        justify-self: right;
        padding: 0;
        padding-right: 250px;
    }

    .eCashRight{
        justify-self: left;
        padding: 0;
        padding-left: 250px;
    }

    .loginContainer{
        margin: 160px 32vw;
    }
}

@media(min-width: 3000px){
    .loginContainer{
        margin: 160px 35vw;
    }
}

@media(min-width: 3400px){
    .loginContainer{
        margin: 160px 37vw;
    }
}

@media(min-width: 3800px){
    .aboutUs .section1, .eCash > .section1{
        padding: 80px 20vw;
    }

    .eHedge > .section1 .comingSoonContainer{
        padding: 44px 20vw;
        padding-bottom: 150px;
    }

    .section7Inner{
        padding: 50px 20vw;
    }
}

@media(min-width: 5500px){
    .loginContainer{
        margin: 160px 41vw;
    }
}


@media(min-width: 5700px){
    .aboutUs .section1, .eCash > .section1{
        padding: 80px 25vw;
    }

    .eHedge > .section1 .comingSoonContainer{
        padding: 44px 25vw;
        padding-bottom: 150px;
    }

    .section7Inner{
        padding: 50px 25vw;
    }

    .navInner{
        padding: 30px 10vw;
    }
}

@media(min-width: 7000px){
    .aboutUs .section1, .eCash > .section11{
        padding: 80px 30vw;
    }

    .eHedge > .section1 .comingSoonContainer{
        padding: 44px 30vw;
        padding-bottom: 150px;
    }

    .section7Inner{
        padding: 50px 30vw;
    }

    .loginContainer{
        margin: 160px 43vw;
    }

}



@media(max-width: 1533px) {

    .loginContainer {
        margin: 160px 20vw;
        margin-bottom: 0;
    }
}

@media(max-width: 1461px) {
    .aboutUsLeft {
        font-size: 18px;
    }

    .aboutUsLeft>p {
        margin-bottom: 10px;
    }

}

@media(max-width: 1327px) {
    .aboutUsLeft {
        font-size: 16px;
    }

    .aboutUsLeft>p {
        margin-bottom: 10px;
    }
}



@media(max-width: 1237px) {
    .loginContainer {
        margin: 160px 15vw;
        margin-bottom: 0;
    }

    .tcContainer{
        margin: 100px 15vw;
        margin-bottom: 0;
    }
}



@media(max-width: 1200px) {

    .section7Inner {
        padding: 50px 70px;
    }

}

@media(max-width: 1177px) {
    .aboutUsLeft {
        padding: 0 20px;
        font-size: 16px;
    }

    .aboutUsLeft>p {
        margin-bottom: 10px;
    }

    .aboutUsRight>img {
        margin: 10px 0;
        padding-left: 25px;
    }
}

@media(max-width: 1100px) {
    .eCashLeft {
        padding-left: 0;
    }
}

@media(max-width: 1049px) {
    .loginContainer {
        margin: 160px 10vw;
        margin-bottom: 0;
    }

    .loginLeft {
        padding: 50px 40px;
        padding-bottom: 50px;
    }

    .loginRight {
        padding: 20px;
        padding-bottom: 30px;
    }

    .loginLeft>svg {
        margin-top: 20px;
    }

    .loginLeft>p:first-of-type {
        margin-top: 30px;
    }

    .aboutUsLeft {
        font-size: 14px;
    }

    .tcContainer{
        margin: 100px 10vw;
        margin-bottom: 0;
    }

}


@media(max-width: 1025px) {

    .nav-links>a,
    .nav-links>div {
        font-size: 1.6rem;
    }
}

@media(max-width: 990px) {

    .nav-links>a,
    .nav-links>div {
        font-size: 1.3rem;
    }

    .footerUpper {
        grid-template-columns: 100%;
        justify-items: center;
    }

    .footerUpper>div:last-of-type {
        justify-self: center;
    }

    .footerUpper>div:first-of-type {
        margin-bottom: 40px;
    }

    .footerUpper>div:first-of-type>svg {
        display: block;
        margin: 0 auto;
    }

    .footerUpper>div:first-of-type>p {
        text-align: center;
    }

    .footerUpper>div:last-of-type>h1 {
        text-align: center;
    }

    .footerLower {
        display: block;
    }

    .aboutLinks {
        justify-items: center;
        margin-bottom: 50px;
        text-align: center;
    }

    .aboutApps>div:first-of-type {
        width: 100%;
    }

    .aboutApps>div:last-of-type {
        text-align: center;
        justify-content: center;
    }


    .cgp {
        max-width: 80%;
        margin: 20px auto !important;
        text-decoration: none;
    }

}

.hamContainer {
    display: none;
}

.mob-nav {
    display: none;
}

@media(max-width: 950px) {
    .comingSoonContainer {
        display: block;
    }

    .comingSoonLeft>div:last-of-type {
        width: fit-content;
    }
}

@media(max-width: 903px) {
    .register {
        display: none;
    }

    .nav-links {
        display: none;
    }

    .navInner {
        grid-template-columns: auto auto;
        justify-items: left;
    }

    .navInner>.hamContainer {
        justify-self: right;
    }

    .hamContainer {
        display: block;
    }

    .checkbox {
        position: absolute;
        display: block;
        height: 32px;
        width: 32px;
        /* top: 20px;
        left: 20px; */
        z-index: 5;
        opacity: 0;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        user-select: none;
        -webkit-touch-callout: none
    }


    .hamburger-lines {
        display: block;
        height: 26px;
        width: 32px;
        /* position: absolute; */
        /* top: 17px;
        left: 20px; */
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .hamburger-lines .line {
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #04B23D;
    }

    .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.4s ease-in-out;
    }

    .hamburger-lines .line2 {
        transition: transform 0.2s ease-in-out;
    }

    .hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.4s ease-in-out;
    }

    .mob-nav {
        position: fixed;
        padding: 120px 0;
        left: 100%;
        display: block;
        transform: translate(0);
        transition: transform 0.5s ease-in-out;
        text-align: center;
        background-color: #2F2F2F;
        min-width: 40vw;
        max-width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        font-family: metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-weight: 500;
    }

    .mob-nav>a,
    .mob-nav>div,
    .mob-nav>button {
        display: block;
        color: white;
        text-decoration: none;
        margin: 0px 20px;
    }

    .mob-nav>button {
        padding: 12px 20px;
        height: max-content;
        font-family: metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-weight: 500;
    }


    .navOuter input[type="checkbox"]:checked~.hamburger-lines .line1 {
        transform: rotate(45deg);
    }

    .navOuter input[type="checkbox"]:checked~.hamburger-lines .line2 {
        transform: scaleY(0);
    }

    .navOuter input[type="checkbox"]:checked~.hamburger-lines .line3 {
        transform: rotate(-45deg);
    }

    .show-nav-side {
        transform: translateX(-100%);
    }


    .aboutUs .section1Inner {
        display: block;
    }

    .aboutUsLeft {
        font-size: 16px;
        padding: 0;
        margin: 0;
        margin-bottom: 30px;
    }

    .aboutUsRight>img {
        max-width: initial;
        padding: 0;
        margin: 0;
        margin-bottom: 30px;
    }

}

@media(max-width: 903px) and (max-height: 631px) {
    .mob-nav {
        padding: 20px;
    }
}


@media(max-width: 903px) and (max-height: 496px) {
    .mob-nav {
        display: flex;
        justify-content: space-around;
        padding: 100px 40px;
        width: 100vw;
        max-width: 100vw;
        flex-flow: wrap;
        height: auto;
    }

    .mob-nav>a,
    .mob-nav>div,
    .mob-nav>button {
        margin: 10px;
    }
}

@media(max-width: 903px) and (max-height: 242px) {
    .innerContainer {
        overflow: hidden;
    }

    .navOuter {
        position: relative;
    }

    .mob-nav {
        position: absolute;
    }
}

@media(max-width: 800px) and (min-width: 774px){
    .otpContainer > div > input{
        height: 40px;
        width: 38px;
        font-size: 16px;
    }

    .otpContainer > div{
        margin-bottom: 40px;
    }
}

@media(max-width: 711px) and (min-width: 665px){
    .otpContainer > div > input{
        height: 40px;
        width: 38px;
        font-size: 16px;
    }
}


@media(max-width: 356px){
    .otpContainer > div{
        justify-content: flex-start;
        align-items: center;
    }
    .otpContainer > div > input{
        height: 40px;
        width: 38px;
        font-size: 16px;
        margin-right: 5px;
    }
}


@media(max-width: 800px) {
    .navInner {
        padding: 30px 20px;
    }

    .eCash .section1Inner {
        display: block;
    }

}

@media(max-width: 775px) {
    .loginContainer {
        margin: 160px 5vw;
        margin-bottom: 0;
    }
}

@media(max-width: 750px) {
    .file {
        grid-template-areas: "a d" "b d" "c d";
        justify-items: left;
    }

    .file>div {
        margin: 10px;
    }

    .file>div:nth-of-type(1) {
        grid-area: a;
    }

    .file>div:nth-of-type(2) {
        grid-area: b;
    }

    .file>div:nth-of-type(3) {
        grid-area: c;
    }

    .file>svg {
        grid-area: d;
    }
}

@media(max-width: 700px) {

    .section7Inner {
        padding: 50px;
    }

    .eCashLeft {
        padding-left: 0px;
    }

    .eCashRight>img {
        position: relative;
        width: 90vw;
        margin: 0 auto;
        max-width: 700px;
    }




}

@media(min-width: 665px) and (max-height: 650px){
    .tcContainer{
        margin: 90px 10vw;
        margin-bottom: 0;
    }

    .tcContainer h3{
        padding: 0;
        margin: 10px 0;
    }

    .tcContainer>div:first-of-type{
        max-height: 180px;
    }
}



@media(min-width: 665px) and (max-height: 570px) {

    .toaster{
        top: 70px;
    }

    .loginContainer {
        margin: 50px 10vw;
        margin-bottom: 0;
    }

    .loginLeft {
        padding: 30px 40px;
        padding-bottom: 20px;
    }

    .loginRight {
        padding: 20px;
        padding-bottom: 30px;
    }

    .otpContainer > div > input{
        height: 40px;
        width: 38px;
        margin-right: 10px;
    }
}

@media(min-width: 665px) and (max-height: 534px){
    .tcContainer{
        margin: 50px 10vw;
        margin-bottom: 0;
    }

    .tcContainer h3{
        padding: 0;
        margin: 10px 0;
    }

    .tcContainer>div:first-of-type{
        max-height: 180px;
    }
}



@media(min-width: 665px) and (max-height: 508px) {

    .toaster{
        top: initial;
        bottom: 50px;
    }

    .loginContainer {
        margin: 30px 10vw;
    }


    .loginLeft {
        padding: 20px 40px;
        padding-bottom: 20px;
    }

    .loginRight {
        padding: 20px;
        padding-bottom: 10px;
    }

    .tcContainer{
        margin: 50px 10vw;
        margin-bottom: 0;
    }

    .tcContainer h3{
        padding: 0;
        margin: 10px 0;
    }

    .tcContainer > div:last-of-type{
        box-shadow: none;
    }

    .tcContainer>div:first-of-type{
        max-height: 100px;
    }

    .tcContainer > button{
        margin: 0 auto;
    }

}

@media(max-width: 665px) and (max-height: 733px) {
    .loginLeft {
        padding: 10px 40px;
        padding-bottom: 20px;
    }

    .loginRight {
        padding: 10px;
        padding-bottom: 10px;
    }

    .loginRight>svg {
        margin: 10px 10px 0 auto;
    }

    .processContainer>div {
        padding: 10px 20px;
    }
}


@media(max-width: 665px) {

    .toaster{
        top: initial;
        bottom: 50px;
    }

    .loginContainer {
        margin: 50px 5vw;
        display: flex;
        flex-direction: column-reverse;
    }

    .loginLeft {
        border-radius: 0 0 12px 12px;
    }

    .chatContent{
        width: fit-content;
        margin: 0 auto;
    }
}


@media(max-width: 625px) {
    .launchingSoon>h2 {
        font-size: 20px;
    }
}


@media(max-width: 600px) {

    .section7Inner {
        padding: 50px 20px;
    }

    .aboutApps>div:last-of-type {
        flex-direction: column;
    }

    .aboutApps>div:last-of-type>svg {
        align-self: center;
        margin-bottom: 15px;
        height: 22px;
        width: 26px;
    }

    .loginContainer {
        margin: 40px 5vw;
    }

    .chatOverlay > .loginContainer{
        margin: 35px 5vw;
    }

    .numberContainer>div:first-of-type {
        margin-bottom: 20px;
    }

    .otpContainer > div{
        margin-bottom: 22px;
    }
}


@media(max-width: 665px) and (min-width:444px) and (min-height: 570px) {
    .loginContainer {
        margin: 50px 10vw;
    }
}


@media(max-width: 586px) {
    .launchingSoon>h2 {
        font-size: 18px;
    }
}

@media(max-width: 540px) {
    .mob-nav {
        min-width: 50vw;
    }
}

@media(max-width: 470px){
    .tcContainer{
        margin: 100px 5vw;
        margin-bottom: 0;
        padding: 30px;
    }

    .tcContainer>svg{
        margin: 0 0 0 auto;
    }
}

@media(max-width: 450px) {

    .comingSoonLeft>div:last-of-type {
        display: block;
    }

    .comingSoonLeft>div:last-of-type>button {
        margin-bottom: 16px;
    }

    .comingSoonLeft>div:last-of-type>button:last-of-type {
        margin-bottom: 36px;
    }

    .nav-links>a,
    .nav-links>div {
        font-size: 0.5rem;
    }

    .aboutLinks>div>h3 {
        font-size: 16px;
    }

    .aboutLinks>div>a {
        font-size: 14px;
    }

    .aboutApps>div:first-of-type {
        display: block;
    }

    .aboutApps>div:first-of-type>span {
        transform: none;
    }

    .aboutApps>div:first-of-type {
        display: grid;
        grid-template-columns: none;
        grid-template-areas: "a a a" "b c d";
        justify-items: flex-start;
        align-items: start;
    }


    .aboutApps>div:first-of-type>span {
        transform: none;
        grid-area: a;
        margin-bottom: 20px;
    }

    .aboutApps>div:first-of-type>img:nth-of-type(1){
        grid-area: b;
    }

    .aboutApps>div:first-of-type>img:nth-of-type(2){
        grid-area: c;
    }

    .aboutApps>div:first-of-type>img:nth-of-type(3){
        grid-area: d;
    }

    .tcContainer > button{
        padding: 12px 50px;
    }


}

@media(max-width: 345px) and (min-height: 496px) {
    .mob-nav {
        width: 100vw;
    }
}


@media(max-width: 374px){

    .toaster{
        font-size: 0.8rem;
        /* width: auto; */
    }

    .chatOverlay > .loginContainer{
        margin: 30px 5vw;
    }

    .chatContent > p{
        margin: 18px 0;
    }
}



@media(max-width: 360px) {
    .file {
        grid-template-areas: "a" "b" "c" "d";
        justify-items: left;
    }

    .file>svg {
        margin: 10px 30px;
    }
}

@media(max-width: 324px){
    .tcContainer > button{
        padding: 12px 20px;
    }

    .tcContainer{
        padding: 30px;
    }

    .tcContainer>svg {
        display: block;
        margin: 0px 0 20px auto;
    }

    .tcContainer h3{
        padding: 20px;
    }
}

@media(max-width: 305px) {
    .footerUpper>div:last-of-type>div {
        display: block;
    }

    .footerUpper>div:last-of-type>div>input {
        outline: none;
        height: 100%;
        border-radius: 8px;
        border: none;
        font-size: 16px;
        padding: 12px 10px;
        font-family: Metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        color: black;
        text-align: center;
        display: block;
        width: 100%;
    }

    .footerUpper>div:last-of-type>div>button {
        display: block;
        margin-top: 20px;
        border-radius: 8px;
        width: 100%;
    }

    .aboutLinks {
        display: flex;
        flex-direction: column;
        flex-flow: wrap;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 100%;
    }

    .aboutLinks>div {
        margin-bottom: 30px;
    }

    .aboutApps>div:first-of-type {
        display: grid;
        
        grid-template-areas: "a" "b" "c" "d";
        justify-items: center;
        align-items: start;
    }

    .aboutApps>div:first-of-type>span, .aboutApps>div:first-of-type>img{
        margin: 10px;
    }

}

@media(max-width: 295px){
    .otpContainer > div{
        justify-content: center;
        align-items: center;
    }
    .otpContainer > div > input{
        height: 40px;
        width: 38px;
        font-size: 16px;
        margin-right: 5px;
    }
    .otpContainer>p:last-of-type>span:first-of-type{
        padding: 0;
    }

    .chatContent > div:last-of-type{
        flex-direction: column;
        justify-content: flex-start;
    }

    .chatContent > div:last-of-type > img{
        margin: 0;
        margin-top: 16px;
    }

    .chatOverlay .loginLeft{
        padding: 30px 40px;
    }

    .chatRight{
        padding: 20px;
    }
}

@media(max-width: 288px){
    .toaster{
        font-size: 0.7rem;
        width: auto;
    }
}

@media(max-width: 275px){
    .otpContainer > div{
        justify-content: center;
        align-items: center;
    }

    .otpContainer > div > input{
        margin: 5px;
    }

    .processContainer>div{
        padding: 10px;
    }
}


@media(max-width: 264px){
    .chatContent > div:first-of-type > svg{
        margin-left: 20px;
    }
}

@media(max-width: 231px){
    .eGrid>div:last-of-type>button{
        display: none;
    }
}