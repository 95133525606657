.custom-table {
    text-align   : left;
    font-size    : 0.72em;
    border-radius: 10px;

    .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
        top        : 75%;
        left       : 30%;
        margin-top : -.5em;
        margin-left: -.5em;
        position   : absolute;
        font-size  : 11px;
    }

    .p-checkbox {
        .p-checkbox-box {
            width        : 1.4em;
            height       : 1.4em;
            border-radius: 3px;
            border       : 1px solid #a6a6a6;
        }

        .p-checkbox-icon {
            font-size: 1.2em;
        }
    }

    .p-datatable-scrollable-header{
        .p-datatable-scrollable-header-table{
            .p-filter-column{
                .p-inputtext{
                    border: 1px solid #ACACAC;
                    border-radius: 5px;
                }

                .table-filter-dropdown{
                    position: absolute;
                    top: 3.5em;
                    width: 10em;
                    .p-dropdown {
                        .p-dropdown-panel{
                            border: 1px solid;
                            
                        }

                        .p-dropdown-label{
                            color: black;
                        }

                        .p-dropdown-trigger-icon{
                            top: 55%;
                        }

                        .p-dropdown-panel{
                            background: #fff;

                            li:hover {
                                background: #ecefe9;
                                border-radius: 4px;
                                box-shadow: 0px 1.5px 3px #4b652e4D;
                            }
                        }

                        
                    }
                }

            }
        }
    }

    .p-datatable,
    .p-datatable-scrollable-body {
        margin       : 0 auto;
        // box-shadow: 0px 4px 10px #00000029;
        border-bottom: none;
        font-family  : sans-serif;
        font-size    : 12px;

        .p-datatable-wrapper,
        .p-datatable-scrollable-wrapper {
            position: relative;
            .p-datatable-thead {
                >tr {
                    height        : 3em;
                    text-transform: capitalize;
                    color         : #ACACAC;

                }
            }

            .p-datatable-tbody {
                .p-datatable-row {
                    height: 4em;

                    text-transform: capitalize;
                    // cursor        : pointer;
                    background    : #ededed;
                    color         : #122025;
                    font-weight   : 600;
                    word-wrap: break-word;

                    td {
                        border-top   : 1px solid #D8D8D8;
                        border-bottom: 1px solid #D8D8D8;

                    }

                    td:first-child {
                        border-top-left-radius   : 5px;
                        border-bottom-left-radius: 5px;
                    }

                    td:last-child {
                        border-top-right-radius   : 5px;
                        border-bottom-right-radius: 5px;
                    }
                }

                .p-datatable-row:hover {
                    background: #ffffff;
                }

                .p-datatable-emptymessage{
                    text-align: center;
                }
            }
        }

        .p-paginator {
            height: 3.9em;

            .p-paginator-pages {
                .p-highlight {
                    background     : #3A7ECB;
                    color          : #fff;
                    //border-radius: 6px;
                }
            }

            .p-dropdown {
                margin-top   : 0.5em;
                border-bottom: 1px solid #00000029;
                float        : left;

                .p-dropdown-items-wrapper {
                    background: #fff;
                    border    : 1px solid #00000029;
                }
            }

            .p-paginator-element {
                width : 1.8em;
                height: 1.8em;

                .p-paginator-icon {
                    top: 65%;
                }
            }

            .p-paginator-left-content {
                margin     : 0.8em 0 0 0.5em;
                font-size  : 13px;
                font-family: sans-serif;
            }

            .p-paginator-right-content {
                margin-right: 4em;

                >div {
                    display   : flex;
                    margin-top: 0.8em;

                    img {
                        cursor     : pointer;
                        margin-left: 0.5em;
                        width      : 1em;
                        height     : 1em;
                    }

                    .pi-refresh {
                        margin-left: 1em;
                        color      : #3A7ECB;
                    }
                }
            }
        }
    }
}