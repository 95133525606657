.box p {
    color: var(--grayishBlue);
}

.products .small-box{
width: 5px;
height: 30px;
background-color: #FDE96C;
box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);

}

@font-face {
    font-family: MetropolisMedium;
    src: url('Metropolis-Medium.otf')
}

@font-face {
    font-family: MetropolisBold;
    src: url('Metropolis-Bold.otf')
}

@font-face {
    font-family: MetropolisRegular;
    src: url('Metropolis-Regular.otf')
} 

.container-css{
    font-family: MetropolisMedium;
}

.products .smallBox{
    display: flex;
    flex-direction: row;
}
.boxText{
    color: #757575 !important;
    /* font-size: 12px; */
    font-size: 22px;
    padding-bottom: 5px;
}
.h4-css {
    font-size: 24px;
    padding-bottom: 7px;
}
.box .image{
    width: 36px !important;
    height: 28px !important;
    margin-right: 11px;
}

.box {
    box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
    border-radius: 12px;
    /* border-radius: 5px;
    box-shadow: 0px 30px 40px -20px var(--grayishBlue); */
    padding: 30px;
    margin: 20px 12px;
    cursor: pointer;
}
.emandiNewsSectionn .row1-container .box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
} 
.emandiNewsSectionn .row1-container .box .card-body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.products .text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.products .instantCash {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.box img {
    /* float: right; */
}

/* @media (max-width: 450px) {
    .box {
        height: 200px;
    }
}

@media (max-width: 950px) and (min-width: 450px) {
    .box {
        text-align: center;
        height: 180px;
    }
} */

.text {
    font-size: small;
}

/* .yellow {
    border-top: 3px solid #FDE96C;
} */

/* .red {
    border-top: 3px solid var(--red);
}
.blue {
    border-top: 3px solid var(--blue);
}
.orange {
    border-top: 3px solid var(--orange);
} */

h2 {
    color: var(--varyDarkBlue);
    font-weight: var(--weight3);
}
.instantCash{
    font-size: 12px;
}
@media only screen and (max-width: 7680px) and (min-width: 5747px){
    .box {
        width: 23% !important;
        padding: 57px !important;
        min-height: 71rem !important;
    }
    .products .small-box {
        width: 9px;
        height: 45px;
    }
    .instantCash {
        font-size: 24px;
        margin-bottom: 0px !important;
    }
    .mx-3 {
        margin-right: 1rem!important;
        margin-left: 2rem!important;
    }
    .h4-css {
        font-size: 66px;
        padding-bottom: 11px;
        margin-top: 30px !important;
        margin-left: 4rem;
    }
    .text{
        font-size: 39px !important;
        margin-left: 4rem;
        margin-top: 3rem;
        margin-bottom: 8rem;
    }
    .box .image {
        width: 66px !important;
        height: 66px !important;
        margin-left: 4rem;
    }
    .eIcon{
        font-family: MetropolisBold;
        font-size: 41pt !important;
    }
    .emandiNewsSectionn .box{
        width: 24% !important;
        padding: 43px 30px !important;
        min-height: 80rem;
    }
    .emandiNewsSectionn .box img{
        height: 31rem !important;
        margin-bottom: 67px !important;
    }
    .emandiNewsSectionn .box .card-text{
        font-size: 38px !important;
        margin-bottom: 67px !important;
    }
    .emandiNewsSectionn .box .fullNews{
        font-size: 30px !important;
    }
    .commodityReaserachh .box{
        width: 18.5% !important;
        min-height: 44rem !important;
    }
    
    
    
}
@media only screen and (max-width: 5746px) and (min-width: 5465px){
    .box {
        width: 22.7% !important;
        padding: 57px !important;
        min-height: 71rem !important;
    }
    .products .small-box {
        width: 9px;
        height: 45px;
    }
    .instantCash {
        font-size: 24px;
        margin-bottom: 0px !important;
    }
    .mx-3 {
        margin-right: 1rem!important;
        margin-left: 2rem!important;
    }
    .h4-css {
        font-size: 66px;
        padding-bottom: 11px;
        margin-top: 30px !important;
        margin-left: 4rem;
    }
    .text{
        font-size: 39px !important;
        margin-left: 4rem;
        margin-top: 3rem;
        margin-bottom: 8rem;
    }
    .box .image {
        width: 66px !important;
        height: 66px !important;
        margin-left: 4rem;
    }
    .eIcon{
        font-family: MetropolisBold;
        font-size: 52pt !important;
    }
    .emandiNewsSectionn .box{
        width: 24% !important;
        padding: 43px 30px !important;
        min-height: 80rem;
    }
    .emandiNewsSectionn .box img{
        height: 31rem !important;
        margin-bottom: 67px !important;
    }
    .emandiNewsSectionn .box .card-text{
        font-size: 38px !important;
        margin-bottom: 67px !important;
    }
    .emandiNewsSectionn .box .fullNews{
        font-size: 30px !important;
    }
    .commodityReaserachh .box{
        width: 18.5% !important;
        min-height: 44rem !important;
    }
    
    
    
}
@media only screen and (max-width: 5464px) and (min-width: 4099px){
    .box {
        width: 22.7% !important;
        padding: 57px !important;
        min-height: 71rem !important;
    }
    .products .small-box {
        width: 9px;
        height: 45px;
    }
    .instantCash {
        font-size: 24px;
        margin-bottom: 0px !important;
    }
    .mx-3 {
        margin-right: 1rem!important;
        margin-left: 2rem!important;
    }
    .h4-css {
        font-size: 66px;
        padding-bottom: 11px;
        margin-top: 30px !important;
        margin-left: 4rem;
    }
    .text{
        font-size: 39px !important;
        margin-left: 4rem;
        margin-top: 3rem;
        margin-bottom: 8rem;
    }
    .box .image {
        width: 66px !important;
        height: 66px !important;
        margin-left: 4rem;
    }
    .eIcon{
        font-size: 52pt !important;
        font-family: MetropolisBold;
    }
    .emandiNewsSectionn .box{
        width: 24% !important;
        padding: 43px 30px !important;
        min-height: 80rem;
    }
    .emandiNewsSectionn .box img{
        height: 31rem !important;
        margin-bottom: 67px !important;
    }
    .emandiNewsSectionn .box .card-text{
        font-size: 38px !important;
        margin-bottom: 67px !important;
    }
    .emandiNewsSectionn .box .fullNews{
        font-size: 30px !important;
    }
    .commodityReaserachh .box{
        width: 18.3% !important;
        min-height: 44rem !important;
    }
    
    
    
}
@media only screen and (max-width: 4098px) and (min-width: 3842px){
    .products .box {
        width: 22.7% !important;
        padding: 57px !important;
        min-height: 71rem !important;
    }
    .products .small-box {
        width: 9px;
        height: 45px;
    }
    .instantCash {
        font-size: 24px;
        margin-bottom: 0px !important;
    }
    .mx-3 {
        margin-right: 1rem!important;
        margin-left: 2rem!important;
    }
    .h4-css {
        font-size: 66px;
        padding-bottom: 11px;
        margin-top: 30px !important;
        margin-left: 4rem;
    }
    .text{
        font-size: 39px !important;
        margin-left: 4rem;
        margin-top: 3rem;
        margin-bottom: 8rem;
    }
    .box .image {
        width: 66px !important;
        height: 66px !important;
        margin-left: 4rem;
    }
    .eIcon{
        font-size: 45pt !important;
        font-family: MetropolisBold;
    }
    .emandiNewsSectionn .box{
        width: 24% !important;
        padding: 43px 30px !important;
        min-height: 80rem;
    }
    .emandiNewsSectionn .box img{
        height: 31rem !important;
        margin-bottom: 67px !important;
    }
    .emandiNewsSectionn .box .card-text{
        font-size: 38px !important;
        margin-bottom: 67px !important;
    }
    .emandiNewsSectionn .box .fullNews{
        font-size: 30px !important;
    }
    .commodityReaserachh .box{
        width: 18.3% !important;
        min-height: 44rem !important;
    }
    
    
    
}
@media only screen and (max-width: 3841px) and (min-width: 2561px){
    .products .instantCash {
        font-size: 42px;
        margin-left: 3rem !important;
        margin-bottom: 5rem;
    }
    .products .container-css{
        display: flex;
        justify-content: space-between;
        width: 87%;
    }
    .emandiNewsSectionn .row1-container .box{
        min-height: 74rem;
        width: 23% !important;
        height: auto !important;
        padding: 59px !important;
    }
    .row1-container .box{
        width: 23% !important;
        height: auto !important;
        padding: 71px !important;
    }
    .products .small-box{
        width: 13px;
        height: 62px;
    }
    .products .h4-css {
        font-size: 84px;
        margin-bottom: 3.5rem;
    }
    .products .h4-css , .products .text{
        margin-left: -17px;
    }
    .products .h4-css .eIcon{
        font-size: 55pt!important;
        margin-right: -9px !important;
        font-family: MetropolisBold;
    }
    .products .text{
        font-size: 40px !important;
        margin-bottom: 4rem;
    }
    .products .box .image {
        width: 100px !important;
        height: 104px !important;
        border-radius: 0% !important;
    }
    .products .smallBox{
        margin-left: 0px;
    }
    .news-section-css .card-text{
        font-size: 44px !important;
        margin-bottom: 52px;
    }
    .news-section-css .fullNews{
        font-size: 38px !important;
    }
}
@media only screen and (max-width: 3414px) and (min-width: 3201px){
    .products .text {
        font-size: 39px !important;
        margin-bottom: 4rem;
    }
    .news-section-css .card-text {
        font-size: 36px !important;
        margin-bottom: 32px;
    }
    .emandiNewsSectionn .row1-container .box {
        min-height: 69rem;
    }
}
@media only screen and (max-width: 3200px) and (min-width: 3073px){
    .products .text {
      font-size: 35px !important;
      margin-bottom: 4rem;
    }
    .news-section-css .box img {
        height: 25rem !important;
        margin-bottom: 57px !important;
    }
    .news-section-css .card-text {
        font-size: 38px !important;
        margin-bottom: 33px;
    }
    .news-section-css .fullNews {
        font-size: 32px !important;
    }
    .emandiNewsSectionn .row1-container .box {
        min-height: 64rem;
    }
}
@media only screen and (max-width: 3072px) and (min-width: 2733px){
    .products .text {
        font-size: 33px !important;
    }
    .news-section-css .card-text {
        font-size: 35px !important;
        margin-bottom: 26px;
    }
    .emandiNewsSectionn .row1-container .box {
        min-height: 62rem;
    }
    .news-section-css .fullNews {
        font-size: 32px !important;
    }
}
@media only screen and (max-width: 2732px) and (min-width: 2561px){
    .news-section-css .box img {
        height: 23rem !important;
        margin-bottom: 56px !important;
    }
    .products .small-box {
        width: 11px;
        height: 51px;
    }
    .products .instantCash {
        font-size: 28px;
        margin-left: 2rem !important;
        margin-bottom: 2rem;
    }
    .products .h4-css {
        font-size: 56px;
        margin-bottom: 1.5rem;
    }
    .products .text {
        font-size: 27px !important;
        margin-bottom: 4rem;
    }
    .products .box .image {
        width: 78px !important;
        height: 78px !important;
        border-radius: 0% !important;
    }
    .emandiNewsSectionn .row1-container .box {
        min-height: 57rem;
        width: 24% !important;
        height: auto !important;
        padding: 43px !important;
    }
    .news-section-css .card-text {
        font-size: 33px !important;
        margin-bottom: 36px;
    }
    .news-section-css .fullNews {
        font-size: 29px !important;
    }
}
@media only screen and (max-width: 2560px) and (min-width: 1921px){
    .products .small-box {
        width: 8px !important;
        height: 39px !important;
    }
    .products .row1-container .box {
        min-height: 408px!important;
    }
    .products .instantCash {
        font-size: 27px !important;
        margin-left: 2rem !important;
        margin-bottom: 1rem;
    }
    .emandiNewsSectionn .row1-container .box{
        min-height: 52rem;
    }
    .row1-container .box {
        padding: 32px !important;
    }
    .products .h4-css {
        font-size: 51px;
        margin-bottom: 1.5rem;
    }
    .products .h4-css , .products .text{
        margin-left: 8px;
    }
    .products .h4-css .eIcon{
        font-size: 34pt !important;
        margin-right: -9px !important;
        font-family: MetropolisBold;
    }
    .products .text {
        font-size: 24px !important;
        margin-bottom: 3rem;
    }
    .products .box .image {
        width: 63px !important;
        height: 68px !important;
        border-radius: 0% !important;
    }
    .container-css {
        margin-left: 8%;
    }
    .row1-container .box{
        width: 22.5% !important;
        height: auto !important;
        padding: 41px !important;
    }
}
@media only screen and (max-width: 2400px) and (min-width: 2049px){
.products .row1-container .box {
    width: 22.3% !important;
}
.emandiNewsSectionn .row1-container .box {
    width: 24% !important;
}
.emandiNewsSectionn .row1-container .box {
    min-height: 48rem;
}
.news-section-css .box img {
    height: 20rem !important;
}
}
@media only screen and (max-width: 2300px) and (min-width: 2049px){
    .products .row1-container .box {
        width: 22% !important;
        height: auto !important;
        padding: 32px !important;
    }
    .products .small-box {
        width: 8px !important;
        height: 34px !important;
    }
    .products .instantCash {
        font-size: 21px !important;
        margin-left: 2rem !important;
        margin-bottom: 1rem;
    }
    .products .h4-css {
        font-size: 41px;
        margin-bottom: 1rem;
    }
    .products .text {
        font-size: 22px !important;
        margin-bottom: 3rem;
    }
    .products .box .image {
        width: 54px !important;
        height: 54px !important;
        border-radius: 0% !important;
    }
    .emandiNewsSectionn .row1-container .box {
        width: 25% !important;
        height: auto !important;
        padding: 26px !important;
        min-height: 45rem;
    }
}
@media only screen and (max-width: 2048px) and (min-width: 1441px){
    .products .small-box {
        width: 8px !important;
        height: 34px !important;
    }
    .emandiNewsSectionn .row1-container .box{
        min-height: 43rem;
    }
    .row1-container .box{
        width: 23.3% !important;
        height: auto !important;
        padding: 39px !important;
    }
    .products .instantCash {
        font-size: 26px !important;
        margin-left: 1rem !important;
        margin-bottom: 0rem;
    }
    .products .h4-css {
        font-size: 48px;
        margin-bottom: 0.5rem;
    }
    .products .text {
        font-size: 19px !important;
        margin-bottom: 1rem;
    }
    .products .box .image {
        width: 52px !important;
        height: 52px !important;
        border-radius: 0% !important;
    }
}
@media only screen and (max-width: 1920px) and (min-width: 1441px){
    .products .small-box {
        width: 7px !important;
        height: 34px !important;
    }
    .emandiNewsSectionn .row1-container .box{
        min-height: 40rem;
    }
    .row1-container .box {
        width: 24% !important;
        height: auto !important;
        padding: 38px !important;
    }
    .news-section-css .card-text {
        font-size: 22px !important;
    }
    .news-section-css .fullNews {
        font-size: 20px !important;
    }
    .products .h4-css {
        font-size: 41px;
        margin-bottom: 1.5rem;
    }
    .products .instantCash {
        font-size: 21px !important;
        margin-left: 1rem !important;
        margin-bottom: 2rem;
    }
    .products .text {
        font-size: 21px !important;
        margin-bottom: 1rem;
    }
    .products .box .image {
        width: 48px !important;
        height: 48px !important;
        border-radius: 0% !important;
    }
    .products .eIcon{
        font-size: 27pt !important;
    margin-right: -5px !important;
    font-family: MetropolisBold;
    }
}
@media only screen and (max-width: 1822px) and (min-width:1709px){
    .products .row1-container .box {
        width: 24% !important;
        height: auto !important;
        padding: 32px !important;
    }
    .products .instantCash {
        font-size: 21px !important;
        margin-left: 1rem !important;
        margin-bottom: 1rem;
    }
    .products .h4-css {
        font-size: 33px;
        margin-bottom: 0.5rem;
    }
    .products .text {
        font-size: 19px !important;
        margin-bottom: 1rem;
    }
    .products .box .image {
        width: 43px !important;
        height: 43px !important;
        border-radius: 0% !important;
    }
    .emandiNewsSectionn .row1-container .box {
        width: 23% !important;
        height: auto !important;
        padding: 25px !important;
        min-height: 3rem;
    }
}
@media only screen and (max-width: 1708px) and (min-width:1681px){
    .products .row1-container .box {
        width: 24% !important;
        height: auto !important;
        padding: 32px !important;
    }
    .products .instantCash {
        font-size: 21px !important;
        margin-left: 1rem !important;
        margin-bottom: 1rem;
    }
    .products .h4-css {
        font-size: 33px;
        margin-bottom: 0.5rem;
    }
    .products .text {
        font-size: 19px !important;
        margin-bottom: 1rem;
    }
    .products .box .image {
        width: 43px !important;
        height: 43px !important;
        border-radius: 0% !important;
    }
    .emandiNewsSectionn .row1-container .box {
        width: 23% !important;
        height: auto !important;
        padding: 25px !important;
        min-height: 35rem;
    }
}
@media only screen and (max-width: 1680px) and (min-width: 1441px){
    .row1-container .box {
        width: 23% !important;
        height: auto !important;
        padding: 35px !important;
    }
    .products .small-box {
        width: 6px;
        height: 27px;
    }
    .emandiNewsSectionn .row1-container .box{
        min-height: 34rem;
        padding: 25px !important;
    }
    .products .h4-css {
        font-size: 33px;
        margin-bottom: 1.5rem;
    }
    .products .instantCash {
        font-size: 18px !important;
        margin-left: 1rem !important;
        margin-bottom: 1rem;
    }
    .products .text {
        font-size: 18px !important;
        margin-bottom: 1rem;
    }
    .products .box .image {
        width: 39px !important;
        height: 38px !important;
        border-radius: 0% !important;
    }
    .products .eIcon{
        font-size: 23pt !important;
        font-family: MetropolisBold;
    }
}
@media only screen and (max-width: 1600px) and (min-width: 1441px){
    .products .h4-css {
        font-size: 31px;
    }
    .products .small-box {
        width: 6px !important;
        height: 28px !important;
    }
    .row1-container .box {
        width: 23% !important;
        height: auto !important;
        padding: 39px !important;
        max-height: 300px;
    }
    .news-section-css .card-text {
        font-size: 17px !important;
    }
    .news-section-css .fullNews {
        font-size: 15px !important;
    }
    .emandiNewsSectionn .row1-container .box {
        min-height: 32rem;
    }
    .news-section-css .box img {
        height: 12rem !important;
        margin-bottom: 18px !important;
    }
    .products .instantCash {
        font-size: 16px !important;
        margin-left: 1rem !important;
        margin-bottom: 0rem;
    }
    .products .text {
        font-size: 16px !important;
        margin-bottom: 1rem;
    }
    /* .products .h4-css {
        font-size: 34px;
        margin-bottom: 0.5rem;
    }
    .products .text {
        font-size: 19px !important;
        margin-bottom: 1rem;
    }
    .products .box .image {
        width: 62px !important;
        height: 62px !important;
        border-radius: 50% !important;
    } */
}
@media only screen and (max-width: 1536px) and (min-width: 1519px){
    .row1-container .box {
        padding: 24px !important;
    }
    .emandiNewsSectionn .row1-container .box {
        min-height: 30rem;
    }
}
@media only screen and (max-width: 1518px) and (min-width: 1441px){
    .products .row1-container .box {
        width: 21% !important;
    }
    .emandiNewsSectionn .row1-container .box {
        width: 23% !important;
        padding: 22px !important;
        min-height: 29rem;
    }
    #timeSelct {
        background-position: right 1.2rem center !important;
    }
    .commodityReaserachh .smallBox select {
        padding: 1rem 2.4rem !important;
    }
    .commodityBody .nav-link {
        border-width: 0px 0px 3px !important;
        padding-bottom: 14px !important;
    }
    .commodityBody .tabs .content .commoditybtn .btn {
        border-radius: 14px;
        width: 43%;
        padding: 11px 26px;
        font-size: 23px;
    }
}
@media only screen and (max-width: 1440px) and (min-width: 1367px){
    .emandiNewsSectionn .row1-container .box{
        min-height: 28rem;
        width: 23% !important;
    }
    .emandiNewsSectionn .row1-container .box img{
        height: 115px !important;
    }
    .emandiNewsSectionn .row1-container .box .card-body .card-text{
        font-size: 17px !important;
    }
    .emandiNewsSectionn .row1-container .box .card-body .fullNews{
        font-size: 14px !important;
    }
    .products .row1-container .box {
        width: 23% !important;
        height: auto !important;
        padding: 41px !important;
    }
    .instantCash{
        font-size: 15px;
    }
    .products .small-box{
        height: 25px;
        width: 5px;
    }
    .products .h4-css{
        font-size: 29px;
    }
    .products .h4-css , .products .text{
        margin-left: -3px;
    }
    .products .eIcon{
        font-size: 20pt !important;
        margin-right: -3px !important;
        font-family: MetropolisBold;
    }
    .products .text{
        font-size: 14px !important;
    }
    .products .box .image{
        width: 51px;
        height: 51px;
    }
}
@media only screen and (max-width: 1366px){
    .emandiNewsSectionn .row1-container .box .card-body .card-text{
        font-size: 15px !important;
    }
    .emandiNewsSectionn .row1-container .box .card-body .fullNews{
        font-size: 14px !important;
    }
    .emandiNewsSectionn .row1-container .box{
        min-height: 26rem;
        width: 23% !important;
    }
    .products .row1-container {
        font-family: MetropolisRegular;
        margin-left: 6% !important;
    }
    .products .row1-container .box {
        width: 21.5% !important;
        min-height: 223px !important;
    }
    .products .instantCash {
        font-size: 13px;
    }
    .products .small-box{
        height: 22px !important;
        width: 4px;    
    }
    .products .h4-css{
        font-size: 26px;
    }
    .products .eIcon{
        font-size: 18pt !important;
        margin-right: -2px !important;
        font-family: MetropolisBold;
    }
    .products .text{
        font-size: 14px !important;
    }
}
/* @media (min-width: 950px) {
    .row1-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .row2-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .box-down {
        position: relative;
        top: 150px;
    }

    .box {
        width: 18%;

    }

    .header p {
        width: 30%;
    }

} */
@media (max-width: 1280px) {
    .emandi-news-css {
        width: 86%;
    }
    .news-section-css {
        width: 88%;
    }
    .products .row1-container .box {
        width: 21.4% !important;
        min-height: 209px !important;
    }
    .products .instantCash {
        font-size: 12px;
    }
    .products .text{
        font-size: 13px !important;
    }
    .emandiNewsSectionn .row1-container .box{
        min-height: 26rem;
    }
    .products .h4-css{
        font-size: 24px;
    }
    .products .eIcon{
        font-size: 18pt !important;
        font-family: MetropolisBold;
    }
    .products .row1-container {
        font-family: MetropolisRegular;
        margin-left: 6% !important;
    }
}
@media (max-width: 1242px) and (min-width: 1025px){
    .products .row1-container {
        margin-left: 6% !important;
    }
    .products .row1-container .box {
        width: 22% !important;
    }
    .carousel.carousel-slider .control-arrow {
        top: -37px;
    }
}
@media (max-width: 1093px) and (min-width: 1025px){
    .emandiNewsSectionn .row1-container .box .card-body .card-text {
        font-size: 14px !important;
    }
    .emandiNewsSectionn .row1-container .box .card-body .fullNews {
        font-size: 13px !important;
    }
    .emandiNewsSectionn .row1-container .box{
        min-height: 24rem;
    }
    .products .text {
        font-size: 11px !important;
    }
    .products .row1-container .box {
        width: 21.7% !important;
    }
    
}
@media (max-width: 1024px) {
    .products .text {
        font-size: 10px !important;
    }
    .products .h4-css {
        font-size: 24px;
    }
    .products .h4-css , .products .text{
        margin-left: -7px;
    }
    .emandiNewsSectionn .row1-container .box{
        min-height: 26rem;
        width: 21% !important;
    }
    .products .row1-container .box {
        width: 21% !important;
    }
    .products .row1-container {
        margin-left: 6% !important;
        display: flex;
    }
    .products .instantCash {
        font-size: 13px;
    }
    
}
@media (max-width: 977px) {
    .products .instantCash {
        font-size: 12px;
    }
    .products .h4-css {
        font-size: 22px;
    }
}
@media (max-width: 908px){
    .emandiNewsSectionn .row1-container .box {
        min-height: 26rem;
        width: 20% !important;
    }
}
@media (max-width: 856px){
    .products .h4-css {
        font-size: 20px;
    }
}
@media (max-width: 808px){
    .products .h4-css {
        font-size: 18px;
    }
    .products .row1-container {
        flex-wrap: wrap;
        width: 88%;
    }
    .products .row1-container .box {
        width: 46.5% !important;
    }
    .smallBox {
        margin-left: 5%;
    }
    .products .small-box {
        height: 22px !important;
        width: 5px;
    }
    .products .instantCash {
        font-size: 13px;
    }
    .products .h4-css {
        font-size: 28px;
    }
    .products .text {
        font-size: 15px !important;
    }
    .news-section-css {
        flex-wrap: wrap;
    }
    .emandiNewsSectionn .row1-container .box {
        width: 45% !important;
    }
    .products .eIcon {
        margin-right: -5px !important;
    }
}
@media (max-width: 778px){
    .products .row1-container .box {
        width: 45.5% !important;
    }
}
@media (max-width: 728px){
    .emandiNewsSectionn .row1-container .box {
        width: 44% !important;
    }
}

@media (max-width: 605px){
    .products .row1-container .box {
        width: 44.5% !important;
    }
    .emandiNewsSectionn .row1-container .box {
        width: 42% !important;
    }
}
@media (max-width: 503px){
    .products .h4-css {
        font-size: 25px;
    }
}
@media (max-width: 495px){
    .products .row1-container .box {
        width: 95% !important;
    }  
    .news-section-css {
        width: 90% !important;
    }  
    .emandiNewsSectionn .row1-container .box {
        width: 100% !important;
        margin-right: 10px !important;
    }
    .products .smallBox {
        margin-left: 4%;
    }
}


