@font-face {
  font-family: metropolisLight;
  src: url(Metropolis-Light.otf);
}

@font-face {
  font-family: metropolisSemiBold;
  src: url(Metropolis-SemiBold.otf);
}

@font-face {
  font-family: Metropolis-Regular;
  src: url(Metropolis-Regular.otf);
}

@font-face {
  font-family: metropolisBold;
  src: url(Metropolis-Black.otf);
}

.eCash-home .container-fluid {
  background-color: #f2f2f2 !important;
  /* display: flex; */
  padding: 0px !important;
  /* overflow: hidden; */
  
}

.row-topheader {
  border-radius: 12px !important;
}
.searchTextBox-1 {
  margin-left: 5px;
  font-family: Metropolis-Regular;
  width: 440px;
  height: 40px;
  border: 1px solid #d3d3d3;
  box-shadow: 2px 0px 12px rgba(92, 138, 152, 0.075);
  border-radius: 8px;
  outline: #d3d3d3;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAYAAAAPtVbGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJDSURBVHgBrVVLbiIxEHV3A2tygvHcILNBgwCF7GY3MydI+gS0xHcH7BAfiayyDNyAnAAiAWKXzg06N+hlxDevLFsi6TaBDk+y/CvXc5WrygbTwHGcZCKRKOx2uzzapWEYSbnlUsPasNvtTtgRMDTK61DisK8xWa1Wdr/f9w4JmZ8IeDwef1YE6Cfb7fY/FP3sdDoG+gusXZMVsIwU50m+Uqn8Y8dYQhbQAQw5KYBy+5A7arUaX6/XDcjeyKVfuIgbJmupQS6Xu6ebEYFpmtftdttlBzCdTv35fD5Kp9MXOPMbS39SqdRwsVi8fZY1pRUc3a1YAEGr1fLYkej1euTaCRqPxWKh7yhI4Ka6nA9OIVDAGzWph0UFcnsoCYTEw1mW1WQRIN+OWhLWXAZIwKxywI9ihQIC5UUOgyRgFubBGpd9A3hLcR4X5oE9dibgkkndHlni6W5wIgmXvRcgoXfAho8xLxaLP1hE4JJXchhwu4quoRS0WQRQnuEsBZAXViVMqXwk+4JMzJOAPBtTjwgbhu0LEmKHwB2GVL/GpxChOFIii3qHWtbXkhA2m01DVlZ+DBFldrVafYCrGzSnio2S74fJfvhPqLLCorGKFGAA4sflcikUkGLKaOTEFeQclcRoInwxb6CwNg+SKJRKJbKqzr4A3R6kNryQx/RBR2ToFMj/gmraX4oceVtftkcQjPYjqVwu3+qItCRRoCOy2BmBT8zNZDKvGJIH8tlsls1ms6ezWqKwbxFgn61A7gN//YCUq/k7IusWomJ+ZjQAAAAASUVORK5CYII=);
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 58px;
  background-position-x: 15px;
  font-family: metropolisLight;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  background-size: 18px;
  line-height: 16px;
  /* background-position-y: -9px; */
  padding-left: 40px;
}

.searchTextBox {
  margin-left: 15px;
  font-family: Metropolis-Regular;
  width: 436.77px;
  height: 50px;
  /* Outline/box */
  border: 1px solid #d3d3d3;
  /* Shadow/Navigation bar */
  box-shadow: 2px 0px 12px rgba(92, 138, 152, 0.075);
  border-radius: 8px;
  outline: #d3d3d3;
  background-image: url('../Images/Vector.png');
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 58px;
  background-position-x: 20px;
  font-family: metropolisLight;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.thirdCard .canvasjs-chart-canvas {
  height: 200px !important;
  width: 220px !important;
  background-color: transparent !important;
  background-image: none !important;
}

.canvasjs-chart-credit {
  top: 180px !important;
}

.firstCard {
  width: 160px;
  height: 226px;
  left: 238px;
  top: 131px;
  background: #f5f1d8;
  /* Divider */
  border: 1px solid #ececec;
  border-radius: 12px;
  padding: 20px;
  display: inline-block;
  vertical-align: top;
  margin-left: 30px;
}

.select-css {
  font-family: Metropolis-Regular;
} 

.secondCard {
  width: 160px;
  height: 226px;
  left: 238px;
  top: 131px;
  background: #fff;
  margin-left: 30px;

  /* Divider */
  border: 1px solid #ececec;
  border-radius: 12px;
  padding: 10px;
  display: inline-block;
  vertical-align: top;
}

.cardContainer {
  margin-top: -5px !important;
  margin-left: 5px;
  padding: 40px;
  margin-bottom: -215px;
}
.eCash-home .active{
  background-color: #04b23d !important;
  color:white !important
}
.eCash-home .active>.page-link, .eCash-home .page-link.active{
  color:white !important;
}
.canvasjs-chart-canvas {
  height: 215px;
}

.firstCard, .secondCard span {
  font-family: metropolisSemiBold;
  font-size: 11pt;
}
.secondCard span {
margin-top: 10px !important;
display: block;
}

.cardValue {
  font-family: metropolisSemiBold;
  position: relative;
  margin-top: 100px;

}

.cardArrow {
  position: absolute;
  margin-left: 50px;
  margin-top: 20px;
}

.sectionBtn {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 20px;
}

.btnAdd {
  background: #04b23d;
  width: 148px;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  border: none;
  color: white;
  font-family: metropolisLight;
  border-radius: 40px;
  float: right;
  margin-right: 30px;
}
.img-date {
  width: 14px !important;
  margin: 11px !important;
  padding-bottom: 2px !important;
}
.viewAllLink {
  font-family: metropolisSemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height */
  color: #04b23d;
  float: right;
  margin-top: 10px;
  margin-right: 20px;
  
}

.viewAllLink-1 {
  font-family: metropolisSemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height */
  color: #04b23d;
  float: right;
  margin-top: 9.5px;
  margin-right: 20px;
}
.viewAll-1 :hover { 
  cursor: pointer;

}
.customTable {
  font-family: metropolisSemiBold;
  color: #757575;
  width: 100%;
}

.customTable tr {
  height: 80px;
}

.button:focus {
  outline: none;
}

.tableCard {
  background: #ffffff;
  margin-top: 20px;
  border-radius: 12px;
  padding: 30px;
  margin-left: 30px;
}

.statusBtn {
  background: rgba(13, 180, 66, 0.1);
  border: 1px solid #0A8330;
  color: #0A8330;
  border-radius: 8px;
  height: auto;
  font-family: metropolisLight;
  text-transform: uppercase;
  font-size: 9pt;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.statusBtnunderProcess {
  background: rgba(242, 123, 14, 0.13);
  border: 1px solid #f27b0e;
  color: #f27b0e;

  border-radius: 8px;
  height: auto;
  font-family: metropolisLight;
  text-transform: uppercase;
  font-size: 9pt;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.statusRejected {
  color: #F11C1C;
  background: rgba(241, 28, 28, 0.1);
  border: 1px solid #f11c1c;
  border-radius: 8px;
  height: auto;
  font-family: metropolisLight;
  text-transform: uppercase;
  font-size: 9pt;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.statusSystemApprovedRisk {
  color: #11CFF9;
  background: white;
  border: 1px solid #11CFF9;
  border-radius: 8px;
  height: auto;
  font-family: metropolisLight;
  text-transform: uppercase;
  font-size: 9pt;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.statusRecommendedLender {
  color: #0E32F2;
  border: 1px solid #0E32F2;
  ;
  border-radius: 8px;
  height: auto;
  font-family: metropolisLight;
  text-transform: uppercase;
  font-size: 9pt;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.statusIncomplete {
  color: #F5D405;
  background: white;
  border: 1px solid #F5D405;
  border-radius: 8px;
  height: auto;
  font-family: metropolisLight;
  text-transform: uppercase;
  font-size: 9pt;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.pagination-section {
  font-family: metropolisRegular;
  margin-left: 35px;
  margin-top: 20px;
}
/* .page-link-1 {
  background-color: #E8E7E7;
} */
.pagination-left {
width: 204px;
height: 18px;
left: 238px;
top: 1257px;
font-family: metropolisRegular;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 18px;
/* identical to box height */


color: rgba(117, 117, 117, 0.71);

  font-size: 16px;
  color: rgba(117, 117, 117, 0.71);
}

.pagination-left a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.pagination-left a.active {
  background-color: dodgerblue;
  color: white;
}

/* Add a grey background color on mouse-over */
.pagination-left a:hover:not(.active) {
  /* Style the active/current link */
  background-color: #ddd;
}

/* .eCash-home  */
.pagination-section .active {
  background-color: #04b23d !important;
  color: white !important;
} 

.form-select {
  font-size: 1.5rem !important;
}
.p-calendar calender
{
    font-size: 15pt !important;
}

.pagination-right {
  float: right;
  width: 25%;
  justify-content: space-between;
}

.pagination-left {

}

.pagination-right a {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.page-num {
  width: 6px;
height: 18px;

/* Button/Small */

font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 10pt !important;
line-height: 18px;
/* identical to box height, or 150% */


/* Primary Palette/White */

color: #FFFFFF;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
.active>.page-link {
  background-color: #04B23D !important;
  border-color: #04B23D !important;
  color: #ffffff !important;
  /* width: 32px !important;
  height: 32px !important; */
}
.page-link {
  color: #9A97A6 !important;
  width: 32px !important;
  height: 32px !important;
}

/* Style the active/current link */
.pagination-right a.active {
  background-color: dodgerblue;
  color: white;
}

/* Add a grey background color on mouse-over */
.pagination-right a:hover:not(.active) {
  background-color: #ddd;
}

.pagination-button {
  border-radius: 100px;
}

.page-item:first-child .page-link {
  border-radius: 50px !important;
}

.page-item:last-child .page-link {
  border-radius: 50px !important;
}

.page-link {
  border-radius: 50px !important;
}

.pagination {
  justify-content: space-evenly;
}

.thirdCard {
  width: 420px !important;
  margin-right: 60px !important;
  /* width: 193px; */
  height: 226px;
  left: 20px;
  top: 0px;
  background: #fff;
  position: relative;
  /* Divider */
  border: 1px solid #ececec;
  border-radius: 12px;
  padding: 10px;
  display: inline-block;
  vertical-align: top;
  z-index: 8;

}

.totalHeading {
  text-align: center;
  position: absolute;
  top: 80px;
  left: 100px;
  z-index: 9;
}

.totalHeading span {
  font-family: metropolisSemiBold;
  color: #757575;
}

.customTable 
{
  font-size: 9pt;
}

.customTable > tbody > tr{
  border-bottom: 1px solid #ececec
}

.legend {
  float: right;
  font-family: metropolisLight;
  top: 26px;
  right: 30px;
  position: absolute;
  z-index: 9;
}
.view-all-arrow {
  font-size:13px;
  margin-left: 6px;
  color: #04B23D;
}
.legend span {
  color: #757575;
  font-size: 14px;
}

.legend h1 {
  font-family: metropolisSemiBold;
  font-size: 20px;
}

.legendcolor {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  display: inline-block;
  margin-right: 10px;
}

.recommendedLegend {
  background-color: #04B23D;
}

.underProcessLegend {
  background-color: #F5D405;
}

.incompleteLegend {
  background-color: #F27B0E;
}