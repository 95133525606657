.commoditity-research-cls {
	font-family: "Poppins", sans-serif;


	.header-cls {
		display: flex;
		margin: 0.5em 3em;
		justify-content: start;
		img {
			width: 2em;
			height: 2em;
			// border: 2px solid bisque;
			border-radius: 50%;
			padding: 4px;
			box-shadow: 1px 1px 6px;
		}
		p {
			margin: 0.5em;
			font-size: 16px;
		}

		.pi-angle-left {
			margin: 0.5em 0em;
			font-size: 16px;
			box-shadow: 0px 0px 20px #00000029;
			border-radius: 50%;
			height: 1.5em;
			width: 1.5em;
			text-align: center;
			line-height: 1.5em;
			cursor: pointer;
		}
	}
	.menu-cls {
		display: flex;
		height: 30px;
		.filters{
			display: flex;
			margin-left: 0.5em;
			.dropdown-content-section .p-dropdown{
				height: 2.8em;
				// border-radius: 3em;
				border: 1px solid #ada4a3;
				width: 11em;
				margin-right: 1em;
				.p-dropdown-panel .p-dropdown-item {
					font-weight: normal;
					background-color: #FFFFFF;
					cursor: pointer;
					font-family: 'Poppins', sans-serif;
					margin: 0 !important;
				}
			}
		}
		.list-cls{
			display: flex;
			width: 30em;
			font-size: 16px;	
			font-weight: bold;
			
			img{
				width: 1.5em;
				height: 1.5em;
			}
			li{
				display: flex;
				padding: 0.8em;
				align-items: center;
				border-radius: 10px;
				>span{
					margin-left: 0.5em;
				}
			}
		
			 .active-cls{
				background-color: #0f9d58;
				color: #FFFFFF;
				height: 3em;
			}
			.norma-cls:hover{
				background-color: #f2f2f2;
				
			}
			.norma-cls{
				height: 3em;
			}
			.justluanched{
				display: flex;
				font-size: 10px;
				margin-left: 2em;
				color: #0f9d58;
				// text-align: center;
				height: 2em;
				width: 100px;
				padding-top: 5px;
				padding-left: 10px;
				border: 1px solid #F2F2F2;
				background-color: #f5f8f4;
				border-radius: 10px;
			}

			
			
			// text-decoration: underline;
			// li:hover{
			// 	background-color: #f2f2f2;
			// }
			// li:active{
			// 	background-color:#;
			// 				}
		}
.commodity-view{
	color: black;
	cursor: pointer;
}
		.product-list{
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			margin-top: 20px;
			.product-item{
				box-shadow: 0px 0px 3px 0px #f2f2f2;
				border: 1px solid #f2f2f2;
				padding: 8px;
				border-radius: 10px;
				margin: 0.5em;
				width: 35em;
				.ribbon-wrapper {
					width: 85px;
					height: 88px;
					overflow: hidden;
					position: absolute;
					background-color: transparent;
					.ribbon {
					  font: bold 10px sans-serif;
					  color: #333;
					  text-align: center;
					  -webkit-transform: rotate(-45deg);
					  -moz-transform:    rotate(-45deg);
					  -ms-transform:     rotate(-45deg);
					  -o-transform:      rotate(-45deg);
					  position: relative;
					  top: 15px;
					  left: -30px;
					  margin-left: 16px;
					//   background-color: #ebb134;
					background-color: red;
					  color: #fff;
					}
				  }
				.top-cls{
					display:flex;
					justify-content: space-between;
					align-items: center;
					font-size: 14px;
					.image_text{
						display: flex;
						gap: 30px;
					}
					.imagenotfound-cls{
						background-color: lightgreen,;
						// background-image: url("");
						border-radius: 12px;
							width: 4em;
							height: 4em;
							border-radius: 12px;
							display: flex;
							justify-content: center;
							align-items: center;
							>img{
								width: 3em;
								height: 3em;
							}
					}
					
					.image-cls{
						img{
							width: 4em;
							height: 4em;
							border-radius: 12px;
						}
					}
					.commodity-info{
						.commodity-name{
							color:black;
							margin: 0.5em 0;
							// font-size: 14px;
							font-weight: bold;
							text-transform: capitalize;
						}	
					}
					.commodity-qty{
						display: flex;
						flex-direction: column;
						align-items: flex-end;
						.pi{
							color: green;
							cursor: pointer;
						}
						.commodity-name{
							color:#f2f2f2;
							margin: 0.5em 0;
							// font-size: 14px;
							text-transform: capitalize;
						}
						.commodity-date{
							color:black;
							margin: 0.5em 0;
							font-size: 1px !important;
						}
						.commodity-label{
							color:black;
							margin: 0.5em 0;

						}
						.updown-prices{
										display: flex;
							margin: 0.5em 0;
										
										.green, .pi-caret-up{
											color: #40e905;
											margin: 0;
										}
							
										.red, .pi-caret-down{
											color: red;
											margin: 0;
										}
						
										.pi{
											margin-top: 0.25em;
											margin-right: 0.5em;
										}
									}
					}
				}
			
			}
		}
		.Emandi-TV-cls{
			.product-item{
				border-bottom: 2px solid gray;
				width: 80%;
				cursor: pointer;
				.top-cls{
					display: flex;
					.youtube-symbol-cls{	
					    margin: 0.5em 2em 0.5em 0;
						padding: 0.5em;
						margin-right: 2em;
						display: flex;
						align-items: center;
						border-radius: 14px;
						width: 10em;
						height: 5em;
						background: black;
						img{
							align-items: center;
    						margin: 0 auto;
						}
					
					}
					.emandi-tittle{
						color:black;
						// margin: 0.5em 0;
						font-size: 14px;
						font-weight: bold;
						text-transform: capitalize;
					}
					
					
					.emandi-date{
							color:black;
							// margin: 0.5em 0;
							font-size: 14px;
					}
			}
		}
	}
	.Emandi-news-cls{
		.p-accordion-tab{
			border: 2px solid lightseagreen;
    		border-radius: 8px;
			margin: 0.5em;
				.p-accordion-header {
					background: gainsboro;
					color: black;
					border-radius: 4px;
					text-transform: capitalize;
					.p-accordion-header-text{
						margin: 0 0.5em;
					}
					>a{
						color: black;
					}
				}
				.accordian-content-cls{
					display: flex;
					align-items: center;
					>img{
						width: 4em;
						height: 4em;
						margin: 0 0.5em;
					}
					>p::first-letter{
							text-transform: capitalize;
						  
					}
				}
		}
		hr{
			width: 90%;
			float: left;
		}
		.product-item{
			// border-bottom: 2px solid #f2f2f2;
			width: 40em;
			display: flex;
			align-items: center;
			justify-content: space-between;
			// margin-left: 0.5em;
			margin-bottom: 1em;
			margin: 0.5em;
			>div{
				display: flex;
				>img{
					width: 100px;
					margin-right: 0.5em;
				}
			}
			.emandi-tittle{
				color:black;
				// margin: 1em 0;
				font-size: 14px;
				font-weight: bold;
				text-transform: capitalize;
				.readme{
					display: flex;
					border-radius: 25px;
					// margin-left: 45em;
					font-size: 12px;
					color: white;
					padding: 10px 10px 10px 15px;
				}
				.readme:hover{
					background-color: #0f9d58;
				}
			}
			.readme{
				cursor: pointer;
				border-radius: 8px;
				font-size: 14px;
				color: white;
				background-color: #0f9d58;
				padding: 10px 10px 10px 15px;
			}
			.emandi-date{
					color:black;
					margin: 0.5em 0;
					font-size: 12px;
			}
		}
		
	}
}
.content-cls{
margin-left: 2em;	
// h2{
// 	margin-left: 0.5em;
// }
}
.showing-page{
	margin-left: 650px;
}
.direct-btn{
	display: flex;
	gap: 20px;
	margin-left: 600px;
}
.move-to-first , .move-to-last{
	cursor: pointer;
}
.pageNumbers{
	list-style: none;
	display: flex;
	height: 39px;
	// margin-left: 550px;
	// margin-bottom: 50px;
	position: absolute;
    // left: 79vh;
    top: 76vh;
	right: 179px;
}
.pageNumbers li{
	padding: 7px 12px;
	cursor: pointer;
	border-radius: 50%;
}
.pageNumbers li:first-child , .pageNumbers li:last-child{
	font-size: 20px;
}
.pageNumbers li.active-page{
	// background-color: #0f9d58;
	// color: white;
	background-color: white;
	color: #0f9d58;
	font-weight: 800;
	font-size: 20px;
}
.pageNumbers li button{
	background-color: transparent;
	border: none;
	color: black;
	font-size: 1rem;
	cursor: pointer;
}
.pageNumbers li button:hover{
	background-color: rgb(167, 164, 164);
}

.weekly-heading-pagination, .marketprice-heading-pagination, .seasonal-heading-pagination, .daily-heading-pagination{
	display: flex;
	gap: 200px;
	align-items: center;
	// justify-content: space-between;
}
.weekly-heading-pagination>h3, .marketprice-heading-pagination>h3, .seasonal-heading-pagination>h3, .daily-heading-pagination>h3{
	color: rgb(109, 108, 108);
}
.weeklyCommodityDropDown{
	height: 2.8em;
    border: 1px solid #ada4a3;
    width: 11em;
    margin-right: 1em;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: none;
}
.p-multiselect .p-multiselect-panel {
    background-color: white !important;
	width: 197px !important;
	top: 47px !important;
}
.p-multiselect-panel .p-multiselect-list {
    padding-left: 5px !important;
}
.p-checkbox .p-checkbox-box {
    border: 1px solid black !important;
}
.p-multiselect-header .p-checkbox .p-hidden-accessible .p-checkbox-box .p-component{
    display: none !important;
}
.p-multiselect-header .p-checkbox:first-child{
	display: none !important;
}

	// .menu-cls {
	// 	display: flex;
	// 	margin: 0 0 1em;
	// 	background-color: #f5f9f3;
	// 	height: 4em;
	// 	justify-content: space-between;

	// 	.custom-calender {
	// 		.p-calendar.p-calendar-w-btn input {
	// 			border-radius: 4px !important;
	// 			height: 4em;
	// 			width: 7em;
	// 			margin: 0.4em;
	// 		}
	// 	}
	// 	.left-div {
	// 		width: 53%;
	// 		display: flex;
	// 		justify-content: inherit;
	// 		margin: 1em 1em 1em 3em;
	// 		.type-cls {
	// 			display: flex;
	// 			width: 50%;
	// 			.p-dropdown {
	// 				font-size: 14px;
	// 				box-shadow: 0px 2px 5px #0000001a;
	// 				border-radius: 5px;
	// 				background: #ffffff;
	// 				min-width: 0;
	// 				width: 80%;
	// 				.p-dropdown-items {
	// 					background: #ffffff;
	// 					border-radius: 4px;
	// 					border: 1px solid #00000019;
	// 				}
	// 			}
	// 		}
	// 		.input-wrapper {
	// 			background-color: #fff;
	// 			box-shadow: 0px 2px 30px #00000026;
	// 			display: flex;
	// 			border-radius: 4px;

	// 			.dropdown-content-section {
	// 				display: block;
	// 				width: 13em;

	// 				.Dropdown-label {
	// 					padding-left: 4px;
	// 					// text-align: center;
	// 					color: #8d8d8d;
	// 					font-size: 14px;
	// 				}

	// 				.p-dropdown {
	// 					width: 100%;
	// 					font-weight: bold;
	// 					height: 1.5em;
	// 					font-size: 13px;
	// 				}

	// 				.p-dropdown-label{
	// 					padding-top: 0;
	// 					padding-left: 0.25em;
	// 					font-weight: bold;
	// 					margin-bottom: 0.5em;
	// 					text-transform: capitalize;
	// 					// width: 9em;
	// 				}

	// 				.p-dropdown-panel {
	// 					background-color: #fff;
	// 					// position: absolute;
	// 					top: 21px !important;
	// 				}
					
	// 			}
	// 		}
	// 	}
	// 	.right-div {
	// 		display: flex;
	// 		justify-content: right;
	// 		margin-right: 10px;

	// 		.archive-text {
	// 			font-size: 14px;
	// 			margin: 1em 0.5em;
	// 			width: 11em;
	// 		}

	// 		.input-wrapper {
	// 			background-color: #fff;
	// 			margin: 0.5em;
	// 			box-shadow: 0px 2px 30px #00000026;
	// 			display: flex;
	// 			border-radius: 4px;

	// 			.dropdown-content-section {
	// 				display: block;
	// 				width: 13em;

	// 				.Dropdown-label {
	// 					padding-left: 4px;
	// 					// text-align: center;
	// 					color: #8d8d8d;
	// 					font-size: 14px;
	// 				}

	// 				.p-dropdown {
	// 					width: 100%;
	// 					font-weight: bold;
	// 					height: 1.5em;
	// 					font-size: 13px;
	// 				}

	// 				.p-dropdown-label{
	// 					padding-top: 0;
	// 					padding-left: 0.25em;
	// 					font-weight: bold;
	// 					margin-bottom: 0.5em;
	// 					text-transform: capitalize;
	// 					width: 9em;
	// 				}

	// 				.p-dropdown-panel {
	// 					background-color: #fff;
	// 					// position: absolute;
	// 					top: 21px !important;


	// 				}
					
	// 			}

	// 			.custom-calender-cls {
	// 				> p {
	// 					margin: 0;
	// 					padding-left: 4px;
	// 					// text-align: center;
	// 					color: #8d8d8d;
	// 					font-size: 14px;
	// 				}
	// 				.p-calendar {
	// 					.p-inputtext {
	// 						border: none;
	// 						box-shadow: none;
	// 						width: 100%;
	// 						margin: 0;
	// 						height: 2em;
	// 					}

						
	// 				}

	// 				.p-datepicker-group{
	// 					.p-highlight{
	// 						background-color: #FFD900;
	// 					}
	// 				}

	// 				img {
	// 					left: -0.5em;
	// 					top: 0.2em;
	// 				}
	// 			}
	// 		}

	// 		.commodity-wrapper {
	// 			.autoComplete-content-section {
	// 				padding: 0;

	// 				.autoComplete-label {
	// 					text-align: center;
	// 					color: #8d8d8d;
	// 					font-size: 14px;
	// 					margin: 0;
	// 				}

	// 				.p-autocomplete {
	// 					.p-inputtext {
	// 						box-shadow: none;
	// 						border: solid #fff;
	// 						border-radius: none;
	// 					}
	// 				}
	// 			}

				
	// 		}
	// 	}
	// }
	// .content-cls {
	// 	display: flex;
	// 	flex-wrap: wrap;
	// 	// justify-content: space-evenly;

	// 	.product-item {
	// 		width: 15em;
	// 		margin: 0.5em 1.5em;
	// 		background-color: lightgreen;
	// 		color: white;
	// 		text-shadow: 2px 2px 6px #000000;
	// 		background-size:cover;
	// 		background-position:center;
	// 		background-repeat:no-repeat;
	// 		.top-warpper {
	// 			display: flex;
	// 			// width: 19em;
	// 			height: 7em;
	// 			margin: 0 0.5em;
	// 			// background-color: #ffd700;
	// 			.image-wrapper {
	// 				.commodity-image {
	// 					border: 1px solid black;
	// 					height: 62px;
	// 					margin: 0.5em 0.5em;
	// 					background: #ffffff 0% 0% no-repeat padding-box;
	// 					border: 1px solid #8a8a8a;
	// 					border-radius: 0px 25px 25px 25px;
	// 					width: 62px;
	// 					// box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	// 				}

	// 				img {
	// 					width: 100%;
	// 					height: 100%;
	// 					border-radius: 0px 25px 25px 25px;
	// 				}
	// 			}

	// 			.commodity-info {
	// 				padding-top: 1em;
	// 				text-transform: capitalize;
	// 				overflow: hidden;
	// 				word-wrap: break-word;

	// 				p {
	// 					margin: 0;
	// 				}

	// 				.commodity-name {
	// 					font-size: 20px;
	// 				}

	// 				.commodity-price {
	// 					font-size: 14px;
	// 				}
	// 			}

	// 			.commodity-qty {
	// 				color: #ff4848;
	// 				padding-top: 0.5em;
	// 				padding-right: 0.3em;

	// 				p {
	// 					margin: 0;
	// 				}
	// 			}
	// 		}

	// 		.bottom-wrapper {
	// 			display: flex;
	// 			justify-content: space-between;
	// 			// color: black;
	// 			font-weight: bold;
	// 			padding: 1em;
	// 			// background: #ffd700;
				
	// 			.view-pdf-cls{
	// 				z-index: 10;
	// 				width: 100px;
	// 				height: 72px;
	// 				display: none;
	// 				>button{
	// 					position: absolute;
	// 					right: 2em;
	// 				}
	// 			}
	// 			p {
	// 				margin: 0;
	// 				font-size: 14px;
	// 				cursor: pointer;
	// 			}
	// 			img {
	// 				width: 1.5em;
	// 				height: 1.5em;
	// 			}
	// 			.closes-in {
	// 				font-weight: normal;
	// 				font-size: 12px;
	// 			}

	// 			.pi-download {
	// 				cursor: pointer;
	// 			}
	// 		}
	// 	}
	// }

	// .customTable-cls {
	// 	padding: 0 1.5em;
	// 	.p-button {
	// 		padding: 6px;
	// 		border: navajowhite;
	// 		background: yellowgreen;
	// 		color: black;
	// 	}
	// 	.p-datatable .p-datatable-wrapper .p-datatable-tbody .p-datatable-row,
	// 	.custom-table .p-datatable .p-datatable-scrollable-wrapper .p-datatable-tbody .p-datatable-row,
	// 	.custom-table .p-datatable-scrollable-body .p-datatable-wrapper .p-datatable-tbody .p-datatable-row,
	// 	.custom-table .p-datatable-scrollable-body .p-datatable-scrollable-wrapper .p-datatable-tbody .p-datatable-row {
	// 		background: none;
	// 	}

	// 	.p-datatable-resizable {
	// 		tr{
	// 			padding-left: 0.5em;
	// 		}
	// 		.p-datatable-thead > tr > th {
	// 			text-align: left;
	// 			color: #d4d4d4;
	// 			height: 4em;
	// 		}
	// 		.p-datatable-tfoot > tr > td,
	// 		.p-datatable-tbody > tr > td {
	// 			color: black;
	// 			text-align: left;
	// 			height: 4em;
	// 			font-family: "Poppins", sans-serif;
	// 			font-weight: normal;
	// 			padding-left: 2em;

	// 		}
	// 		.p-datatable-scrollable-header {
	// 			background-color: #707070;
	// 			padding-left: 2em ;
	// 		}

	// 		.commodity-name{
	// 			display: flex;
	// 			margin: 0;
	// 		}

	// 		.updown-prices{
	// 			display: flex;
				
	// 			.green, .pi-caret-up{
	// 				color: #40e905;
	// 				margin: 0;
	// 			}
	
	// 			.red, .pi-caret-down{
	// 				color: red;
	// 				margin: 0;
	// 			}

	// 			.pi{
	// 				margin-top: 0.25em;
	// 				margin-right: 0.5em;
	// 			}
	// 		}

			
	// 	}
	// }
	}
