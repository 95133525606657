.autoComplete-content-section{
    
    // padding: 1em;

    .autoComplete-label{
        margin-bottom: 0.5em;
    }

    .p-autocomplete{

        >input{
            box-shadow: 0px 3px 6px #00000029;
            border: 0.5px solid #d2d2d2;
            border-radius: 4px;
            height: 2em;
            background: #fff;
            opacity: 1;
            width: 100%;
        }

        .p-autocomplete-multiple-container{
            box-shadow: 0px 3px 6px #00000029;
            border: 0.5px solid #d2d2d2;
            border-radius: 4px;
            // height: 2em;
            background: #fff;
            opacity: 1;
            width: 100%;

            .p-autocomplete-token{
                color: #000;
                background-color: #d2d2d2;
                border-radius: 4px;
            }

        }
    }

    .p-autocomplete-panel{
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 4px;

        .p-autocomplete-items{
            padding: 0.4em;
            border: 0 none;
            margin: 0;
            list-style-type: none;
            background: #fff;
            border: 1px solid #7070704D;
            color: #000;
            
        }

        li:hover {
            background: #ecefe9;
            border-radius: 4px;
            box-shadow: 0px 1.5px 3px #4b652e4D;
        }
        
        .p-highlight{
            background: #ecefe9;
            border-radius: 4px;
            box-shadow: 0px 1.5px 3px #4b652e4D;
        }
    }
}