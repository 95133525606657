	.emandi-tv-cls {
		font-family: "Poppins", sans-serif;
		padding:50px;
	
		.contact-us-scrollbar{
			height: 55vh !important;
		}

		.header-cls {
			display: flex;
			margin: 0.5em 3em;
			justify-content: start;
			img {
				width: 2em;
				height: 2em;
				// border: 2px solid bisque;
				border-radius: 50%;
				padding: 4px;
				box-shadow: 1px 1px 6px;
			}
			p {
				margin: 0.5em;
				font-size: 16px;
			}

			.pi-angle-left {
				margin: 0.5em 0em;
				font-size: 16px;
				box-shadow: 0px 0px 20px #00000029;
				border-radius: 50%;
				height: 1.5em;
				width: 1.5em;
				text-align: center;
				line-height: 1.5em;
				cursor: pointer;
			}
		}
		
		
		.menu-cls {
			display: flex;
			margin: 2em;
			h2{
				margin: 0.1em 0 0.5em 0;
			}
			justify-content: space-between;
			.list-cls{
				width: 16em;
				p{
					align-items: center;
					display: flex;
					justify-content:space-between;
					color: white;
					margin: 0.1em 0;
					font-size: 17px;
					font-weight: bold;
					border-radius: 10px;
					padding-right: 30px;
					padding-left: 20px;
					width: 9em;
					>img{
						width: 1.5em;
						height: 3em;
					}
					
				}
			}
			.list-EMandi{
				background-color: #0f9d58;
				color: white;
				height: 3em;
			}
			// .list-EMandi:hover{
			// 	background-color: #0f9d58;
			// 	color: white;
				
			// }
			.Emandi-TV-cls{
				.product-item{
					border-bottom: 2px solid gray;
					width: 60%;
					cursor: pointer;
					.top-cls{
						display: flex;
						padding: 0.5em 0;
						.youtube-symbol-cls{	
							margin: 0.5em 2em 0.5em 0;
							padding: 0.5em;
							margin-right: 2em;
							display: flex;
							align-items: center;
							border-radius: 8px;
							width: 11em;
							height: 5em;
							background: black;
							img{
								align-items: center;
								margin: 0 auto;
								display: none
							}
						}
						.youtube-symbol-cls:hover{
							img{
								display: block;
							}
						}
						
						.emandi-tittle{
							color:black;
							margin: 0.5em 0;
							font-size: 18px;
							font-weight: bold;
							text-transform: capitalize;
						}
						.emandi-date{
								color:black;
								margin: 0.5em 0;
								font-size: 14px;
						}
				}
			}
		}
	}
	.emandiTV-dailog{
		.buy-now-dialog,.cashandcarry-dialog,.setalert-dialog,.E-mandicash-dialog{
			.custom-dialog{
				.p-dialog{
					
					.p-component{
						.p-dialog-enter-done{
							width: 20vw !important;
						}
					}
				}
				.p-dialog-mask{
					.p-dialog{
						
						.p-dialog-content {
							padding: 0 !important;
							border-bottom-left-radius: 10px;
							border-bottom-right-radius: 10px;
							background-color: #ffffff;
							// background: linear-gradient(180deg, #DEDEDE 0%, #fff);
						}
						.p-dialog-titlebar{
							font-family: 'Poppins', sans-serif;
							font-size: 25px;
							display: flex;
							justify-content: center;
							border: none !important;
							border-top-left-radius: 10px;
							border-top-right-radius: 10px;
							background: linear-gradient(180deg, #DEDEDE 0%, #fff);
							p{
								font-size: 25px;
								margin: 0;
								font-weight: bold;
								text-align: center;
								color: #333333;
								border: none;
								margin-top: 0.25em;
							}

							.p-dialog-titlebar-icons{
								display: none;
							}
						}
						.p-dialog-footer{
							background-color: #eff1f2;
							display: none;
								>div{
									justify-content: flex-end;
									margin-top     : -60px;
								}
						}
					}
				}
			}
			
			.p-dialog-footer {
		
				.cancel {
					display: none;
				}
		
				.accept {
					display       : none;
				}
			}
		.buynow-cls {
			.message-us{
				display: flex;
				flex-direction: column;
				align-items: center;
				iframe{
					border: none;
					border-radius: 12px;
					display: flex;
					align-items: center;
				}
			}
			
				.btns-cls{
					>div{
						margin: 0.5em 0em;
						display: flex;
						justify-content: center;
						padding: 10px;
						.cancel{
						background-color: #707070;
						}
					}
				.button-content-section {
					display        : flex;
					justify-content: center;
					margin     : 1em 1em;
		
					.p-button {
						color         : #ffffff;
						border        : 1px solid #707070;
						padding       : 4px 10px;
						border-radius : 5px;
						letter-spacing: 1px;
						font-size     : 16px;
						box-shadow    : 0px 0px 3px 2px #0000001A;
		
					}
				}
			}
		}
		}
	}
	.dropdown-content-section .p-dropdown{
		height: 3.2em;
		// border-radius: 3em;
		border: 1px solid #ada4a3;
		width: 15em;
		.p-dropdown-panel .p-dropdown-item {
			font-weight: normal;
			background-color: #FFFFFF;
			cursor: pointer;
			font-family: 'Poppins', sans-serif;
			margin: 0 !important;
			
		}
	}
	}
	.showing-page{
		margin-left: 650px;
	}
	.direct-btn{
		display: flex;
		gap: 20px;
		margin-left: 600px;
	}
	.move-to-first , .move-to-last{
		cursor: pointer;
	}
	.pageNumbers{
		list-style: none;
		display: flex;
		height: 39px;
		// margin-left: 550px;
		// margin-bottom: 50px;
		position: absolute;
		// left: 79vh;
		top: 76vh;
		right: 381px;
	}
	.heading{
        color: black;
        font-size: 14px;
        font-weight: bold;
        width: 30em;
        text-transform: capitalize;
        font-family: "Poppins", sans-serif;
        }
	.pageNumbers li{
		padding: 7px 12px;
		cursor: pointer;
		border-radius: 50%;
	}
	.pageNumbers li:first-child , .pageNumbers li:last-child{
		font-size: 20px;
	}
	.pageNumbers li.active-page{
		background-color: white;
		color: #0f9d58;
		font-weight: 800;
		font-size: 20px;
	}
	.pageNumbers li button{
		background-color: transparent;
		border: none;
		color: black;
		font-size: 1rem;
		cursor: pointer;
	}
	.pageNumbers li button:hover{
		background-color: rgb(167, 164, 164);
	}
