@font-face {
    font-family: MetropolisLight;
    src: url('Metropolis-Light.otf')
}

@font-face {
    font-family: MetropolisRegular;
    src: url('Metropolis-Bold.otf')
}

@font-face {
    font-family: MetropolisRegular;
    src: url('Metropolis-Regular.otf')
} 
ul > li > label > span{
    color:#979797;
    font-family: MetropolisLight !important;

}
#timeSelct {
    font-family: MetropolisRegular;
}
.commodity-research-css {
    font-family: MetropolisRegular !important;
}

.commodityBody {
    max-width: 84% !important;
}
.commodityBody .tabs{
    left: 47% !important;
}
.commodityBody .tabs .content .text {
    color: #757575;
    margin-bottom: 3rem;
}

.ul-css-1 {
    border-width: 0px !important;
}

/* .nav-tabs {
    border-width: 2px;
} */

.commodityBody .tabs .content .ImageTextDiv .commodityImage {
    width: 34px;
    height: 34px;
    float: left;
    border-radius: 100%;
}

.commodityBody  .tabs .content .commodityRow1 {
    display: flex;
    flex-direction: row;
}

.commodityBody .tabs .content .commoditybtn {
    display: flex;
    justify-content: center;
    margin-top: 3.5% !important;
}

.commodityBody .tabs .content .commoditybtn .btn {
    background: #FFFFFF;
    /* Outline/box */
    color: #979797;
    border: 1px solid #D3D3D3;
    font-family: 'MetropolisRegular';
    border-radius: 8px;
    width: 60%;
    padding: 12px 16px;
    font-size: 17px;
}

.text-css-1 {
    font-family: MetropolisRegular;
    margin-top: -8px;
    font-size: 16px;
}
.ImageTextDiv{
    display: flex;
    flex-direction: column;
    padding-left: 7%;
}
.commodityReaserachh .smallBox {
    margin-left: 7%;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 36px;
}
.commodityReaserachh .smallBox .mx-3{
    margin-bottom: -7px !important;
}
#timeSelct{
    margin-bottom: 0px !important;
    margin-top: 10px !important;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none !important;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAUCAYAAAADU1RxAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADPSURBVEhL7ZYxDoQgEEVnt6PU0pKW+9BaegyvYEvpFTgQJa0l5e5+FjfExDhiYTbhJUQzMM4LmAyP1we6mWd63kqVWKkSK/8hMU1THCGEFOHhvadxHMlamyL7sHYCHzTGsEXy9cuypOg+hxLDMFDXdWyRfB3ytNZpZp9DCSEEW2QrgDzkH8E6Do5IqQA41TtQAIVQEIX6vqemaS4JgNMNLBcBUkpyzsX3EgFQ1EUhMs/zrzhQSsWf8KwAuNTKsRsQats2Hksp9T6xUiW+EL0BuWSYvXqa5esAAAAASUVORK5CYII=) !important;
    background-position: right 3.75rem center !important;
    background-size: 76px 52px !important;
    background-repeat: no-repeat !important;
}
#timeSelct:focus {
    outline: none;
    border-color: none;
}
.nav-link {
    color: #9d9d9d !important;
}
.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
    color: var(--bs-navbar-active-color) !important;
}
.ellipsis {
    display: inline-block;
    width: 100px; 
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;
    font-family: MetropolisRegular;
  }
  .dailyCards{
    justify-content: flex-start;
  }
  .container, .container-md, .container-sm {
    max-width: 9999px !important;
}
/* .form-select:focus {
    border-color: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
} */
@media only screen and (max-width: 7680px) and (min-width: 5747px){
    .commodityReaserachh .smallBox .mx-3{
        margin-right: 6.5rem !important;
        margin-left: 5.5rem !important;
        font-size: 68px !important;
    }
    #timeSelct{
        border-radius: 29px !important;
        padding: 3.375rem 4.5rem !important;
        font-size: 4.5rem!important;
    }
    .commodityReaserachh .smallBox {
        margin-top: 22rem !important;
        margin-bottom: 14rem;
    }
    .commodityBody .nav-item button span {
        font-size: 52.5px !important;
    }
    .commodityBody .nav-link {
        border-width: 0px 0px 6px !important;
        padding-bottom: 34px !important;
    }
    .commodityReaserachh .daily-seasonal-tab {
        margin-right: 112px !important;
    }
    .tabs {
        margin-left: 1.5% !important;
        width: 83%;
    }
    .commodityBody .tabs .content .text {
        color: #757575;
        margin-bottom: 10rem !important;
        margin-top: 11rem !important;
        font-size: 43px !important;
    }
    .commodityBody .tabs .content .ImageTextDiv .commodityImage {
        width: 133px !important;
        height: 134px !important;
    }
    .ellipsis {
        display: inline-block;
        width: 336px !important;
        font-size: 44px;
        margin-top: 5rem !important;
    }
    .commodityBody .tabs .content .commoditybtn .btn {
        background: #FFFFFF;
        color: #979797;
        border: 1px solid #D3D3D3;
        font-family: 'MetropolisRegular';
        border-radius: 18px;
        width: 46%;
        padding: 42px 28px;
        font-size: 48px;
        margin-bottom: 30rem;
    }
}
@media only screen and (max-width: 5746px) and (min-width: 5465px){
    .commodityReaserachh .smallBox .mx-3{
        margin-right: 6.5rem !important;
        margin-left: 5.5rem !important;
        font-size: 68px !important;
    }
    #timeSelct{
        border-radius: 29px !important;
        padding: 3.375rem 4.5rem !important;
        font-size: 4.5rem!important;
    }
    .commodityReaserachh .smallBox {
        margin-top: 22rem !important;
        margin-bottom: 14rem;
    }
    .commodityBody .nav-item button span {
        font-size: 52.5px !important;
    }
    .commodityBody .nav-link {
        border-width: 0px 0px 6px !important;
        padding-bottom: 34px !important;
    }
    .commodityReaserachh .daily-seasonal-tab {
        margin-right: 112px !important;
    }
    .tabs {
        margin-left: 1% !important;
        width: 83%;
    }
    .commodityBody .tabs {
        left: 47.5% !important;
    }
    .commodityBody .tabs .content .text {
        color: #757575;
        margin-bottom: 10rem !important;
        margin-top: 11rem !important;
        font-size: 43px !important;
    }
    .commodityBody .tabs .content .ImageTextDiv .commodityImage {
        width: 133px !important;
        height: 134px !important;
    }
    .ellipsis {
        display: inline-block;
        width: 336px !important;
        font-size: 44px;
        margin-top: 5rem !important;
    }
    .commodityBody .tabs .content .commoditybtn .btn {
        background: #FFFFFF;
        color: #979797;
        border: 1px solid #D3D3D3;
        font-family: 'MetropolisRegular';
        border-radius: 18px;
        width: 46%;
        padding: 42px 28px;
        font-size: 48px;
        margin-bottom: 30rem;
    }
}
@media only screen and (max-width: 5464px) and (min-width: 4099px){
    .commodityReaserachh .smallBox .mx-3{
        margin-right: 6.5rem !important;
        margin-left: 5.5rem !important;
        font-size: 68px !important;
    }
    #timeSelct{
        border-radius: 29px !important;
        padding: 3.375rem 4.5rem !important;
        font-size: 4.5rem!important;
    }
    .commodityReaserachh .smallBox {
        margin-top: 22rem !important;
        margin-bottom: 14rem;
    }
    .commodityBody .nav-item button span {
        font-size: 52.5px !important;
    }
    .commodityBody .nav-link {
        border-width: 0px 0px 6px !important;
        padding-bottom: 34px !important;
    }
    .commodityReaserachh .daily-seasonal-tab {
        margin-right: 112px !important;
    }
    .tabs {
        margin-left: 1.5% !important;
        width: 84%;
    }
    .commodityBody .tabs .content .text {
        color: #757575;
        margin-bottom: 10rem !important;
        margin-top: 11rem !important;
        font-size: 43px !important;
    }
    .commodityBody .tabs .content .ImageTextDiv .commodityImage {
        width: 133px !important;
        height: 134px !important;
    }
    .ellipsis {
        display: inline-block;
        width: 336px !important;
        font-size: 44px;
        margin-top: 5rem !important;
    }
    .commodityBody .tabs .content .commoditybtn .btn {
        background: #FFFFFF;
        color: #979797;
        border: 1px solid #D3D3D3;
        font-family: 'MetropolisRegular';
        border-radius: 18px;
        width: 46%;
        padding: 42px 28px;
        font-size: 48px;
        margin-bottom: 30rem;
    }
}
@media only screen and (max-width: 4098px) and (min-width: 3842px){
    .commodityReaserachh .smallBox .mx-3{
        margin-right: 5.5rem !important;
        margin-left: 4.5rem !important;
        font-size: 60px !important;
    }
    #timeSelct{
        border-radius: 29px !important;
        padding: 3.375rem 4.5rem !important;
        font-size: 4.5rem!important;
    }
    .commodityReaserachh .smallBox {
        margin-top: 22rem !important;
        margin-bottom: 14rem;
    }
    .commodityBody .nav-item button span {
        font-size: 52.5px !important;
    }
    .commodityBody .nav-link {
        border-width: 0px 0px 6px !important;
        padding-bottom: 34px !important;
    }
    .commodityReaserachh .daily-seasonal-tab {
        margin-right: 112px !important;
    }
    .tabs {
        margin-left: 1% !important;
        width: 83%;
    }
    .commodityBody .tabs .content .text {
        color: #757575;
        margin-bottom: 10rem !important;
        margin-top: 11rem !important;
        font-size: 43px !important;
    }
    .commodityBody .tabs .content .ImageTextDiv .commodityImage {
        width: 133px !important;
        height: 134px !important;
    }
    .ellipsis {
        display: inline-block;
        width: 336px !important;
        font-size: 44px;
        margin-top: 5rem !important;
    }
    .commodityBody .tabs .content .commoditybtn .btn {
        background: #FFFFFF;
        color: #979797;
        border: 1px solid #D3D3D3;
        font-family: 'MetropolisRegular';
        border-radius: 18px;
        width: 46%;
        padding: 42px 28px;
        font-size: 48px;
        margin-bottom: 30rem;
    }
}
@media only screen and (max-width: 3841px) and (min-width: 1441px){
    .commodityReaserachh .small-box {
        width: 17px;
        height: 98px;
    }
    .commodityReaserachh .smallBox{
        margin-top: 23rem!important;
        margin-bottom: 17rem;
    }
    .commodityReaserachh .mx-3{
        margin-right: 4.5rem !important;
        margin-left: 4.5rem !important;
        font-size: 62px !important;
      }
      .commodityBody .tabs {
        left: 48% !important;
    }
      .commodityReaserachh .smallBox select{
        padding: 3.375rem 5rem !important;
        font-size: 47px!important;
        border-radius: 31px !important;
        width: 18% !important;
      }
      .commodityBody .nav-item button span{
        font-size: 54.5px !important;
      }
      .daily-seasonal-tab{
        margin-right: 106px !important;
      }
      .commodityBody .nav-link{
        font-size: 17.5px !important;
        border-width: 0px 0px 6px !important;
        padding-bottom: 34px !important;
      }
      .commodityBody {
        max-width: 92% !important;
        padding-left: 5% !important;
    }
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 19%;
    }
    .ellipsis {
        display: inline-block;
        width: 340px;
    }
      .text-css-1 {
        font-family: MetropolisRegular;
        margin-top: 114px;
        margin-bottom: 114px !important;
        font-size: 57px;
    }
    .commodityReaserachh .containerBody{
        display: flex;
        justify-content: space-between;
        width: 87%;
        margin-left: 8%;
    }
    .dailyCards{
        width: 100% !important;
        margin-left: 0px !important;
        display: flex;
        justify-content: space-between;
    }
    .dailyBoxx{
        height: 370px;
        padding: 62px;
        margin-bottom: 88px;
    }
    .commodityBody .tabs .content .ImageTextDiv .commodityImage {
        width: 108px;
        height: 108px;
    }
    .commodityBody .tabs .content .ImageTextDiv a span {
        font-size: 48px !important;
        margin-top: 27px !important;
    }
    .commodityBody .tabs .content .commoditybtn .btn {
        border-radius: 26px;
        width: 53%;
        padding: 48px 27px;
        font-size: 61px;
    }

}
@media only screen and (max-width: 3491px) and (min-width: 2733px){
  .commodityReaserachh .smallBox select {
    padding: 3.375rem 5rem !important;
    font-size: 38px!important;
    border-radius: 31px !important;
}
.commodityBody .col-md-2 {
    flex: 0 0 auto;
    width: 18%;
}
  }
@media only screen and (max-width: 3200px) and (min-width: 3073px){
#timeselct{
    width: 19%;
}
.commodityBody .col-md-2 {
    flex: 0 0 auto;
    width: 18%;
}
}
@media only screen and (max-width: 3072px) and (min-width: 2733px){
    #timeselct{
        width: 20% !important;
    }
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 18%;
    }
}
@media only screen and (max-width: 3051px) and (min-width: 2733px){
    #timeselct{
        width: 19%;
    }
}
@media only screen and (max-width: 2732px) and (min-width: 2561px){
    .commodityReaserachh .small-box {
        width: 13px;
        height: 67px;
    }
    .commodityReaserachh .mx-3 {
        margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
    font-size: 47px !important;
    }
    .commodityReaserachh .smallBox select {
        padding: 1.6rem 3rem !important;
        font-size: 31px!important;
        border-radius: 17px !important;
    }
    #timeSelct {
        background-position: right 2.75rem center !important;
        background-size: 62px 37px !important;
    }
    .commodityBody .nav-item button span {
        font-size: 37.5px !important;
    }
    .commodityBody .nav-link {
        font-size: 17.5px !important;
        border-width: 0px 0px 4px !important;
        padding-bottom: 23px !important;
    }
    .daily-seasonal-tab {
        margin-right: 72px !important;
    }
    .text-css-1 {
        font-family: MetropolisRegular;
        margin-top: 85px;
        margin-bottom: 110px !important;
        font-size: 39px;
    }
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 18%;
    }
    .commodityBody .tabs .content .ImageTextDiv .commodityImage {
        width: 92px;
        height: 92px;
    }
    .commodityBody .tabs .content .ImageTextDiv a span {
        font-size: 38px !important;
        margin-top: 27px !important;
    }
    .ellipsis {
        display: inline-block;
        width: 260px;
    }
    .dailyBoxx {
        height: 305px;
        padding: 62px;
        margin-bottom: 45px;
    }
    .commodityBody .tabs .content .commoditybtn .btn {
        border-radius: 20px;
        width: 48%;
        padding: 25px 27px;
        font-size: 42px;
    }
}
@media only screen and (max-width: 2560px) and (min-width: 1441px){
    .commodityReaserachh .smallBox {
        margin-top: 16rem!important;
        margin-bottom: 8rem;
    }
    .commodityBody .tabs {
        left: 49% !important;
    }
    .daily-seasonal-tab{
        margin-right: 66px !important;
    }
    .commodityReaserachh .small-box {
        width: 12px;
        height: 66px;
    }
    .commodityReaserachh .mx-3 {
        margin-right: 3.5rem !important;
        margin-left: 4.5rem !important;
        font-size: 43px !important;
    }
    .commodityReaserachh .smallBox select {
        padding: 1.9rem 3rem !important;
        font-size: 29px!important;
        border-radius: 17px !important;
    }
    .commodityBody .nav-item button span {
        font-size: 40.5px !important;
    }
    .text-css-1 {
        font-family: MetropolisRegular;
        margin-top: 61px;
        margin-bottom: 47px !important;
        font-size: 40px;
    }
    .dailyBoxx {
        height: 330px;
        padding: 71px;
        margin-bottom: 85px;
    }
    .commodityBody .tabs .content .ImageTextDiv .commodityImage {
        width: 65px;
        height: 65px;
    }
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 409px;
    }
    .commodityBody .tabs .content .ImageTextDiv a span {
        font-size: 29px !important;
        margin-top: 24px !important;
    }
    .ellipsis {
        display: inline-block;
        width: 189px;
    }
    .commodityBody .tabs .content .commoditybtn .btn {
        border-radius: 26px;
        width: 54%;
        padding: 16px 26px;
        font-size: 49px;
    }
}
@media only screen and (max-width: 2560px) and (min-width: 1441px){
    #timeSelct{
        background-position: right 2rem center !important;
        background-size: 63px 36px !important;
    }
    .commodityBody .nav-link {
        border-width: 0px 0px 4px !important;
        padding-bottom: 27px !important;
    }
    .commodityBody .nav-item button span {
        font-size: 32.5px !important;
    }
    .text-css-1 {
        font-size: 31px;
    }
    .commodityBody {
        max-width: 90% !important;
    padding-left: 2.5% !important;
    }
    .dailyBoxx {
        height: 242px;
        padding: 52px;
        margin-bottom: 33px;
    }
    .commodityBody .tabs .content .commoditybtn .btn {
            width: 50%;
            font-size: 39px;
            padding: 21px 26px;
    }
}
@media only screen and (max-width: 2560px) and (min-width: 1441px){
.commodityReaserachh .smallBox select {
    font-size: 27px!important;
}
.commodityBody .col-md-2 {
    flex: 0 0 auto;
    width: 18%;
}
}
@media only screen and (max-width: 2400px) and (min-width: 2331px){
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 18%;
    }
    .commodityReaserachh .mx-3  {
        margin-right: 2.5rem !important;
        margin-left: 3.5rem !important;
        font-size: 39px !important;
    }
    .commodityReaserachh .small-box {
        width: 12px;
        height: 57px;
    }
}
@media only screen and (max-width: 2330px) and (min-width: 2301px){
    #timeselct{
        width: 19% !important;
    }
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 18%;
    }
}
@media only screen and (max-width: 2300px) and (min-width: 2049px){
    .commodityReaserachh .small-box {
        width: 10px;
        height: 70px;
    }
    .commodityReaserachh .mx-3 {
        margin-right: 2rem !important;
        margin-left: 3rem !important;
        font-size: 39px !important;
    }
    .commodityReaserachh .smallBox select {
        padding: 1.5rem 2.5rem !important;
        font-size: 24px!important;
        border-radius: 17px !important;
    }
    #timeSelct {
        background-position: right 1.75rem center !important;
        background-size: 55px 30px !important;
    }
    .commodityBody .nav-link {
        border-width: 0px 0px 5px !important;
        padding-bottom: 17px !important;
    }
    .commodityBody .nav-item button span {
        font-size: 28.5px !important;
    }
    .text-css-1 {
        font-size: 29px;
    }
    .commodityBody .tabs .content .commoditybtn .btn {
        width: 46%;
        font-size: 33px;
        padding: 17px 26px;
    }
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 328px;
    }
    .commodityBody .tabs .content .ImageTextDiv .commodityImage {
        width: 52px;
        height: 52px;
    }
    .commodityBody .tabs .content .ImageTextDiv a span {
        font-size: 25px !important;
        margin-top: 24px !important;
    }
    .dailyBoxx {
        height: 219px;
    }
}
@media only screen and (max-width: 2048px) and (min-width: 1441px){
    .commodityBody .nav-link {
        border-width: 0px 0px 4px !important;
        padding-bottom: 24px !important;
    }
    .commodityReaserachh .small-box {
        width: 12px;
        height: 82px;
    }
    .commodityReaserachh .smallBox .mx-3 {
        margin-right: 2.5rem !important;
        margin-left: 3.5rem !important;
        font-size: 46px !important;
    }
    .commodityReaserachh .smallBox select {
        width: 21% !important;
        padding: 2rem 2rem !important;
        font-size: 29px!important;
        border-radius: 16px !important;
    }
    #timeSelct{
        background-position: right 2.75rem center !important;
        background-size: 59px 32px !important;
    }
    .commodityReaserachh .daily-seasonal-tab{
        margin-right: 52px !important;
    }
    .commodityBody .nav-item button span {
        font-size: 33.5px !important;
    }
    .commodityReaserachh .smallBox {
        margin-top: 11rem!important;
        margin-bottom: 6rem;
    }
    .text-css-1 {
        font-size: 29px;
    }
    .commodityBody .tabs {
        left: 49% !important;
    }
    .commodityBody {
        max-width: 86% !important;
        padding-left: 0.5% !important;
    }
    .commodityBody .tabs .content .ImageTextDiv .commodityImage {
        width: 54px;
        height: 54px;
    }
    .ellipsis {
        width: 180px;
    }
    .commodityBody .mt-3 {
        margin-top: 2rem!important;
    }
    .dailyBoxx {
        height: 204px;
    }
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 18% !important;
    }
    .commodityBody .tabs .content .ImageTextDiv a span {
        font-size: 27px !important;
    }
    .commodityBody .tabs .content .commoditybtn .btn {
        width: 43%;
        border-radius: 18px;
        padding: 19px 26px;
        font-size: 31px;
    }
}
@media only screen and (max-width: 1920px) and (min-width: 1441px){
    .commodityBody .nav-link {
        border-width: 0px 0px 2px !important;
        padding-bottom: 21px !important;
    }
    .commodityReaserachh .small-box {
        width: 10px;
        height: 47px;
    }
    .commodityReaserachh .smallBox .mx-3 {
        font-size: 30px !important;
        margin-right: 2.5rem !important;
        margin-left: 2.5rem !important;
    }
    .commodityReaserachh .smallBox select {
        width: 18% !important;
        padding: 1.4rem 2.7rem !important;
        font-size: 23px!important;
        border-radius: 13px !important;
    }
    #timeSelct {
        background-position: right 1.75rem center !important;
        background-size: 47px 22px !important;
    }
    .commodityReaserachh .daily-seasonal-tab{
        margin-right: 52px !important;
    }
    .commodityBody .nav-item button span {
        font-size: 24.5px !important;
    }
    .commodityReaserachh .smallBox {
        margin-top: 11rem!important;
        margin-bottom: 6rem;
    }
    .text-css-1 {
        font-size: 24px;
    }
    .commodityBody .tabs {
        left: 49% !important;
    }
    .commodityBody {
        max-width: 88% !important;
        padding-left: 1% !important;
    }
    .commodityBody .tabs .content .ImageTextDiv .commodityImage {
        width: 54px;
        height: 54px;
    }
    .ellipsis {
        width: 154px;
    }
    .commodityBody .mt-3 {
        margin-top: 2rem!important;
    }
    .dailyBoxx {
        height: 204px;
    }
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 18% !important;
    }
    .commodityBody .tabs .content .ImageTextDiv a span {
        font-size: 22px !important;
    }
    .commodityBody .tabs .content .commoditybtn .btn {
        width: 43%;
        border-radius: 18px;
        padding: 19px 26px;
        font-size: 31px;
    }
}
@media only screen and (max-width: 1822px) and (min-width:1709px){
    .commodityReaserachh .small-box {
        width: 10px;
        height: 46px;
    }
    .commodityReaserachh .smallBox .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 32px !important;
    }
    .commodityReaserachh .smallBox select {
        width: 18% !important;
        padding: 1rem 2.7rem !important;
        font-size: 21px!important;
        border-radius: 13px !important;
    }
    .commodityBody .nav-link {
        border-width: 0px 0px 3px !important;
        padding-bottom: 15px !important;
    }
    .commodityBody .nav-item button span {
        font-size: 23.5px !important;
    }
    .text-css-1 {
        font-size: 22px;
        margin-top: 47px;
    }
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 18%!important;
    }
    .box {
        padding: 44px;
    }
    .commodityBody .tabs .content .ImageTextDiv .commodityImage {
        width: 45px;
        height: 45px;
    }
    .commodityBody .tabs .content .ImageTextDiv a span {
        font-size: 20px !important;
    }
    .dailyBoxx {
        height: 194px;
    }
    .commodityBody .tabs .content .commoditybtn .btn {
        width: 48%;
        border-radius: 13px;
        padding: 16px 26px;
        font-size: 27px;
    }
}
@media only screen and (max-width: 1708px) and (min-width:1681px){
    .commodityReaserachh .small-box {
        width: 10px;
        height: 46px;
    }
    .commodityReaserachh .smallBox .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 29px !important;
    }
    .commodityReaserachh .smallBox select {
        width: 18% !important;
        padding: 1rem 2.7rem !important;
        font-size: 20px!important;
        border-radius: 13px !important;
    }
    .commodityBody .nav-link {
        border-width: 0px 0px 3px !important;
        padding-bottom: 15px !important;
    }
    .commodityBody .nav-item button span {
        font-size: 23.5px !important;
    }
    .text-css-1 {
        font-size: 22px;
        margin-top: 47px;
    }
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 267px!important;
    }
    .commodityBody .tabs .content .ImageTextDiv .commodityImage {
        width: 45px;
        height: 45px;
    }
    .commodityBody .tabs .content .ImageTextDiv a span {
        font-size: 20px !important;
    }
    .dailyBoxx {
        height: 194px;
    }
    .commodityBody .tabs .content .commoditybtn .btn {
        width: 48%;
        border-radius: 13px;
        padding: 16px 26px;
        font-size: 27px;
    }
}
@media only screen and (max-width: 1680px) and (min-width: 1441px){
    
    .commodityReaserachh .smallBox .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 27px !important;
        padding: 0px !important;
    }
    .commodityBody .nav-link {
        border-width: 0px 0px 2px !important;
        padding-bottom: 17px !important;
    }
    .commodityReaserachh .small-box {
        width: 9px;
        height: 43px;
    }
    #timeSelct {
        background-position: right 1.75rem center !important;
        background-size: 40px 18px !important;
    }
    .commodityReaserachh .smallBox select {
        padding: 1.2rem 2.4rem !important;
        font-size: 18px!important;
        border-radius: 9px !important;
    }
    .commodityReaserachh .daily-seasonal-tab{
        margin-right: 52px !important;
    }
    .commodityBody .nav-item button span {
        font-size: 22.5px !important;
    }
    .commodityReaserachh .smallBox {
        margin-top: 11rem!important;
        margin-bottom: 6rem;
    }
    .text-css-1 {
        font-size: 22px;
        margin-top: 40px;
    }
    .commodityBody .tabs {
        left: 48% !important;
    }
    .commodityBody {
        max-width: 93% !important;
        padding-left: 4.5% !important;
    }
    .commodityBody .tabs .content .ImageTextDiv .commodityImage {
        width: 54px;
        height: 54px;
    }
    .ellipsis {
        width: 154px;
    }
    .commodityBody .mt-3 {
        margin-top: 2rem!important;
    }
    .dailyBoxx {
        height: 204px;
    }
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 18% !important;
    }
    .commodityBody .tabs .content .ImageTextDiv a span {
        font-size: 22px !important;
    }
}
@media only screen and (max-width: 1600px) and (min-width: 1441px){
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 18% !important;
    }
    .commodityBody .nav-link {
        border-width: 0px 0px 2px !important;
        padding-bottom: 20px !important;
    }
    .ellipsis {
        width: 132px;
        margin-right: 0px !important;
    }
        
    .commodityReaserachh .mx-2 {
        width: 18% !important;
    }
    .commodityBody {
        max-width: 92% !important;
    padding-left: 4% !important;
    }
    .commodityReaserachh .small-box {
        width: 8px;
        height: 42px;
    }
    .commodityReaserachh .smallBox .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 26px !important;
    }   
    .commodityBody .nav-item button span {
        font-size: 24.5px !important;
    }
    .commodityReaserachh .smallBox {
        margin-top: 8rem!important;
        margin-bottom: 4rem;
    }
    .commodityBody .tabs {
        left: 48% !important;
    }
    .commodityBody .nav-item button span {
        font-size: 20.5px !important;
    }
    .commodityReaserachh .daily-seasonal-tab{
        margin-right: 52px !important;
    }
    .text-css-1 {
        font-family: MetropolisRegular;
        margin-top: 25px;
        margin-bottom: 34px !important;
        font-size: 21px;
    }
    .dailyBoxx {
        height: 164px;
        padding: 17px;
        margin-bottom: 22px;
    }
    .commodityBody .tabs .content .ImageTextDiv .commodityImage {
        width: 52px;
        height: 52px;
    }
    .commodityBody .tabs .content .ImageTextDiv a span {
        font-size: 21px !important;
    }
    .commodityBody .tabs .content .commoditybtn .btn {
        border-radius: 14px;
        width: 49%;
        padding: 12px 26px;
        font-size: 26px;
    }
}
@media only screen and (max-width: 1536px) and (min-width: 1519px){
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 18% !important;
    }
}
@media only screen and (max-width: 1518px) and (min-width: 1441px){
    .commodityReaserachh .smallBox select {
        padding: 1.2rem 2.4rem !important;
        font-size: 17px!important;
        border-radius: 9px !important;
    }
    .commodityBody .nav-link {
        border-width: 0px 0px 3px !important;
        padding-bottom: 12px !important;
    }
    .commodityBody .nav-item button span {
        font-size: 19.5px !important;
    }
    .text-css-1 {
        font-size: 19px;
    }
    .commodityBody .tabs .content .commoditybtn .btn {
        border-radius: 14px;
        width: 45%;
        padding: 12px 26px;
        font-size: 24px;
    }
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 18% !important;
    }
    .commodityBody .tabs .content .ImageTextDiv .commodityImage {
        width: 40px;
        height: 40px;
    }
    .commodityBody .tabs .content .ImageTextDiv a span {
        font-size: 18px !important;
    }
}
@media only screen and (max-width: 1440px){
    .commodityBody {
        max-width: 88% !important;
        padding-left: 3% !important;
    }
    .commodityReaserachh .smallBox .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 23px !important;
    }  
    #timeSelct{
        background-position: right 1.75rem center !important;
        background-size: 34px 19px !important;
        padding: 0.9rem 2.5rem !important;
        width: 18% !important;
    }
    .commodityReaserachh .smallBox {
        margin-top: 77px !important;
    }
    .commodityBody .tabs {
        left: 47% !important;
    }
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 17%;
    }
}
@media only screen and (max-width: 1366px){
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 16.5%;
    }
    .dailyCards{
        width: 100% !important;
    }
    .commodityReaserachh .smallBox {
        margin-top: 77px !important;
    }
    .commodityBody .tabs {
        left: 53% !important;
    }
    #timeSelct{
        padding: 0.7rem 2.5rem !important;
        font-size: 15px!important;
    }
    .commodityReaserachh .smallBox .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 24px !important;
    }
}
@media only screen and (max-width: 1280px){
    .commodityBody .tabs {
        left: 46.5% !important;
    }
    .commodityBody {
        max-width: 99% !important;
        padding-left: 8.5% !important;
    }
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 17.5%;
    }
    .commodityReaserachh .mx-2 {
        width: 18% !important;
    }
    #timeSelct{
        padding: 0.7rem 1.5rem !important;
    }
    .commodityReaserachh .smallBox .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 21px !important;
    }
    .dailyCards{
        width: 100% !important;
    }
}
@media (max-width: 1093px) and (min-width: 1025px){
    #timeSelct {
        padding: 0.7rem 2.5rem !important;
        font-size: 12px!important;
        width: 19% !important;
        background-size: 26px 14px !important;
    }
    .commodityBody .col-md-2 {
        flex: 0 0 auto;
        width: 22%;
    }
    
}
@media only screen and (max-width: 1024px){
    #timeSelct {
        padding: 0.7rem 1.5rem !important;
        font-size: 13px!important;
        width: 19% !important;
        background-position: right 0.75rem center !important;
        background-size: 27px 15px !important;
    }
    .commodityReaserachh .form-select{
        padding: 0.7rem 1.5rem !important;
    }
    .commodityReaserachh .select-css{
        margin-left: 10px !important;
    }
    .commodityReaserachh .mx-2 {
        font-size: 14px !important;
    }
    .commodityReaserachh .box{
        width: 199px !important;
    }
    .dailyCards .box {
        width: 22% !important;
    } 
}
@media screen and (max-width: 987px){
    .commodityReaserachh .smallBox .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 19px !important;
    }
}
@media screen and (max-width: 976px) {
    #timeSelct {
        padding: 0.7rem 1.5rem !important;
        font-size: 13px!important;
        width: 21% !important;
    }
}
@media screen and (max-width: 915px){
    .commodityReaserachh .smallBox .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 17px !important;
    }
    .dailyCards .box {
        width: 21.6% !important;
    }
}
@media screen and (max-width: 881px) {
    #timeSelct {
        padding: 0.7rem 1.5rem !important;
        font-size: 13px!important;
        width: 24% !important;
    }
}
@media screen and (max-width: 814px){
    .commodityBody .nav-link {
        font-size: 15.5px !important;
    border-width: 0px 0px 2px !important;
    padding-bottom: 15px !important;
    }
}
@media screen and (max-width: 804px){
    .dailyCards .box {
        width: 29.6% !important;
    }
}

@media screen and (max-width: 773px) {
    #timeSelct {
        padding: 0.7rem 1.5rem !important;
        font-size: 13px!important;
        width: 26% !important;
    }
}
@media screen and (max-width: 734px){
    .dailyCards .box {
        width: 45.6% !important;
    }
    .commodityBody .tabs .content .ImageTextDiv .commodityImage {
        width: 42px;
        height: 42px;
    }
}
@media screen and (max-width: 627px){
    .dailyCards .box {
        width: 45% !important;
    }
}
@media screen and (max-width: 713px) {
    #timeSelct {
        padding: 0.7rem 1.5rem !important;
        font-size: 13px!important;
        width: 29% !important;
    }
}
@media screen and (max-width: 640px) {
    #timeSelct {
        padding: 0.7rem 1.5rem !important;
        font-size: 13px!important;
        width: 31% !important;
    }
}
@media screen and (max-width: 596px) {
    #timeSelct {
        padding: 0.7rem 1.5rem !important;
        font-size: 13px!important;
        width: 34% !important;
    }
}
@media screen and (max-width: 555px) {
    .dailyCards .box {
        width: 44.5% !important;
    }
}

@media screen and (max-width: 545px) {
    #timeSelct {
        padding: 0.7rem 1.5rem !important;
        font-size: 13px!important;
        width: 38% !important;
    }
}
@media screen and (max-width: 506px) {
    .dailyCards .box {
        width: 43% !important;
    }
}
@media screen and (max-width: 495px) {
    #timeSelct {
        padding: 0.7rem 1.5rem !important;
        font-size: 13px!important;
        width: 46% !important;
    }
    .commodityReaserachh .smallBox {
        width: 84%;
        flex-wrap: wrap;
    }
}
@media screen and (max-width: 462px){
    .commodityBody .nav-link {
        font-size: 15px !important;
        border-width: 0px 0px 2px !important;
        padding-bottom: 15px !important;
    }
    .commodityReaserachh .daily-seasonal-tab {
        margin-right: 19px !important;
    }
}
@media screen and (max-width: 451px){
    #timeSelct {
        padding: 0.7rem 1.5rem !important;
        font-size: 13px!important;
        width: 51% !important;
    }
}
@media screen and (max-width: 406px){
    #timeSelct {
        padding: 0.7rem 1.5rem !important;
        font-size: 13px!important;
        width: 59% !important;
    }
    .dailyCards .box {
        width: 94% !important;
    }
    .ImageTextDiv {
        padding-left: 9%;
    }
}
@media screen and (max-width: 418px){
.commodityBody .nav-link {
    font-size: 13px !important;
}
}
@media screen and (max-width: 388px){
    .commodityReaserachh .daily-seasonal-tab {
        margin-right: 13px !important;
    }
    .commodityBody .nav-link {
        padding-bottom: 14px !important;
    }
}
@media screen and (max-width: 358px){
.commodityBody .nav-link {
    font-size: 10px !important;
}
}
@media screen and (max-width: 352px){
    #timeSelct {
        padding: 0.7rem 1.5rem !important;
        font-size: 13px!important;
        width: 67% !important;
    }
}
@media screen and (max-width: 328px){
    .commodityBody .nav-link {
        font-size: 10px !important;
        border-width: 0px 0px 2px !important;
        padding-bottom: 15px !important;
    }
    .commodityReaserachh .daily-seasonal-tab {
        margin-right: 13px !important;
    }
}
@media screen and (max-width: 307px){
    #timeSelct {
        padding: 0.7rem 1.5rem !important;
        font-size: 13px!important;
        width: 76% !important;
    }
}
@media screen and (max-width: 299px){
    .commodityBody .nav-link {
        font-size: 7px !important;
        border-width: 0px 0px 2px !important;
        padding-bottom: 10px !important;
    }
}
@media screen and (max-width: 269px){
    #timeSelct {
        padding: 0.7rem 1.5rem !important;
        font-size: 13px!important;
        width: 87% !important;
    }
    .commodityReaserachh .smallBox .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 15px !important;
    }
}
@media screen and (max-width: 238px){
    #timeSelct {
        padding: 0.7rem 1.5rem !important;
        font-size: 13px!important;
        width: 94% !important;
    }
}

