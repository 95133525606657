.Emandi-news-cls{
    background-color: #fff;
    width: 100em;
    height: 27em;
    overflow: scroll;
    
    .Emandi-news-heading{
        margin-top: 0.5em;
        margin-left:1em ;
        font-size: 25px;
        font-weight: bold;
    }
	.filters-Time{
        display: flex;
        margin-left: 1.5em;
        .dropdown-content-section .p-dropdown{
            margin-top:1em;
            height: 2.8em;
            // border-radius: 3em;
            border: 1px solid #ada4a3;
            width: 11em;
            margin-right: 1em;
            .p-dropdown-panel .p-dropdown-item {
                font-weight: normal;
                background-color: #FFFFFF;
                cursor: pointer;
                font-family: 'Poppins', sans-serif;
                margin: 0 !important;
            }
        }
    }



    // .filters-Time{
    //     background: #fff;
    //     margin-left:1em ;
    //     border: 1px solid black;
    //     border-radius: 5px;
    //     margin-right:52em;
    //     height: 45px;
    //     width: 10em;
    //     // padding-left: 20px;
    // }
    .time1{
        width: 159px;
        background: white;
    }
    .p-accordion-tab{
        border: 2px solid lightseagreen;
        border-radius: 8px;
        margin: 0.5em;
            .p-accordion-header {
                background: gainsboro;
                color: black;
                border-radius: 4px;
                text-transform: capitalize;
                .p-accordion-header-text{
                    margin: 0 0.5em;
                }
                >a{
                    color: black;
                }
            }
            
            .accordian-content-cls{
                display: flex;
                align-items: center;
                >img{
                    width: 4em;
                    height: 4em;
                    margin: 0 0.5em;
                }
                >p::first-letter{
                        text-transform: capitalize;
                      
                }
            }
    }
    hr{
        width: 90%;
        float: left;
    }
    .product-item{
        // border-bottom: 2px solid #f2f2f2;
        width: 40em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // margin-left: 0.5em;
        margin-bottom: 1em;
        margin: 0.5em;
        >div{
            display: flex;
            >img{
                width: 100px;
                margin-right: 0.5em;
            }
        }
        .emandi-tittle{
            color:black;
            // margin: 1em 0;
            font-size: 14px;
            font-weight: bold;
            text-transform: capitalize;
            .readme{
                display: flex;
                border-radius: 25px;
                // margin-left: 45em;
                font-size: 12px;
                color: white;
                padding: 10px 10px 10px 15px;
            }
            .readme:hover{
                background-color: #0f9d58;
            }
        }
        .readme{
            cursor: pointer;
            border-radius: 8px;
            font-size: 14px;
            color: white;
            background-color: #0f9d58;
            padding: 10px 10px 10px 15px;
        }
        .emandi-date{
                color:black;
                margin: 0.5em 0;
                font-size: 12px;
        }
    }
    
}
.header-icon-cls {
    display: flex;
    margin: 0.5em 3em;
    justify-content: start;
    img {
        width: 2em;
        height: 2em;
        // border: 2px solid bisque;
        border-radius: 50%;
        padding: 4px;
        box-shadow: 1px 1px 6px;
    }
    p {
        margin: 0.5em;
        font-size: 16px;
    }

    .pi-angle-left {
        margin: 0.5em 0em;
        font-size: 16px;
        box-shadow: 0px 0px 20px #00000029;
        border-radius: 50%;
        height: 1.5em;
        width: 1.5em;
        text-align: center;
        line-height: 1.5em;
        cursor: pointer;
    }
}
.menu-cls-Emandhi {
    display: flex;
    margin: 2em;    
h2{
    margin: 0.1em 0 0.5em 0;
}
// justify-content: space-between;
.list-cls{
    width: 16em;
    p{
        align-items: center;
        display: flex;
        justify-content:space-between;
        color: white;
        margin: 0.1em 0;
        font-size: 17px;
        font-weight: bold;
        border-radius: 10px;
        padding-right: 30px;
        padding-left: 20px;
        width: 9em;
        >img{
            width: 1.5em;
            height: 3em;
        }
        
    }
}
.list-EMandi{
    background-color: #0f9d58;
    color: white;
    height: 3em;
}
// .filters-Time{
// border-radius: 5px solid red;
// }
}
.product-item{
    border-bottom: 2px solid gray;
    width: 60%;
    cursor: pointer;
    .top-cls{
        display: flex;
        padding: 0.5em 0;
        .youtube-symbol-cls{	
            margin: 0.5em 2em 0.5em 0;
            padding: 0.5em;
            margin-right: 2em;
            display: flex;
            align-items: center;
            border-radius: 8px;
            width: 11em;
            height: 5em;
            background: black;
            img{
                align-items: center;
                margin: 0 auto;
                display: none
            }
        }
        .youtube-symbol-cls:hover{
            img{
                display: block;
            }
        }
        
        .emandi-tittle{
            color:black;
            margin: 0.5em 0;
            font-size: 18px;
            font-weight: bold;
            text-transform: capitalize;
        }
        .emandi-date{
                color:black;
                margin: 0.5em 0;
                font-size: 14px;
        }
}
}
.showing-page{
    margin-left: 650px;
}
.direct-btn{
    display: flex;
    gap: 20px;
    margin-left: 600px;
}
.move-to-first , .move-to-last{
    cursor: pointer;
}
.pageNumbers{
    list-style: none;
    display: flex;
    height: 39px;
    // margin-left: 550px;
    // margin-bottom: 50px;
    position: absolute;
    // left: 79vh;
    top: 76vh;
    right: 310px;
}
.pageNumbers li{
    padding: 7px 12px;
    cursor: pointer;
    border-radius: 50%;
}
.pageNumbers li:first-child , .pageNumbers li:last-child{
    font-size: 20px;
}
.pageNumbers li.active-page{
    background-color: white;
	color: #0f9d58;
	font-weight: 800;
	font-size: 20px;
}
.pageNumbers li button{
    background-color: transparent;
    border: none;
    color: black;
    font-size: 1rem;
    cursor: pointer;
}
.pageNumbers li button:hover{
    background-color: rgb(167, 164, 164);
}

