
.contact-us .message-us .inputtext-content-section{
    margin-top: 0 !important;
}

.register-Landing-page{
    background-image: url("../../assets/images/Group84795.png");   
    height: 100%; 
    // border-radius: 15px;
    background-color: #707070;
    background-attachment: fixed; 
    background-size: 100% 100%;

 

    .terms-and-condition{
        text-decoration: underline;
        color: #fff;
        text-align: center;
        position: absolute;
        bottom: 1em;
        width: 100%;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        cursor: pointer;
    }
.Authentication_back{
    // background-color: white;
    // border-radius: 2px 2px 2px 2px;
    padding-right: 30px;
    color: white;
}
    .home-page-cls{
        padding: 0.5em 2em !important;
        display: flex !important;
        justify-content: space-around;
        .header-cls{
            background: #333333;
            // color: #dadada;
            height: 5em;
            text-align: center;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom: 2px solid #fff;
            strong{
                cursor: pointer;
            }
            .auth-cls-label{
                font-size: 22px;
                font-weight:bold;
                color:#FFFFFF;
                font-family: 'Poppins', sans-serif;

            }
            .header-label{
                font-size: 32px;
                color:#ffff;
                font-family: 'Poppins', sans-serif;
            }
            .member-type-cls{
                color: #9c9c9c;
                font-family: 'Poppins', sans-serif;
                font-size:16px;
            }
        }
        .passwordfield-cls {
            // padding-top: 1em;
            margin: auto;
            width: 80%;
            .forgot-label{
                color: #000099;
                font-weight: bold;
                font-size: 14px;
                text-decoration: underline;
                cursor: pointer;
            }
            p {
                margin : 5px 0px;
                font-size: 12px;
                // color: #565656;
                font-weight: bold;
                color: #2d3927;
            }
    
            .input-cls {
                .p-inputtext{
                    margin : 0 !important;
                    width: 350px !important;
                    height : 35px !important;
                    // border-radius: 5px;
                    border : none;
                    box-shadow : 0px 0px 2px 2px #0000001A; 
                }
                .inputtext-content-section .p-inputtext {
                    margin : 0 !important;
                    width: 350px;
                    height : 35px;
                    // border-radius: 5px;
                    border : none;
                    box-shadow : 0px 0px 2px 2px #0000001A;
                }
            }
        }
        .showauth-cls{
            background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            opacity: 0.6;
            margin-top: 1em;
            .radioButtons-cls {
                padding: 0px 0 12px 10px;
                p {
                    font-size: 12px;
                    color: #565656;
                    font-weight: bold;
                }
    
                .radio-btn-class {
                    display: flex;
                    justify-content: center;
                    padding-left: 10px;
                    .p-field-radiobutton {
                        margin-right: 1em;
                        font-size : 14px;
                        margin-top: -5px;
                        label{
                            font-weight: bold;
                            font-size: 12px;
                        }
                        .p-radiobutton {
                            border : 1px solid black;
                            border-radius: 50%;
                            width: 14px;
                            height : 14px;
                            margin-right: 0.7em;
                            .p-highlight {
                                background: black;
                                height: 12px;
                                width : 12px;
    
                                .p-radiobutton-icon {
                                    font-size : 0.5em;
                                    margin-top: -.5em;
                                    color : #fff;
                                }
                            }
                        }
                    }
                }
            }
            .first-div,.second-div,.third-div{
                align-items: center;
                text-align: center;
                // color: #515151;
            }
           
            .first-div{
                margin: .1em 0;
                font-weight: bold;
                padding: 10px;
                // padding-right: 60px;
                font-family: 'Poppins', sans-serif;
            }
            // .first-Authenticate{
            //     background-color: #000000;
            // }
        }
       
        .mandatory-fields-cls{
            .inputfield-cls {
                margin-top: 1em;
                .input-container {
                    display: -ms-flexbox; /* IE10 */
                    display: flex;
                    width: 100%;
                    margin-bottom: 15px;
                    img{
                        width: 1em;
                        height: 1em;
                    }
                    .code{
                        font-size: 13px;
                        background-color: #ffffff;
                        padding-top: 10px;
                        padding-right: 4px;
                    }
                    .p-inputtext{
                        margin : 0 !important;
                        width: 270px !important;
                        height : 35px !important;
                        // border-radius: 5px;
                        border : none;
                        padding-left: 0 !important;
                        // box-shadow : 0px 0px 2px 2px #0000001A; 
                    }
                  }
                  
                  .icon {
                    padding: 10px 0 0 0;
                    background: #ffffff;
                    color: white;
                    width: 2em;
                    text-align: center;
                  }
                  
                  .input-field {
                    width: 100%;
                    padding: 10px;
                    outline: none;
                  }
                p {
                    margin : 5px 0px;
                    font-size: 12px;
                    // color: #565656;
                    font-weight: bold;
                    font-family: 'Poppins', sans-serif;
                    span{
                        color: #FF4848;
                    }
                }
        
                .input-cls {
                    .p-inputtext{
                        margin : 0 !important;
                        width: 350px;
                        height : 35px !important;
                        // border-radius: 5px;
                        border : none;
                        // box-shadow : 0px 0px 2px 2px #0000001A; 
                    }
                    // .inputtext-content-section .p-inputtext {
                    //     margin : 0 !important;
                    //     width: 350px;
                    //     height : 35px;
                    //     // border-radius: 5px;
                    //     border : none;
                    //     box-shadow : 0px 0px 2px 2px #0000001A;
                    // }
                }
            }
        }
        
        .contact-us-scrollbar {
            >div {
                display: flex !important;
                ;
                justify-content: space-around !important;
                ;
            }
        }
        .container-cls {
            // width:38%;
            width: 26em;
           background-color: #B8DB91;
            align-items: center;
            border-radius: 8px;
            border: 2px  solid #eef7e7;
            .fields-cls{
            margin: auto;
            margin-top: 16px;
            width: 80%;
            }
            .button-content-section {
                display: flex;
                justify-content: center;
                 margin: 0.5em 0.5em;
    
                .p-button {
                    margin-bottom: -5px;
                    margin-top: 1px;
                    color : #ffffff;
                    background: #000000;
                    border: 1px solid black;
                    padding : 1px 4px;
                    border-radius : 5px;
                    letter-spacing: 1px;
                    font-size : 16px;
                    font-weight: bold;
                    box-shadow: 0px 0px 3px 2px #0000001A;
    
                }
            }
        }
        .verifyOTP-cls {
            .verify-text {
                font-size: 12px;
                color: #00000099;
                font-family: 'Poppins', sans-serif;
                font-weight: bold;
                margin-bottom: 5px;                
                text-align: center;
            }
    
            .otp-cls {
                #sendOTP {
                    font-size: 12px;
                    color: #00000099;
                    font-family: 'Poppins', sans-serif;
                    font-weight: bold;
                    margin-bottom: 5px;
                    text-align: center;
                    span {
                        font-size: 12px;
                        color: #E43137;
                        font-family: 'Poppins', sans-serif;
                        letter-spacing : 1px;
                        text-decoration: underline;
                        cursor : pointer;
                        margin-left: 10px;
                    }
                }
    
                #resendOTP {
                    font-size: 12px;
                    color: #0000004C;
                    font-family: 'Poppins', sans-serif;
                    font-weight: bold;
                    margin-top : 0px;
                    text-align: center;
                    #resend {
                        text-decoration: underline;
                        // color: #E43137;
                        font-size: 14px;
                        cursor: pointer !important;
                        color: #000099;
                        font-family: 'Poppins', sans-serif;
                    }
                    .otp-time-cls{
                        text-decoration: underline;
                        // color: #E43137;
                        font-size: 14px;
                        color: #000099;
                        font-family: 'Poppins', sans-serif;
                        
                    }
    
                }
    
                .inputOTP-content-section {
                    justify-content: center;
                    .p-inputtext {
                        background : #ffffff 0% 0% no-repeat padding-box;
                        border : 1px solid #d8d8d8;
                        border-radius: 7px;
                        height : 35px;
                        width: 2.5em;
                        box-shadow : 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
                        text-align : center;
                    }
                }
            }
    
        }
    }
    .register-container-cls {
        padding: 0.5em 2em !important;;
        display: flex !important;
        justify-content: space-around;
        .header-cls{
            background: #333333;  
            color: #dadada;
            height: 5em;
            text-align: center;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom: 2px solid #fff;
            .header-label{
                font-size: 32px;
                color:#ffff;
                font-family: 'Poppins', sans-serif;
            }
            .member-type-cls{
                color: #9c9c9c;
                font-family: 'Poppins', sans-serif;
                font-size:16px;
            }
    
        }
        .names-div{
            display: flex;
            margin-top: 1em;
            .inputfield-cls {
                // margin-top: 2em;
                margin-right: 1em;
                p {
                    margin : 5px 0px;
                    font-size: 12px;
                    // color: #565656;
                    font-weight: bold;
                    font-family: 'Poppins', sans-serif;
                    span{
                        color: #FF4848;
                    }
                }
        
                .input-cls {
                    .inputtext-content-section .p-inputtext {
                        margin : 0;
                        width: 140px;
                        height : 35px;
                        // border-radius: 5px;
                        border : none;
                        box-shadow : 0px 0px 2px 2px #0000001A;
                    }
                }
                
               
            }
        }
        .mandatory-fields-cls{
            .inputfield-cls {
                margin-top: 0.5em;
                .input-container {
                    display: -ms-flexbox; /* IE10 */
                    display: flex;
                    width: 100%;
                    margin-bottom: 15px;
                    img{
                        width: 1em;
                        height: 1em;
                    }
                    .code{
                        font-size: 13px;
                        background-color: #ffffff;
                        padding-top: 10px;
                        padding-right: 4px;
                    }
                    .p-inputtext{
                        margin : 0 !important;
                        width: 287px !important;
                        height : 35px !important;
                        // border-radius: 5px;
                        border : none;
                        padding-left: 0 !important;

                        // box-shadow : 0px 0px 2px 2px #0000001A; 
                    }
                  }
                  
                  .icon {
                    padding: 10px 0 0 0;
                    background: #ffffff;
                    color: white;
                    width: 2em;
                    text-align: center;
                  }
                  
                  .input-field {
                    // width: 100%;
                    padding: 10px;
                    outline: none;
                  }
                p {
                    margin : 5px 0px;
                    font-size: 12px;
                    // color: #565656;
                    font-weight: bold;
                    font-family: 'Poppins', sans-serif;
                    span{
                        color: #FF4848;
                    }
                }
        
                .input-cls {
                    .p-inputtext{
                        margin : 0 !important;
                        width: 100%;
                        height : 35px !important;
                        // border-radius: 5px;
                        border : none;
                        // box-shadow : 0px 0px 2px 2px #0000001A; 
                    }
                    // .inputtext-content-section .p-inputtext {
                    //     margin : 0 !important;
                    //     width: 350px;
                    //     height : 35px;
                    //     // border-radius: 5px;
                    //     border : none;
                    //     // box-shadow : 0px 0px 2px 2px #0000001A;
                    // }
                }
            }
        }
        
        .contact-us-scrollbar {
            >div {
                display: flex !important;
                ;
                justify-content: space-around !important;
                ;
            }
        }
        .container-cls {
            // width: 50%;
            // width: 23em;
            background: #aedc94;
            align-items: center;
            width: 45%;
            height: fit-content;
            border-radius: 8px;
            margin-bottom: 1em;
            border: 2px  solid #eef7e7;
            .fields-cls{
                display: grid;
                justify-content: center;
                .terms-conditions-cls{
                    display: flex;
                    .checkboxComponentCls{
                        display: inline;
                    }
                    .label{
                        margin: 5px 4px;
                        font-size: 12px;
                        font-weight: bold;
                        font-family: 'Poppins', sans-serif;
                        >span{
                            // text-decoration: underline;
                            // cursor: pointer;
                        }
                    }
                }
            }
            .button-content-section {
                display: flex;
                justify-content: center;
                margin-top : 1em;
                margin-bottom : 1em;
    
                .p-button {
                    color : #ffffff;
                    background: #000000;
                    border: 1px solid black;
                    padding : 3px 5px;
                    border-radius : 5px;
                    letter-spacing: 1px;
                    font-size : 16px;
                    font-weight: bold;
                    box-shadow: 0px 0px 3px 2px #0000001A;
    
                }
            }
        }
        .verifyOTP-cls {
            margin-left: 40px;
            margin-top : 32px;
    
            .otp-cls {
                #sendOTP {
                    font-size: 12px;
                    color: #00000099;
                    font-family: 'Poppins', sans-serif;
                    font-weight: bold;
                    margin-bottom: 1px;
    
                    span {
                        font-size: 12px;
                        color: #E43137;
                        font-family: 'Poppins', sans-serif;
                        letter-spacing : 1px;
                        text-decoration: underline;
                        cursor : pointer;
                        margin-left: 10px;
                    }
                }
    
                #resendOTP {
                    font-size: 12px;
                    color: #0000004C;
                    font-family: 'Poppins', sans-serif;
                    font-weight: bold;
                    margin-top : 0px;
    
                    #resend {
                        text-decoration: underline;
                        // color: #E43137;
                        cursor : pointer !important;
                        font-size: 14px;
                        color: #000099;
                        font-family: 'Poppins', sans-serif;
                    }
                    .otp-time-cls{
                        text-decoration: underline;
                        // color: #E43137;
                        font-size: 14px;
                        color: #000099;
                        font-family: 'Poppins', sans-serif;
                    }
    
                }
    
                .inputOTP-content-section {
                    margin: -5px;
    
                    .p-inputtext {
                        background : #ffffff 0% 0% no-repeat padding-box;
                        border : 1px solid #d8d8d8;
                        border-radius: 7px;
                        height : 35px;
                        width: 2.5em;
                        box-shadow : 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
                        text-align : center;
                    }
                }
            }
    
        }
    
    }
    .otp-page-cls{
        
            padding: 2em !important;;
            display: flex !important;
            justify-content: space-around;
            .header-cls{
                background: #333333;
                // color: #dadada;
                height: 5em;
                text-align: center;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            border-bottom: 2px solid white;
                .header-label{
                    font-size: 32px;
                    color:#ffff;
                    font-family: 'Poppins', sans-serif;
                }
                .member-type-cls{
                    color: #9c9c9c;
                    font-family: 'Poppins', sans-serif;
                    font-size:16px;
                }
        
            }
            .fields-cls{
                display: flex;
                justify-content: center;
            }
            .contact-us-scrollbar {
                >div {
                    display: flex !important;
                     justify-content: space-around !important;
                }
            }
            .container-cls {
                // width: 35%;
            width: 23em;
                height: 50vh;
                background: #aedc94;
                align-items: center;
                // width: 70%;
                border-radius: 8px;
            border: 2px  solid #eef7e7;
                .button-content-section {
                    display: flex;
                    justify-content: center;
                    margin-top : 1em;
        
                    .p-button {
                        color : #ffffff;
                        background: #000000;
                        border: 1px solid black;
                        padding : 3px 5px;
                        border-radius : 5px;
                        letter-spacing: 1px;
                        font-size : 16px;
                        font-weight: bold;
                        box-shadow: 0px 0px 3px 2px #0000001A;
        
                    }
                }
            }
            .verifyOTP-cls {
                // margin-left: 40px;
                margin-top : 32px;
        
                .otp-cls {
                    #sendOTP {
                        font-size: 12px;
                        color: #00000099;
                        font-family: 'Poppins', sans-serif;
                        font-weight: bold;
                        margin-bottom: 5px;
        
                        span {
                            font-size: 12px;
                            color: #E43137;
                            font-family: 'Poppins', sans-serif;
                            letter-spacing : 1px;
                            text-decoration: underline;
                            cursor : pointer;
                            margin-left: 10px;
                        }
                    }
        
                    #resendOTP {
                        font-size: 12px;
                        color: #0000004C;
                        font-family: 'Poppins', sans-serif;
                        font-weight: bold;
                        // margin-top : 8px;
                        margin-left: 12px;
                        #resend {
                            text-decoration: underline;
                            // color: #E43137;
                            cursor : pointer !important;
                            font-size: 14px;
                            color: #000099;
                            font-family: 'Poppins', sans-serif;
                        }
                        .otp-time-cls{
                            text-decoration: underline;
                            // color: #E43137;
                            font-size: 14px;
                            font-family: 'Poppins', sans-serif;
                            color: #000099;
                        }
        
                    }
                }
        
            }
        
        
    }
    .verified-page-cls{
        
        padding: 2em !important;;
        display: flex !important;
        justify-content: space-around;
        .header-cls{
            background: #333333;
            // color: #dadada;
            height: 5em;
            text-align: center;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            border-bottom: 2px solid white;

            strong{
                cursor: pointer;
            }
            .header-label{
                font-size: 32px;
                color:#ffff;
                font-family: 'Poppins', sans-serif;
            }
            .member-type-cls{
                color: #9c9c9c;
                font-family: 'Poppins', sans-serif;
                font-size:16px;
            }
        }
        .fields-cls{
            // display: flex;
            // justify-content: center;
            
            margin: auto;
            width: 60%;
            // padding: 10px 0px;
            .verified-page{
                img{
                    width: 40px;
                    height: 40px;
                    // margin-top: 1em;
                    // margin-left: 6em;
                    
                }
                p{
                    text-align: center;
                    font-size: 20px;
                    color: #363735;
                }
            }
        }
        .container-cls {
            // width: 30%;
            width: 23em;
            height: fit-content;
            background: #aedc94;
            align-items: center;
            // width: 70%;
            border-radius: 8px;
            border: 2px  solid #eef7e7;
    
            .button-content-section {
                display: flex;
                justify-content: center;
                margin-top : 1em;
    
                .p-button {
                    color : #ffffff;
                    background: #000000;
                    border: 1px solid black;
                    padding : 3px 5px;
                    border-radius : 5px;
                    letter-spacing: 1px;
                    font-size : 16px;
                    font-weight: bold;
                    box-shadow: 0px 0px 3px 2px #0000001A;
    
                }
            }
        }
    
    }
}

