@font-face {
  font-family: MetropolisMedium;
  src: url('Metropolis-Medium.otf')
}

@font-face {
  font-family: MetropolisBold;
  src: url('Metropolis-Bold.otf')
}

@font-face {
  font-family: MetropolisRegular;
  src: url('Metropolis-Regular.otf')
} 

@font-face {
  font-family: MetropolisLight;
  src: url('Metropolis-Light.otf')
} 

.video-slider .carousel .control-next.control-arrow::before {
  font-size: 30px;
}
.lblViews{
  font-family: MetropolisLight !important;
  color:#757575 !important;
  font-size: 9pt !important;
}
.nav-link{
  font-family: MetropolisMedium;
}

.small-box {
    width: 5px;
    height: 30px;
    background-color: #FDE96C;
    box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
}

.yellow {
    /* border-top: 3px solid #FDE96C; */
}

.slider-css {
  font-family: MetropolisRegular;
}

.slider-css-1 {
  margin-bottom: 20px;
}

.boxText {
    color: #757575 !important;
}

.smallBox {
    margin-left: 7%;
    margin-right: auto;
    display: flex;
    flex-direction: row;
}
.emandiTV{
  font-family: MetropolisMedium;
}
.p-css {
  /* padding-top: 7px; */
  color: 'black' !important;
  font-family: MetropolisRegular !important;
  font-weight: 'bold';
}
.emandiTV .card{
    border: none;
    width:25%
}
.emandiTV .card .views{
    color: gray;
}
select:checked {
    color:gray;
  }


.video-slider {
    position: relative;
    /* width: 1170px; */
    margin: 0 auto;
    max-width: 100%;
    overflow: hidden;
  }
  .video-slider .carousel-slider {
    width: 33% !important
  }
  .video-slider .carousel-slider {
    overflow: visible !important;
  }
  .video-slider .carousel-slider .control-next {
    right: -140px !important;
    height: 40px;
    width:40px;
    border-radius: 100%;
    /* background-color: #757575 !important; */
    background-color: #F5F5F5 !important;
    margin-top: 250px !important;
    position: absolute;
    
  }

  .video-slider .carousel .control-prev.control-arrow {
    right: -70px !important;
    left: auto !important;
    height: 40px;
    width:40px;
    border-radius: 100%;
    /* background-color:#757575 !important; */
    background-color: #F5F5F5 !important;
    margin-top: 250px !important;
    position: absolute;
  }
  .video-slider .carousel .thumbs-wrapper {
    margin: 0 !important;
    padding-left: 72px;
  }
  .video-slider .carousel .thumbs-wrapper .thumbs {
    margin: 0 !important;
  }
  .video-slider .carousel .thumbs-wrapper .thumbs .thumb {
    width: 160px !important
  }
  .video-slider .carousel .control-prev.control-arrow:before {
        position: absolute;
        top: 14px;
        left:10px;
        border-right-color: #000000;
  }


  .video-slider .carousel .control-next.control-arrow:before {
    position: absolute;
    top:14px !important;
    left:14px;
    border-left-color: #000000;
  }
  .video-slider .carousel-root {
    position: relative;
  }
  .video-slider .carousel-root .carousel + .carousel {
    width: 50% !important;
    position: absolute;
    right: 0;
    bottom: -15px;
    top: 232px;
    height: 135px;
  }

  

.slider_box img{
    border-radius:10px;
}
.thumb img{
    border-radius: 10px;
}
.carousel .thumb.selected, .carousel .thumb:hover, .carousel .thumb:focus, .carousel .thumb:active{
    border: none !important;
}
.video-slider .carousel .slider-wrapper {
    overflow: visible !important;
   }
   .video-slider .slider .slide {
     visibility: hidden;
   }
   .video-slider .slider .slide.selected {
     visibility: visible !important;
   }
   .video-slider .carousel .slide .legend {
    padding-top: 0px !important;
     left: 110% !important;
     margin-left:0 !important;
     bottom: 140px !important;
     background-color: transparent !important;
     color:#000 !important;
     opacity: 1 !important;
     font-size:16pt !important;
     font-weight: 600;
     text-align: left !important;
     vertical-align: top !important;
   }
   .carousel-status{
    display: none;
   }
   .carousel .control-dots{
    display: none;
   }
   .headerEmandiTV{
    display: flex;
    align-items: center;
   }
   .video-slider .carousel .slide .legend {
    overflow: hidden;
   }
   .tVTitle{
      display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
   }
   .carousel .control-arrow, .carousel.carousel-slider .control-arrow{
    z-index: 1 !important;
   }
   @media only screen and (max-width: 7680px) and (min-width: 5747px){
    iframe{
      width: 109rem !important;
        height: 55rem !important;
        border-radius: 37px !important;
    }
    .emandiTV .mt {
      width: 87%;
      margin-left: 0%;
    }
    .video-slider .carousel .slide .legend{
      left: 103% !important;
      font-size: 36pt !important;
      top: 2rem;
    }
    .video-slider .carousel .thumbs-wrapper .thumbs .thumb {
      width: 428px !important;
  }
  .thumbs  {
    padding-left: 43rem !important;
}
.video-slider .carousel-root .carousel + .carousel {
  top: 403px;
}
.lblViews {
  font-size: 21pt !important;
}
.carousel.carousel-slider .control-arrow {
  padding: 65px !important;
}
.video-slider .carousel-slider .control-next {
  right: -523px !important;
  margin-top: 451px !important;
}
.video-slider .carousel .control-prev.control-arrow {
  right: -203px !important;
  margin-top: 458px !important;
}
.video-slider .carousel-slider .control-next {
  right: -455px !important;
}
.video-slider .carousel .control-next.control-arrow:before{
  position: absolute;
  top: 60px !important;
  left: 59px;
  transform: scale(3.3);
}
.video-slider .carousel .control-prev.control-arrow:before{
  position: absolute;
  top: 60px !important;
  left: 55px;
  transform: scale(3.3);
}
   }
   @media only screen and (max-width: 5746px) and (min-width: 5465px){
    iframe{
      width: 109rem !important;
        height: 55rem !important;
        border-radius: 37px !important;
    }
    .emandiTV .mt {
      width: 132%;
    margin-left: -5%;
    }
    .video-slider .carousel .slide .legend{
      left: 106% !important;
      font-size: 36pt !important;
      top: 2rem;
    }
    .video-slider .carousel .thumbs-wrapper .thumbs .thumb {
      width: 428px !important;
  }
  .thumbs  {
    padding-left: 43rem !important;
}
.video-slider .carousel-root .carousel + .carousel {
  top: 403px;
}
.lblViews {
  font-size: 21pt !important;
}
.carousel.carousel-slider .control-arrow {
  padding: 65px !important;
}
.video-slider .carousel-slider .control-next {
  right: -523px !important;
  margin-top: 451px !important;
}
.video-slider .carousel .control-prev.control-arrow {
  right: -203px !important;
  margin-top: 458px !important;
}
.video-slider .carousel-slider .control-next {
  right: -455px !important;
}
.video-slider .carousel .control-next.control-arrow:before{
  position: absolute;
  top: 60px !important;
  left: 59px;
  transform: scale(3.3);
}
.video-slider .carousel .control-prev.control-arrow:before{
  position: absolute;
  top: 60px !important;
  left: 55px;
  transform: scale(3.3);
}
   }
   @media only screen and (max-width: 5464px) and (min-width: 4099px){
    iframe{
      width: 109rem !important;
        height: 55rem !important;
        border-radius: 37px !important;
    }
    .emandiTV .mt {
      width: 124%;
      margin-left: -3%;
    }
    .video-slider .carousel .slide .legend{
      left: 106% !important;
      font-size: 36pt !important;
      top: 2rem;
    }
    .video-slider .carousel .thumbs-wrapper .thumbs .thumb {
      width: 428px !important;
  }
  .thumbs  {
    padding-left: 43rem !important;
}
.video-slider .carousel-root .carousel + .carousel {
  top: 403px;
}
.lblViews {
  font-size: 21pt !important;
}
.carousel.carousel-slider .control-arrow {
  padding: 65px !important;
}
.video-slider .carousel-slider .control-next {
  right: -523px !important;
  margin-top: 451px !important;
}
.video-slider .carousel .control-prev.control-arrow {
  right: -203px !important;
  margin-top: 458px !important;
}
.video-slider .carousel-slider .control-next {
  right: -455px !important;
}
.video-slider .carousel .control-next.control-arrow:before{
  position: absolute;
  top: 60px !important;
  left: 59px;
  transform: scale(3.3);
}
.video-slider .carousel .control-prev.control-arrow:before{
  position: absolute;
  top: 60px !important;
  left: 55px;
  transform: scale(3.3);
}
   }
   @media only screen and (max-width: 4098px) and (min-width: 3842px){
    iframe{
      width: 109rem !important;
        height: 55rem !important;
        border-radius: 37px !important;
    }
    .emandiTV .mt {
      width: 93%;
    margin-left: 5%;
    }
    .video-slider .carousel .slide .legend{
      left: 106% !important;
      font-size: 36pt !important;
      top: 2rem;
    }
    .video-slider .carousel .thumbs-wrapper .thumbs .thumb {
      width: 428px !important;
  }
  .thumbs  {
    padding-left: 34rem !important;
}
.video-slider .carousel-root .carousel + .carousel {
  top: 403px;
}
.lblViews {
  font-size: 21pt !important;
}
.carousel.carousel-slider .control-arrow {
  padding: 65px !important;
}
.video-slider .carousel-slider .control-next {
  right: -523px !important;
  margin-top: 451px !important;
}
.video-slider .carousel .control-prev.control-arrow {
  right: -203px !important;
  margin-top: 458px !important;
}
.video-slider .carousel-slider .control-next {
  right: -455px !important;
}
.video-slider .carousel .control-next.control-arrow:before{
  position: absolute;
  top: 60px !important;
  left: 59px;
  transform: scale(3.3);
}
.video-slider .carousel .control-prev.control-arrow:before{
  position: absolute;
  top: 60px !important;
  left: 55px;
  transform: scale(3.3);
}
   }
   @media only screen and (max-width: 3841px) and (min-width: 2561px){
    .emandiTVV {
      margin-bottom: 21rem;
    }
  .smallBox {
      margin-left: 8%;
      margin-right: auto;
      display: flex;
      flex-direction: row;
  }
  .emandiTV .small-box {
    width: 17px;
    height: 98px;
}
.small-box {
  border-radius: 0px 54px 41px 0px !important;
}
    .emandiTV .mx-3{
      font-size: 65px !important;
      margin-left: 5rem!important;
    }
    .emandiTV .mx-1{
      font-size: 51px !important;
      margin-left: 8.25rem!important;
    }
    .emandiTV .mt{
      width: 97%;
    margin-left: 6%;
    }
    .video-slider {
      position: relative;
      width: 100%; 
    }
    iframe{
      width: 107rem !important;
      height: 61rem !important;
      border-radius: 40px !important;
    }
    .video-slider .carousel .slide .legend {
      font-size: 35pt !important;
      top: 47px;
      left: 104% !important;
    }
    .carousel.carousel-slider .control-arrow {
      top: 294px !important;
      padding: 57px !important;
      left: 150rem;
    }
    .video-slider .carousel .control-next.control-arrow:before {
      position: absolute;
      top: 52px !important;
      left: 56px !important;
      transform: scale(2.2);
  }
    .video-slider .carousel .control-prev.control-arrow {
      right: -173px !important;
      top: 287px !important;
      padding: 60px !important;
    }
    .video-slider .carousel .control-prev.control-arrow:before {
      position: absolute;
      top: 52px;
      left: 45px;
      transform: scale(2.3);
    }
    .lblViews {
      font-size: 27pt !important;
    }
    .video-slider .carousel-root .carousel + .carousel {  
      top: 40rem;
    }
    .video-slider .carousel .thumbs-wrapper .thumbs .thumb {
      width: 463px !important;
    }
  }
  @media only screen and (max-width: 3491px) and (min-width: 2733px){
    .video-slider .carousel .slide .legend {
      left: 114% !important;
  }
  .video-slider .carousel .control-prev.control-arrow {
    right: -272px !important;
  }
  }
  @media only screen and (max-width: 3200px) and (min-width: 3073px){
    .video-slider .carousel .slide .legend {
      left: 124% !important;
  }
  .video-slider .carousel .control-prev.control-arrow {
    right: -366px !important;
}
.video-slider .carousel .thumbs-wrapper .thumbs .thumb {
  width: 417px !important;
}
.video-slider .carousel .thumbs-wrapper .thumbs .thumb {
  width: 362px !important;
}
.video-slider .carousel .thumbs-wrapper {
  margin: 0 !important;
  padding-left: 285px;
}
.video-slider .carousel-root .carousel + .carousel {
  top: 48rem;
}
  }
  @media only screen and (max-width: 3072px) and (min-width: 2733px){
    .video-slider .carousel .slide .legend {
      left: 126% !important;
  }
  .carousel.carousel-slider .control-arrow {
    top: 294px !important;
    padding: 55px !important;
    left: 135rem;
}
.video-slider .carousel .control-prev.control-arrow {
  right: -380px !important;
  padding: 56px !important;
}
.video-slider .carousel .thumbs-wrapper .thumbs .thumb {
  width: 409px !important;
}
  }
  @media only screen and (max-width: 2732px) and (min-width: 2561px){
    .emandiTV .mt {
      width: 95%;
      margin-left: 7%;
  }
.emandiTVV {
  margin-top: 6rem !important;
  margin-bottom: 6rem;
}
iframe {
  width: 89rem !important;
  height: 40rem !important;
    border-radius: 24px !important;
}
.video-slider .carousel .slide .legend {
  font-size: 26pt !important;
  top: 39px;
  left: 122% !important;
}
.carousel.carousel-slider .control-arrow {
  top: 179px !important;
  padding: 45px !important;
  left: 116rem;
}
.video-slider .carousel .control-prev.control-arrow {
  right: -291px !important;
  top: 182px !important;
  padding: 45px !important;
}
.video-slider .carousel .control-prev.control-arrow:before {
  position: absolute;
  top: 39px;
  left: 35px;
  transform: scale(1.8);
}
.video-slider .carousel .control-next.control-arrow:before {
  position: absolute;
  top: 38px !important;
  left: 43px !important;
  transform: scale(1.8);
}
.video-slider .carousel .thumbs-wrapper .thumbs .thumb {
  width: 320px !important;
}
.video-slider .carousel-root .carousel + .carousel {
  top: 34rem;
}

  }
   @media only screen and (max-width: 2560px) and (min-width: 1921px){
    .emandiTV .small-box {
        width: 12px;
        height: 66px;
      }
      .small-box {
        border-radius: 0px 25px 25px 0px !important;
      }
      .emandiTV .mx-3 {
        font-size: 51px !important;
        margin-left: 3rem!important;
      }
      .emandiTV .mx-1 {
        font-size: 38px !important;
        margin-left: 3.25rem!important;
      }
      .emandiTVV {
        margin-bottom: 7rem;
      }
      .emandiTV .mt{
        width: 89%;
        margin-left: 7%;
      }
      .video-slider {
        position: relative;
        width: 100%; 
      }
      iframe {
        width: 74rem !important;
        height: 40rem !important;
        border-radius: 24px !important;
      }
      .video-slider .carousel .slide .legend {
        font-size: 23pt !important;
        top: 40px;
        margin-left: -2rem !important;
      }
      .lblViews {
        font-size: 17pt !important;
    }
      .video-slider .carousel .thumbs-wrapper .thumbs .thumb {
        width: 321px !important;
      }
      .carousel.carousel-slider .control-arrow {
        top: 104px !important;
        padding: 40px !important;
        left: 99rem;
      }
      .video-slider .carousel .control-next.control-arrow:before {
        position: absolute;
        top: 36px !important;
        left: 35px !important;
        transform: scale(1.5);
      } 
      .video-slider .carousel .control-prev.control-arrow {
        right: -149px !important;
        top: 107px !important;
        padding: 40px !important;
      }
      .video-slider .carousel .control-prev.control-arrow:before {
        position: absolute;
        top: 34px;
        left: 29px;
        transform: scale(1.5);
      }
      .video-slider .carousel-root .carousel + .carousel {
        top: 30rem;
      }
      .emandiTVV {
        width: 86% !important;
      }
   }
   @media only screen and (max-width: 2400px) and (min-width: 2331px){
    .video-slider .carousel .thumbs-wrapper .thumbs .thumb {
      width: 301px !important;
    }
    .video-slider .carousel .slide .legend {
      font-size: 23pt !important;
      top: 40px;
      margin-left: 3rem !important;
  }
  .video-slider .carousel .control-prev.control-arrow {
    right: -202px !important;
  }
   }
   @media only screen and (max-width: 2330px) and (min-width: 2301px){
    .video-slider .carousel .slide .legend {
      font-size: 23pt !important;
      top: 40px;
      margin-left: 8rem !important;
  }
  .video-slider .carousel .control-prev.control-arrow {
    right: -243px !important;
    top: 107px !important;
    padding: 40px !important;
}
  }
   @media only screen and (max-width: 2300px) and (min-width: 2049px){
    .emandiTV .small-box {
      width: 10px;
      height: 70px;
  }
  .headerEmandiTV .mx-3 {
    margin-right: 2rem !important;
    margin-left: 3rem !important;
    font-size: 39px !important;
}
.headerEmandiTV .mx-1 {
  font-size: 27px !important;
}
.viewAll {
  font-size: 31px;
}
iframe {
  width: 66rem !important;
  height: 36rem !important;
  border-radius: 24px !important;
}
.video-slider .carousel .slide .legend {
  font-size: 20pt !important;
  top: 40px;
  margin-left: 8rem !important;
}
.carousel.carousel-slider .control-arrow {
  top: 93px !important;
  padding: 33px !important;
  left: 86rem;
}
.video-slider .carousel .control-prev.control-arrow {
  right: -215px !important;
  top: 93px !important;
  padding: 33px !important;
}
.video-slider .carousel .control-next.control-arrow:before {
  position: absolute;
  top: 28px !important;
  left: 28px !important;
  transform: scale(1.3);
}
.video-slider .carousel .control-prev.control-arrow:before {
  position: absolute;
  top: 28px;
  left: 22px;
  transform: scale(1.3);
}
.video-slider .carousel .thumbs-wrapper .thumbs .thumb {
  width: 273px !important;
}
   }
   @media only screen and (max-width: 2276px) and (min-width: 2049px){
    .video-slider .carousel .slide .legend {
      font-size: 20pt !important;
      top: 40px;
      margin-left: 3rem !important;
  }
  .video-slider .carousel .control-prev.control-arrow {
    right: -184px !important;
    top: 93px !important;
    padding: 33px !important;
}
.carousel.carousel-slider .control-arrow {
  top: 93px !important;
  padding: 33px !important;
  left: 90rem;
}
.video-slider .carousel .thumbs-wrapper .thumbs .thumb {
  width: 295px !important;
}
.video-slider .carousel-root .carousel + .carousel {
  top: 28rem;
}
   }
   @media only screen and (max-width: 2048px) and (min-width: 1601px){
    .emandiTVV {
      width: 85% !important;
  }
    .video-slider .carousel .control-prev.control-arrow:before {
      position: absolute;
      top: 30px;
      left: 25px;
      transform: scale(1.5);
  }
  .video-slider .carousel .control-next.control-arrow:before {
    position: absolute;
    top: 31px !important;
    left: 31px !important;
    transform: scale(1.5);
}
    .emandiTV .mt{
      width: 88%;
      margin-left: 7%;
    }
    iframe {
    width: 66rem !important;
    height: 38rem !important;
    }
    .video-slider {
      position: relative;
      width: 100%; 
    }
    .video-slider .carousel .slide .legend {
      font-size: 23pt !important;
      top: 40px;
      margin-left: 7rem !important;
  }
    .video-slider .carousel .thumbs-wrapper .thumbs .thumb {
      width: 254px !important;
    }
    .video-slider .carousel-root .carousel + .carousel {
      top: 32rem;
    }
    .carousel.carousel-slider .control-arrow {
      left: 88rem;
    top: 105px !important;
    padding: 37px !important;
    }
    .video-slider .carousel .control-prev.control-arrow {
      right: -217px !important;
      top: 102px !important;
      padding: 37px !important;
    }
   }
   @media only screen and (max-width: 1920px) and (min-width: 1441px){
    .video-slider .carousel .thumbs-wrapper .thumbs .thumb {
      width: 231px !important;
  }
  .video-slider .carousel .thumbs-wrapper {
    margin: 0 !important;
    padding-left: 97px;
}
    .emandiTV .small-box {
      width: 10px;
      height: 47px;
    }
    .small-box {
      border-radius: 0px 39px 37px 0px !important;
    }
    iframe {
      width: 56rem !important;
      height: 31rem !important;
  }
  .video-slider .carousel-root .carousel + .carousel {
    top: 24rem;
}
.video-slider .carousel .control-prev.control-arrow {
  top: 46px !important;
}
    .emandiTV .mx-3 {
      font-size: 40px !important;
      margin-left: 2rem!important;
    }
    .emandiTV .mx-1 {
      font-size: 28px !important;
      margin-left: 1.25rem!important;
    }
    .viewAll {
      font-size: 26px !important;
    }
    .emandiTVV {
      margin-bottom: 7rem;
    }
    .emandiTV .mt {
      width: 87%;
      margin-left: 6%;
    }
    .video-slider .carousel .slide .legend {
      left: 117% !important;
      top: 35px;
      font-size: 19pt !important;
      margin-left: 0px !important;
    }
    .lblViews {
      font-family: MetropolisLight !important;
      color: #757575 !important;
      font-size: 12pt !important;
    }
    .carousel.carousel-slider .control-arrow {
      top: 49px !important;
      left: 75rem;
      padding: 28px !important;
    }
    .video-slider .carousel .control-next.control-arrow:before {
      position: absolute;
      top: 20px !important;
      left: 20px !important;
    }
    .video-slider .carousel .control-prev.control-arrow {
      right: -161px !important;
      padding: 28px !important;
    }
    .video-slider .carousel .control-prev.control-arrow:before {
      position: absolute;
      top: 20px;
      left: 18px;
    }
   }
    @media only screen and (max-width: 1822px) and (min-width:1709px){
    .products .container-css {
      margin-left: 5%;
      margin-bottom: 9rem;
      width: 94%;
  }
  .emandiTV .small-box {
    width: 10px;
    height: 48px;
}
.headerEmandiTV .mx-3 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
  font-size: 32px !important;
}
.headerEmandiTV .mx-1 {
  font-size: 21px !important;
}
iframe {
  width: 50rem !important;
  height: 27rem !important;
}
.video-slider .carousel .slide .legend {
  left: 112% !important;
  top: 35px;
  font-size: 16pt !important;
  margin-left: 0px !important;
}
.video-slider .carousel .thumbs-wrapper .thumbs .thumb {
  width: 218px !important;
}
.video-slider .carousel-root .carousel + .carousel {
  top: 22rem;
}
.carousel.carousel-slider .control-arrow {
  top: 5px !important;
  left: 70rem;
  padding: 27px !important;
}
.video-slider .carousel .control-prev.control-arrow {
  top: 5px !important;
  right: -138px !important;
    padding: 27px !important;
}
.video-slider .carousel .control-prev.control-arrow:before {
  transform: scale(1.2);
}
.video-slider .carousel .control-next.control-arrow:before {
  transform: scale(1.2);
}
   }
   @media only screen and (max-width: 1708px) and (min-width:1681px){
    .products .container-css {
      margin-left: 5%;
      margin-bottom: 9rem;
      width: 94%;
  }
  .emandiTVV {
    width: 86% !important;
}
  .emandiTV .small-box {
    width: 10px;
    height: 46px;
}
.headerEmandiTV .mx-3 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
  font-size: 32px !important;
}
.headerEmandiTV .mx-1 {
  font-size: 21px !important;
}
iframe {
  width: 50rem !important;
  height: 27rem !important;
}
.video-slider .carousel .slide .legend {
  left: 117% !important;
  top: 35px;
  font-size: 16pt !important;
  margin-left: 0px !important;
}
.video-slider .carousel .thumbs-wrapper .thumbs .thumb {
  width: 218px !important;
}
.video-slider .carousel-root .carousel + .carousel {
  top: 22rem;
}
.carousel.carousel-slider .control-arrow {
  top: 5px !important;
  left: 70rem;
  padding: 27px !important;
}
.video-slider .carousel .control-prev.control-arrow {
  top: 5px !important;
  right: -158px !important;
    padding: 27px !important;
}
   }
   @media only screen and (max-width: 1680px) and (min-width: 1441px){
    .emandiTVV {
      width: 86% !important;
  }
  .video-slider .carousel .thumbs-wrapper {
    margin: 0 !important;
    padding-left: 79px;
}
    .video-slider .carousel .control-next.control-arrow:before {
      position: absolute;
      top: 20px !important;
      transform: scale(1.2);
      left: 22px !important;
  }
  .video-slider .carousel .control-prev.control-arrow:before {
    position: absolute;
    top: 21px;
    left: 17px;
    transform: scale(1.2);
}
    .video-slider .carousel-root .carousel + .carousel {
      top: 24rem;
  }
    .emandiTV .small-box {
      width: 9px;
      height: 43px;
  }
  .viewAll {
    font-size: 23px !important;
}
iframe {
  width: 51rem !important;
  height: 28rem !important;
}
    .video-slider .carousel .slide .legend {
      left: 113% !important;
      top: 35px;
      font-size: 16pt !important;
    }
    .video-slider .carousel .thumbs-wrapper .thumbs .thumb {
        width: 191px !important;
    }
    .carousel.carousel-slider .control-arrow {
        left: 70rem;
        top: 17px !important;
    }
    .video-slider .carousel .control-prev.control-arrow {
      right: -132px !important;
      top: 17px !important;
    }
    .news-section-css .box img {
        height: 14rem !important;
        margin-bottom: 18px !important;
    }
    .news-section-css .card-text {
        font-size: 19px !important;
    }
    .news-section-css .fullNews {
        font-size: 17px !important;
    }
    .emandiTV .mt {
      width: 96%;
      margin-left: 5%;
    }
   }
   @media only screen and (max-width: 1639px) and (min-width: 1601px){
    .video-slider .carousel .slide .legend {
      left: 113% !important;
    }
   }
   @media only screen and (max-width: 1600px) and (min-width: 1441px){
    .video-slider .carousel .thumbs-wrapper {
    margin: 0 !important;
    padding-left: 97px;
}
    .emandiTV .small-box {
      width: 8px;
      height: 42px;
    }
    .headerEmandiTV .mx-3 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
      font-size: 28px !important;
  }
  .headerEmandiTV .mx-1 {
    font-size: 18px !important;
    }
    .emandiTVV {
      width: 86%;
    }
    .viewAll {
      font-size: 22px !important;
  }
    .emandiTV .mx-3 {
      font-size: 33px !important;
      margin-left: 2rem!important;
    }
    .emandiTV .mx-1 {
      font-size: 24px !important;
      margin-left: 1.25rem!important;
    }
    .emandiTVV {
      margin-bottom: 4rem;
      margin-top: 5rem !important;
    }
    .video-slider {
      position: relative;
      width: 100%; 
    }
    .emandiTV .mt {
      width: 87%;
      margin-left: 6%;
    }
    iframe {
      width: 46rem !important;
      height: 25rem !important;
      border-radius: 17px !important;
  }
    .video-slider .carousel .slide .legend {
      left: 117% !important;
      top: 33px;
      font-size: 15pt !important;
    }
    .lblViews {
      font-family: MetropolisLight !important;
      color: #757575 !important;
      font-size: 11pt !important;
  }
  .video-slider .carousel-root .carousel + .carousel {
    top: 210px;
  }
    .carousel.carousel-slider .control-arrow {
      top: -12px !important;
      left: 64rem;
      padding: 28px !important;
    }
    .video-slider .carousel .control-prev.control-arrow:before {
      position: absolute;
      top: 21px;
      left: 16px;
  }
    .video-slider .carousel .control-prev.control-arrow {
      right: -145px !important;
      top: -14px !important;
    }
   }
   @media only screen and (max-width: 1536px) and (min-width: 1519px){
    .news-section-css .box img {
      height: 12rem !important;
    }
    .news-section-css .card-text {
      font-size: 17px !important;
  }
  .video-slider .carousel .slide .legend {
    left: 120% !important;
  }
   }
   @media only screen and (max-width: 1518px) and (min-width: 1441px){
    .video-slider .carousel .slide .legend {
      left: 122% !important;
    }
    .video-slider .carousel .control-prev.control-arrow {
        right: -165px !important;
    }
    .news-section-css .card-text {
      font-size: 17px !important;
  }
  .news-section-css .box img {
    height: 12rem !important;
    margin-bottom: 18px !important;
}
   }
   @media only screen and (max-width: 1440px) and (min-width: 1281px){
    .video-slider .carousel .slide .legend {
      padding-top: 11px !important;
    }
    .news-section-css {
      margin-left: 6.5%;
    }
    .video-slider .carousel .thumbs-wrapper {
      margin: 0 !important;
      padding-left: 74px;
  }
    .carousel .slide iframe {
      margin: 0 49px 40px;
    }
    .emandiTV .mt{
      width: 94%;
      margin-left: 5%;
    }
     .big{
      font-size: 26px !important;
    }
     .small{
      font-size: 18px !important;
    }
    .video-slider {
      position: relative;
      width: 100%; 
    }
    iframe{
      width: 42rem !important;
      height: 23rem !important;
      border-radius: 16px !important;
    }
    .video-slider .carousel .slide .legend {
      font-size: 14pt !important;
      top: 28px;
      left: 50rem !important;
    }
    .carousel.carousel-slider .control-arrow {
        top: -40px !important;
        padding: 26px !important;
        left: 59rem;
      }
    .video-slider .carousel .control-next.control-arrow:before {
      position: absolute;
      top: 19px !important;
      left: 19px !important;
    }
    .video-slider .carousel .control-prev.control-arrow {
      right: -113px !important;
      top: -40px !important;
      padding: 26px !important;
    }
    .video-slider .carousel .control-prev.control-arrow:before {
      position: absolute;
      top: 19px;
      left: 15px;
    }
    .lblViews {
      font-size: 10pt !important;
    }
    .video-slider .carousel-root .carousel + .carousel {  
      top: 21rem;
    }
    .video-slider .carousel .thumbs-wrapper .thumbs .thumb {
      width: 159px !important;
    }
    .small-box {
      border-radius: 0px 12px 15px 0px !important;
      width: 7px;
      height: 37px;
    }
    .headerEmandiTV .mx-3 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
      font-size: 23px !important;
  }
  .headerEmandiTV .mx-1 {
    font-size: 17px !important;
}
   }
   @media only screen and (max-width: 1366px) and (min-width: 1281px){
    .video-slider .carousel .slide .legend {
      padding-top: 7px !important;
    }
    .big {
      font-size: 24px !important;
  }
    .emandiTV .mt{
      width: 87%;
      margin-left: 5%;
    }
    .video-slider {
      position: relative;
      width: 100%; 
    }
    iframe{
      width: 41rem !important;
      height: 22rem !important;
      border-radius: 14px !important;
    }
    .video-slider .carousel .slide .legend {
      font-size: 12pt !important;
      top: 32px;
      left: 49rem !important;
    }
    .carousel.carousel-slider .control-arrow {
      top: -39px !important;
      padding: 24px !important;
      left: 58rem;
    }
    .video-slider .carousel .control-next.control-arrow:before {
      position: absolute;
      top: 17px !important;
      left: 16px !important;
    }
    .video-slider .carousel .control-prev.control-arrow {
      right: -157px !important;
      top: -39px !important;
      padding: 24px !important
    }
    .video-slider .carousel .control-prev.control-arrow:before {
      position: absolute;
      top: 17px;
      left: 14px;
    }
    .lblViews {
      font-size: 10pt !important;
    }
    .video-slider .carousel-root .carousel + .carousel {  
      top: 20rem;
    }
    .video-slider .carousel .thumbs-wrapper .thumbs .thumb {
      width: 172px !important;
    }
    .emandiTVV {
      width: 86%;
    }
    .carousel .slider-wrapper .slider .slide .slider_box iframe{
      margin-left: 35px;
    }
   }

   @media screen and (max-width: 1280px){
    .video-slider .carousel .slide .legend {
      padding-top: 7px !important;
    }
    .video-slider .carousel .thumbs-wrapper .thumbs .thumb {
      width: 142px !important;
  }
  .smallBox {
    margin-left: 7%;
  }
    .carousel.carousel-slider .control-arrow {
      top: -39px !important;
    }
    .emandiTVViewAll {
      font-size: 27px !important;
      margin-left: 1.5rem !important;
      font-size: 21px !important;
  }
    .video-slider .carousel .slide .legend {
      font-size: 13pt !important;
      left: 120% !important;
      top: 32px;
    }
    .video-slider .carousel-root .carousel + .carousel {
      width: 50% !important;
      position: absolute;
      right: 0;
      bottom: -15px;
      top: 183px;
    }
    .carousel.carousel-slider .control-arrow {
      top: -38px;
    }
    .video-slider .carousel-slider .control-next {
      right: -219px !important;
    }
    .video-slider .carousel .control-prev.control-arrow {
      right: -140px !important;
    }
    .video-slider .carousel .control-next.control-arrow:before {
      position: absolute;
      top: 12px !important;
      left: 13px;
      transform: scale(0.9);
  }
  .video-slider .carousel .control-prev.control-arrow:before {
    position: absolute;
    top: 12px;
    left: 9px;
    transform: scale(0.9);
}
    .carousel .slider-wrapper .slider .slide .slider_box iframe{
      margin-left: 93px;                                                       
      width: 379px !important;
      height: 212px !important;
      border-radius: 13px !important;
    }
    .small-box {
      width: 6px;
      height: 35px !important;
    }
    .headerEmandiTV .mx-3 {
      font-size: 25px !important;
  }
   }

   @media (max-width: 1093px) and (min-width: 1025px){
    .video-slider .carousel .slide .legend {
      padding-top: 7px !important;
    }
    .carousel .slider-wrapper .slider .slide .slider_box iframe {
      margin-left: 104px;
      width: 323px !important;
      height: 183px !important;
    }
    .video-slider .carousel .thumbs-wrapper .thumbs .thumb {
      width: 130px !important;
  }
  .carousel.carousel-slider .control-arrow {
    top: -67px;
  }
}
   @media screen and (max-width: 1024px){
    .video-slider .carousel .slide .legend {
      padding-top: 7px !important;
    }
    .video-slider .carousel .slide .legend {
      top: 36px;
      left: 131% !important;
      font-size: 12.5pt !important;
    }
    .video-slider .carousel .control-next.control-arrow:before , .video-slider .carousel .control-prev.control-arrow:before{
      position: absolute;
      top: 8px !important;
      left: 8px;
      transform: scale(0.7);
  }
  .video-slider .carousel .control-next.control-arrow:before{
    left: 8px;
  }
  .video-slider .carousel .control-prev.control-arrow:before{
    left: 5px;
  }
    .video-slider .carousel-root .carousel + .carousel {
      top: 200px !important;
    }
    .carousel .slide iframe{
      margin: 0px 98px 40px;
    }
    .video-slider .carousel .thumbs-wrapper{
      padding-left: 0px;
    }
    .video-slider .carousel .thumbs-wrapper .thumbs .thumb {
      width: 131px !important;
    }
    .carousel.carousel-slider .control-arrow{
      top: -36px !important;
      height: 30px !important;
      width: 30px !important;
    }
    .video-slider .carousel .control-prev.control-arrow{
      left: 441px !important;
    }
    .video-slider .carousel-slider .control-next{
      left: 476px;
    }
    .mt{
      margin-left: 3% !important;
    }
    .carousel .slider-wrapper .slider .slide .slider_box iframe{
      margin-left: 54px;
      width: 358px !important;
      height: 206px !important;
      border-radius: 12px !important;
    }
   }

   @media screen and (max-width: 1024px){
    .carousel .slider-wrapper .slider .slide .slider_box iframe {
      margin-left: 54px;
      width: 335px !important;
      height: 200px !important;
      border-radius: 12px !important;
    }
    .mt {
      margin-left: 1.5% !important;
  }
  .video-slider .carousel .slide .legend {
    top: 32px;
    left: 120% !important;
    font-size: 11.5pt !important;
}
.video-slider .carousel .thumbs-wrapper .thumbs .thumb {
  width: 121px !important;
}
.video-slider .carousel .control-prev.control-arrow {
  left: 408px !important;
}
.video-slider .carousel-slider .control-next {
  left: 444px;
}
 }
 @media screen and (max-width: 981px){
  .video-slider .carousel .slide .legend {
    top: 32px;
    left: 125% !important;
  }
 }
 @media screen and (max-width: 944px){
  .video-slider .carousel .slide .legend {
    top: 32px;
    left: 129% !important;
}
 }
 @media screen and (max-width: 911px){
  .video-slider .carousel .thumbs-wrapper .thumbs .thumb {
    width: 109px !important;
  }
  .carousel .thumbs{
    padding-left: 5rem;
  }
  .video-slider .carousel .slide .legend {
    top: 32px;
    left: 135% !important;
}
 }
 @media screen and (max-width: 871px){
  .video-slider .carousel .slide .legend {
      top: 32px;
      left: 141% !important;
      height: fit-content;
  }
}
@media screen and (max-width: 833px){
.carousel .slider-wrapper .slider .slide .slider_box iframe {
    margin-left: 54px;
    width: 306px !important;
    height: 172px !important;
}
.video-slider .carousel .control-prev.control-arrow {
  left: 390px !important;
}
.video-slider .carousel-slider .control-next {
  left: 427px;
}
.carousel.carousel-slider .control-arrow {
  top: -65px !important;
}
.video-slider .carousel-root .carousel + .carousel {
  top: 172px !important;
}
.carousel .thumb {
  margin-right: -1px;
}
}
@media screen and (max-width: 807px){
  .video-slider .carousel .thumbs-wrapper .thumbs {
    display: none;
}
.mt{
  margin-bottom: 4vh !important;
}
.carousel.carousel-slider .control-arrow {
  padding: 24px !important;
}
.video-slider .carousel-slider .control-next {
  left: 465px;
}
.carousel.carousel-slider .control-arrow {
  top: -87px !important;
}
.video-slider .carousel .control-next.control-arrow:before, .video-slider .carousel .control-prev.control-arrow:before {
  top: 16px !important;
  transform: scale(0.9);
}
.video-slider .carousel .control-prev.control-arrow:before {
  left: 13px;
}
.video-slider .carousel .control-next.control-arrow:before {
  left: 15px;
}
}
@media screen and (max-width: 766px){
  .video-slider .carousel .slide .legend {
    top: 32px;
    left: 153% !important;
}
.mt {
  margin-left: 0.5% !important;
}
}
@media screen and (max-width: 715px){
  .video-slider .carousel .slide .legend {
    top: 32px;
    left: 163% !important;
}
.mt {
  margin-left: 0% !important;
}
}
@media screen and (max-width: 666px){
  .carousel .slider-wrapper .slider .slide .slider_box iframe {
    margin-left: 54px;
    width: 277px !important;
    height: 155px !important;
}
.carousel.carousel-slider .control-arrow {
  padding: 20px !important;
}
.carousel.carousel-slider .control-arrow {
  top: -87px !important;
}
.video-slider .carousel .control-next.control-arrow:before, .video-slider .carousel .control-prev.control-arrow:before {
  top: 13px !important;
  transform: scale(0.8);
}
.video-slider .carousel .control-prev.control-arrow:before {
  left: 10px;
}
.video-slider .carousel .control-next.control-arrow:before {
  left: 13px;
}
.video-slider .carousel .control-prev.control-arrow {
  left: 369px !important;
}
.video-slider .carousel-slider .control-next {
  left: 439px;
}
.video-slider .carousel .slide .legend {
  height: fit-content;
}
}
@media screen and (max-width: 598px){
  .video-slider .carousel .slide .legend {
    top: 32px;
    left: 178% !important;
}
}
@media screen and (max-width: 554px){
  .mt {
    margin-left: -2% !important;
}
.emandiTVV {
  margin-bottom: 0rem;
}
.carousel .slider-wrapper .slider .slide .slider_box iframe {
  margin-left: 54px;
  width: 249px !important;
  height: 140px !important;
}
.video-slider .carousel .slide .legend {
  top: 32px;
  left: 170% !important;
  height: fit-content;
  width: 95%;
}
.carousel.carousel-slider .control-arrow {
  padding: 17px !important;
}
.video-slider .carousel .control-next.control-arrow:before, .video-slider .carousel .control-prev.control-arrow:before {
  top: 9px !important;
  transform: scale(0.7);
}
.carousel.carousel-slider .control-arrow {
  top: -103px !important;
}
.video-slider .carousel .control-prev.control-arrow {
  left: 327px !important;
}
.video-slider .carousel-slider .control-next {
  left: 388px;
}
.video-slider .carousel .control-prev.control-arrow:before {
  left: 7px;
}
.video-slider .carousel .control-next.control-arrow:before {
  left: 10px;
}
}
@media screen and (max-width: 555px){
  .headerEmandiTV , .headerEmandiNews{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 80%;
  }
  .headerEmandiTV .mx-1 , .emandi-news-css .mx-1{
    display: none;
  }
  .video-slider .carousel .slide .legend {
    font-size: 10.5pt !important;
}
}
@media screen and (max-width: 514px){
  .mt {
    height: 37rem;
  }
  .video-slider {
    height: 100%;
}
.video-slider .carousel-root {
  height: 100%;
}
.carousel .slider-wrapper .slider .slide .slider_box iframe {
  margin-left: 54px;
  width: 303px !important;
  height: 170px !important;
}
.video-slider .carousel .slide .legend {
  top: 221px;
  left: 28% !important;
  width: 168%;
}
.video-slider .carousel .control-prev.control-arrow {
  left: 229% !important;
}
.video-slider .carousel-slider .control-next {
  left: 264%;
}
}
@media screen and (max-width: 467px){
  .mt {
    margin-left: -4% !important;
  }
  .carousel .slider-wrapper .slider .slide .slider_box iframe {
    margin-left: 54px;
    width: 258px !important;
    height: 147px !important;
}
.video-slider .carousel-slider .control-next {
  left: 261%;
}
.video-slider .carousel .control-prev.control-arrow {
  left: 224% !important;
}
}
@media screen and (max-width: 409px){
  .video-slider .carousel .control-prev.control-arrow {
    left: 249% !important;
  }
  .video-slider .carousel-slider .control-next {
    left: 247%;
    top: -186px !important;
}
.video-slider .carousel .control-prev.control-arrow{
  top: -120px;
}
}
@media screen and (max-width: 376px){
  .video-slider .carousel .slide .legend {
    left: 33% !important;
    top: 204px;
  }
  .mt {
    margin-left: -7% !important;
}
.video-slider .carousel-slider .control-next, .video-slider .carousel .control-prev.control-arrow {
  left: 257% !important;
}
}
@media screen and (max-width: 376px){
  .video-slider .carousel-slider .control-next {
    left: 247%;
    top: -40px !important;
}
.video-slider .carousel .control-prev.control-arrow {
  top: 28px !important;
}
}
@media screen and (max-width: 331px){
  .headerEmandiTV , .headerEmandiNews{
    width: 72%;
  }
}
@media screen and (max-width: 303px){
  .carousel .slider-wrapper .slider .slide .slider_box iframe {
    margin-left: 41px;
  }
}
@media screen and (max-width: 283px){
  .carousel .slider-wrapper .slider .slide .slider_box iframe {
    margin-left: 41px;
    width: 241px !important;
    height: 135px !important;
  }
}
@media screen and (max-width: 265px){
  .carousel .slider-wrapper .slider .slide .slider_box iframe {
    margin-left: 41px;
    width: 222px !important;
    height: 155px !important;
}
}
@media screen and (max-width: 247px){
  .carousel .slider-wrapper .slider .slide .slider_box iframe {
    margin-left: 41px;
    width: 206px !important;
    height: 155px !important;
}
.video-slider .carousel-slider .control-next, .video-slider .carousel .control-prev.control-arrow {
  left: 241% !important;
}
}
@media screen and (max-width: 233px){
  .carousel .slider-wrapper .slider .slide .slider_box iframe {
    margin-left: 35px;
  }
}
@media screen and (max-width: 231px){
  .headerEmandiTV , .headerEmandiNews{
    width: 68%;
  }
}
@media screen and (max-width: 226px){
  .carousel .slider-wrapper .slider .slide .slider_box iframe {
    margin-left: 33px;
  }
}
   
   
