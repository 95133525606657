@media only screen and (max-width: 400px) {

    .app-header{
        .eauctionHeader{
            .header-logo {
                margin-left: 2em;
            }
        }
    }

    .landing-home-page{

        .popup-cls{
            .custom-dialog{
                .p-dialog{
                    width: 90vw !important;
                }
            }
        }

        .create-filter-auction{
            display: block;
            padding: 0;

            .create-auction-wrapper{
                width: 100%;
                padding: 0;

                .buy-sell-wrapper{
                    height: fit-content;
                    padding-bottom: 0.5em;
                }
            }

            .filter-auction-warpper{
                width: 100%;
                padding: 0;

                .filter-list{
                    display: none;
                }

                >p{
                    display: none;
                }

                .auction-list{
                    .custom-carousel{
                        .p-carousel{
                            >div{
                                padding: 0;
                            }

                            .p-carousel-content{
                                .p-carousel-container{
                                    padding: 0;
                                }

                                >.p-button, .p-carousel-prev, .p-carousel-next{
                                    width: 1.5em;
                                }
                            }
                        }
                    }
                }

                .table-cls{
                    margin-bottom: 3em;

                    >p{
                        margin: 0.5em;
                    }

                    .customTable-cls{
                        padding: 0em
                    }
                }
            }
            
        }

        .footer{
            display: none;
        }
    }
}