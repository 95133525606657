@font-face {
    font-family: MetropolisRegular;
    src: url('../Metropolis-Regular.otf')
}

@font-face {
    font-family: MetropolisMedium;
    src: url('../Metropolis-Medium.otf')
}
*{
    font-family:MetropolisRegular ;
}
/* profile top fixed section */

.nameContainer{
    padding-left: 7%;
    padding-right: 7%;
    display: flex;
    gap: 2rem;  
    height: 42px;
    align-items: center;
    margin-top: 4rem;
}
.profile-tabs-container{
    padding-left: 7%;
    padding-right: 7%;
    margin-top: 4rem;
}
.FirstLetterDiv{
    color: #04B23D;
    background-color: #FDE96C;
    font-size: 30px;
    border-radius: 50%;
    height: 100%;
    width: 42px;
    display: flex;
    justify-content: center;
}
.fullName{
    color: var(--text-dark, #2F2F2F);
    font-size: 18px;
    font-family: MetropolisMedium;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.18px;
}
.profile-tabs{
    display: flex;
    width: fit-content;
    /* border-bottom: 2px solid #ECECEC; */
    gap: 3rem;
}
.profile-tab {
    flex: 1 1;
    padding: 0px;
    text-align: left;
    cursor: pointer !important;
    color: var(--text-secondary, #757575);
    font-size: 14px;
    white-space: nowrap;
    padding-bottom: 5px;
    font-family: MetropolisMedium;
}
.profile-tab.active-profile-tab {
    color: #000;
    border-bottom: 3px solid #0DB442;
}

/* personal div */

.personal-div , .kyc-div , .help-div{
    padding-left: 7%;
    padding-right: 7%;
    background: #FAFAFA;
    padding-top: 4rem;
}
.personal-form-container{
    width: 76%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-bottom: 10rem;
}
.form-rows {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.form-labels{
    color: var(--text-tertiary, #979797);
    font-size: 16px;
    font-family: MetropolisMedium;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.16px;
}
.form-inputs{
    width: 68%;
    padding: 13px 23px;
    border-radius: 8px;
    border: 1px solid var(--outline-box, #D3D3D3);
    background: var(--brand-white, #FFF);
    box-shadow: 0px 0px 8px 0px rgba(129, 161, 191, 0.12);
    color: var(--text-dark, #2F2F2F);
    font-size: 16px;
    font-family: MetropolisMedium;
    font-weight: 500;
    outline: none;
}
.name-form-input{
  background: #F5F5F5;
  color: var(--text-secondary, #757575);
  font-family: MetropolisMedium !important;
}
.phone-container{
    width: 68%;
    padding: 0px 23px;
    border-radius: 8px;
    border: 1px solid var(--outline-box, #D3D3D3);
    background: #F5F5F5;
    box-shadow: 0px 0px 8px 0px rgba(129, 161, 191, 0.12);    
    display: flex;
    align-items: center;
    gap: 2rem;
}
.country-code-div{
    display: flex;
    gap: 1rem;
}
.country-code{
    color: var(--text-tertiary, #979797);
    font-size: 16px;
    font-family: MetropolisMedium;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.16px;
}
.form-phone-inputs{
    font-size: 16px;
    font-family: MetropolisMedium !important;
    font-weight: 500;
    outline: none;
    height: 100%;
    width: 80%;
    background: #F5F5F5;
    padding: 16px 1px;
    border: none;
    color: var(--text-secondary, #757575);
}
.form-address-row{
    align-items: flex-start;
}
.form-address-label{
    margin-top: 15px;
}
.form-Address-inputs-container{
    width: 68%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.form-address-pin-state-container{
    display: flex;
    justify-content: space-between;
}
.form-address-pin-state-container>input{
    width: 49%;
}
.form-address-input{
    width: 100%;
    padding: 13px 23px;
    border-radius: 8px;
    border: 1px solid var(--outline-box, #D3D3D3);
    background: var(--brand-white, #FFF);
    box-shadow: 0px 0px 8px 0px rgba(129, 161, 191, 0.12);
    color: var(--text-dark, #2F2F2F);
    font-size: 16px;
    font-family: MetropolisMedium;
    font-weight: 500;
    outline: none;
}
.personal-detail-btn-container{
    display: flex;
    justify-content: flex-end;
}
.personal-details-submit-btn{
    width: 29%;
    padding: 8px 0px;
}  

/* help center */
.faq-heading{
    color: var(--text-dark, #2F2F2F);
    font-size: 16px;
    font-family: MetropolisMedium;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.help-tabs{
    display: flex;
    gap: 1rem;
}
.ecash-btn{
    border-radius: 14px;
    background: none;
    border: 1px solid #979797;
    color: #979797;
    padding: 4px 16px;
    font-size: 14px;
    font-family: MetropolisMedium;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    width: fit-content;
    margin-top: 16px;
    cursor: pointer;
}
.active-ecash-btn{
    border-radius: 14px;
    background: #04B23D;
    padding: 4px 16px;
    color: var(--brand-white, #FFF);
    font-size: 14px;
    font-family: MetropolisMedium;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    width: fit-content;
    margin-top: 16px;
    cursor: pointer;
    border: none;
}
.QA-container{
    display: flex;
    margin-top: 38px;
    padding-bottom: 45px;
}
.left-qa-div , .right-qa-div{
    width: 50%;
}
.ques-ans-div{
    margin-bottom: 25px;
    border-bottom: 1px solid #ECECEC;
    padding-bottom: 25px;
}
.ques-div{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.ques{
    color: var(--text-dark, #2F2F2F);
    font-size: 16px;
    font-family: MetropolisMedium;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.16px;
}
.ans{
    margin-top: 15px;
    color: var(--text-dark, #2F2F2F);
    font-size: 17px;
    font-family: MetropolisRegular;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.16px;
    display: none;
}
.right-qa-div {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
.help-card{
    display: flex;
    width: fit-content;
    border-radius: 12px;
    background: var(--brand-white, #FFF);
    padding: 25px;
    gap: 2rem;
}
.help-card-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.help-text-upper{
    color: var(--text-secondary, #757575);
    font-size: 14px;
    font-family: MetropolisMedium;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.help-text-lower{
    color: var(--brand-green, #0DB442);
    font-size: 16px;
    font-family: MetropolisMedium;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px;
}

 /* loader */
 #loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  #spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #0DB442;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  /* date Picker */
  .react-datepicker {
    font-family: Arial, sans-serif;
  }
  
  .react-datepicker__header {
    background-color: #f0f0f0;
    border-bottom: 1px solid #ccc;
    padding: 10px;
  }
  
  .react-datepicker__month {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .react-datepicker__month .react-datepicker__day {
    display: inline-block;
    margin: 5px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
  }
  
  .react-datepicker__month .react-datepicker__day:hover {
    /* background-color: #e0e0e0; */
    background-color: #d3f3de;
  }
  
  .react-datepicker__month .react-datepicker__day--selected {
    background-color: #0DB442;
    color: #fff;
  }
  
  .react-datepicker__month .react-datepicker__day--selected:hover {
    background-color: #0DB442;
  }
  .react-datepicker__day-names{
    display: flex !important;
    justify-content: space-evenly !important;
  }
  .react-datepicker__month .react-datepicker__day--disabled {
    color: #ccc;
    cursor: default;
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    font-size: 16px !important;
  }
  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
    margin-bottom: 15px !important;
    font-size: 16px !important;
  }
  .react-datepicker__header__dropdown, .react-datepicker__header__dropdown--select{
    margin-bottom: 20px !important;
  }
  .react-datepicker__month-select , .react-datepicker__year-select{
    padding: 3px 3px!important;
    font-size: 12px !important;
  }
  .react-datepicker__year-dropdown {
    max-height: 200px !important; /* Adjust the desired height */
    overflow-y: auto;
  }

  /* different names in kyc and personal details popup */
  .diffNamesPopup-header{
    justify-content: center !important;
    font-size: 18px !important;
    border-bottom: 1px solid #f0f0f0 !important;
  }
  .diffNamesPopup-para{
    font-size: 14px !important;
  }
  .diffNamesPopup-okBtn-conatiner{
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .diffNamesPopup-okBtn{
    width: 35%;
    border: 2px solid var(--brand-green, #0DB442);
    background: #0DB442;
    color: var(--brand-green, #FFF);
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 16px;
    font-family: MetropolisMedium;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
  .diffNameKycPersonal-popup-content{
    width: 42% !important;
    border-radius: 12px;
    padding-bottom: 1rem !important;
  }

/* kyc section */
.radio-group {
    display: flex;
    align-items: center;
    gap: 5rem;
  }
  
  .radio-group input[type="radio"] {
    display: none;
  }
  
  .radio-group label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    color: var(--text-dark, #2F2F2F);
    font-size: 16px;
    font-family: MetropolisMedium;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
  
  .radio-group label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 17px;
    height: 17px;
    border: 2px solid grey;
    border-radius: 50%;
  }
  
  .radio-group input[type="radio"]:checked + label:before {
    border: 2px solid green;
    background-color: white;    
  }
  
  .radio-group input[type="radio"]:checked + label:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 4px;
    width: 9px;
    height: 9px;
    border: 0px solid white;
    border-radius: 50%;
    background-color: green;
  }
  .radio-group.kyc-completed input[type="radio"]:checked + label:before {
    border-color: gray;
    background-color: white;
  }
  
  .radio-group.kyc-completed input[type="radio"]:checked + label:after {
    background-color: gray;
  }

  .account-type-container{
    border-radius: 8px;
    background: #F2F2F2;
    padding: 20px 30px;
    margin-bottom: 4rem;
    width: 74%;
  }
  .kyc-form-container{
    width: 74%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .kyc-form-rows{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .firm-name-row{
    justify-content: flex-end;
  }
  .firmNameText{
    display: flex;
    align-items: center;
    color: var(--text-tertiary, #979797);
    font-family: MetropolisMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
  }
  .greenFetchedFirmName{
    color: var(--brand-green, #0DB442);
  }
  .rollingImage{
    width: 30px;
  }
  .firmNameTextContainer{
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .pan-input-error-container, .kyc-done-input-container{
    display: flex;
    flex-direction: column;
    width: 68%;
  }
  .panNumberError , .PanDobError{
    display: none;
  }
  .kyc-form-inputs{
    width: 100%;
    padding: 13px 23px;
    border-radius: 8px;
    border: 1px solid var(--outline-box, #D3D3D3);
    background: var(--brand-white, #FFF);
    box-shadow: 0px 0px 8px 0px rgba(129, 161, 191, 0.12);
    color: var(--text-dark, #2F2F2F);
    font-size: 16px;
    font-family: MetropolisMedium !important;
    font-weight: 500;
    outline: none;
  }
  .kyc-form-inputs::placeholder{
    color: var(--text-tertiary, #979797);
    font-size: 16px;
    font-family: MetropolisMedium;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.16px;
    
  }
  .addMoreID-save-conatiner{
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .addIdBtn-container{
    display: flex;
    justify-content: flex-end;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .addIdBtn{
    display: flex;
    width: 20rem;
    padding: 7px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 2px solid var(--brand-green, #0DB442);
    background: var(--brand-white, #FFF);
    color: var(--brand-green, #0DB442);
    text-align: center;
    font-size: 16px;
    font-family: MetropolisMedium;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
  .saveChangesBtn{
    display: flex;
    width: 20rem;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #D3D3D3;
    border: none;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-family: MetropolisMedium;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
  .disable-addId-btn{
    background: #D3D3D3;
    border: none;
    color: white;
    cursor: not-allowed !important;
  }
  .enable-savechanges-btn{
    border: 2px solid var(--brand-green, #0DB442);
    background: #0DB442;
    color: var(--brand-green, #FFF);
  }

  /* add Ids in Individual */
  .addIdPopup-content{
    /* position: absolute !important;
    right: 16%;
    top: 30%;
    transform: translate(-50%, -50%); */
    width: 31rem;
    border-radius: 12px;
    box-shadow: 0px 0px 5px 0px rgba(64, 81, 81, 0.14);
  }
  .addIdHeader{
    justify-content: space-between !important;
  }
  .leftHeading-container{
    display: flex;
    gap: 1rem;
  }
  .crossAddItemPopupImg{
    cursor: pointer;
  }
  .leftHeading-container>span{
    color: #757575;
    font-family: MetropolisMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
  .addIdModal-content{
    padding-top: 0px;
  }
  .addItemRow{
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    align-items: center;
    border-bottom: 1px solid #F2F2F2;
  }
  .addItemLabel{
    color: var(--text-dark, #2F2F2F);
    font-family: MetropolisMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
.addIdModal-content input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 17px;
  height: 17px;
  border: 2px solid grey;
  border-radius: 50%;
  outline: none;
  margin: 0;
  cursor: pointer;
}
.addIdModal-content input[type="radio"]:checked {
  border: 2px solid green;
  background-color: white;
  position: relative;
}
.addIdModal-content input[type="radio"]:checked:before {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  border: 0px solid white;
  border-radius: 50%;
  background-color: green;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.addItemSubmit{
  display: flex;
  width: 270px;
  height: 44px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--brand-green, #04B23D);
  color: #FFF;
  text-align: center;
  font-family: MetropolisMedium;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.18px;
  border: none;
  margin-top: 20px;
}
.disabledAddItemSubmit{
  background: var(--outline-box, #D3D3D3);
}
.selectedKYCItemFieldsPopup-header{
  display: flex;
  flex-direction: column;
  background-image: url('../../../../assets/images/yellowBackgroundAddNewId.png');
  overflow: hidden;
  background-size: cover;
}
.selectedKYCItemOtpPopup-header{
  display: flex;
  flex-direction: column;
  background-image: url('../../../../assets/images/yellowBackgroundAddNewId.png');
  overflow: hidden;
  background-size: cover !important;
  background-repeat: round;
}
.cross-header{
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.heading-header{
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
  height: 6rem;
  align-items: center;
  padding-left: 4rem;
}
.heading-header>span{
  color: var(--text-dark, #2F2F2F);
  font-family: MetropolisMedium;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.selectedKYCItemFieldsPopup-content{
  padding: 0px !important;
  border-radius: 12px;
}
.selectedKYCItemFields-content{
  padding: 6rem;
}
.selectedItemToAddInputs-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selectedItemToAddInputsRows-container{
  display: flex;
  flex-direction: column  ;
  gap: 2rem;
}
.selectedItemToAddSubmit-row{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.adharOtpFields{
  width: 48px;
  height: 50px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid var(--outline-box, #D3D3D3);
  background: var(--brand-white, #FFF);
  padding: 0px !important;
  color: var(--text-dark, #2F2F2F);
  font-family: MetropolisMedium;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  outline: none;
}
.adharOtpFields::placeholder{
  text-align: center;
  font-size: 20px;
}
.otpBoxesContainer{
  display: flex;
  width: 72%;
  justify-content: space-between;
}
.selectedKYCItemOtpPopup-content{
  width: 40%;
  padding: 0px;
  border-radius: 12px;
}
.addItemOtpVerify{
  width: 72%;
}
.selectedItemToAddCounter-container{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
  font-family: MetropolisMedium;
}
.selectedItemToAddCounter-container>span{
  width: 72%;
    display: flex;
    gap: 1rem;
    font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
}
.selectedItemToAddCounter-container>span:first-child{
  color: var(--text-tertiary, #979797);
}
.successfullyAddedKycPopup-modalContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  padding-bottom: 8rem;
}
.laptop-hr-conatiner{
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.laptopBottomHr{
  color: #ECECEC;
  margin: 0px !important;
  width: 60%;
}
.successfullyAddedText{
  color: var(--text-dark, #2F2F2F);
  text-align: center;
  font-family: MetropolisMedium;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.AddAnotherIdBtn{
  border-radius: 8px;
  background: var(--brand-green, #04B23D);
  width: 328px;
  padding: 12px 24px;  
  border: none;
  color: #FFF;
  font-family: MetropolisMedium;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.backToHome{
  color: var(--brand-green, #04B23D);
  text-align: center;
  font-family: MetropolisMedium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.successfullyAddedKycPopup-header{
  padding: 26px !important;
}
.successfullyAddedKycPopup-content{
  border-radius: 12px;
  width: 57%;
}
.selectedItemToAddInputs-row .react-datepicker-wrapper {
  width: 68%;
}
.selectedItemToAddInputs-row .react-datepicker-wrapper .form-inputs{
  width: 100%;
}
.checkbox-row{
  justify-content: flex-end;
}
.checkbox-text-container{
  width: 68%;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.agree-aadhar-consent-text{
  color: var(--text-tertiary, #979797);
  font-family: MetropolisMedium;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  display: flex;
  gap: 1rem;
  white-space: nowrap;
}
.Aadhar_consent_text{
  color: var(--brand-green, #04B23D);
  font-family: MetropolisMedium;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  display: flex;
  gap: 1rem;
  cursor: pointer;
  white-space: nowrap;
}
.checkBox{
  width: 15.495px;
  height: 18px;
}
.aadhar-consent-header{
  flex-direction: column;
  align-items: flex-start;
}
.aadhar-consent-header{
  padding-left: 40px !important;
}
.aadhar-consent-Heading{
  width: 100%;
  margin-top: 2rem;
  color: var(--text-dark, #2F2F2F);
  font-family: MetropolisMedium;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.aadhar-consent-modal-content{
  height: 32rem;
    overflow: auto;
    padding: 40px;
    margin-bottom: 5rem;
    position: relative;
}
.aadhar-consent-popup-content{
  border-radius: 12px;
}
.aadhar-consent-para1, .aadhar-consent-para2{
  color: var(--brand-grey, #2F2F2F);
  font-family: MetropolisRegular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 3rem;
} 
.whiteShadow{
  content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 12px;

    -webkit-box-shadow: inset 0px -200px 57px -3px rgba(255, 255, 255, 1);
    -moz-box-shadow: inset 0px -200px 57px -3px rgba(255, 255, 255, 1);

    /* Disable click events */
    pointer-events: none;
    box-shadow: inset 0px -130px 57px -3px rgb(255, 255, 255);

}
.selectedKYCItemFieldsPopup-overlay{
  overflow: auto;
}
.react-datepicker__day-name{
  display: flex !important;
  margin: 5px !important;
  width: 30px !important;
  height: 30px !important;
  justify-content: center !important;
  align-items: center !important;
}

/* responsiveness */
@media screen and (max-width: 5464px) and (min-width: 2561px){
  .fullName {
    font-size: 58px;
  }
  .nameContainer {
    height: fit-content;
    margin-top: 8rem;
  }
  .FirstLetterDiv {
    font-size: 58px;
    height: 100%;
    width: 95px;
  }
  .profile-tab {
    font-size: 53px;
  }
  .profile-tabs {
    gap: 11rem;
  }
  .profile-tabs-container {
    margin-top: 9rem;
}
.profile-tab.active-profile-tab {
  border-bottom: 6px solid #0DB442;
}
.form-labels {
  font-size: 42px;
}
.personal-form-container {
  gap: 7rem;
}
.personal-div, .kyc-div, .help-div {
  padding-top: 12rem;
}
.form-inputs , .form-inputs::placeholder {
  font-size: 44px;
  padding: 25px 55px;
}
.country-code {
  font-size: 41px;
}
.form-phone-inputs , .form-phone-inputs::placeholder {
  font-size: 49px;
}
.phone-container {
  padding: 25px 55px;
}
.form-address-input , .form-address-input::placeholder {
  font-size: 47px;
  padding: 25px 55px;
}
.personal-details-submit-btn {
  padding: 11px 0px;
  font-size: 6.8rem !important;
}
.radio-group {
  gap: 11rem;
}
.radio-group label {
  padding-left: 46px;
  font-size: 42px;
}
.account-type-container {
  padding: 49px 30px;
}
.radio-group input[type="radio"]:checked + label:after {
  left: 6px;
  top: 6px;
  width: 22px;
  height: 21px;
}
.radio-group label:before {
  width: 36px;
  height: 36px;
}
.kyc-form-inputs {
  padding: 25px 55px;
  font-size: 52px;
}
.addIdBtn {
  padding: 30px 16px;
  font-size: 39px;
}
.faq-heading {
  font-size: 40px;
}
.help-tabs {
  gap: 6rem;
  margin: 9rem 0rem;
}
.active-ecash-btn , .ecash-btn{
  border-radius: 30px;
  padding: 21px 26px;
  font-size: 38px;
}
.ques {
  font-size: 43px;
}
.ques-ans-div {
  padding-bottom: 100px;
}
.open-ans-arrow-img{
  width: 43px;
}
.ans {
  margin-top: 47px;
  font-size: 55px;
  line-height: 64px;
}
.profileClick-popup-content {
  padding: 51px !important;
  top: 26rem !important;
  right: 36rem !important;
  border-radius: 18px !important;
}
.ProfileImgPopupCol {
  font-size: 42px;
}
.addIdPopup-content {
  right: 26%;
  top: 29%;
}
.leftHeading-container img{
  width: 30px;
}
.addIdPopup-content {
  width: 58rem;
}
.addItemLabel {
  color: var(--text-dark, #2F2F2F);
  font-family: MetropolisMedium;
  font-size: 38px;
}
.addItemRow {
  padding: 33px 0px;
}
.addItemSubmit {
  width: 100%;
  height: 74px;
  padding: 16px 16px;
  font-size: 34px;
}
.addIdModal-content input[type="radio"] {
  width: 41px;
  height: 41px;
}
.addIdModal-content input[type="radio"]:checked:before {
  width: 22px;
  height: 22px;
}
.heading-header>span {
  font-size: 41px;
}
.fingerPrintImg{
  width: 57px;
}
.cross-header img{
  width: 40px;
}
.adharOtpFields {
  width: 20rem;
  height: 15rem;
  font-size: 7rem;
}
.adharOtpFields::placeholder{
  font-size: 7rem;
}
.selectedItemToAddCounter-container>span {
  font-size: 39px;
  line-height: unset;
}
.successfullyAddedText {
  font-size: 37px;
}
.AddAnotherIdBtn {
  width: 49rem;
  padding: 23px 24px;
  font-size: 35px;
}
.backToHome {
  font-size: 34px;
}
.leftHeading-container>span {
  font-size: 40px;
}
.agree-aadhar-consent-text, .Aadhar_consent_text {
  font-size: 23px;
}
.checkBox {
  width: 54.495px;
  height: 34px;
}
.info-aadhar-consent{
  height: 20px;
}
.aadhar-consent-Heading {
  font-size: 33px;
}
.aadhar-consent-para1, .aadhar-consent-para2 {
  font-size: 30px;
}
.aadhar-consent-modal-content {
  height: 62rem;
}
}
@media screen and (max-width: 1200px){
  .selectedKYCItemOtpPopup-content {
    width: 61%;
  }
}
@media screen and (max-width: 1150px){
  .QA-container {
    flex-direction: column;
  }
}
@media screen and (max-width: 968px){
  .personal-form-container {
    width: 100%;
  }
  .left-qa-div{
    width: 70%;
  }
  .right-qa-div{
    width: 70%;
  }
  .kyc-form-container , .account-type-container{
    width: 88%;
  }
  .popup-content {
    width: 97%;
  }
  .selectedItemToAddInputs-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}
.selectedItemToAddInputs-row .react-datepicker-wrapper {
  width: 100%;
}
.addIdPopup-content {
  top: 5%;
  transform: unset;
  width: 94%;
  right: 7px;
}
.addItemSubmit {
  width: 100%;
}
.form-inputs {
  width: 100%;
}
.form-rows {
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}
.form-inputs , .phone-container , .form-Address-inputs-container{
  width: 100%;
}
.personal-details-submit-btn {
  width: 54%;
}
.kyc-form-rows {
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
}
.pan-input-error-container, .kyc-done-input-container {
  width: 100%;
}
.selectedItemToAddCounter-container {
 align-items: unset;
}
}
@media screen and (max-width: 679px){
  .personal-details-submit-btn {
    width: 37%;
  }
  .left-qa-div{
    width: 100%;
  }
  .right-qa-div{
    width: 100%;
  }
  .kyc-form-container , .account-type-container{
    width: 100%;
  }
  .otpBoxesContainer {
    width: 98%;
  }
}
@media screen and (max-width: 492px){
  .form-rows {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  .form-inputs , .phone-container , .form-Address-inputs-container{
    width: 100%;
  }
  .personal-details-submit-btn {
    width: 54%;
  }
  .kyc-form-rows {
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
  }
  .pan-input-error-container, .kyc-done-input-container {
    width: 100%;
  }
}
@media screen and (max-width: 472px){
  .selectedKYCItemFields-content {
    padding: 6rem 2rem;
  }
}
@media screen and (max-width: 450px){
  .AddAnotherIdBtn {
    border-radius: 8px;
    background: var(--brand-green, #04B23D);
    width: 241px;
  }
}
@media screen and (max-width: 419px){
  .hideOnMobile{
    display: none;
  }
}
@media screen and (max-width: 400px){
  .personal-details-submit-btn {
    width: 100%;
  }
  .radio-group {
    gap: 1rem;
    padding-left: 12px;
  }
  .addIdPopup-content {
    top: 5%;
    transform: unset;
    width: 94%;
    right: 7px;
  }
  .popup-content {
    width: 97%;
  }
  .addItemSubmit {
    width: 100%;
  }
  .selectedItemToAddInputs-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}
.selectedItemToAddInputs-row .react-datepicker-wrapper {
  width: 100%;
}
}
@media screen and (max-width: 316px){
  .radio-group label {
    padding-left: 22px;
  }
}
