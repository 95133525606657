.newEmandiNews{
    .container{
        .css-p-1-css {
            font-family: MetropolisBold!important;
        }
        .row1-container{
            .card{
            width: 30%;
                border: none;
            .views{
                color: gray;
            }
            
            }

        }
    }
}

@font-face {
    font-family: MetropolisMedium;
    src: url('Metropolis-Medium.otf')
}
@font-face {
    font-family: MetropolisRegular;
    src: url('Metropolis-Regular.otf')
}

@font-face {
    font-family: MetropolisExtraLight;
    src: url('Metropolis-ExtraLight.otf')
}

@font-face {
    font-family: MetropolisLight;
    src: url('Metropolis-Light.otf')
}


@font-face {
    font-family: MetropolisBold;
    src: url('Metropolis-Bold.otf')
}

.newEmandiNews , .newEmandiNewsViewAll {
    font-family: MetropolisRegular;
    margin-left: 3%;
}

.p-css-1 {
    font-family: MetropolisRegular;
}

.input-css-1 {
    font-family: MetropolisExtraLight;
}

.css-p-1 {
    font-family: MetropolisRegular !important;
}

.i-css-1 {
    font-size: 40px;
    // float: left;
    // position: absolute;
    opacity: 0.4 !important;
    // left: 702px;
    // top: 8px;
    z-index: 1000;
    background-color: #ffffff;
    // padding: 5px;
    // padding-top: 10px;
    // padding-left: 8px;
    color: #000000;
    // padding-left: 20px !important;
    // margin-left: 290px !important;
    // margin-top: 125px !important;
}

.input-css-2 {
    border: 1px solid #DDDDDD;
    // padding-left: 40px !important;    // width: 200px;
    // height: 200px;
    position: relative;
    font-family: MetropolisLight !important;
    margin-left: 16px !important;
    margin-top: 12px !important;
}

.input-css-2 .placeholder {
 
    font-family: MetropolisExtraLight !important;
}

// .input-group {
//     display: flex;
//     align-items: center;
//     // max-width: 300px;
//     background: #fff;
//     border: 1px solid #a0a0a0;
//     border-radius: 4px;
//     // padding-left: 0.5rem;
//     overflow: hidden;
//     font-family: MetropolisExtraLight !important;
//   }
  
//   .input-group .prefix {
//     font-weight: 300;
//     font-size: 14px;
//     color: #999;
//   }
  
//   .input-group input {
//     flex-grow: 1;
//     font-size: 14px;
//     background: #fff;
//     border: none;
//     outline: none;
//     padding: 0.5rem;
//   }
  
//   .input-group:focus-within {
//     border-color: #777;
//   }

.input-box {
    display: flex;
    align-items: center;
    // width: 100px;
    // max-width: 400px;
    background: #fff;
    border: 1px solid #a0a0a0;
    // border-radius: 4px;
    padding-left: 0.5rem;
    overflow: hidden;
    font-family: sans-serif;
    // margin-left: 730px !important;
    width: 220px;
    height: 40px;
    border-radius: 9px;
  }
  
  .input-box .prefix {
    font-weight: 300;
    font-size: 14px;
    color: #999;
  }
  
  .input-box input {
    flex-grow: 1;
    font-size: 14px;
    background: #fff;
    border: none;
    outline: none;
    padding: 0.5rem;
  }
  
  .input-box:focus-within {
    border-color: #777;
  }

  .stay-ahead-css {
    font-size: 20px !important;
    font-family: MetropolisMedium !important;
  }
.color-line-css {
    position: absolute;
    z-index: 10;
    // transform: rotate(180deg);
    width: 0.57% !important;
    margin-top: 15px;
    margin-left: 10px;
    border-radius: 40px;
}
 
.form-page-css-1 {
    // border-radius: 100px;
    width: 26px;
    height: 26px;
    border-radius: 50px !important;
    position: relative;   
}
.emandiTVV {
    margin-top: 6rem!important;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    width: 86%;
}
.viewAll{
    font-size: 18px;
}
.newEmandiNews .container .container-css-1{
    display: flex;
    justify-content: space-between;
}
.newEmandiNews .container .container-css-1 .input-box{
    margin-left: 0px !important;
    // margin-right: 1%;
}
.newEmandiNews .container .container-css-1 .smallBox{
    margin-right: 0px !important;
}
.emandiTvNewFormBg{
    height: 41rem !important;
}
.formDivv{
    margin-right: 64px;
}
.stayP{
    font-size: 16px !important;
    margin-top: 27px;
}
.headerEmandiTV .mx-3{
    margin-right: 1.5rem!important;
    margin-left: 1.5rem!important;
    font-size: 21px !important;
}
.headerEmandiTV .mx-1{
    font-size: 15px !important;
}
.newEmandiNews .container .backbtn, .newEmandiNewsViewAll .container .backbtn {
    font-family: MetropolisRegular !important;
}
.backbtn{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.backbtn img{
    width: 4rem;
}
.carousel .slide iframe {
    margin: 0 0px 40px;
}
@media only screen and (max-width: 7680px) and (min-width: 5747px){
    .newEmandiNews .container .backbtn, .newEmandiNewsViewAll .container .backbtn {
        font-size: 52px;
        margin-top: 73px;
        width: 50vw;
    }
    .newEmandiNews .container .backbtn img , .newEmandiNewsViewAll .container .backbtn img{
        width: 4rem;
    }
    .emandiTVViewAll{
        font-size: 75px !important;
    }
    .newEmandiNews .mx-3, .newEmandiNewsViewAll .mx-3{
        margin-left: 5.5rem !important;
        font-size: 71px !important;
    }
    .newEmandiNews .yellow, .newEmandiNewsViewAll .yellow{
        width: 17px !important;
        height: 115px !important;
    }
    .input-box {
        // margin-left: 237rem !important;
        width: 66rem;
        height: 13rem;
        margin-bottom: 19rem;
        padding-left: 78px;
        border-radius: 23px;
    }
    .i-css-1 {
        font-size: 58px;
    }
    .input-box input {
        font-size: 5.5rem !important;
        margin-bottom: -6px !important;
    }
    .TvRow {
        width: 100%;
    }
    .TvRow .col-md-3{
        width: 32% !important;
        overflow: hidden;
        margin-bottom: 124px;
    }
    .TvRow .col-md-3 .card-text {
        font-size: 50px !important;
         margin-top: 10rem !important;
         margin-bottom: 7rem;
    }
    .TvRow .col-md-3 .views{
        font-size: 42px !important;
    }
    .TvRow .col-md-3 iframe{
        width: 100% !important;
    }
    .newEmandiNews .container, .newEmandiNewsViewAll .container {
        width: 87%;
        margin: 0px;
        margin-left: 6%;
        overflow: hidden;
    }
    .emandiTvNewFormBg {
        height: 112rem !important;
    }
    .formSection{
        margin-bottom: 55rem !important;
    }
    .newEmandiNews , .newEmandiNewsViewAll{
        font-family: MetropolisRegular;
        margin-left: 1%;
    }
    .products .container-css {
        font-family: MetropolisRegular;
        margin-left: 7%;
    }
    .newEmandiNewsViewAll .container .row .box img {
        width: 100% !important;
        height: 42rem;
    }
    // .newEmandiNewsViewAll .container .backbtn {
    //     font-size: 37px;
    //     font-family: MetropolisRegular !important;
    // }
    .emandiTVV {
        margin-top: 23rem !important;
        margin-bottom: 16rem !important;
      }
      .small-box {
        width: 17px;
        height: 108px;
      }
      .headerEmandiTV .mx-3 {
        margin-right: 6.5rem !important;
        margin-left: 5.5rem !important;
        font-size: 68px !important;
    }
    .headerEmandiTV .mx-1 {
      font-size: 40px !important;
  }
  .viewAll {
    font-size: 51px;
  }
}
@media only screen and (max-width: 5746px) and (min-width: 5465px){
    .newEmandiNews .container .backbtn, .newEmandiNewsViewAll .container .backbtn {
        font-size: 43px;
        margin-top: 73px;
        width: 50vw;
    }
    .newEmandiNews .container .backbtn img , .newEmandiNewsViewAll .container .backbtn img{
        width: 4rem;
    }
    .emandiTVViewAll{
        font-size: 75px !important;
    }
    .newEmandiNews .mx-3, .newEmandiNewsViewAll .mx-3{
        margin-left: 5.5rem !important;
        font-size: 71px !important;
    }
    .newEmandiNews .yellow , .newEmandiNewsViewAll .yellow{
        width: 17px !important;
        height: 109px !important;
    }
    .input-box {
        // margin-left: 237rem !important;
        width: 66rem;
        height: 13rem;
        margin-bottom: 19rem;
        padding-left: 78px;
        border-radius: 23px;
    }
    .i-css-1 {
        font-size: 58px;
    }
    .input-box input {
        font-size: 5.5rem !important;
        margin-bottom: -6px !important;
    }
    .TvRow {
        width: 100%;
    }
    .TvRow .col-md-3{
        width: 32% !important;
        overflow: hidden;
        margin-bottom: 124px;
    }
    .TvRow .col-md-3 .card-text {
        font-size: 50px !important;
         margin-top: 10rem !important;
         margin-bottom: 7rem;
    }
    .TvRow .col-md-3 .views{
        font-size: 42px !important;
    }
    .TvRow .col-md-3 iframe{
        width: 100% !important;
    }
    .newEmandiNews .container, .newEmandiNewsViewAll .container {
        width: 87%;
        margin: 0px;
        margin-left: 6%;
        overflow: hidden;
    }
    .emandiTvNewFormBg {
        height: 112rem !important;
    }
    .formSection{
        margin-bottom: 55rem !important;
    }
    .container-css {
        font-family: MetropolisRegular;
        margin-left: 7%;
    }
    .newEmandiNews , .newEmandiNewsViewAll{
        font-family: MetropolisRegular;
        margin-left: 1% !important;
    }
    .emandiTVV {
        margin-top: 23rem !important;
        margin-bottom: 16rem !important;
        width: 85%;
      }
      .small-box {
        width: 17px;
        height: 108px;
      }
      .headerEmandiTV .mx-3 {
        margin-right: 6.5rem !important;
        margin-left: 5.5rem !important;
        font-size: 68px !important;
    }
    .headerEmandiTV .mx-1 {
      font-size: 40px !important;
  }
  .viewAll {
    font-size: 51px;
  }
}
@media only screen and (max-width: 5464px) and (min-width: 4099px){
    .newEmandiNews .container .backbtn, .newEmandiNewsViewAll .container .backbtn {
        font-size: 52px;
        margin-top: 73px;
        margin-bottom: 88px;
        width: 50vw;
    }
    .newEmandiNews .container .backbtn img , .newEmandiNewsViewAll .container .backbtn img{
        width: 4rem;
    }
    .emandiTVV {
        width: 85%;
    }
    .emandiTVViewAll{
        font-size: 75px !important;
    }
    .newEmandiNews .mx-3, .newEmandiNewsViewAll .mx-3{
        margin-left: 5.5rem !important;
        font-size: 71px !important;
    }
    .newEmandiNews .yellow , .newEmandiNewsViewAll .yellow{
        width: 17px !important;
        height: 115px !important;
    }
    .input-box {
        // margin-left: 237rem !important;
        width: 66rem;
        height: 13rem;
        margin-bottom: 19rem;
        padding-left: 78px;
        border-radius: 23px;
    }
    .i-css-1 {
        font-size: 58px;
    }
    .input-box input {
        font-size: 5.5rem !important;
        margin-bottom: -6px !important;
    }
    .TvRow {
        width: 100%;
    }
    .TvRow .col-md-3{
        width: 32% !important;
        overflow: hidden;
        margin-bottom: 124px;
    }
    .TvRow .col-md-3 .card-text {
        font-size: 50px !important;
         margin-top: 10rem !important;
         margin-bottom: 7rem;
    }
    .TvRow .col-md-3 .views{
        font-size: 42px !important;
    }
    .TvRow .col-md-3 iframe{
        width: 100% !important;
    }
    .newEmandiNews .container, .newEmandiNewsViewAll .container {
        width: 87%;
        margin: 0px;
        margin-left: 6%;
        overflow: hidden;
    }
    .emandiTvNewFormBg {
        height: 112rem !important;
    }
    .formSection{
        margin-bottom: 55rem !important;
    }
    .container-css {
        font-family: MetropolisRegular;
        margin-left: 7%;
    }
    .newEmandiNews , .newEmandiNewsViewAll{
        font-family: MetropolisRegular;
        margin-left: 1% !important;
    }
    .emandiTVV {
        margin-top: 23rem !important;
        margin-bottom: 16rem !important;
      }
      .small-box {
        width: 17px;
        height: 108px;
      }
      .headerEmandiTV .mx-3 {
        margin-right: 6.5rem !important;
        margin-left: 5.5rem !important;
        font-size: 68px !important;
    }
    .headerEmandiTV .mx-1 {
      font-size: 40px !important;
  }
  .viewAll {
    font-size: 51px;
  }
}
@media only screen and (max-width: 4098px) and (min-width: 3842px){
    .newEmandiNews .container .backbtn, .newEmandiNewsViewAll .container .backbtn {
        font-size: 42px;
        margin-top: 73px;
        margin-bottom: 88px;
        width: 50vw;
    }
    .newEmandiNews .container .backbtn img , .newEmandiNewsViewAll .container .backbtn img{
        width: 4rem;
    }
    .emandi-news-css {
        width: 86%;
    }
    .emandiTVV {
        width: 86%;
    }
    .emandiTVViewAll{
        font-size: 75px !important;
    }
    .newEmandiNews .mx-3, .newEmandiNewsViewAll .mx-3{
        margin-left: 4.5rem !important;
        font-size: 62px !important;
    }
    .newEmandiNews .yellow , .newEmandiNewsViewAll .yellow{
        width: 15px !important;
        height: 94px !important;
    }
    .input-box {
        // margin-left: 237rem !important;
        width: 54rem;
        height: 10rem;
        margin-bottom: 19rem;
        padding-left: 78px;
        border-radius: 23px;
    }
    .i-css-1 {
        font-size: 44px;
    }
    .input-box input {
        font-size: 4.5rem !important;
        margin-bottom: -6px !important;
    }
    .TvRow {
        width: 100%;
    }
    .TvRow .col-md-3{
        width: 32% !important;
        overflow: hidden;
        margin-bottom: 124px;
    }
    .TvRow .col-md-3 .card-text {
        font-size: 50px !important;
        margin-top: 6rem !important;
        margin-bottom: 4rem;
    }
    .TvRow .col-md-3 .views{
        font-size: 42px !important;
    }
    .TvRow .col-md-3 iframe{
        width: 100% !important;
    }
    .newEmandiNews .container, .newEmandiNewsViewAll .container {
        width: 87%;
        margin: 0px;
        margin-left: 6%;
        overflow: hidden;
    }
    .emandiTvNewFormBg {
        height: 112rem !important;
    }
    .formSection{
        margin-bottom: 55rem !important;
    }
    .newEmandiNews , .newEmandiNewsViewAll {
        font-family: MetropolisRegular;
        margin-left: 1%;
    }
    .products .container-css {
        font-family: MetropolisRegular;
        margin-left: 7%;
    }
    .emandiTVV {
        margin-top: 23rem !important;
        margin-bottom: 16rem !important;
      }
      .small-box {
        width: 17px; 
                height: 94px;
        border-radius: 0px 54px 41px 0px !important;
      }
      .headerEmandiTV .mx-3 {
        margin-right: 5.5rem !important;
    margin-left: 4.5rem !important;
    font-size: 60px !important;
    }
    .headerEmandiTV .mx-1 {
      font-size: 40px !important;
  }
  .viewAll {
    font-size: 51px;
  }
}
@media only screen and (max-width: 3841px) and (min-width: 2561px){
    .emandiTVV {
        margin-top: 6rem !important;
        margin-bottom: 11rem;
        display: flex;
        justify-content: space-between;
        width: 85%;
    }
    .newEmandiNews , .newEmandiNewsViewAll {
        font-family: MetropolisRegular;
        margin-left: 1%;
    }
    .headerEmandiTV .mx-3 {
        margin-right: -2.5rem !important;
        margin-left: 4.5rem !important;
        font-size: 62px !important;
    }
    .headerEmandiTV .mx-1 {
        font-size: 49px !important;
    }
    .products .container-css {
        margin-left: 7%;
        margin-bottom: 27rem;
    }
    .viewAll{
        font-size: 54px;
    }
    .newEmandiNews .container .backbtn, .newEmandiNewsViewAll .container .backbtn {
        font-size: 40px;
        margin-top: 73px;
        margin-bottom: 88px;
        width: 50vw;
    }
    .newEmandiNews .container .backbtn img , .newEmandiNewsViewAll .container .backbtn img{
        width: 4rem;
    }
    .emandiTVViewAll{
        font-size: 75px !important;
    }
    .newEmandiNews .mx-3, .newEmandiNewsViewAll .mx-3{
        margin-left: 4.5rem !important;
        font-size: 61px !important;
    }
    .newEmandiNews .yellow , .newEmandiNewsViewAll .yellow{
        width: 17px !important;
        height: 98px !important;
    }
    .input-box {
        // margin-left: 237rem !important;
        width: 61rem;
    height: 10rem;
        margin-bottom: 19rem;
        padding-left: 78px;
        border-radius: 23px;
    }
    .i-css-1 {
        font-size: 45px;
    }
    .input-box input {
        font-size: 4.5rem !important;
        margin-bottom: -6px !important;
    }
    .TvRow {
        width: 100%;
    }
    .TvRow .col-md-3{
        width: 32% !important;
        overflow: hidden;
        margin-bottom: 124px;
    }
    .TvRow .col-md-3 .card-text {
        font-size: 50px !important;
        margin-top: 5rem !important;
        margin-bottom: 4rem;
    }
    .TvRow .col-md-3 .views{
        font-size: 42px !important;
    }
    .TvRow .col-md-3 iframe{
        width: 100% !important;
    }
    .newEmandiNews .container, .newEmandiNewsViewAll .container {
        width: 88%;
        margin: 0px;
        margin-left: 6%;
        overflow: hidden;
    }
    .emandiTvNewFormBg {
        height: 112rem !important;
    }
    .formSection{
        margin-bottom: 55rem !important;
    }
}
@media only screen and (max-width: 2732px) and (min-width: 2561px){
    .newEmandiNews .container .backbtn, .newEmandiNewsViewAll .container .backbtn {
        font-size: 32px;
        margin-top: 73px;
        width: 50vw;
    }
    .newEmandiNews .container .backbtn img , .newEmandiNewsViewAll .container .backbtn img{
        width: 4rem;
    }
    .newEmandiNews .mx-3, .newEmandiNewsViewAll .mx-3 {
        margin-left: 3.5rem !important;
        font-size: 50px !important;
    }
    .newEmandiNews .yellow, .newEmandiNewsViewAll .yellow {
        width: 12px !important;
        height: 75px !important;
    }
    .TvRow .col-md-3 .card-text {
        font-size: 36px !important;
        margin-top: 5rem !important;
        margin-bottom: 2rem;
    }
    .TvRow .col-md-3 .views {
        font-size: 30px !important;
    }
    .input-box {
        width: 47rem;
        height: 8rem;
        margin-bottom: 13rem;
        padding-left: 60px;
        border-radius: 19px;
    }
    .input-box input {
        font-size: 3.5rem !important;
    }
    .i-css-1 {
        font-size: 36px;
    }
    .emandiTV .small-box {
        width: 13px;
        height: 67px;
    }
    .carousel .slide iframe {
        margin: 0 29px 40px;
    }
    .headerEmandiTV .mx-3 {
        margin-right: -3.5rem !important;
        margin-left: 2.5rem !important;
        font-size: 47px !important;
  }
  .headerEmandiTV .mx-1 {
    font-size: 33px !important;
  }
  .viewAll {
    font-size: 44px;
  }
}
@media only screen and (max-width: 2560px) and (min-width: 1921px){
    .emandiTVV {
        margin-top: 6rem !important;
        margin-bottom: 7rem;
    }
    .newEmandiNews , .newEmandiNewsViewAll {
        font-family: MetropolisRegular;
        margin-left: 1%;
    }
    .newEmandiNews .container .container-css-1 {
        display: flex;
        justify-content: space-between;
        padding-right: 1%;
    }
    .headerEmandiTV .mx-3 {
        margin-right: 2.5rem !important;
        margin-left: 3.5rem !important;
        font-size: 43px !important;
    }
    .headerEmandiTV .mx-1 {
        font-size: 30px !important;
    }
    .products .container-css {
        margin-left: 7%;
        margin-bottom: 14rem;
    }
    .viewAll {
        font-size: 35px;
    }
    .newEmandiNews .container .backbtn, .newEmandiNewsViewAll .container .backbtn {
        font-size: 30px;
        margin-top: 43px;
        margin-bottom: 88px;
        width: 50vw;
    }
    .newEmandiNews .container .backbtn img , .newEmandiNewsViewAll .container .backbtn img{
        width: 3rem;
    }
    .emandiTVViewAll{
        font-size: 56px !important;
    }
    .newEmandiNews .mx-3, .newEmandiNewsViewAll .mx-3{
        margin-left: 3.5rem !important;
        font-size: 46px !important;
    }
    .newEmandiNews .yellow , .newEmandiNewsViewAll .yellow{
        width: 12px;
        height: 74px !important;
    }
    .input-box {
        // margin-left: 174rem !important;
        width: 42rem;
    height: 7rem;
    margin-bottom: 14rem;
    padding-left: 44px;
    border-radius: 13px;
    }
    .input-box input {
        font-size: 3.5rem !important;
        margin-bottom: 0px !important;
    }
    .TvRow {
        width: 100%;
    }
    .TvRow .col-md-3{
        width: 32% !important;
        overflow: hidden;
        margin-bottom: 102px;
    }
    .TvRow .col-md-3 .card-text {
        font-size: 33px !important;
        margin-bottom: 2rem;
        margin-top: 3rem !important;
    }
    .TvRow .col-md-3 .views{
        font-size: 28px !important;
    }
    .TvRow .col-md-3 iframe{
        width: 100% !important;
    }
    .newEmandiNews .container, .newEmandiNewsViewAll .container {
        width: 88%;
        margin: 0px;
        margin-left: 6%;
        overflow: hidden;
    }
    .emandiTvNewFormBg {
        height: 79rem !important;
    }
    .formSection{
        margin-bottom: 55rem !important;
    }
    .stayP{
        font-size: 47px !important;
        margin-top: 27px;
    }
}
@media only screen and (max-width: 2400px) and (min-width: 2049px){
    .headerEmandiTV .mx-3 {
        margin-right: 2.5rem !important;
        margin-left: 3.5rem !important;
        font-size: 39px !important;
    }
    .emandiTV .small-box {
        width: 12px;
        height: 57px;
    }
    .products .container-css {
        margin-left: 6%;
    }
    .newEmandiNews .container .backbtn, .newEmandiNewsViewAll .container .backbtn {
        font-size: 27px;
        margin-top: 43px;
        margin-bottom: 88px;
        width: 50vw;
    }
    .newEmandiNews .container .backbtn img , .newEmandiNewsViewAll .container .backbtn img{
        width: 3rem;
    }
    .input-box input {
        font-size: 3rem !important;
        margin-bottom: 0px !important;
    }
    .i-css-1 {
        font-size: 31px;
    }
    .newEmandiNews .mx-3, .newEmandiNewsViewAll .mx-3 {
        margin-left: 2.5rem !important;
        font-size: 42px !important;
    }
    .newEmandiNews .yellow, .newEmandiNewsViewAll .yellow {
        width: 12px;
        height: 66px !important;
    }
    .input-box {
        width: 39rem;
        height: 6rem;
        margin-bottom: 9rem;
        padding-left: 54px;
        border-radius: 13px;
    }
}
@media only screen and (max-width: 2048px) and (min-width: 1601px){
    .products .container-css {
        margin-left: 6%;
        margin-bottom: 14rem;
        width: 89%;
    }
    .newEmandiNews .mx-3, .newEmandiNewsViewAll .mx-3 {
        margin-left: 3rem !important;
        font-size: 43px !important;
    }
    .newEmandiNews .container .backbtn, .newEmandiNewsViewAll .container .backbtn {
        font-size: 29px;
        margin-top: 43px;
        width: 50vw;
    }
    .newEmandiNews .container .backbtn img , .newEmandiNewsViewAll .container .backbtn img{
        width: 3rem;
    }
    .i-css-1 {
        font-size: 35px;
    }
    .emandiTVViewAll{
        font-size: 56px !important;
    }
    .newEmandiNews .yellow , .newEmandiNewsViewAll .yellow{
        width: 12px;
    height: 70px !important;
    }
    .input-box {
        // margin-left: 103rem !important;
        width: 41rem;
    height: 7rem;
    margin-bottom: 11rem;
    border-radius: 17px;
    }
    .input-box input {
        font-size: 3rem !important;
        margin-bottom: 0px !important;
    }
    .TvRow {
        width: 100%;
    }
    .TvRow .col-md-3{
        width: 32% !important;
        overflow: hidden;
        margin-bottom: 82px;
    }
    .TvRow .col-md-3 .card-text {
        font-size: 31px !important;
    }
    .TvRow .col-md-3 .views{
        font-size: 29px !important;
    }
    .TvRow .col-md-3 iframe{
        width: 100% !important;
    }
    .newEmandiNews .container, .newEmandiNewsViewAll .container {
        width: 88%;
        margin: 0px;
        margin-left: 6%;
        overflow: hidden;
    }
    .emandiTvNewFormBg {
        height: 76rem !important;
    }
    .formSection {
        margin-bottom: 43rem !important;
    }
}
@media only screen and (max-width: 1920px) and (min-width: 1601px){
    .carousel .slide iframe {
        margin: 0 25px 40px;
    }
    .newEmandiNews , .newEmandiNewsViewAll {
        font-family: MetropolisRegular;
        margin-left: 1%;
    }
    .newEmandiNews .container .container-css-1 {
        display: flex;
        justify-content: space-between;
        padding-right: 1%;
    }
    .newEmandiNews .container .backbtn, .newEmandiNewsViewAll .container .backbtn {
        font-size: 20px;
        margin-top: 43px;
        width: 50vw;
    }
    .newEmandiNews .container .backbtn img , .newEmandiNewsViewAll .container .backbtn img{
        width: 2rem;
    }
    .emandiTVV {
        margin-top: 6rem !important;
        margin-bottom: 5rem;
    }
    .headerEmandiTV .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 30px !important;
    }
    .headerEmandiTV .mx-1 {
        font-size: 24px !important;
    }
    .products .container-css {
        margin-left: 6%;
        margin-bottom: 14rem;
        width: 87%;
    }
    .input-box input {
        font-size: 2.3rem !important;
    margin-bottom: 0px !important;
    }
    .input-box {
        width: 31rem;
        height: 5rem;
        margin-bottom: 8rem;
        padding-left: 34px;
        border-radius: 12px;
    }
    .newEmandiNews .mx-3, .newEmandiNewsViewAll .mx-3{
        margin-left: 1.5rem !important;
        font-size: 32px !important;
    }
    .newEmandiNews .yellow , .newEmandiNewsViewAll .yellow{
        width: 9px;
    height: 52px !important;
    }
    .emandiTVViewAll{
        font-size: 45px !important;
    }
    .TvRow .col-md-3 iframe {
        width: 100% !important;
        border-radius: 17px;
    }
    .TvRow .col-md-3 .card-text {
        font-size: 25px !important;
        margin-top: 3rem !important;
        margin-bottom: 2rem;
    }
    .TvRow .col-md-3 .views {
        font-size: 21px !important;
    }
    .fa-search:before {
        content: "\f002";
    }
    *, ::after, ::before {
        box-sizing: border-box;
    }
    .i-css-1 {
        font-size: 24px;
    }
    .emandiTvNewFormBg {
        height: 65rem !important;
    }
    .formSection {
        margin-bottom: 35rem !important;
    }
}
@media only screen and (max-width: 1822px) and (min-width: 1709px){
    .TvRow .col-md-3 {
        width: 31% !important;
    }
    .newEmandiNews .container .container-css-1 {
        display: flex;
        justify-content: space-between;
        padding-right: 2%;
    }
    .headerEmandiTV .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 29px !important;
    }
    .headerEmandiTV .mx-1 {
        font-size: 21px !important;
    }
}
@media only screen and (max-width: 1708px) and (min-width: 1681px){
    .TvRow .col-md-3 {
        width: 31% !important;
    }
    .newEmandiNews .container, .newEmandiNewsViewAll .container {
        width: 89%;
    }
    .products .container-css {
        width: 88%;
    }
    .newEmandiNews .container .container-css-1 {
        display: flex;
        justify-content: space-between;
        padding-right: 2%;
    }
    .headerEmandiTV .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 29px !important;
    }
}
@media only screen and (max-width: 1680px) and (min-width: 1601px){
    .carousel .slide iframe {
        margin: 0 41px 40px;
    }
    .newEmandiNews .container .container-css-1 {
        display: flex;
        justify-content: space-between;
        padding-right: 2%;
    }
    .products .container-css {
        margin-left: 6%;
        margin-bottom: 14rem;
        width: 90%;
    }
    .TvRow .col-md-3 {
        width: 31.9% !important;
        overflow: hidden;
        margin-bottom: 82px;
    }
    .headerEmandiTV .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 27px !important;
    }
    .headerEmandiTV .mx-1 {
        font-size: 20px !important;
    }
    .newEmandiNews .container .backbtn, .newEmandiNewsViewAll .container .backbtn {
        font-size: 19px;
    margin-top: 24px;   
    width: 50vw;
    }
    .newEmandiNews .container .backbtn img , .newEmandiNewsViewAll .container .backbtn img{
        width: 2rem;
    }
    .emandiTVViewAll {
        font-size: 43px !important;
    }
    .newEmandiNews .mx-3, .newEmandiNewsViewAll .mx-3 {
        margin-left: 1.5rem !important;
        font-size: 28px !important;
    }
    .newEmandiNews .yellow , .newEmandiNewsViewAll .yellow {
        width: 8px;
    height: 46px !important;
    }
    .input-box {
        // margin-left: 96rem !important;
        width: 26rem;
        height: 4.5rem;
        border-radius: 12px;
        margin-bottom: 5rem;
    }
    .input-box input {
        font-size: 2rem !important;
        margin-bottom: 0px !important;
    }
    .i-css-1 {
        font-size: 22px;
    }
    .TvRow .col-md-3 .card-text {
        font-size: 21px !important;
    margin-top: 2rem !important;
    margin-bottom: 1rem;
    }
    .TvRow .col-md-3 .views {
        font-size: 18px !important;
    }
    .form .formBG .formText {
        font-size: 30px !important;
    }
}
@media only screen and (max-width: 1600px) and (min-width: 1367px){
    .carousel .slide iframe {
        margin: 0 22px 40px;
    }
    .newEmandiNews , .newEmandiNewsViewAll {
        font-family: MetropolisRegular;
        margin-left: 1%;
    }
    .newEmandiNews .container .container-css-1 {
        display: flex;
        justify-content: space-between;
        padding-right: 2%;
    }
    .emandiTVV {
        width: 86%;
    }
    .products .container-css {
        margin-left: 6%;
        margin-bottom: 14rem;
        width: 89%;
    }
    .headerEmandiTV .mx-3 {
        margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
    font-size: 26px !important;
    }
    .headerEmandiTV .mx-1 {
      font-size: 17px !important;
    }
    .newEmandiNews .container .backbtn, .newEmandiNewsViewAll .container .backbtn {
        font-size: 17px;
        margin-top: 22px;
        width: 50vw;
    }
    .newEmandiNews .container .backbtn img , .newEmandiNewsViewAll .container .backbtn img{
        width: 2rem;
    }
    .emandiTVViewAll{
        font-size: 39px !important;
    }
    .newEmandiNews .mx-3, .newEmandiNewsViewAll .mx-3{
        margin-left: 1.5rem !important;
        font-size: 23px !important;
    }
    .newEmandiNews .yellow , .newEmandiNewsViewAll .yellow{
        width: 7px;
        height: 36px !important;
    }
    .input-box {
        // margin-left: 76rem !important;
        width: 24rem;
        height: 4rem;
        margin-bottom: 6rem;
        padding-left: 32px;
    }
    .input-box input {
        font-size: 2rem !important;
        margin-bottom: 0px !important;
        padding: 0.375rem 1rem !important;
    }
    .i-css-1 {
        font-size: 21px;
    }
    .TvRow {
        width: 100%;
    }
    .TvRow .col-md-3{
        width: 31% !important;
        overflow: hidden;
        margin-bottom: 31px;
    }
    .TvRow .col-md-3 .card-text {
        font-size: 20px !important;
        margin-top: 2rem !important;
        margin-bottom: 1rem;
    }
    .TvRow .col-md-3 .views{
        font-size: 15px !important;
    }
    .TvRow .col-md-3 iframe{
        width: 100% !important;
    }
    .newEmandiNews .container{
        width: 90%;
        margin: 0px;
        margin-left: 6%;
        overflow: hidden;
    }
    .newEmandiNewsViewAll .container {
        width: 88%;
        margin: 0px;
        margin-left: 6%;
        overflow: hidden;
    }
    .emandiTvNewFormBg {
        height: 53rem !important;
    }
    .formSection{
        margin-bottom: 29rem;
    }
}
@media only screen and (max-width: 1536px) and (min-width: 1441px){
    .newEmandiNews , .newEmandiNewsViewAll {
        font-family: MetropolisRegular;
        margin-left: 1%;
    }
    .row1-container .box {
        width: 22% !important;
    }
    .products .container-css {
        margin-left: 6%;
        margin-bottom: 14rem;
        width: 93%;
    }
}
@media only screen and (max-width: 1440px) and (min-width: 1367px){
    .headerEmandiTV .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 23px !important;
    }
    .emandiTVV {
        width: 85%;
    }
    .carousel .slide iframe {
        margin: 0 32px 40px;
    }
    .products .container-css {
        margin-left: 6%;
        width: 88%;
    }
    .i-css-1 {
        font-size: 18px;
    }
    .emandiTvNewFormBg {
        height: 38rem !important;
    }
    .stayP {
        font-size: 23px !important;
        margin-top: 27px;
    }
    .input-box {
        width: 22rem;
    height: 3.5rem;
    margin-bottom: 4rem;
    padding-left: 26px;
}
.input-box input {
    font-size: 1.7rem !important;
    margin-bottom: 0px !important;
    padding: 0.375rem 1rem !important;
}
.TvRow .col-md-3 .card-text {
    font-size: 18px !important;
    margin-top: 2rem !important;
    margin-bottom: 1rem;
}
.TvRow .col-md-3 .views {
    font-size: 14px !important;
}
.emandiTVViewAll {
    font-size: 31px !important;
}
}
@media only screen and (max-width: 1366px) and (min-width: 1281px){
    .newEmandiNews .container .container-css-1 , .newEmandiNewsViewAll .container .container-css-1{
        display: flex;
        justify-content: space-between;
        padding-right: 3%;
    }
    .newEmandiNews , .newEmandiNewsViewAll {
        font-family: MetropolisRegular;
        margin-left: 1%;
    }
    .emandiTVV {
        margin-top: 6rem !important;
        display: flex;
        justify-content: space-between;
        width: 85%;
    }
    .small-box {
        width: 8px;
    height: 38px;
    }
    .headerEmandiTV .mx-3 {
        margin-right: 1.5rem !important;            
    margin-left: 1.5rem !important;
    font-size: 24px !important;
    }
    .headerEmandiTV .mx-1 {
        font-size: 17px !important;
    }
    .newEmandiNews .container .backbtn, .newEmandiNewsViewAll .container .backbtn {        
        font-size: 15px !important;
    margin-top: 14px !important;
    width: 50vw;
    }
    .newEmandiNews .container .backbtn img , .newEmandiNewsViewAll .container .backbtn img{
        width: 1.5rem;
    }
    .emandiTVViewAll{
        font-size: 33px !important;
    }
    .newEmandiNews .mx-3, .newEmandiNewsViewAll .mx-3{
        margin-left: 1.5rem !important;
        font-size: 23px !important;
    }
    .newEmandiNews .yellow , .newEmandiNewsViewAll .yellow{
        width: 8px;
    height: 36px !important; 
    }
    .input-box {
        // margin-left: 68rem !important;
        width: 22rem;
        height: 3.8rem;
        margin-bottom: 4rem;
        padding-left: 24px;
    }
    .input-box input {
        font-size: 1.8rem !important;
        margin-bottom: 0px !important;
        padding: 0px !important;
        padding-left: 10px !important;
    }
    .TvRow {
        width: 100%;
    }
    .TvRow .col-md-3 {
        width: 31% !important;
    overflow: hidden;
    margin-bottom: 38px;
    }
    .TvRow .col-md-3 .card-text {
        font-size: 17px !important;
    }
    .TvRow .col-md-3 .views{
        font-size: 13px !important;
    }
    .TvRow .col-md-3 iframe{
        width: 100% !important;
        height: 25rem;
    }
    .newEmandiNews .container, .newEmandiNewsViewAll .container {
        width: 90%;
        margin: 0px;
        margin-left: 6%;
        overflow: hidden;
    }
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 9999px !important;
    }
    .fa-search:before {
        content: "\f002";
    }
    *, ::after, ::before {
        box-sizing: border-box;
    }
    .i-css-1 {
        font-size: 17px;
    }
}
@media only screen and (max-width: 1280px){
    .emandiTVViewAll {
        font-size: 21px !important;
    }
    .emandiTVV {
        width: 85%;
    }
    .newEmandiNews , .newEmandiNewsViewAll {
        font-family: MetropolisRegular;
        margin-left: -1%;
    }
    .newEmandiNews .container, .newEmandiNewsViewAll .container {
        width: 86%;
    }
    .newEmandiNews .container .backbtn, .newEmandiNewsViewAll .container .backbtn {
        margin-bottom: 22px;
        font-size: 15px !important;
        margin-top: 11px !important;
        width: 50vw;
    }
    .newEmandiNews .container .backbtn img , .newEmandiNewsViewAll .container .backbtn img{
        width: 1.5rem;
    }
    .i-css-1 {
        font-size: 17px;
    }
    .input-box {
        // margin-left: 68rem !important;
        width: 21rem;
        height: 3.5rem;
        margin-bottom: 3rem;
        padding-left: 24px;
    }
    .input-box input {
        font-size: 1.6rem !important;
        margin-bottom: 0px !important;
        padding: 0px !important;
        padding-left: 10px !important;
    }
    .TvRow .col-md-3 {
        width: 31% !important;
        overflow: hidden;
        margin-bottom: 31px;
    }
    .headerEmandiTV .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 21px !important;
    }
}
@media screen and (max-width: 1024px){
    .newEmandiNews , .newEmandiNewsViewAll {
        font-family: MetropolisRegular;
        margin-left: -1%;
    }
    .newEmandiNews .container, .newEmandiNewsViewAll .container {
        width: 87%;
    }
    .i-css-1 {
        font-size: 20px;
    }
    .input-box {
        // margin-left: 68rem !important;
        width: 20rem;
        height: 3.5rem;
        margin-bottom: 3rem;
        padding-left: 24px;
    }
    .TvRow .col-md-3 {
        width: 30% !important;
        overflow: hidden;
        margin-bottom: 31px;
    }
    .input-box input {
        font-size: 1.5rem !important;
        margin-bottom: 0px !important;
        padding: 0px !important;
        padding-left: 10px !important;
    }
    .emandiTvNewFormBg{
        height: 41rem !important;
    }
    .formDivv{
        margin-right: 64px;
    }
    .stayP{
        font-size: 16px !important;
        margin-top: 27px;
    }
    .emandiTVV {
        margin-top: 6rem !important;
        display: flex;
        justify-content: space-between;
        width: 85%;
    }
}
@media screen and (max-width: 987px){
    .headerEmandiTV .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 19px !important;
    }
    .headerEmandiTV .mx-1 {
        font-size: 14px !important;
    }
    .viewAll {
        font-size: 16px;
    }
}
@media screen and (max-width: 915px){
    .headerEmandiTV .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 17px !important;
    }
    .headerEmandiTV .mx-1 {
        font-size: 13px !important;
    }
    .viewAll {
        font-size: 14px;
    }
    .small-box {
        width: 6px;
        height: 30px !important;
    }
}

element.style {
    width: 350px;
}
@media screen and (max-width: 768px){
    .TvRow .col-md-3,.newEmandiNewsViewAll .container .row .box {
        width: 50%!important;
    }
}
@media screen and (max-width: 722px){
    .newEmandiNewsViewAll .input-box {
        padding-left: 11px;
    }
}
@media screen and (max-width: 486px){
    .i-css-1 {
        font-size: 15px;
    }
}
@media screen and (max-width: 481px){
    .newEmandiNewsViewAll .input-box {
        width: 18rem;
    }
    .newEmandiNewsViewAll .input-box input {
        font-size: 1.3rem !important;
    }
}
@media screen and (max-width: 465px){
    .TvRow .col-md-3,.newEmandiNewsViewAll .container .row .box {
        width: 100% !important;
    }
}
@media screen and (max-width: 413px){
    .input-box {
        width: 13rem;
        padding-left: 11px;
    }
    .input-box input {
        font-size: 1.2rem !important;
    }
    .i-css-1 {
        font-size: 15px;
    }
}
@media only screen and (max-width: 360px){
    .emandiTVViewAll {
        font-size: 19px !important;
    }
    .newEmandiNewsViewAll .yellow, .newEmandiNews .yellow {
        height: 32px !important;
    }
}
@media screen and (max-width: 317px){
    .newEmandiNews .container .container-css-1 ,.newEmandiNewsViewAll .container .container-css-1{
        flex-direction: column;
    }
    .input-box {
        // width: 20rem;
        height: 3.5rem;
        margin-bottom: 1rem;
    }
    .newEmandiNewsViewAll .input-box {
        width: 14rem;
    }
}


