html{
    font-size: 100%;  
}

.landing-home-page{

    .create-filter-auction{
        margin: 0.6em 1.6em 0em 1.6em;
        // height: 68vh;
        display: flex;

        .create-auction-wrapper{
            width: 25%;
            padding: 2.3em 0.5em 0.5em 0.5em;

            p{
                font-size: 1.6rem;
                margin: 0;
                font-family: 'Poppins', sans-serif;
            }

            .buy-sell-wrapper{
                margin-top: 1rem;
                height: fit-content;
                // padding-bottom: 0.8em;
                background: #aedc94;
                font-family: 'Poppins', sans-serif;
                // padding-left: 0.8em;
                // padding-right: 0.8em;
                padding: 0.8em ;
                box-sizing: border-box;
                // border-radius: 3px;
                .flag-text-class{
                    p{
                        top: 20%;
                        position: relative;
                        padding: 0.5em;
                        padding-left: 10px;
                        font-size: 12px;
                        font-family: 'Poppins', sans-serif;
                        color: #000000;
                        font-weight: bold;
                        margin-top: 8px;
                    }
                    .input-container {
                        display: -ms-flexbox; /* IE10 */
                        display: flex;
                        width: 100%;
                        margin-bottom: 15px;
                        // padding: 0 0.5em;
                        .icon{
                            background-color: #FFFFFF;
                            width: 10%;
                            height: 36px;
                        }
                        
                        img{
                            width: 1em;
                            height: 1em;
                            padding: 8px 8px 6px 8px;
                        }
                        .code{
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            background-color: #ffffff;
                            padding-top: 7px;
                            padding-right: 4px;
                            width: 8%;
                        }
                        .input-cls{
                            width: 82%;
                        }
                        .inputtext-content-section{
                            padding-left: 0 !important;
                        }
                        .inputtext-content-section .p-inputtext{
                            padding: 1px !important;
                            
                        }
                    } 
                
                }
                .inputtext-content-section{
                    
                    .inputtext-label > label{
                        color: #000000;
                        font-family: 'Poppins', sans-serif;

                    }
                } 
                .inputtext-content-section input{
                    height: 36px;
                }
                .p-dropdown-panel {
                    .p-dropdown-list{
                        padding: 0.4em;
                        border: 0 none;
                        margin: 0;
                        list-style-type: none;
                        background: #fff;
                        border: 1px solid #7070704D;
                    }
                }
                .auction-type{
                    display: flex;

                    p{
                        margin: 0.5em;
                        cursor: pointer;
                    }

                    .active{
                        font-weight: bold;
                        border-bottom: 2px solid;
                    }
                    
                }

                .inputtext-content-section{
                    display: flex;
                    flex-direction: column;
                    // padding: 0 0.5em;

                    input{
                        border: none;
                    }
                }

                .qty-class{
                    display:flex;
                    align-items: center;
                    justify-content: space-between;
                    .unit-cls{
                        width: 28.5%;
                        .inputtext-content-section{
                            // width: 25%;    
                        }
                    }
                    .quantity-cls{
                        width: 60%;
                        .inputtext-content-section{
                            // width: 65%;    
                        }
                    }
                

                    .dropdown-content-section {
                        width: 25%;
                        display: block;

                        .Dropdown-label{
                            color: #aedc94;
                            height: 1.94em;
                        }

                        .p-dropdown{
                            width: 105%;
                            min-width: 0;
                            height: 36px;
                            border-radius: 1px;
                            // border: 1px solid #ada4a3;
                        }
                    }
                }

                .submit-btn{
                    text-align: center;
                    border-radius: 5px;
                    // border: 1px solid;
                    width: fit-content;
                    padding: 0.2em 1em;
                    margin: 1em auto;
                    color: #fff;
                    background: #000;
                    cursor: pointer;
                }
            }
            .buynow-paylater-div{
                // .buy-now-pay-later-cls{
                //     margin-bottom: 1em;
                // }
                .buy-now-cls{
                    background: #ffd700;
                    font-family: 'Poppins', sans-serif;
                    // height: 6em;
                    cursor: pointer;
                    .buynow-label{
                            background-color: #ffd700;
                            font-weight: bold;
                            font-size: 26px;
                            margin-top: 14px;
                            text-align: center;
                            margin-top: 1em;
                            padding-top: 0.5em;
                            color: #000000;
                            font-family: 'Poppins', sans-serif;
                            img{
                                float: right;
                                width: 2em;
                                height: 1.5em;
                            }

                    }
                    .fin-cls{
                        margin-top: 0.5em;
                        text-align: center;
                        font-size: 16px;
                        color: #000000;
                        font-family: 'Poppins', sans-serif;
                    }
                    .sub-label{
                        text-align: center;
                        font-size:12px;
                        color: #000000;
                        font-family: 'Poppins', sans-serif;
                    }
                    
                }
                .buy-news{
                    background-color: #FFD900;
                    padding-bottom: 1rem;
                    // height: 4em;
                    cursor: pointer;
                    .buynews-label{
                        font-weight: bold;
                        font-size: 26px;
                        margin-top: 14px;
                        text-align: center;
                        margin-top: 1em;
                        padding-top: 0.6em;
                        color: #000000;
                        font-family: 'Poppins', sans-serif;
                        img{
                            float: right;
                            width: 2em;
                            height: 1.5em;
                        }
                    }
                }
                .commodity-cls{
                    cursor: pointer;
                    margin-top: 1.6em;
                    background: #ffd700;
                    font-family: 'Poppins', sans-serif;
                    text-align: center; 
                    padding: 8px 0 ;
                    font-size: 22px;
                    font-weight:bold
                }
            }

        }
        
        .filter-auction-warpper{
            width: 73%;
            padding: 0.5em;
            .containe {
                display: flex;
                justify-content: flex-end; /* Align items to the left */
                align-items: center; /* Center items vertically */
                height: 5.2vh; /* Optional: Full height of the viewport */
            }
            .act-btn{
                background-color: #ADDC94;
                color: #031200;
                padding: 7px 17px;
                border: none;
                border-radius: 20px;
                font-size: 1.6rem;
                font-weight: bold;
                cursor: pointer;
            }
            .act-btn:hover{
                background-color: #a8d690;;
            }
            .emandi-cash-cls{
                display: flex;
                justify-content: space-between;
                background: linear-gradient(166deg, #DEDEDE 44%, #EEE8AA);
                border-radius: 10px;
                margin: 0.84em 0 2.24em 0;
                padding: 0 1.6rem;
                align-items: center;
                font-family: 'Poppins', sans-serif;
                .icons-cls{
                    display: flex;
                    flex-direction: column;
                    >span{
                        font-size: 12px;
                    }
                }
                .finance-icon-img{
                    width: 5em;
                    cursor: pointer;
                    border-radius: 50%;
                }
                .logo{
                    font-size: larger;
                    font-weight: bold;
                }

                .p-button {
                    background   :#0f9d58;
                    color        : #FFFFFF;
                    border       : 1px solid #0f9d58;
                    padding      : 1em;
                    border-radius: 10px;
                    font-weight  : bold;

                    .p-c{
                        font-size: 1.6em;
                    }
                }
            }
            .year-book-cls{
                margin: 2.2em 0 1.4em -0.2em;
                position: relative;
                
                .year-book-banner{
                    width: 100%;
                }
                .btn-class{
                    display: flex;
                    position: absolute;
                    right: 40%;
                    left: 62%;
                    bottom: 11%;
                    background-color: #0f9d58;
                    width: 21%;
                    padding: 3px 10px;
                    align-items: center;
                    justify-content: space-evenly;
                    border-radius: 45px;
                    color: white;
                    .btn-download{
                        display:flex;
                        justify-content: space-between;
                        align-items: center;
                        text-decoration: none;
                    }
                   
                    .icon-img{
                        height: 22px; 
                    }
                    .bttn {
                        outline: none;
                        border: none;
                        background: none;
                        color: white;
                        font-family: Verdana;
                        font-size: 18px; 
                    }
                    
                }
                .btn-class:hover {
                    cursor: pointer
                }
                    .bttn:hover{
                        cursor: pointer
                    }         
            }
            .view-more-cls{
                float: right;
                position: relative;
                // right: 4em;
                top: 2em;
                cursor: pointer;
                font-size: 1.25rem
            }
            .upcomingauction-view-more-cls{
                float: right;
                position: relative;
                right: 4em;
                top: 1.5em;
                cursor: pointer;
            }
            .table-cls{
                h5{
                    margin: 1em;
                    padding-left: 8px;
                    font-weight: bold;
                    font-family: 'Poppins', sans-serif;
                    color: #000000;
    
                }
                .customTable-cls{
                    padding: 0 1.5em;

                    .bids-activity-grid-cls{
                        // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
                    }

                    .p-button{
                        font-size: 14px;
                    font-family: 'Poppins', sans-serif;
                        padding: 6px;
                        border: navajowhite;
                        background: black;
                        color: white;
                    }
                    .p-datatable .p-datatable-wrapper .p-datatable-tbody .p-datatable-row, .custom-table .p-datatable .p-datatable-scrollable-wrapper .p-datatable-tbody .p-datatable-row, .custom-table .p-datatable-scrollable-body .p-datatable-wrapper .p-datatable-tbody .p-datatable-row, .custom-table .p-datatable-scrollable-body .p-datatable-scrollable-wrapper .p-datatable-tbody .p-datatable-row{
                        background: none;
                    }
                
                    .p-datatable-resizable 
                    {
                        .p-datatable-thead>tr>th
                        {
                            // text-align: center;
                            padding: 0.25em 1.3em;
                            color: #FFFFFF;
                            height: 4em;
                            font-family: 'Poppins', sans-serif;
                        }
                        .p-datatable-tfoot>tr>td, .p-datatable-tbody>tr>td{
                            color: black;
                            // text-align: center;
                            height: 4em;
                            font-family: 'Poppins', sans-serif;
                            padding: 0.25em 1.8em;

                        }
                        .p-datatable-scrollable-header{
                            background-color: #707070 ;
                        }
    
                    }
                }
            }
            .filter-list{
                display: flex;
                justify-content: space-between;

                >div{
                    width: 23%;
                }
                .p-dropdown-items{
                    text-transform: none;
                }
                .dropdown-content-section {
                    height: 2em;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: bold;

                    
                    // .p-inputwrapper-focus{
                    //     background: #FFD900;
                    //     border-radius: 0;
                    // }
                    .p-dropdown label.p-dropdown-label{
                        font-weight: bold;
                    }
                    .p-dropdown{
                        width: 100%;

                        .p-dropdown-panel{
                            background: #FFD900;
                        }
                        .p-dropdown label.p-dropdown-label{
                            text-transform: capitalize;
                        }
                    }
                }

                .custom-silder{
                    display: flex;
                    position: relative;
                    bottom: 0.8em;

                    .silder-qty{
                        padding-top: 1.1em;
                        margin-right: 0.7em;
                        font-weight: bold;
                    }

                    .slider-range-details, p{
                        margin: 0;
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                    }
    
                    .rc-slider{
                        // margin: 0.5em 1em;
    
                        .rc-slider-handle{
                            border: solid 4px #FFD900;
                            background-color: black;
                        }
    
                        .rc-slider-track{
                            background-color: #FFD900;
                        }
                    }
                }
                
            }
        }

        .auction-list{

        }
    }

    .footer{
        margin-top: 5em;
        padding: 1em 3em;
        position: relative;
        display: flex;
        justify-content: space-between;
        background-color: #000000;
        // border-bottom-left-radius: 15px;
        // border-bottom-right-radius: 15px;
        >div{
            width: 25%;
        }
        .footer-address-cls{
            color: #979797 ;
            font-family: 'Poppins', sans-serif;
            .logo{
                    /* width: 110px; */
                height: 2.3em;
                margin-left: 1em;
                border-radius: 2px;
                // margin-top: 1em;
                margin-bottom: 0.5em;
                cursor: pointer;
                min-width: 87px;
            }
            .social-logo{
                display: flex;
                justify-content: left;
                img{
                    margin-left: 1em;
                    margin-top: 0.2em;
                    height: 24px;
                    width: 24px;
                    border-radius: 2px;
                    cursor: pointer;
                }
            }
            ul{
                list-style: none;
                text-decoration: none;
                padding: 0 0 0 10px;
    
                li{
                    padding: 0 0 4px 0px;
                    font-size: 12px;
                }
            }
            

            .footercontent-cls{
                margin-left: 1em;
                .store-cls{
                    // align-items: center;
                    // text-align: center;
                    color: #fff;
                    font-weight: bold;
                    font-size: 12px;
                }
                .whatsapp-details{
                    color: #FFFFFF;
                    font-weight: bold;
                    >p{
                        margin: 0.5em 0;
                        font-size: 12px;
                    }
                    .whatsapp-cls{
                        cursor: pointer;
                        margin: 0.5em 0;
                        font-size: 14px;
                        >img{
                            width: 6em;
                            height: 6em;
                        }
                    }
                }
            
                .store-link-apple-play{
                    display: flex;
                    font-weight: bold;
                    color: white;
                    cursor: pointer;
                    margin-left: -1em;
                    img{
                        width: 94px;
                        height: 31px;
                    }
                }

                >p{
                    color: white;
                    font-weight: bold;
                    margin-top: 0;
                    margin: 0.5em 0;

                    >p{
                        font-size: 12px;
                        cursor: pointer;
                        margin-left: -3em;
                    }

                }
               
                .address-cls{
                    display: flex;
                }
            }
        }
        .contactInfo-cls{
            color: #979797 ;
            font-family: 'Poppins', sans-serif;
            span{
                color: white;
                font-weight: bold;
            }
            .terms-cls{
                display: flex;
                justify-content: space-between;
                margin: 0.5em 0;
                >p{
                    font-size: 12px;
                    cursor: pointer;
                    margin-left: -3em;
                }
            }
            .address-cls{
                display: flex;
                align-items: baseline;
                .pi{
                    margin-top: 1em;
                }
                
                ul{
                    list-style: none;
                    padding: 0 0 0 20px;
                    margin: 0;
                    li{
                        padding: 0 0 10px 0px;
                        font-size: 14px;
                        cursor: pointer;
                        width: fit-content;
    
                    }
                    >p{
                        margin: 0.5em 0;
                        font-weight: bold;
                    }
                }
                
            }
            
        }
        .footercontent-cls {
            color: #979797 ;
            font-family: 'Poppins', sans-serif;
            .whatsapp-cls{
                cursor: pointer;
            }
            img{
                width: 10em;
                height: 10em;
            }
            span{
                color: white;
                font-weight: bold;
            }
            ul{
                list-style: none;
                text-decoration: underline;
                padding: 0px;
                // padding: 0 0 0 20px;
    
                li{
                    padding: 0 0 10px 0px;
                    font-size: 14px;
                    cursor: pointer;
                    width: fit-content;

                }
            }
            
        }
    
    }
}