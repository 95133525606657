.inputtext-content-section {
  display: flex;

  .inputtext-label {
    padding: 0.2em;
    > label {
      top: 20%;
      position: relative;
      padding: 0.5em;
      padding-left: 0;
      font-size: 12px;
      font-family: sans-serif;
      color:#00000099;
      font-weight: bold;

    }

    > span {
      color: red;
    }
  }

  .p-inputtext {
    &.p-disabled {
      background: rgb(239, 239, 239);
    }
  }

  .p-input-icon-right {
    position: relative;
    display: inline-block;
  }

  .p-input-icon-right > i {
    position: absolute;
    top: 50%;
    margin-top: -.5rem;
  }

  .p-input-icon-right > i:last-of-type {
    right: 0.5rem;
    color: #6c757d;
  }

  .p-input-icon-right > .p-inputtext {
    padding-right: 2rem;
  }

  .p-inputtext {
    margin: 0;
    padding-left: 1em !important;
  }

  .mobile-details-with-image{
    display: flex;
    width: fit-content;
    // position: absolute;
    // top: 2em;
    
    >p{
      position: relative;
      top: 3px;
    }
  }

  .p-input-icon-left{
    position: relative;
    display: inline-block;
    span{
      img{
        height: 30px;
        margin: 0 0.5em;
      }
      p{
        width: fit-content;
      }
    }
    
  }

  .mobile-input{
    padding-left: 5em !important;
    font-size: 16px;
  }
}

.textLabelCls {
  display: flex;
  flex-direction: row;
  flex: 1;

  .labelCls {
    display: flex;
    flex-direction: row;
    flex: 1;

    .labelTextcls {
      flex: 1;
      align-self: center;

      p {
        color: black;
        font-size: 12px;
      }
    }

    .astrickCls {
      align-self: center;
      margin-right: 5px;

      b {
        color: red;
      }
    }

    .divideCls {
      flex: 0.1;
      align-self: center;
    }

    .textInputCls {
      flex: 1;
      align-self: center;

      .p-inputtext {
        width: 130px;
      }
    }
  }

  .textInputCls {
    flex: 1;
    align-self: center;

    .p-inputtext {
      width: 150px;
      height: 28px;
      border-radius: 5px;
      border: 1px solid #b3afaf;
      box-shadow: 2px 0px 3px 1px #d4d4d4;
    }
  }
}

.formCls {
  display: flex;
  flex-direction: column;
  flex: 1;

  .labelCls {
    display: flex;
    flex-direction: row;
    flex: 1;

    .labelTextcls {
      align-self: center;

      p {
        color: black;
      }
    }

    .astrickCls {
      align-self: center;

      b {
        color: red;
      }
    }

    .divideCls {
      flex: 1;
      align-self: center;
      margin: 0 15px 0 25px;
    }
  }

  .textInputCls {
    flex: 1;
    align-self: flex-start;
    margin-top: -10px;

    .p-inputtext {
      width: 130px;
      height: 28px;
      border-radius: 5px;
      border: 1px solid #b3afaf;
      box-shadow: 2px 0px 3px 1px #d4d4d4;
    }
  }
}

.iconLabelCls {
  display: flex;
  flex-direction: row;
  flex: 1;

  .labelCls {
    display: flex;
    flex-direction: row;
    flex: 1;

    .labelTextcls {
      flex: 1;
      align-self: center;

      p {
        color: black;
        font-size: 12px;
      }
    }

    .astrickCls {
      align-self: center;
      margin-right: 5px;

      b {
        color: red;
      }
    }

    .divideCls {
      flex: 0.1;
      align-self: center;
    }

    .textInputCls {
      flex: 1;
      align-self: center;

      .p-inputtext {
        width: 115px;
        height: 28px;
        border-radius: 5px;
        border: 1px solid #b3afaf;
        box-shadow: 2px 0px 3px 1px #d4d4d4;
      }
    }
  }

  .textinputIcon {
    flex: 1;
    align-self: center;
  }
}
