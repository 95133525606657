.continue-btn {
    background-color: rgb(218, 81, 81);
    font-weight     : bold;
    width           : 8em;
    border-radius   : 0.5em;
    height          : 3em;
    border          : none;
    color           : #f7f4f4;


}

.previous-btn {
    background-color: rgb(218, 81, 81);
    font-weight     : bold;
    width           : 8em;
    border-radius   : 0.5em;
    height          : 3em;
    border          : none;
    color           : #f7f4f4;
}

.buttons-container {
    display        : flex;
    flex-direction : row;
    justify-content: space-between;
    position       : absolute;
    /* top         : 20%; */
    bottom         : 19.5%;
    right          : 5%;

    .p-button {
        background   : #E43137;
        box-shadow   : 0px 3px 6px #D62E3440;
        border       : 1px solid #E43137;
        border-radius: 10px;
        opacity      : 1;
        width        : 160px;
        height       : 40px;
    }

    .p-button-text-only .p-button-text {
        text-align : center;
        font-size  : 14px;
        font-weight: bold;
        font-family: sans-serif;
        color      : #FFFFFF;
        opacity    : 1;
    }
}