.form-control:focus {
  outline: 0 !important;
  border-color: initial;
  box-shadow: none;
}
/* .has-feedback .form-control1{
 width: 50%;
 padding-right: 72px;
} */
.radio-font {
  font-weight: 200;
}

h6 {
  position: relative;
  border: none;
  height: 1px;
  background: #dee2e6;
}

h6::before {
  content: "OR";
  display: inline-block;
  background: #dee2e6;
  font-weight: 700;
  font-size: 1.05rem;
  color: #999;
  border-radius: 30rem;
  padding: 0.2rem 2rem;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mdWidth {
  width: 1250px;
}

.right-vl {
  border-left: 1px solid #dee2e6;
  height: 300px;
  position: absolute;
  left: 70%;
  display: flex;
  /* margin-left: -2px; */
  top: 22%;
}

h5 {
  /* text-align: center; */
  font-weight: bold;
  padding-left: 5px;
}

.btn-close {
  color: green;
}

.rupee-txt {
  padding-left: 12px;
}

.rupee-img {
  position: relative;
  left: 17px;
  top: 2px;
  height: 14px;
  width: 10px;
  display: inline-block;
  background-image: url('http://i.stack.imgur.com/nGbfO.png');
}

.search-box-container input {
  padding: 5px 20px 5px 5px;
  
}

.search-box-container:after {
    content: "\f002";
    font-family: FontAwesome;
    margin-left: -25px;
    margin-right: 25px;
    
}
.card{
  border-color: green;
}
.buyer-details{
  width: 30%;
  margin-top: -23%;
  margin-left: 37%;
  margin-bottom: 14px;
  height: 40vh;
  /* z-index: 1100 !important; */
}
hr{
  border-top: 1px solid black;
  margin-bottom: 0px;
  margin-top: 0px;
}
.hr-existingBuyer{
  border-top: 1px solid black;
  margin-bottom: 3px;
  margin-top: 3px;
}

.flex-parent-element {
  display: flex;
  width: 70%;
}

.flex-child-element {
  flex: 1;
  margin: 10px;
}

.flex-child-element:first-child {
  margin-right: 20px;
}
/* .flex-child-element:second-child {
  margin-right: 20px;
} */

table {
  border-collapse: separate;
  border-spacing: 0 3px;
}
th,
td {
  height: 12px;
  text-align: center;
}
tr{
  height: 55px;
  text-align: center;
}
th{
  text-align: center;
}
.dataResult{
  margin-top: 5px;
  width: 25em;
  height: 7em;
  overflow: auto;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: white;
}
.dataResult::-webkit-scrollbar{
  display: none;
}
.dataResult .dataItem{
  width: 25em;
  height: 8em;
  text-align:center;
  color: black;
  /* display: flex;
  align-items:center; */
}
.dataShow:hover{
  background-color: lightgray;

}

.search {
  padding: 10px;
  font-size: 17px;
  border: 1px solid grey;
  float: left;
  width: 80%;
  background: #f1f1f1;
}

 .searchButton {
  float: left;
  width: 15%;
  padding: 10px;
  background: #5CB85C;
  color: white;
  font-size: 17px;
  border: 1px solid grey;
  border-left: none;
  cursor: pointer;
  border-radius: 2px;
}

.searchButton:hover {
  background: #3aa73a;
}

.search::after {
  content: "";
  clear: both;
  display: table;
}

table th{
  text-align: center;
}

.buyerTable{
  display: none;
}