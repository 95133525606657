.popup-cls{
    .buy-now-dialog,.cashandcarry-dialog,.setalert-dialog{
        .custom-dialog{
            .p-dialog{
                
                .p-component{
                    .p-dialog-enter-done{
                        width: 20vw !important;
                    }
                }
            }
            .p-dialog-mask{
                .p-dialog{
                    
                    .p-dialog-content {
                        padding: 0 !important;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;
                        background-color: #ffffff;
                        // background: linear-gradient(180deg, #DEDEDE 0%, #fff);
                    }
                    .p-dialog-titlebar{
                        .first-div{
                            display: flex;
                        }
                        .bell-icon{
                            width: 30px;
                            height: 25px;
                        margin-top: 0.5em;
                        margin-right: 0.2em;
                        }
                        // display: none;
                        font-family: 'Poppins', sans-serif;
                        font-size: 25px;
                        display: flex;
                        justify-content: center;
                        border: none !important;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                        background: linear-gradient(180deg, #DEDEDE 0%, #fff);
                        p{
                            font-size: 25px;
                            margin: 0;
                            font-weight: bold;
                            text-align: center;
                            color: #333333;
                            border: none;
                            margin-top: 0.25em;
                        }

                        .p-dialog-titlebar-icons{
                            display: none;
                        }
                    }
                    .p-dialog-footer{
                        background-color: #eff1f2;
                        display: none;
                            >div{
                                justify-content: flex-end;
                                margin-top     : -60px;
                            }
                    }
                }
            }
            .p-dialog-titlebar-icons {
                // display: block !important;
            }
        }
        
        .p-dialog-footer {
    
            .cancel {
                display: none;
            }
    
            .accept {
                display       : none;
            }
        }
    .buynow-cls {
        margin-top: 15px;
        .input-container {
            display: -ms-flexbox; /* IE10 */
            display: flex;
            width: 100%;
            .inputtext-content-section .p-inputtext{
                padding: 4px !important;
            }
          }
          
          .icon {
              margin-top: 3px;
            font-size: 14px;
              padding-left: 10px;
            color: black;
            text-align: center;
          }
        p {
            font-size: 16px;
            color: #707070;
            padding-left: 10px;
            margin: 0.5em 0;
        }
      
        .inputtext-content-section .p-inputtext{
            border: none !important;
        }
        .contact-us-scrollbar {
            >div {
                display: flex !important;;
                justify-content: space-around !important;
            }
        }
        .header-cls{
            .first-div,.second-div,.third-div{
                align-items: center;
                text-align: center;
                color: #515151;
            }
            .first-div{
                font-size: 25px;
                margin: .8em;
                font-weight: bold;
            }
            .third-div{
                margin: .8em 0;
                font-weight: bold;
                background-color: #f5f9f3;
                padding: 10px
            }
        }
    
        .message-us {
            // width: 50%;
    
            >p {
                font          : normal normal bold 22px/16px sans-serif;
                letter-spacing: 1px;
                color         : #122025;
            }
            .label-cls{
                align-items: center;
                text-align: center;
                background-color: #f5f9f3;
                color: #515151;
                font-family: 'Poppins', sans-serif;
                // font-size: 20px;

            }
             .btns-cls{
                display: flex;
                justify-content: center;
                >div{
                    margin: 0.5em 0em;
                    display: flex;
                    justify-content: center;
                    padding: 10px;
                    .cancel{
                       background-color: #707070;
                    }
                    .send{
                       background-color: #000000;
                    }
                 }
               
            }
        
            .names-div{
                display: grid;
                // margin-top: 1em;
                justify-content: center;
                .inputfield-cls {
                    margin-top: 1em;
                    box-shadow   : 0px 0px 2px 2px #0000001A;
                    border: 1px solid #0000001A;
                    border-radius: 1px;
                    height: 70px;
                    p {
                       
                        font-size  : 16px;
                        color      : #707070;
                        padding-left: 10px;
                        margin: 0.5em 0;
                    }
            
                    .input-cls {
                        .inputtext-content-section .p-inputtext {
                            margin       : 0;
                            width        : 260px;
                            // border-radius: 5px;
                            border       : none;
                            
                        }
                    }
                    
                   
                }
            }
            .button-content-section {
                display        : flex;
                justify-content: center;
                margin     : 1em 1em;
    
                .p-button {
                    color         : #ffffff;
                    border        : 1px solid #707070;
                    padding       : 4px 10px;
                    border-radius : 5px;
                    letter-spacing: 1px;
                    font-size     : 16px;
                    box-shadow    : 0px 0px 3px 2px #0000001A;
    
                }
            }
        }
    }
    }
    .status-pop-up{
        .custom-dialog .p-dialog{
            .p-dialog-titlebar{
                border-bottom: none;
                font-size: 20px;
                font-weight: bold;
                font-family: 'Poppins', sans-serif;
                text-align: center;
                >p{
                    margin: 0.5em;
                    align-items: center;
                    text-align: center;
                }
            }
            .p-dialog-titlebar-icons{
                display: none;
            }
            .p-dialog-titlebar-icon span{
                display: none;
            }
            .p-dialog-footer{
                background-color: #eff1f2;
                display: none;
                    >div{
                        justify-content: flex-end;
                        margin-top     : -60px;
                    }
            }
        }
        
        .btns-cls{
            display: flex;
            justify-content: center;
            >div{
                margin: 0.5em 0em;
                display: flex;
                justify-content: center;
                padding: 10px;
                .cancel{
                   background-color: #707070;
                }
                .send{
                   background-color: #000000;
                }
             }
             .button-content-section {
                display        : flex;
                justify-content: center;
                margin     : 1em 1em;
    
                .p-button {
                    color         : #ffffff;
                    border        : 1px solid #707070;
                    padding       : 4px 10px;
                    border-radius : 5px;
                    letter-spacing: 1px;
                    font-size     : 16px;
                    box-shadow    : 0px 0px 3px 2px #0000001A;
    
                }
            }
        }
       
    }
    .E-mandicash-dialog{
        .p-dialog-footer{
            background-color: #eff1f2;
            display: none;
                >div{
                    justify-content: flex-end;
                    margin-top     : -60px;
                }
        }
    }
.cashandcarry-dialog{
    .cashandcarry-cls {
        padding: 40px 50px 5px 50px !important;
        .input-container {
            display: -ms-flexbox; /* IE10 */
            display: flex;
            width: 100%;
            .inputtext-content-section .p-inputtext{
                padding: 4px !important;
            }
          }
          
          .icon {
              margin-top: 3.5px;
            font-size: 14px;
              padding-left: 10px;
            color: black;
            text-align: center;
          }
        p {
            font-size: 16px;
            color: #707070;
            padding-left: 10px;
            margin: 0.5em 0;
        }
        .inputtext-content-section .p-inputtext{
            border: none;
        }
        .header-cls{
            .first-div,.second-div,.third-div{
                align-items: center;
                text-align: center;
                color: #515151;
            }
            .first-div{
                font-size: 25px;
                margin: .8em;
                font-weight: bold;
            }
            .third-div{
                margin: .8em 0;
                font-weight: bold;
                background-color: #f5f9f3;
                padding: 10px
            }
        }
    .type-cls{
        display: flex;
        justify-content: space-evenly;
        .cls{
            background-color: #efefef;
            width: 30.3%;
            height: 3.5em;
            border-radius: 4px;
            border: 1px solid #BEBEBE;
            .item-type{
                padding-left: 10px;
                font-size: 12px;
                color: #000000;
                margin-top: 5px;
            }
            .label-type{
                font-size: 16px;
                padding-left: 10px;
                color: #707070;
                font-family: 'Poppins', sans-serif;
            }
    
        }
        
    }
        .message-us {
            // width: 50%;
    
            >p {
                font          : normal normal bold 22px/16px sans-serif;
                letter-spacing: 1px;
                color         : #122025;
            }
            .label-cls{
                align-items: center;
                text-align: center;
                background-color: #f5f9f3;
                padding: 10px;
                color: #646664;
                // font-size: 20px;
            }
             .btns-cls{
                >div{
                    display: flex;
                    justify-content: center;
                    padding: 10px;
                    .cancel{
                       background-color: #707070;
                    }
                    .send{
                       background-color: #000000;
                    }
                 }
            }
        
            .names-div{
                display: flex ;
                // margin-top: 1em;
                justify-content: space-evenly;
                .inputfield-cls {
                    margin-top: 1em;
                    box-shadow   : 0px 0px 2px 2px #0000001A;
                    border: 1px solid #0000001A;
                    border-radius: 1px;
                    height: 70px;
                    width: 260px;
                    p {
                       
                        font-size  : 16px;
                        color      : #707070;
                        padding-left: 10px;
                        margin: 0.5em 0;
                    }
            
                    .input-cls {
                        .inputtext-content-section .p-inputtext {
                            margin       : 0;
                            width        : 260px;
                            // border-radius: 5px;
                            border       : none;
                            
                        }
                    }
                    
                   
                }
            }
            .button-content-section {
                display        : flex;
                justify-content: center;
                margin     : 30px;
    
                .p-button {
                    color         : #ffffff;
                    border        : 1px solid #707070;
                    padding       : 4px 10px;
                    border-radius : 5px;
                    letter-spacing: 1px;
                    font-size     : 16px;
                    box-shadow    : 0px 0px 3px 2px #0000001A;
    
                }
            }
        }
        .financeneeded-cls{
            margin: 1em 1.5em;
            display: flex;
            color: #333333;
                label{
                    font-family: 'Poppins', sans-serif;
                    font-size: 17px;
                }
                div{
                    display: flex;
                    margin-left: 1em;
                    font-family: 'Poppins', sans-serif;
                    label{
                        padding: 1px 7px;
                        margin-top: 2px;
                    }
                }
        }
    }
}
.cashandcarryconfirm-dialog{
    .custom-dialog {
        
        .p-dialog-mask {
            .p-dialog {
                position: absolute;
                top: 15vh;
                .p-dialog-titlebar-icon{
                    color: #BD0019;
                    border: 3px solid #BD0019;
                    border-radius: 50%;
                    padding: 4px;
                    position: absolute;
                    right: 1em !important;
                }
                .p-dialog-footer{
                    display: none;
                }
                .p-dialog-titlebar{
                    text-align: center;
                    // font-size: 20px;
                    font-family: 'Poppins', sans-serif;
                    display: flex !important;
                    justify-content: center;
                    border: none !important;
                    border-top-left-radius: 10px !important;
                    border-top-right-radius: 10px !important;
                    div{
                        display: flex;
                        img{
                            width: 2em;
                            height: 2em;
                            margin-right: 1em;
                        }
                    }
                }
                .p-dialog-content{
                    font-size: 14px;
                    padding: 0px 12px;
                    border-top-left-radius: 0 !important;
                    border-top-right-radius: 0 !important;
                    border-bottom-left-radius: 10px !important;
                    border-bottom-right-radius: 10px !important;
                    background-color: #ffffff;
                    font-family: 'Poppins', sans-serif;
                    color: #333333;
                    

                    p{
                        // line-height: 25px !important;
                    }

                    
                }
            }
        }
    }
    .cashandcarryconfirm-cls{
        text-align: center;
        .Id-cls{
            font-weight: bold;
        }
    }
}
.setalert-dialog{
   
    .setalert-cls {
        // padding: 2em 0 !important;
        .contact-us-scrollbar {
            >div {
                display: flex !important;
                ;
                justify-content: space-around !important;
                ;
            }
        }
        .header-cls{
            .first-div,.second-div,.third-div{
                align-items: center;
                text-align: center;
                color: #515151;
            }
            .first-div{
                font-size: 25px;
                margin: .8em;
                font-weight: bold;
            }
            .third-div{
                margin: .8em 0;
                font-weight: bold;
                background-color: #f5f9f3;
                padding: 10px
            }
        }
    
        .message-us {
            // width: 50%;
    
            >p {
                font          : normal normal bold 22px/16px sans-serif;
                letter-spacing: 1px;
                color         : #122025;
            }
            .label-cls{
                align-items: center;
                text-align: center;
                padding: 10px;
                color: #000000;
                font-family: 'Poppins', sans-serif;
                // font-size: 20px;
            }
            .register-label-cls{
                align-items: center;
                text-align: center;
                background-color: #F6F9F3;
                padding: 10px;
                color: #000000;
                font-family: 'Poppins', sans-serif;
                // font-size: 20px;
                >span{
                    font-weight: bold;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
             .btns-cls{
                 >div{
                    display: flex;
                    justify-content: center;
                    padding: 10px;
                    .cancel{
                       background-color: #707070;
                    }
                    .send{
                       background-color: #000000;
                    }
                 }
                
            }
        
            .names-div{
                display: grid ;
                // margin-top: 1em;
                justify-content: center;
                .inputfield-cls {
                    .input-container {
                        display: -ms-flexbox; /* IE10 */
                        display: flex;
                        width: 100%;
                        .inputtext-content-section .p-inputtext{
                            padding-left: 4px !important;
                            border: none;
                        }
                      }
                      
                      .icon {
                          margin-top: 2.5px;
                         font-size: 14px;
                          padding-left: 10px;
                        color: black;
                        text-align: center;
                      }
                    margin-top: 1em;
                    box-shadow   : 0px 0px 2px 2px #0000001A;
                    border: 1px solid #0000001A;
                    border-radius: 1px;
                    height: 70px;
                    p {
                       
                        font-size  : 16px;
                        color      : #707070;
                        padding-left: 10px;
                        margin: 0.5em 0;
                    }
            
                    .input-cls {
                        .inputtext-content-section .p-inputtext {
                            margin       : 0;
                            width        : 260px;
                            // border-radius: 5px;
                            border       : none;
                            
                        }
                    }
                    
                   
                }
            }
            .button-content-section {
                display        : flex;
                justify-content: center;
                margin     : 1em 1em;
    
                .p-button {
                    color         : #ffffff;
                    border        : 1px solid #707070;
                    padding       : 4px 10px;
                    border-radius : 5px;
                    letter-spacing: 1px;
                    font-size     : 16px;
                    box-shadow    : 0px 0px 3px 2px #0000001A;
    
                }
            }
        }
    }
}
.emandi-icon-popUp{
    .heading-cls{
        p {
            font-size: 14px;
            color: black;
            padding-left: 10px;
            margin: 0.5em 0;
            font-family: 'Poppins', sans-serif;

        }
        >iframe{
            border: none;
            display: flex;
            align-items: center;
            border: none;
            border-radius: 4px;
        }
        margin: 1em;
        .header{
            display: flex;
            justify-content: space-between;
            >div{
                .user-name{
                    color: #000000;
                    // font-weight: bold;
                    font-size: 14px;
                    padding: 0 10px
                }
                p{
                    font-weight: bold;
                    font-size: 20px;
                    span{
                        color: #0f9d58;
                    }
                }
              
            }
        }
        .list-cls{
            display: flex;
            justify-content: space-between;
            .menu-cls{
                >div{
                    display: flex;
                    align-items: center;
                    .pi{
                        color: #0f9d58;
                    }
                }
            }
        }
        
            .button-content-section {
                display        : flex;
					justify-content: center;
					margin     : 1em 1em;
    
                .p-button {
                    color         : #ffffff;
                    background-color: #707070;
                    border        : 1px solid #707070;
                    padding       : 4px 10px;
                    border-radius : 5px;
                    letter-spacing: 1px;
                    font-size     : 16px;
                    box-shadow    : 0px 0px 3px 2px #0000001A;
    
                }
            }
        
    }
    .p-dialog-footer{
            background-color: #eff1f2;
            display: none;
                >div{
                    justify-content: flex-end;
                    margin-top     : -60px;
                }
        }
        .p-dialog-titlebar-icons{
            display: none;
        }
        .p-dialog-titlebar{
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            display: flex;
            justify-content: center;
            border: none !important;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            background: linear-gradient(180deg, #DEDEDE 0%, #fff);
            .p-dialog-title{
                >p{
                    margin: 0.5em;
                }
            }
            
        }
}
.E-mandicash-dialog{
    color: black;
    .heading-cls{
        p {
            font-size: 14px;
            color: black;
            padding-left: 10px;
            margin: 0.5em 0;
            font-family: 'Poppins', sans-serif;

        }
        >iframe{
            border: none;
            display: flex;
            align-items: center;
            border: none;
            border-radius: 4px;
        }
        margin: 1em;
        .header{
            display: flex;
            justify-content: space-between;
            >div{
                .user-name{
                    color: #000000;
                    // font-weight: bold;
                    font-size: 14px;
                    padding: 0 10px
                }
                p{
                    font-weight: bold;
                    font-size: 20px;
                    span{
                        color: #0f9d58;
                    }
                }
              
            }
        }
        .list-cls{
            display: flex;
            justify-content: space-between;
            .menu-cls{
                >div{
                    display: flex;
                    align-items: center;
                    .pi{
                        color: #0f9d58;
                    }
                }
            }
        }
        .continue-cls{
                    display: flex;
                    margin-top: 1em;
                    align-items: center;
                    width: 100%;
                    font-weight: bold;
                    color         : #ffffff;
                    border        : 1px solid #0f9d58;
                    background-color:#0f9d58;
                    padding       : 12px 10px;
                    border-radius : 5px;
                    letter-spacing: 1px;
                    font-size     : 16px;
                    cursor: pointer;
                    box-shadow    : 0px 0px 3px 2px #0000001A;
                    justify-content: center;
            .button-content-section {
                display        : flex;
                justify-content: center;
    
                .p-button {
                    width: 100%;
                    font-weight: bold;
                    color         : #ffffff;
                    border        : 1px solid #0f9d58;
                    background-color:#0f9d58;
                    border-radius : 5px;
                    letter-spacing: 1px;
                    font-size     : 16px;
    
                }
            }
        }
    }
   
}
}