.custom-silder{
    p{
        margin: 0;
        margin-bottom: 0.5em;
    }
    .p-slider{
        background: #dee2e6;
        border: 0 none;
        border-radius: 6px;
        width: 95%;
        height: 0.286rem;
    
        .p-slider-range {
            background: rgb(238, 213, 72);
        }

        .p-slider-handle{
            transition: none 0s ease 0s;
            height: 1.143rem;
            width: 1.143rem;
            background: #ffffff;
            border: 2px solid #6366F1;
            border-radius: 50%;
        }
    }
}