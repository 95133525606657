.loginScreen-cls {
    .container {
        display         : flex;
        height          : 85vh;
        background-color: #F5F8D3;
        border-radius   : 15px;
        box-shadow      : 0px 3px 30px #00000029;

        .bgText-cls {
            background: url("../../assets/images/graphic_web.png");
            background-repeat: no-repeat;
            background-position: bottom;
        }

        .store-link-apple-play{
            display: flex;

            img{
                height: 50px;
                cursor: pointer;
                border-radius: 5px;
                margin-left: 1em;
            }
        }

        .loginContainer-cls {
            display          : flex;
            flex-direction   : column;
            width            : 50%;
            // flex          : 1;

            .loginSubContainer-cls {
                flex-direction: column;
                background    : #FFFFFF;
                box-shadow    : 0px 3px 30px #00000029;
                border-radius : 10px;
                opacity       : 1;
                margin        : 10% 20% 12px 10%;

                .loginText-cls {
                    margin-left: 40px;

                    #text {
                        font-size  : 22px;
                        font-weight: bold;
                        font-family: sans-serif;
                        padding-top: 40px;
                        color      : #122025;

                        span {
                            font-size: 16px;

                        }
                    }

                    #subText {
                        font-weight   : bold;
                        font-family   : sans-serif;
                        letter-spacing: 1px;
                        font-size     : 11px;
                        margin-top    : -16px;
                    }
                }

                .userMobile-cls {
                    margin-left: 40px;
                    margin-top : 40px;

                    .mobileNo-cls {
                        display      : flex;
                        align-items  : center;
                        background   : #FFFFFF 0% 0% no-repeat padding-box;
                        box-shadow   : 1px 5px 5px #0000001A;
                        border-radius: 5px;
                        opacity      : 1;
                        height       : 34px;
                        max-width    : 16em;

                        .inputtext-content-section{
                            width: 95%;
                        }

                        input {
                            border   : none;
                            font-size: 14px;
                            outline  : none;
                            width    : 100%;
                            color    : #000;
                        }

                        p {
                            font-size  : 14px;
                            margin-left: 5px;
                            color      : #050E11;
                        }
                    }
                   
                }

                .name-cls {
                    margin-left: 40px;
                    margin-top : 20px;
                    .referalNo-cls {
                        display      : flex;
                        align-items  : center;
                        background   : #FFFFFF 0% 0% no-repeat padding-box;
                        box-shadow   : 1px 5px 5px #0000001A;
                        border-radius: 5px;
                        opacity      : 1;
                        height       : 34px;
                        max-width    : 16em;
                        margin-top: 1em;
                        .inputtext-content-section{
                            width: 95%;
                        }

                        input {
                            border   : none;
                            font-size: 14px;
                            outline  : none;
                            width    : 100%;
                            color    : #000;
                        }

                        p {
                            font-size  : 14px;
                            margin-left: 5px;
                            color      : #050E11;
                        }
                        
                    }
                    .referral-btn-cls{
                        margin-top: 1em;
                        width: 146px;
                        height: 40px;
                        background: #E43137 0% 0% no-repeat padding-box;
                        box-shadow: 0px 3px 6px #d62e3440;
                        border-radius: 10px;
                        border: none;
                        color: #fff;
                        letter-spacing: 1px;
                        font-size: 12px;
                        font-family: sans-serif;
                    }
                    .text-cls {
                        
                        p {
                            font-size    : 12px;
                            color        : #00000099;
                            font-family  : sans-serif;
                            font-weight  : bold;
                            margin-bottom: 5px;
                        }

                        .inputtext-content-section {
                            margin: 0;

                            input {
                                background   : #FFFFFF 0% 0% no-repeat padding-box;
                                box-shadow   : 0px 5px 30px #00000029;
                                border-radius: 5px;
                                opacity      : 1;
                                border       : none;
                                outline      : none;
                                height       : 20px;
                                height       : 2.8em;
                                border-radius: 10px;
                                border-color : #fff;
                            }
                        }
                    }
                }

                .verifyOTP-cls {
                    margin-left: 40px;
                    margin-top : 32px;

                    .otp-cls {
                        #sendOTP {
                            font-size    : 12px;
                            color        : #00000099;
                            font-family  : sans-serif;
                            font-weight  : bold;
                            margin-bottom: 5px;

                            span {
                                font-size      : 12px;
                                color          : #E43137;
                                font-family    : sans-serif;
                                letter-spacing : 1px;
                                text-decoration: underline;
                                cursor         : pointer;
                                margin-left    : 10px;
                            }
                        }

                        #resendOTP {
                            font-size  : 12px;
                            color      : #0000004C;
                            font-family: sans-serif;
                            font-weight: bold;
                            margin-top : 8px;

                            #resend {
                                text-decoration: underline;
                                color          : #E43137;
                                cursor         : pointer;
                            }

                        }

                        .inputOTP-content-section {
                            margin: -5px;

                            .p-inputtext {
                                background   : #ffffff 0% 0% no-repeat padding-box;
                                border       : 1px solid #d8d8d8;
                                border-radius: 7px;
                                height       : 35px;
                                width        : 2.5em;
                                box-shadow   : 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
                                text-align   : center;
                            }
                        }
                    }

                }

                .moreDetails-cls {
                    margin-top : 50px;
                    margin-left: 40px;

                    .contactTerms-cls {
                        #contactus {
                            font-size    : 12px;
                            color        : #00000099;
                            font-family  : sans-serif;
                            font-weight  : bold;
                            margin-bottom: 5px;

                            span {
                                font-size      : 12px;
                                color          : #E43137;
                                font-family    : sans-serif;
                                letter-spacing : 1px;
                                text-decoration: underline;
                                margin-left    : 2px;
                                cursor         : pointer;
                            }
                        }

                        #termnCond {
                            display    : flex;
                            font-size  : 12px;
                            color      : #00000099;
                            font-family: sans-serif;
                            font-weight: bold;
                            margin-top : -8px;
                            align-items: center;
                            margin-left: -3px;

                            span {
                                font-size      : 12px;
                                color          : #E43137;
                                font-family    : sans-serif;
                                letter-spacing : 1px;
                                text-decoration: underline;
                                margin-left    : 5px;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .submitButton-cls {
                    .button-cls {
                        margin-left: 40px;

                        .custom-btn-cls {
                            width         : 146px;
                            height        : 40px;
                            background    : #E43137 0% 0% no-repeat padding-box;
                            box-shadow    : 0px 3px 6px #D62E3440;
                            border-radius : 10px;
                            border        : none;
                            color         : #fff;
                            letter-spacing: 1px;
                            font-size     : 12px;
                            font-family   : sans-serif;
                        }
                    }
                }

                display: flex;

                .bgText-cls {
                    // flex: 1;
                    width: 50%;
                    .text-cls {
                        margin: 100px 95px 60px 95px;

                        .textHeader {

                            margin   : 20px 0px;
                            // display: flex;
                            // justify-content: center;

                            p {
                                font-size  : 40px;
                                font-weight: bold;
                            }

                            .store-link{
                                display: flex;
        
                                img{
                                    height: 50px;
                                }
                            }

                        }


                        .subHeader {
                            font-size: 1.2rem;
                            color: #313131;
                        }
                    }

                    .bgImage-cls {
                        img {
                            margin-left: 92px;
                            width      : 70vh;
                            height     : 30vh;
                        }
                    }
                }

                .loginPage-cls {
                    flex: 1;
                }
                
            }

            
        }
    }
}

.progreeBar-cls {
    display    : flex;
    align-items: center;
    margin     : 35px 0px 0px 50px;

    .mobileVer-cls {
        display        : flex;
        align-items    : center;
        padding        : 0px 10px;
        background     : #FFFFFF 0% 0% no-repeat padding-box;
        justify-content: center;
        opacity        : 1;
        width          : 170px;
        height         : 35px;
        justify-content: center;

        p {
            color    : #7DB851;
            font-size: 14px;
        }
    }

    .personalInfo-cls {
        display        : flex;
        align-items    : center;
        justify-content: center;
        height         : 35px;
        width          : 170px;
    }

    .kycInfo-cls {
        display        : flex;
        align-items    : center;
        justify-content: center;
        height         : 35px;
        width          : 170px;

        .img-cls {
            img {
                width : 12px;
                height: 14px;
            }
        }
    }


    .img-cls {
        margin-right: 10px;
        margin-top  : 3px;

        img {
            width : 18px;
            height: 18px;
        }
    }

    .labelText-cls {}

    .nextArrow-cls {
        width      : 80px;
        align-items: center;
        text-align : center;

        img {
            transform: rotateZ(270deg);
            width    : 12px;
            height   : 20px;
        }
    }

    // .mobileVer-cls{

    // }
    // .personalInfo-cls{

    // }
    // .kycInfo-cls{

    // }

}