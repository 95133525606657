.kycInfoBlock-cls {
    .load-mask-cls {
        height: 70vh !important;
        // position: initial !important;
    }
    .kycInfo-cls {
        margin: 2% 5%;
        .aadhaarGetOTP {
            display: flex;

            .aadhaarNumber-cls {
                .inputtext-content-section {
                    input {
                        box-shadow   : 0px 2px 5px #0000001A !important;
                        border-radius: 5px !important;
                        border       : none !important;
                        height       : 30px !important;
                        width        : 95% !important;
                    }
                }
            }

            .getOTPButton-cls {
                align-self: flex-end !important;

                .p-button {
                    border       : none !important;
                    box-shadow   : 0px 2px 5px #0000001A !important;
                    font-size    : 12px !important;
                    border-radius: 8px !important;
                    font-weight  : bold !important;
                    width        : 7.5em !important;
                    height       : 2.8em !important;
                }
            }
        }
        .upload-cls1 {
           

            margin-top   :3.2em;
            background   : white;
            margin-bottom: 10px;
            height       : 28px;
            border-radius: 5px;
            width        : 0px;
            display      : flex;
            .insideAadhaar-cls {
                position  : relative;
                right     : 15em;
                background: #e5e5e5;
            }
            img {
                position: relative;
                z-index : 10;
                top     : -28px;
                right   : -14em
            }

            .inputtext-content-section {
                background: #e5e5e5 !important;

                input {
                    opacity         : 1 !important;
                    width           : 176px !important;
                    height          : 28px !important;
                    top             : -34px !important;
                    position        : relative;
                    background-color: #fff;
                }
            }

            input {
                width   : 226px !important;
                height  : 24px !important;
                opacity : 0;
                top     : -49px !important;
                position: relative;
            }

            // img {
            //     position: relative;
            //     top     : -28px;
            //     right   : -17em;

            // }
            .uploadButton-cls {
                align-self : flex-end !important;
                margin-left: 10px;
                right      : 14.2em;
                position   : relative;
                top        : -33px;

                .p-button {
                    border       : none !important;
                    box-shadow   : 0px 2px 5px #0000001A !important;
                    font-size    : 12px !important;
                    border-radius: 8px !important;
                    font-weight  : bold !important;
                    width        : 7.5em !important;
                    height       : 2.5em !important;
                }
            }
        }
        .kycInfoDetails-cls {
            background   : #0000001A 0% 0% no-repeat padding-box;
            box-shadow   : 0px 3px 6px #0000001A;
            border-radius: 10px;
            opacity      : 1;
            height       : 50vh;

            .KYC-name-status{
                .custom-dialog{
                    .p-dialog-content{
                        background: #fff;
                        color: black;
                        padding:0 1em;
                    }

                    .p-dialog-footer{
                        > div {
                            display: flex;
                            justify-content: center;
                        }
                        .p-button{
                            color: #ffffff;
                            background: #E43137;
                            border: 1px solid #E43137;
                            padding: 0.1em 1em;
                            font-size: 1em;
                            transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
                            border-radius: 3px;
                        }
                    }
                }
            }

            .uploadImageDialog-cls {
                .p-dialog {
                    width: 35vw !important;
                }
                .custom-dialog .p-dialog-mask .p-dialog .p-dialog-content {
                    background   : #272727;
                    color        : #a3a3a3;
                    padding      : 10px 0px;
                    border-radius: 10px;

                }

                .custom-dialog .p-dialog-mask .p-dialog .p-dialog-titlebar {
                    display: none;
                }

                .custom-dialog .p-dialog-mask .p-dialog .p-dialog-footer {
                    display: none;
                }

                .header1-cls {
                    text-align : center;
                    font-size  : 14px;
                    font-family: sans-serif;
                    margin-bottom: -8px;
                }

                .header2-cls {
                    text-align : center;
                    font-size  : 14px;
                    font-family: sans-serif;
                }
            }

            .p-inputtext {
                margin       : 0;
                outline      : none;
                padding      : .25em;
                font-weight  : normal;
                width        : 260px;
                border       : none;
                background   : #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow   : 0px 2px 5px #0000001A;
                border-radius: 5px;
                opacity      : 1;
                height       : 24px;
            }

            .calendar-cls {
                .custom-calender {
                    p {
                        font-size    : 14px;
                        color        : #00000099;
                        margin-bottom: 5px;
                    }

                    img {
                        position: relative;
                        right   : 25px;
                    }
                }

                .custom-calender .p-calendar .p-calendar-button {
                    right: 136px;
                }
            }

            .block1-cls {

                display   : flex;
                flex-wrap : wrap;
                height    : 43vh;
                // overflow-x: auto;

                .multiDropdown-cls {
                    width  : 310px;
                    padding: 20px;

                    .renderLabels-cls {
                        justify-content: center;

                        .dropdown-cls {
                            .pan-cls {
                                margin-left: -10px;

                                .header-cls {
                                    display: flex;
                                }

                                .addPicture-cls {
                                    height: 9em;
                                    border: 1px dashed #707070;
                                    cursor: pointer;
                                    width : 40%;

                                    .uploadFile-cls {
                                        border-radius: 10px;
                                        opacity      : 1;
                                        width        : 327px;
                                        height       : 139px;

                                        h3 {
                                            color      : #ffffff;
                                            text-align : center;
                                            margin-top : 8px;
                                            padding    : 10px;
                                            font-size  : 16px;
                                            font-weight: bold;
                                            font-family: sans-serif;
                                        }
                                    }

                                    .upload-pan-image {
                                        margin-top: 2em;
                                    }

                                    .prePicture-cls {
                                        width       : 27px;
                                        height      : 24px;
                                        border      : 1px solid black;
                                        border-width: 1px 0px 0px 1px;
                                        cursor      : pointer;
                                        margin      : 0 auto;
                                    }

                                    .onClickPicture-cls {
                                        width     : 53px;
                                        height    : 35px;
                                        background: #ebf6e1 0% 0% no-repeat padding-box;
                                        border    : 1px solid #f5f5f5;
                                        margin    : 0 auto;
                                        cursor    : pointer;
                                    }

                                    .postPicture-cls {
                                        width       : 27px;
                                        height      : 24px;
                                        border      : 1px solid black;
                                        margin      : 0 auto;
                                        border-width: 0px 1px 1px 0px;
                                        cursor      : pointer;
                                    }

                                    .previewImage {
                                        img {
                                            width : 25px !important;
                                            height: 20px;
                                        }
                                    }
                                }

                                .panName-cls {
                                    margin-left: -8px;
                                }

                                .dob-cls {
                                    margin-left: 5px;
                                    margin-top : -5px;

                                    .p-datepicker {
                                        z-index: 1099;
                                        top    : -218px;
                                        left   : 0px;
                                    }

                                    .p-datepicker table {
                                        width          : 100%;
                                        font-size      : 0.7em;
                                        border-collapse: collapse;
                                        margin         : 0 0 .4em;
                                    }
                                }
                            }

                            p {
                                font-size    : 12px;
                                color        : #00000099;
                                margin-bottom: 5px;
                                font-weight  : bold;
                            }

                            .p-dropdown label.p-dropdown-label {
                                cursor       : pointer;
                                background   : #FFFFFF 0% 0% no-repeat padding-box;
                                border       : 1px solid #7070704D;
                                border-radius: 6px;
                                opacity      : 1;
                                width        : 260px;
                                text-overflow: ellipsis;
                                height       : 27px;
                                font-size    : 14px;
                            }

                            .p-dropdown-panel .p-dropdown-list {
                                padding        : 0.4em;
                                border         : 0 none;
                                margin         : 0;
                                list-style-type: none;
                                background     : #fff;
                                border         : 1px solid #7070704D;

                            }

                            .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
                                top        : 55%;
                                left       : 30%;
                                margin-top : -.5em;
                                margin-left: -.5em;
                                position   : absolute;
                            }

                            .p-dropdown-panel .p-dropdown-item {
                                font-size: 13px;

                            }
                        }
                    }
                }
            }

            .addRow-cls {
                margin-left   : 20px;
                margin-top    : 10px;
                font          : normal normal bold 12px/16px Roboto;
                letter-spacing: 0px;
                color         : #000000;
                opacity       : 1;
                display       : flex;
                align-items   : center;

                .add-id {
                    cursor: pointer;

                    .pi-plus {
                        border     : 1px solid #e43137;
                        background : #e43137;
                        color      : #ffffff;
                        font-weight: normal;
                    }

                }

            }
        }

        .personalInfoButtons-cls {
            display   : flex;
            margin-top: 40px;

            .skipButton {
                flex: 1;

                p {
                    font-size  : 11px;
                    font-weight: bold;
                    font-family: sans-serif;
                    color      : #00000099;
                    opacity    : 1;
                }

                .p-button {
                    background   : #FFFFFF;
                    box-shadow   : 0px 3px 6px #D62E3440;
                    border       : 1px solid #E43137;
                    border-radius: 10px;
                    opacity      : 1;
                    width        : 210px;
                    height       : 40px;
                }

                .p-button-text-only .p-button-text {
                    text-align : center;
                    font-size  : 14px;
                    font-weight: bold;
                    font-family: sans-serif;
                    color      : #E43137;
                    opacity    : 1;
                }
            }

            .continueButton {
                .p-button {
                    background   : #E43137;
                    box-shadow   : 0px 3px 6px #D62E3440;
                    border       : 1px solid #E43137;
                    border-radius: 10px;
                    opacity      : 1;
                    width        : 160px;
                    height       : 40px;
                }

                .p-button-text-only .p-button-text {
                    text-align    : center;
                    font-size     : 13px;
                    font-family   : sans-serif;
                    color         : #FFFFFF;
                    opacity       : 1;
                    letter-spacing: 1px;
                    font-weight   : bold;
                }
            }

        }
    }
}