.landing-page-ticker{
    width: 100%;
	background: #656565;
	// height: 100%;
    box-shadow: 0px 2px 30px #00000026;
    display: flex;
    .loader{
        color: white;
        font-size: 22px;
    }
    .radio-btns{
        display: flex;
        // margin: 0 1.25em;
        width: 31%;
        .left-radio-btns{
            border-right: 2px solid white;
            .spot-label{          
                color: white;
                font-size: 12px;
                padding: 4px 0px 0px 10px;
            }
        }
        .right-radio-btns{
            .future-label{
                color: white;
                font-size: 12px;
                padding: 4px 0px 0px 12px;
            }
            .radios-btns-cls{
                display: flex;
            }
        }
        .p-field-radiobutton{
            display: flex;
            width: fit-content;
            padding: 0.5em 0.5em;
            margin: auto;
            font-size: 14px;
            color: #fff;
            font-family: 'Poppins', sans-serif;
        
            .p-radiobutton{
                margin-top: 0.15em;
    
                .p-radiobutton-box{
                    border: 1.3px solid #000;
                    background-color: #fff;
            
                    .p-radiobutton-icon{
                        color: #000;
                        font-size: 0.6em;
                        margin-top: -.4em;
                        // margin-left: -.5em;
                    }
                }
            }
        }
    }

    
        
    .marquee-container{
        .ticker-warpper{
            width: fit-content;
            font-family: 'Poppins', sans-serif;
            padding: 0.5em 1em;
            color: #fff;
            display: flex;

            .ticker-header{
                margin-right: 0.5em;

                >p{
                    margin: 0;
                }

                .commodity-name{
                    font-weight: bold;
                    font-size: 16px;
                }

                
                .location{
                    font-size: 12px;

                }
            }
            .ticker-footer{
                text-align: right;
                >p{
                    margin: 0;
                }

                .source{
                    font-size: 16px;
                }

                .upDown{
                    font-size: 12px;
                }

                .pi{
                    margin-right: 0.5em;
                }

                .green{
                    color: #40e905;
                }

                .red{
                    color: #FF4848;
                }

                
            }
        }
        
    }

    .ticker-control{
        width: 100px;
        margin: auto;
        margin-left: 20px;

        .pi{
            cursor: pointer;
            color: #fff;
            margin-right: 7px;
            font-weight: normal;
        }
    }
}