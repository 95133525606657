.terms-cls {
    margin: 1em;
    .heading {
      display: flex;
      justify-content: center;
      text-decoration: underline;
      font-weight: bold;
      font-family: sans-serif;
    }
  
  }
  