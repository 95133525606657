.terms-cls {
    margin: 1em;
    .Declaration-cls{
        font-weight: bold;
    }
    .heading {
      display: flex;
      justify-content: center;
      text-decoration: underline;
      font-weight: bold;
      font-family: sans-serif;
    }
    .Cash_termback{
      color: black;
      text-decoration: none;
        }
    .label {
      font-weight: bold;
      .sub-label-cls {
        font-weight: bold;
        text-decoration: underline;
        text-transform: capitalize;
        margin-left: 1em;
      }
    }
    .Content {
      text-align: justify;
      font-size: 14px;
      font-family: sans-serif;
      padding: 10px;
      a {
          cursor: pointer;
          text-decoration: underline;
          color: blue;
      }
      .gmail {
        margin-left: 4em;
        a {
          cursor: pointer;
          text-decoration: underline;
          color: blue;
        }
      }
     
      .content-cls {
        display: flex;
        .sub-content {
          padding-left: 20px;
        }
        .italic-number {
          margin-left: 4em;
        }
        .italic-content {
          padding-left: 20px;
        }
      }
    }
  }
  