@media only screen and (max-width: 400px) {
    .commoditity-research-cls{
        .landing-page-ticker{
            display: none;
        }

        .header-cls{
            margin: 0.5em;
        }

        .menu-cls{

            .left-div{
                .dropdown-content-section{
                    width: 100%;
                }
            }

            .right-div{
                display: none;
            }
        }
        
    }
}