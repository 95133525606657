.checkboxComponentCls {
    display: flex;
    margin : 5px 0px;

    .label-cls {
        display: flex;
        .required-cls{
            b{
                color:red;
                margin-right: 5px;
            }
        }

        .labelText-cls {
            margin-top: -9px;

            p {
                font-size: 12px;
            }
        }
    }

    .verticalCheckCls {
        flex       : 1;

        .checkbox-cls {
            display    : flex !important;
            align-items: center !important;
            height     : 25px;

            .checkInput {
                margin-right: 5px;
                input{
                    box-shadow: 0px 0px 2px 0px #5d5d5d;
                    
                }
            }

            .label {
                p {
                    font-size: 13px;
                }
            }
        }
    }

    .horizontalCheckCls {
        display: flex;
      
        .checkbox-cls {
            display    : flex !important;
            align-items: center !important;
            height     : 25px;
            margin-left: 10px;
            flex-direction: row;

            .checkInput {
                margin-right: 2px;
                
            }
             .input{
                 
                border: 12px solid black;
             }
            .label {
                p {
                    font-size: 13px;
                }
            }
        }
    }
}