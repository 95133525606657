.cashandcarryarbitage-cls {
	font-family: "Poppins", sans-serif;
    .success-dialog{
		.p-dialog .p-dialog-titlebar-icon span{
			   display: block;
		   }
		   .p-dialog .p-dialog-titlebar-icon{
			   display: block;
		   }
			   .p-dialog{
				   position: absolute;
				   top: 15vh;
				   
			   }
			   .p-dialog-footer{
				   display: none;
			   }
			   .p-dialog .p-dialog-titlebar-icon{
				   color: #BD0019 ;
				   border: 3px solid #BD0019;
				   border-radius: 50%;
				   padding: 4px;
				   position: absolute;
				   right: 1em !important;
			   }
			   .custom-dialog .p-dialog-mask .p-dialog .p-dialog-titlebar{
				   text-align: center;
				   // font-size: 20px;
				   font-family: 'Poppins', sans-serif;
				   display: flex !important;
				   justify-content: center;
				   border: none !important;
				   border-top-left-radius: 10px !important;
				   border-top-right-radius: 10px !important;
				   div{
					   display: flex;
					   img{
						   width: 2em;
						   height: 2em;
						   margin-right: 1em;
					   }
				   }
				 
			   }
			   .custom-dialog .p-dialog-mask .p-dialog .p-dialog-content {
				   // padding: 0 !important;
				   font-size: 14px;
				   padding: 0px 12px;
				   border-top-left-radius: 0 !important;
				   border-top-right-radius: 0 !important;
				   border-bottom-left-radius: 10px !important;
				   border-bottom-right-radius: 10px !important;
				   background-color: #ffffff;
				   font-family: 'Poppins', sans-serif;
				   color: #333333;
			   }
			   .cashandcarryconfirm-cls{
				   text-align: center;
			   }
		   }

	.header-cls {
		display: flex;
		margin: 0.5em 3em;
		justify-content: start;
		img {
			width: 2em;
			height: 2em;
			// border: 2px solid bisque;
			border-radius: 50%;
			padding: 4px;
			box-shadow: 1px 1px 6px;
		}
		p {
			margin: 0.5em;
			font-size: 16px;
		}

		.pi-angle-left {
			margin: 0.5em 0em;
			font-size: 16px;
			box-shadow: 0px 0px 20px #00000029;
			border-radius: 50%;
			height: 1.5em;
			width: 1.5em;
			text-align: center;
			line-height: 1.5em;
			cursor: pointer;
		}
	}

	.customTable-cls{
		padding: 0 1.5em;

		.bids-activity-grid-cls{
			// box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		}

		.p-button{
			font-size: 14px;
		font-family: 'Poppins', sans-serif;
			padding: 6px;
			border: navajowhite;
			background: black;
			color: white;
		}
		 .p-datatable .p-datatable-wrapper .p-datatable-tbody .p-datatable-row, .custom-table .p-datatable .p-datatable-scrollable-wrapper .p-datatable-tbody .p-datatable-row, .custom-table .p-datatable-scrollable-body .p-datatable-wrapper .p-datatable-tbody .p-datatable-row, .custom-table .p-datatable-scrollable-body .p-datatable-scrollable-wrapper .p-datatable-tbody .p-datatable-row{
			background: none;
		}
	
		.p-datatable-resizable 
		{
			.p-datatable-thead>tr>th
			{
				// text-align: center;
				padding: 0.25em 1.3em;
				color: #FFFFFF;
				height: 4em;
				 font-family: 'Poppins', sans-serif;
			}
			.p-datatable-tfoot>tr>td, .p-datatable-tbody>tr>td{
				color: black;
				// text-align: center;
				height: 4em;
				font-family: 'Poppins', sans-serif;
				padding: 0.25em 1.8em;

			}
			.p-datatable-scrollable-header{
				background-color: #707070 ;
			}

		}
	}
}
