@font-face {
    font-family: MetropolisMedium;
    src: url('Metropolis-Medium.otf')
}

@font-face {
    font-family: MetropolisBold;
    src: url('Metropolis-Bold.otf')
}

@font-face {
    font-family: MetropolisRegular;
    src: url('Metropolis-Regular.otf')
}
.carousel-body {
    display: flex;
    justify-content: center;
    margin-top: 5%;
}

.carousel-body  .card {
    display: flex;
    align-items: center;
    height: 280px !important;
    width: 87% !important;
    border: none;
    margin-left: 0%;
    padding: unset;
    margin: unset;
}
.carousel {
    height: 100%;
}
.carousel-inner {
    height: 100%;
}
.hr-line{
    width: 26%;
  height: 1px;
  background: #F9DB24;
  border: none;
  opacity: 1 !important;
}
@font-face {
    font-family: MetropolisMedium;
    src: url('Metropolis-Medium.otf')
}

@font-face {
    font-family: MetropolisBold;
    src: url('Metropolis-Bold.otf')
}

@font-face {
    font-family: MetropolisRegular;
    src: url('Metropolis-Regular.otf')
} 


.carousel-css{
    font-family: MetropolisMedium;
    font-size: '40px';
}

.btn-css-1 {
    border-radius: 100px;
    width: 43px;
    height: 43px;
    border-radius: 30px !important;
    position: relative;
    
}

.img-css-3 {
    position: absolute;
    z-index: 10;
    transform: rotate(180deg);
    width: 0.57% !important;
    margin-top: 15px;
    margin-left: 10px;
    border-radius: 40px;
}
 
.btn-css-2 {
    border-radius: 100px;
    width: 26px;
    height: 26px;
    border-radius: 50px !important;
    position: relative;   
}

.img-css-1 {
  position: absolute;
  z-index: 10;
  width: 76% !important
}

.img-css-2 {
    position: absolute;
    z-index: 10;
    transform: rotate(180deg);
    width: 76% !important
}

/* .fa {
    font-size: large;
} */

.img-css-3 {
    position: absolute;
    z-index: 1;
    transform: rotate(180deg);
    width: 0.57% !important;
    margin-top: 6px;
    margin-left: 10px;
    border-radius: 40px;
}

.button-right-arrow {
    margin-left: 25px;
    margin-bottom: 20px;
}

.carousel-css-1 {
    font-weight: 400 !important;
}

.formtext-css-1 {
    font-weight: 300 !important;
}

.carousel-text-css-2 {
    font-family: MetropolisRegular;
    font-size:medium;
}

.card-img {     
    width: fit-content;
}

.carousel-control-next, .carousel-control-prev {
    opacity: 1 !important;
    z-index: 1 !important;
    color: #f5f5f7 !important;
    width: 1% !important;
}

.carousel-body .carouselText {
    /* text-shadow: 2px 0px 12px rgba(92, 138, 152, 0.075); */
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #FFFFFF;
    font-weight: 600;
    font-size: 22px;
}
.carousel-body .carousel-caption{
    width: 100%;
    position: absolute;
    left: 0;
    cursor: pointer;
    height: 100%;
    top: 8px;
    display: block !important;
}
.carousel-body .carousel-text {
    /* text-shadow: 2px 0px 12px rgba(92, 138, 152, 0.075); */
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #f5f5f7 !important;
    
}
.carousel-text{
    font-size: 3rem;
    margin-bottom: 1rem;
}

.i-css {
    margin-left: 19px;
    color: #f5f5f7 !important;
    /* padding-left: 20px !important; */
}

.carousel-body .containerrr{
    position: absolute;
    height: 55px;
    background: #fff3a9d1;
    /* background: #edd95fd1; */
    border-radius: 0px 0px 12px 12px;
    width: 100%;
    margin-bottom: 10px;
    top: 205px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 12rem;
}
.containerInnerDiv{
    display: flex;
    justify-content: space-evenly;
}
.carousel-body .carousel-caption .formText{
    color: #2F2F2F;
    font-weight: 600;
    font-size: 16px;
}
.carousel-item-next, .carousel-item-prev, .carousel-item.active {
    height: 100%;
}
.backgroundImage{
    height: 100%;
    object-fit: cover;
    border-radius: 14px;
}
/* .icon-e{
    color: '#04B23D';
    text-shadow:"2px 2px 5px #000";
    font-family:"Monotype Corsiva";
    font-weight:"bold";
    font-size:"24pt";
    margin-right:"7px";
    display:"inline-block"; 
} */
.instant-text{
    font-size: 18px;
    font-family: MetropolisMedium;
}
.yellow-hr{
    height: '2px' !important ;
}
.yellow-ecash{
    font-size: '30px';
    color: '#FAD02C';
}
#carouselExampleInterval .formText {
    font-weight: 500;
    margin-top: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media only screen and (max-width: 7680px) and (min-width: 5747px){
    .backgroundImage {
        border-radius: 55px;
    }
    .carousel-body .card {
        height: 92rem !important;
    }
    .carousel-body .carousel-caption {
        top: 41px;
    }
    .icon-e{
        font-family: MetropolisBold;
        font-size: 70pt !important;
    }
    .instant-text {
        margin-bottom: 4rem;
        font-size: 6rem !important;
    }
    .carouselText{
        margin-top: 50px;
    }
    .carousel-text {
        font-size: 9rem;
    }
    .carousel-body .carouselText {
        font-size: 79px;
    }
    .btn-css-2 {
        border-radius: 100px;
        width: 90px;
        height: 90px;
    }
    .btn-css-1 {
        border-radius: 100px;
        width: 129px;
        height: 129px;
        border-radius: 62px !important;
    }
    .img-css-3 {
        margin-top: 35px;
        margin-left: 34px;
    }
    .carousel-body .containerrr {
        position: absolute;
        height: 207px;
        top: 56rem;
    }
    .carousel .formText img {
        width: auto;
        height: 6rem;
    }
    .carousel-body .carousel-caption .formText {
        font-size: 50px;
    }
}
@media only screen and (max-width: 5746px) and (min-width: 5465px){
    .backgroundImage {
        border-radius: 55px;
    }
    .carousel-body .card {
        height: 92rem !important;
    }
    .carousel-body .carousel-caption {
        top: 41px;
    }
    .icon-e{
        font-size: 66pt !important;
        font-family: MetropolisBold;
    }
    .instant-text {
        margin-bottom: 4rem;
        font-size: 6rem !important;
    }
    .carouselText{
        margin-top: 50px;
    }
    .carousel-text {
        font-size: 9rem;
    }
    .carousel-body .carouselText {
        font-size: 79px;
    }
    .btn-css-2 {
        border-radius: 100px;
        width: 90px;
        height: 90px;
    }
    .btn-css-1 {
        border-radius: 100px;
        width: 129px;
        height: 129px;
        border-radius: 62px !important;
    }
    .img-css-3 {
        margin-top: 35px;
        margin-left: 34px;
    }
    .carousel-body .containerrr {
        position: absolute;
        height: 207px;
        top: 56rem;
    }
    .carousel .formText img {
        width: auto;
        height: 6rem;
    }
    .carousel-body .carousel-caption .formText {
        font-size: 50px;
    }
}
@media only screen and (max-width: 5464px) and (min-width: 4099px){
    .backgroundImage {
        border-radius: 49px;
    }
    .carousel-body .card {
        height: 92rem !important;
    }
    .carousel-body .carousel-caption {
        top: 41px;
    }
    .icon-e{
        font-size: 68pt !important;
        font-family: MetropolisBold;
    }
    .instant-text {
        margin-bottom: 4rem;
        font-size: 6rem !important;
    }
    .carouselText{
        margin-top: 50px;
    }
    .carousel-text {
        font-size: 9rem;
    }
    .carousel-body .carouselText {
        font-size: 79px;
    }
    .btn-css-2 {
        border-radius: 100px;
        width: 90px;
        height: 90px;
    }
    .btn-css-1 {
        border-radius: 100px;
        width: 129px;
        height: 129px;
        border-radius: 62px !important;
    }
    .img-css-3 {
        margin-top: 35px;
        margin-left: 34px;
    }
    .carousel-body .containerrr {
        position: absolute;
        height: 207px;
        top: 56rem;
    }
    .carousel .formText img {
        width: auto;
        height: 6rem;
    }
    .carousel-body .carousel-caption .formText {
        font-size: 50px;
    }
}
@media only screen and (max-width: 4098px) and (min-width: 3842px){
    .backgroundImage {
        border-radius: 45px;
    }
    .carousel-body .card {
        height: 92rem !important;
    }
    .carousel-body .carousel-caption {
        top: 41px;
    }
    .icon-e{
        font-size: 61pt !important;
        margin-right: 8px !important;
        font-family: MetropolisBold;
    }
    .instant-text {
        margin-bottom: 4rem;
        font-size: 6rem !important;
    }
    .carouselText{
        margin-top: 50px;
    }
    .carousel-text {
        font-size: 9rem;
    }
    .carousel-body .carouselText {
        font-size: 79px;
    }
    .btn-css-2 {
        border-radius: 100px;
        width: 90px;
        height: 90px;
    }
    .btn-css-1 {
        border-radius: 100px;
        width: 129px;
        height: 129px;
        border-radius: 62px !important;
    }
    .img-css-3 {
        margin-top: 35px;
        margin-left: 34px;
    }
    .carousel-body .containerrr {
        position: absolute;
        height: 207px;
        top: 56rem;
    }
    .carousel .formText img {
        width: auto;
        height: 6rem;
    }
    .carousel-body .carousel-caption .formText {
        font-size: 50px;
    }
}
@media only screen and (max-width: 3841px) and (min-width: 2561px){
    .backgroundImage {
        border-radius: 41px;
    }
    .hr-line{
        width: 30%;
    }
    .carousel-body{
        margin-top: 7%;
    }
    .carousel-body .card{
        height: 800px !important;
    }
    .carousel-body .carousel-caption{
        top: 60px;
    }
    .carousel-text{
        font-size: 11rem;
        margin-bottom: 1rem;
        margin-top: 20px;
    }
    .icon-e{
        font-size: 67pt !important;
        margin-right: 10px !important;
        font-family: MetropolisBold;
    }
    /* .yellow-hr{
        width: '40%';
        background: '#FDE96C';
        height: '2px';
        border: '1px solid #FDE96C';
    } */
    .carousel-body .carouselText {
        font-size: 70px;
        margin-top: 30px;
    }
    .instant-text{
        font-size: 54px !important;
        margin-bottom: 43px;
        margin-top: 0px !important;
    }
    .carousel-body .containerrr{
        margin-top: 40px;
        height: 140px;
        top: 563px;
        border-radius: 0px 0px 40px 40px;
    }
    .carousel-body .carousel-caption .formText{
        font-size: 50px;
    }
    #carouselExampleInterval .formText img{
        width: 62px !important;
    }
    .yellow-ecash{
        font-size: 76px;
    }
    .center-right-circle{
        --bs-btn-padding-y: 4.25rem;
        --bs-btn-padding-x: 4.5rem;
    }
    .img-css-3{
        margin-top: 39px;
        margin-left: 37px;
    }
    .btn-css-2{
        width: 88px;
        height: 88px;
    }
    .btn-css-1 {
        width: 141px;
        height: 141px;
    }
    .btn-css-1{
        border-radius: 72px !important;
    }
    .img-css-3 {
        position: absolute;
        z-index: 10;
        transform: rotate(180deg);
        width: 0.57% !important;
        margin-top: 37px;
        margin-left: 34px;
        border-radius: 40px;
    }
}
@media only screen and (max-width: 3072px) and (min-width: 2733px){
    .carousel-body .carousel-caption .formText {
        font-size: 39px;
    }
}
@media only screen and (max-width: 2732px) and (min-width: 2561px){
    .icon-e {
        font-size: 43pt !important;
        margin-right: 8px !important;    
        font-family: MetropolisBold;
    }
    .carousel-body .card {
        height: 610px !important;
    }
    .carousel-body .carousel-caption {
        top: 10px;
    }
    .instant-text {
        font-size: 41px !important;
        margin-bottom: 34px;
        margin-top: -10px !important;
    }
    .carousel-text {
        font-size: 7rem;
    }
    .carousel-body .carouselText {
        font-size: 49px;
        margin-top: 24px;
    }
    .yellow-ecash {
        font-size: 59px;
    }
    .btn-css-2 {
        width: 72px;
        height: 68px;
    }
    .img-css-3 {
        margin-top: 34px;
        margin-left: 32px;
    }
    .carousel-body .containerrr {
        top: 432px;
    }
    .carousel-body .carousel-caption .formText {
        font-size: 34px;
    }
    #carouselExampleInterval .formText img {
        width: 48px !important;
    }
    .btn-css-2 {
        width: 65px;
        height: 63px;
    }
    .btn-css-1 {
        width: 93px;
        height: 93px;
    }
}
@media only screen and (max-width: 2560px) and (min-width: 1921px){
    .backgroundImage {
        border-radius: 31px;
    }
    .hr-line{
        width: 32%;
    }
    .carousel-body .card {
        height: 594px !important;
    }
    .carousel-text {
        font-size: 7rem;
        margin-bottom: 0rem;
    }
    .icon-e {
        font-size: 44pt!important;
        margin-right: 7px!important;
        font-family: MetropolisBold;
    }
    .instant-text {
        font-size: 42px !important;
        margin-bottom: 3rem;
    }
    .carousel-body .carouselText {
        font-size: 54px;
        margin-top: 2rem;
    }
    .carousel-body .containerrr {
        margin-top: 39px;
        height: 105px;
        top: 389px;
        border-radius: 0px 0px 28px 28px;
    }
    .carousel-body .carousel-caption .formText {
        font-size: 31px;
    }
    .carousel-body .carousel-caption {
        top: 62px;
    }
    .btn-css-2, .btn-css-1 {
        width: 60px;
        height: 60px;
    }
    .img-css-3 {
        position: absolute;
        z-index: 10;
        transform: rotate(180deg);
        width: 0.57% !important;
        margin-top: 26px;
        margin-left: 25px;
        border-radius: 40px;
    }
    .containerInnerDiv .formText img {
        width: 35px !important;
    }
}
@media only screen and (max-width: 2300px) and (min-width: 2049px){
    .carousel-body {
        margin-top: 3%;
    }
    .carousel-body .card {
        height: 516px !important;
    }
    .carousel-body .carousel-caption {
        top: 24px;
    }
    .carousel-text {
        font-size: 5rem;
        margin-bottom: 0rem;
    }
    .instant-text {
        font-size: 35px !important;
        margin-bottom: 2rem !important;
    }
    .carousel-body .carouselText {
        font-size: 44px;
        margin-top: 0rem;
    }
    .btn-css-2{
        width: 51px;
        height: 51px;
    }
    .img-css-3 {
        margin-top: 21px;
        margin-left: 23px;
    }
    .carousel-body .carousel-caption .formText {
        font-size: 27px;
    }
    .carousel-body .containerrr {
        top: 357px;
    }
    .containerInnerDiv .formText img {
        width: 32px !important;
    }
}
@media only screen and (max-width: 2048px) and (min-width: 1601px){
    .backgroundImage {
        border-radius: 27px;
    }
    .hr-line{
        width: 34%;
    }
    .carousel-body .card {
        height: 501px !important;
    }
    .carousel-text {
        font-size: 6rem;
        /* margin-bottom: 2rem; */
        margin-top: 2rem;
    }
    .carousel-body .carousel-caption {
        top: 25px;
    }
    .instant-text {
        font-size: 37px !important;
        margin-top: 0px !important;
    }
    .carousel-body .carouselText {
        font-size: 45px;
    }
    .carousel-body .containerrr {
        margin-top: 40px;
        height: 88px;
        top: 348px;
        border-radius: 0px 0px 17px 19px;
    }
    .carousel-body .carousel-caption .formText {
        font-size: 28px;
    }
    .btn-css-1{
        width: 90px;
        height: 90px;
        border-radius: 50% !important;
    }
    .btn-css-2 {
        width: 43px;
        height: 43px;
    }
    .img-css-3 {
        position: absolute;
        z-index: 10;
        transform: rotate(180deg);
        width: 0.57% !important;
        margin-top: 22px;
        margin-left: 17px;
        border-radius: 40px;
    }
    #carouselExampleInterval .formText img {
        width: 35px !important;
    }
}
@media only screen and (max-width: 1920px) and (min-width: 1601px){
    .icon-e{
        font-size: 31pt!important;
        margin-right: 6px!important;
    font-family: MetropolisBold;
    }
    .hr-line{
        width: 30%;
    }
    .carousel-body .card {
        height: 429px !important;
    }
    .carousel-body .carousel-caption {
        top: 2px;
    }
    .carousel-text {
        font-size: 5rem;
        margin-bottom: 25px;
    }
    .instant-text {
        font-size: 30px !important;
    }
    .carousel-body .carouselText {
        font-size: 39px;
        margin-top: 12px;
    }
    .carousel-body .containerrr {
        margin-top: 40px;
        height: 76px;
        top: 311px;
        border-radius: 0px 0px 27px 25px;
    }
    .carousel-body .carousel-caption .formText {
        font-size: 27px;
    }
    .carousel-body .carousel-caption .formText img{
        width: 22px !important;
    }
    .btn-css-2 {
        width: 34px;
        height: 34px;
    }
    .btn-css-1 {
        width: 63px;
        height: 63px;
    }
    .img-css-3 {
        position: absolute;
        z-index: 10;
        transform: rotate(180deg);
        width: 0.57% !important;
        margin-top: 18px;
        margin-left: 14px;
        border-radius: 40px;
    }
}
@media only screen and (max-width: 1822px) and (min-width:1709px){
    .carousel-body .carousel-caption .formText {
        font-size: 21px;
    }
    #carouselExampleInterval .formText img {
        width: 27px !important;
    }
    .carousel-body .containerrr {
        top: 317px;
    }
    .btn-css-1 {
        width: 56px;
        height: 56px;
    }
}
@media only screen and (max-width: 1708px) and (min-width:1681px){
    .carousel-body .carousel-caption .formText {
        font-size: 21px;
    }
    #carouselExampleInterval .formText img {
        width: 27px !important;
    }
    .carousel-body .containerrr {
        top: 317px;
    }
    .btn-css-1 {
        width: 56px;
        height: 56px;
    }
}
@media only screen and (max-width: 1680px) and (min-width: 1601px){
    .icon-e {
        font-size: 31pt!important;
        margin-right: 5px!important;    
        font-family: MetropolisBold;
    }
    .hr-line{
        width: 33%;
    }
    .carousel-body .carousel-caption .formText {
        font-size: 23px;
    }
    .form .formBG .benefits .mx-5 img {
        width: 155px;
    }
    .btn-css-2 {
        width: 34px;
        height: 34px;
    }
    .img-css-3 {
        position: absolute;
        z-index: 10;
        transform: rotate(180deg);
        width: 0.57% !important;
        margin-top: 18px;
        margin-left: 14px;
        border-radius: 40px;
    }
    #carouselExampleInterval .formText img {
        width: 27px !important;
    }
}
@media only screen and (max-width: 1600px) and (min-width: 1441px){
    .icon-e{
        font-size: 25pt !important;
        margin-right: 5px !important;
        font-family: MetropolisBold;
    }
    .carousel-body .card {
        height: 306px !important;
    }
    .carousel-body .carousel-caption {
        top: 22px;
    }
    .carousel-text {
        font-size: 4rem;
        margin-bottom: 1rem;
    }
    .instant-text {
        font-size: 27px !important;
    }
    .carousel-body .carouselText {
        font-size: 27px;
    }
    .carousel-body .containerrr {
        top: 110px;
    }
    .btn-css-2 {
        width: 32px;
        height: 32px;
    }
    .img-css-3 {
        position: absolute;
        z-index: 10;
        transform: rotate(180deg);
        width: 0.57% !important;
        margin-top: 9px;
        margin-left: 12px;
        border-radius: 40px;
    }
    .carousel-body .carousel-caption .formText {
        font-size: 21px;
    }
    #carouselExampleInterval .formText img {
        width: 25px !important;
    }
}
@media only screen and (max-width: 1440px) and (min-width: 1281px){
    .icon-e{
        font-size: 21pt !important;
        margin-right: 5px !important;
        font-family: MetropolisBold;
    }
    .hr-line{
        width: 24%;
    }
    .carousel-body .containerrr {
        top: 98px;
    }
    .carousel-body .carousel-caption .formText {
        font-size: 19px;
    }
    #carouselExampleInterval .formText img {
        width: 21px !important;
    }
    #carouselExampleInterval .formText {
        margin-top: 13px;
    }
    .carousel-text {
        margin-bottom: 0rem;
        margin-top: 1rem;
    }
    .instant-text {
        margin-bottom: 17px !important;
    }
    .carousel-body .carouselText{
        margin-top: 6px;
    }
}

@media only screen and (max-width: 1280px){
    .icon-e{
        font-size: 18pt !important;
        margin-right: 5px !important;
        font-family: MetropolisBold;
    }
    .carousel.carousel-slider .control-arrow {
        top: -34px;
    }
    .carousel-body .containerrr {
        top: 98px;
    }
    .carousel-body .carousel-caption .formText {
        font-size: 16px;
    }
    #carouselExampleInterval .formText img {
        width: 21px !important;
    }
    #carouselExampleInterval .formText {
        margin-top: 13px;
    }
    .carousel-text {
        margin-bottom: 0rem;
        margin-top: 1rem;
    }
    .instant-text {
        margin-bottom: 19px !important;
    }
    .carousel-body .carouselText{
        margin-top: 6px;
    }
}
@media only screen and (max-width: 1153px) {
    .img-css-3 {
        margin-top: 9px;
        margin-left: 10px;
    }
}

@media only screen and (max-width: 1024px) {
    .hr-line{
        width: 33%;
    }
    .carousel-body .carousel-caption .formText {
        font-size: 13px;
    }
    .carousel-body .card {
        margin-left: 0%;
    }
    .img-css-3 {
        margin-top: 8px;
        margin-left: 11px;
    }
}
@media only screen and (max-width: 952px) {
    .img-css-3 {
        margin-top: 9px;
        margin-left: 11px;
        width: 0.7% !important;
    }
}
@media only screen and (max-width: 680px) {
    .img-css-3 {
        margin-top: 9px;
        margin-left: 11px;
        width: 0.9% !important;
    }
}
@media only screen and (max-width: 628px) {
    .carousel-body .carousel-caption {
        display: flex !important;
        flex-direction: column;
        align-items: center;
    }
    .instant-text {
        width: 70%;
    }
    .carousel-text {
        margin-top: 0rem;
    }
    .carousel-body .carousel-css-1{
        width: 81%;
    }
    .carousel-body .carousel-css-1>br{
        display: none;
    }
}
@media only screen and (max-width: 500px) {
    .containerInnerDiv {
        flex-wrap: wrap;
     }
  
     .containerInnerDiv > div {
        flex-basis: 50%;
     }
     #carouselExampleInterval .formText {
        font-weight: 500;
        margin-top: 5px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    #carouselExampleInterval .formText {
        margin-top: 0px;
        margin-bottom: 0px;
        text-align: left;
        margin-left: 12px;
    }
    #carouselExampleInterval .formText img {
        width: 9px !important;
    }
     .containerInnerDiv > div:nth-child(1) {
        order: 1;
     }
  
     .containerInnerDiv > div:nth-child(2) {
        order: 3;
     }
  
     .containerInnerDiv > div:nth-child(3) {
        order: 2;
     }
  
     .containerInnerDiv > div:nth-child(4) {
        order: 4;
     }
     .img-css-3 {
        margin-top: 8px;
        margin-left: 11px;
        width: 1.3% !important;
    }
}
@media only screen and (max-width: 479px) {
    .instant-text {
        font-size: 15px;
        margin-bottom: 10px !important;
    }
    .carousel-body .carouselText {
        font-size: 16px;
        margin-bottom: 10px !important;
        margin-top: 1px;
    }
    .carousel-body .containerrr {
        top: 70px;
    }
    .carousel-body .card {
        display: flex;
        align-items: center;
        height: 250px !important;
    } 
    .img-css-3 {
        margin-top: 7px;
        margin-left: 11px;
        width: 1.5% !important;
    }
}
@media only screen and (max-width: 357px){
    .img-css-3 {
        margin-top: 7px;
        margin-left: 11px;
        width: 2% !important;
    }
}
@media only screen and (max-width: 338px){
    .carousel-body .carouselText {
        font-size: 13px;
        margin-bottom: 10px !important;
        margin-top: 1px;
    }
    .instant-text {
        font-size: 11px;
    }
    .carousel-text {
        margin-bottom: 2rem;
    }
}
@media only screen and (max-width: 272px){
    .img-css-3 {
        margin-top: 7px;
        margin-left: 11px;
        width: 2.5% !important;
    }
}