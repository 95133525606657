.dob-cls {
	p {
		font-size    : 12px;
		font-weight  : bold;
		color        : #00000099;
		margin-left  : 3px;
		margin-bottom: 7px;
	}
}

.custom-calender {
	display       : flex !important;
	flex-direction: row;
	align-items   : center;



	.p-calendar .p-calendar-button {
		background   : #009d45;
		border       : none;
		border-radius: 10px;
		right        : -8px;
		outline-style: none !important;
		opacity      : 0;
		z-index      : 100;
	}

	.p-calendar.p-calendar-w-btn input {
		box-shadow   : 0px 3px 6px #4b652e4d;
		border       : 1px solid #7070704d;
		height       : 28px;
		border-radius: 10px;
		font-weight  : bold;

	}

	.p-button-icon-only .p-button-icon-left {
		color: #ffffff;
	}

	.p-datepicker {
		padding      : 20px;
		border       : 1px solid #7070704d;
		box-shadow   : 0px 3px 6px #00000029;
		border       : 0.5px solid #d2d2d2;
		border-radius: 4px;
		background   : #fff;
		// top: -429px !important;

		td {
			cursor: pointer;
			span{
				padding: 0.7em 0.8em;
				&:hover{
					background-color: #009d45;
					border-radius: 50%;
					color: white;
				}
			}
			
		}
		tr{
			height: 34px;
		}
	}

	img {
		position: relative;
	}

	.p-datepicker-calendar-container{
		.p-disabled{
			background: #d8d8d8;
		}
	}
	
}