.viewmoreauctions-cls{
    .filter-list{
        display: flex;
        // justify-content: space-between;
        margin: 1em;
        >div{
            width: 23%;
        }
        .p-dropdown-items{
            text-transform: none;
        }
        .dropdown-content-section {
            margin: 0 1em;
            height: 2em;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: bold;

            
            // .p-inputwrapper-focus{
            //     background: #FFD900;
            //     border-radius: 0;
            // }
            .p-dropdown label.p-dropdown-label{
                font-weight: bold;
            }
            .p-dropdown{
                width: 75%;

                .p-dropdown-panel{
                    background: #FFD900;
                }
                .p-dropdown label.p-dropdown-label{
                    text-transform: capitalize;
                }
            }
        }

        .custom-silder{
            display: flex;
            position: relative;
            bottom: 0.8em;

            .silder-qty{
                padding-top: 1.1em;
                margin-right: 0.7em;
                font-weight: bold;
            }

            .slider-range-details, p{
                margin: 0;
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
            }

            .rc-slider{
                // margin: 0.5em 1em;

                .rc-slider-handle{
                    border: solid 4px #FFD900;
                    background-color: black;
                }

                .rc-slider-track{
                    background-color: #FFD900;
                }
            }
        }
        
    }
    .header-cls{
        display: flex;
        margin: 0.5em 3em;
        justify-content: start;
            img{
                width: 2em;
                height: 2em;
                // border: 2px solid bisque;
                border-radius: 50%;
                padding: 4px;
                box-shadow: 1px 1px 6px;
            }
            p{
                margin: 0.8em;
                font-size: 16px;
            }
            // p{
            //     margin: 0.5em;
            //     font-size: 16px;
            // }
            
            .pi-angle-left{
                margin: 0.5em 0em;
                font-size: 16px;
                box-shadow: 0px 0px 20px #00000029;
                border-radius: 50%;
                height: 1.5em;
                width: 1.5em;
                text-align: center;
                line-height: 1.5em;
                cursor: pointer;
            }
    }
    .customTable-cls{
        padding: 0 1.5em;
        
        .p-button{
            padding: 6px;
            border: navajowhite;
            background: #FFD900;
            color: black;
            font-weight: bold;
            border-radius: 5px;
        }

        .p-datatable .p-datatable-wrapper .p-datatable-tbody .p-datatable-row, .custom-table .p-datatable .p-datatable-scrollable-wrapper .p-datatable-tbody .p-datatable-row, .custom-table .p-datatable-scrollable-body .p-datatable-wrapper .p-datatable-tbody .p-datatable-row, .custom-table .p-datatable-scrollable-body .p-datatable-scrollable-wrapper .p-datatable-tbody .p-datatable-row{
            background: none;
        }
                
        .p-datatable-resizable {
            .p-datatable-thead>tr>th{
                text-align: center;
                color: #d4d4d4;
                height: 4em;
            }

            .p-datatable-tfoot>tr>td, .p-datatable-tbody>tr>td{
                color: black;
                text-align: center;
                height: 4em;
            }
                
            .p-datatable-scrollable-header{
                background-color: #707070 ;
            }
        }
    }
}