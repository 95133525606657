.small-box {
	width: 5px;
	height: 30px;
	background-color: #FDE96C;
	box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
}

@font-face {
    font-family: MetropolisRegular;
    src: url('Metropolis-Regular.otf')
}

@font-face {
    font-family: MetropolisMedium;
    src: url('Metropolis-Medium.otf')
}

@font-face {
    font-family: MetropolisBold;
    src: url('Metropolis-Bold.otf')
}

@font-face {
    font-family: MetropolisExtraLight;
    src: url('Metropolis-ExtraLight.otf')
}
.titleReadFullNews .mt-4 p , .titleReadFullNews .mt-4 h4{
    font-family: MetropolisRegular !important;
}
.emandi-news-css{
    font-family: MetropolisRegular;
    margin-top: 6rem!important;
    // margin-bottom: 5rem;
    display: flex;
    justify-content: space-between;
    width: 84%;
}

.p-css {
	padding-top: 2px;
	color: 'black' !important;
	font-family: MetropolisMedium;
  
  }

.news-section-css {
    font-family: MetropolisMedium;
    display: flex;
    justify-content: space-between;
    width: 87%;
    margin-left: 7%;
}

.yellow {
	border-top: 3px solid #FDE96C;
}

.boxText {
	color: #757575 !important;
}

.smallBox {
	margin-left: 10%;
	margin-right: auto;
	display: flex;
	flex-direction: row;
}

.newsCard {
	width: 17% !important;
	box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
	border-radius: 12px;
	.fullNews{
		cursor: pointer;
		color: #04B23D

	}
}

@media (min-width: 950px) {
	.row1-container {
		display: flex;
		// justify-content: space-evenly;
		align-items: center;
	}
}

.emandiNewsSectionn .mx-3 , .emandiNewsSectionn .p-css{
    margin-bottom: 0px !important;
}
.newEmandiNews {
	display: flex;
	justify-content: center;
	flex-direction: column;

	.container {
		width: 80%;

		.row1-container {
			display: flex;
			align-items: center;
			justify-content: space-around;

			.newsCard{
				width: 24% !important;
				
					.fullNews{
						cursor: pointer;
					}
				
			}
		}

		.smallBox {
			margin-left: 0;
		}

		.backbtn {
			cursor: pointer;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			/* identical to box height, or 143% */

			letter-spacing: -0.01em;

			/* Text/Dark */

			color: #2F2F2F;
		}
	}
}

.i-css-2 {
    float: left;
    position: absolute;
    opacity: 0.5;
    left: 702px;
    top: 8px;
    z-index: 1000;
    background-color: #ffffff;
    padding: 5px;
    padding-top: 10px;
    padding-left: 8px;
    color: #000000;
    // z-index: 1000;
    // background-color: #ffffff;
    // padding: 5px;
    // padding-top: 13px;
    // padding-left: 10px;
    // color: #000000;
    // padding-left: 6px !important;
	margin-left: 273px !important;
    margin-top: 126px !important;
} 

.input-css-3 {
    border: 1px solid #DDDDDD;
    padding-left: 20px;
    // width: 200px;
    // height: 200px;
    position: relative;
    font-family: MetropolisLight !important;
    margin-left: 12px;
}

.input-css-3 .placeholder {
 
    font-family: MetropolisExtraLight !important;
}

.input-box {
    display: flex;
    align-items: center;
    // width: 100px;
    // max-width: 320px;
    background: #fff;
    border: 1px solid #a0a0a0;
    border-radius: 4px;
    padding-left: 0.5rem;
    overflow: hidden;
    font-family: sans-serif;
    // margin-left: 700px !important;
  }
  
  .input-box .prefix {
    font-weight: 300;
    font-size: 14px;
    color: #999;
  }
  
  .input-box input {
    flex-grow: 1;
    font-size: 14px;
    background: #fff;
    border: none;
    outline: none;
    padding: 0.5rem;
  }
  
  .input-box:focus-within {
    border-color: #777;
  }

  .newEmandiNews{
    .container{
        .css-p-1-css {
            font-family: MetropolisBold!important;
        }
        .row1-container{
            .card{
            width: 30%;
                border: none;
            .views{
                color: gray;
            }
            
            }

        }
    }
}

@font-face {
    font-family: MetropolisMedium;
    src: url('Metropolis-Medium.otf')
}
@font-face {
    font-family: MetropolisRegular;
    src: url('Metropolis-Regular.otf')
}

@font-face {
    font-family: MetropolisExtraLight;
    src: url('Metropolis-ExtraLight.otf')
}

@font-face {
    font-family: MetropolisLight;
    src: url('Metropolis-Light.otf')
}


@font-face {
    font-family: MetropolisBold;
    src: url('Metropolis-Bold.otf')
}

.container-css {
    font-family: MetropolisRegular;
}

.p-css-1 {
    font-family: MetropolisRegular;
}

.input-css-1 {
    font-family: MetropolisExtraLight;
}

.css-p-1 {
    font-family: MetropolisRegular !important;
}
.headerEmandiNews{
    display: flex;
    align-items: center;
}
.newEmandiNewsViewAll{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20% !important;
}
// .newEmandiNewsViewAll  .container .smallBox .container-css-1{
//     display: flex;
// }
// .newEmandiNewsViewAll  .container .smallBox{
//     display: flex;
//     justify-content: space-between;
//     width: 92%;
//     margin-left: 0px !important;
// }
// .newEmandiNewsViewAll  .container .smallBox .input-box{
//     margin-left: 0px !important;
// }
.newEmandiNewsViewAll .container .container-css-1{
    display: flex;
    justify-content: space-between;
}
.newEmandiNewsViewAll .container .container-css-1 .input-box{
    margin-left: 0px !important;
    // margin-right: 3%;
}
.newEmandiNewsViewAll .container .container-css-1 .smallBox{
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.newEmandiNewsViewAll .yellow{
        width: 14px;
        height: 107px !important;
}
.newEmandiNewsViewAll  .container .row{
    width: 100%;
    margin-bottom: 25rem;   
    justify-content: space-between;
    margin: 0px !important;
}

.newEmandiNewsViewAll  .container .row .box{
    width: 32% !important;
    height: auto !important;
    padding: 71px !important;
}
.newEmandiNewsViewAll  .container .row .box img{
    width: 100% !important;
    height: 30rem !important;
}
// .newEmandiNewsViewAll .container{
//     width: 81%;
//   }
  .card-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 35px;
  }
  .newEmandiNewsViewAll .mx-3 {
    font-family: 'MetropolisRegular';
}
.newEmandiNewsViewAll .container .row .box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.newEmandiNewsViewAll .container .row .box .card-body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.newEmandiNewsViewAll .container .row .box .card-body .fullNews{
    font-size: 17pt !important;
}
@media only screen and (max-width: 7680px) and (min-width: 5747px){
    .emandiNewsSectionn .mx-3, .emandiNewsSectionn .p-css{
        margin-right: 6.5rem !important;
        margin-left: 5.5rem !important;
        font-size: 68px !important;
    }
    .newEmandiNewsViewAll .container .row .box .card-body {
        font-size: 71px;
    }
    .fullNews{
        font-size: 26pt !important;
    }
    .emandiNewsSectionn .p-css{
        font-size: 40px !important;
    }
    .titleReadFullNews .mt-4 h4 {
        font-size: 57px !important;
        width: 50vw;
        margin-bottom: 9rem;
    }
    .titleReadFullNews .mt-4 img {
        width: 60% !important;
        height: 44vh;
    }
    .titleReadFullNews .mt-4 p {
        width: 60% !important;
        font-size: 49px;
        margin-top: 13rem !important;
        margin-bottom: 30rem;
    }
    // .newEmandiNewsViewAll .container {
    //     width: 89%;
    // }
}
@media only screen and (max-width: 5746px) and (min-width: 5465px){
    .emandiNewsSectionn .mx-3, .emandiNewsSectionn .p-css{
        margin-right: 6.5rem !important;
        margin-left: 5.5rem !important;
        font-size: 68px !important;
    }
    .emandi-news-css {
        width: 86%;
    }
    .newEmandiNewsViewAll {
        margin-left: 1.5%;
    }
    // .newEmandiNewsViewAll .container {
    //     width: 89%;
    //     font-size: 34px;
    // }
    .emandiNewsSectionn .p-css{
        font-size: 40px !important;
    }
    .titleReadFullNews .mt-4 h4 {
        font-size: 57px !important;
        width: 50vw;
        margin-bottom: 9rem;
    }
    .titleReadFullNews .mt-4 img {
        width: 60% !important;
        height: 44vh;
    }
    .titleReadFullNews .mt-4 p {
        width: 60% !important;
        font-size: 49px;
        margin-top: 13rem !important;
        margin-bottom: 30rem;
    }
}
@media only screen and (max-width: 5464px) and (min-width: 4099px){
    // .newEmandiNewsViewAll .container{
    //     width: 87%;
    //     margin: 0px;
    //     margin-left: 9%;
    //     overflow: hidden;
    //   }
    .newEmandiNewsViewAll .container .row .box {
        width: 32.86% !important;
        height: auto !important;
        padding: 71px !important;
        margin: 0px;
    }
      .emandi-news-css {
        width: 86%;
    }
      .newEmandiNewsViewAll {
        margin-left: -2%;
    }
    //   .newEmandiNewsViewAll .container .backbtn{
    //         font-size: 52px;
    //         margin-top: 73px;
    //         margin-left: 0%;
    //         margin-bottom: 66px;
    //   }
      .newEmandiNewsViewAll .mx-3 {
        margin-left: 4rem !important;
    }
    //   .newEmandiNewsViewAll .container .smallBox {
    //     display: flex;
    //     justify-content: space-between;
    //     width: 90%;
    //     margin-left: 0px !important;
    // }
    //   .newEmandiNewsViewAll  .container .smallBox {
    //     margin-left: 1%;
    // }
    // .newEmandiNewsViewAll  .container .smallBox .container-css-1 .yellow{
    //     width: 14px;
    //     height: 107px;
    // }
    // .newEmandiNewsViewAll  .container .smallBox .container-css-1 .p-css-2{
    //     font-size: 55pt !important;
    //     margin-left: 36px !important;
    // }
   
    .newEmandiNewsViewAll  .container .row .box img{
        height: 36rem !important;
    }
    
    .newEmandiNewsViewAll  .container .row .box .card-body .card-text{
        font-size: 44px !important;
        margin-bottom: 19px;
        margin-top: 52px;
    }
    .newEmandiNewsViewAll  .container .row .box .card-body .fullNews{
        font-size: 38px !important;
    }
    .titleReadFullNews{
        margin-left: 6% !important;
    }
    .titleReadFullNews .mt-4 h4{
        font-size: 57px !important;
        // margin-left: 4.5%;
        width: 50vw;
        margin-bottom: 9rem;
    }
    .titleReadFullNews .mt-4 img{
        width: 60% !important;
        height: 44vh;
    }
    .titleReadFullNews .mt-4 p{
        width: 60% !important;
        font-size: 49px;
        margin-top: 13rem !important;
        margin-bottom: 30rem;
    }
    .homeback{
        margin-left: 6% !important;
    }
.emandiNewsSectionn .mx-3{
    margin-right: 6.5rem !important;
    margin-left: 5.5rem !important;
    font-size: 68px !important;
}
.emandiNewsSectionn .p-css{
    font-size: 40px !important;
}
.mt-xl-5 {
    margin-bottom: 16rem;
}
}
@media only screen and (max-width: 4098px) and (min-width: 3842px){
    // .newEmandiNewsViewAll .container{
    //     width: 87%;
    //     margin: 0px;
    //     margin-left: 9%;
    //     overflow: hidden;
    //   }
    .newEmandiNewsViewAll .container .row .box {
        width: 32% !important;
        height: auto !important;
        padding: 71px !important;
    }
      .newEmandiNewsViewAll {
        margin-left: -2%;
    }
    //   .newEmandiNewsViewAll .container .backbtn{
    //         font-size: 50px;
    //         margin-top: 73px;
    //         margin-left: 0%;
    //         margin-bottom: 66px;
    //   }
      .newEmandiNewsViewAll .mx-3 {
        margin-left: 4rem !important;
    }
    //   .newEmandiNewsViewAll .container .smallBox {
    //     display: flex;
    //     justify-content: space-between;
    //     width: 90%;
    //     margin-left: 0px !important;
    // }
    //   .newEmandiNewsViewAll  .container .smallBox {
    //     margin-left: 1%;
    // }
    // .newEmandiNewsViewAll  .container .smallBox .container-css-1 .yellow{
    //     width: 14px;
    //     height: 107px;
    // }
    // .newEmandiNewsViewAll  .container .smallBox .container-css-1 .p-css-2{
    //     font-size: 55pt !important;
    //     margin-left: 36px !important;
    // }
   
    .newEmandiNewsViewAll  .container .row .box img{
        height: 36rem !important;
    }
    .newEmandiNewsViewAll  .container .row .box .card-body .card-text{
        font-size: 44px !important;
        margin-bottom: 19px;
        margin-top: 52px;
    }
    .newEmandiNewsViewAll  .container .row .box .card-body .fullNews{
        font-size: 38px !important;
    }
    .titleReadFullNews{
        margin-left: 10% !important;
    }
    .titleReadFullNews .mt-4 h4{
        font-size: 57px !important;
        // margin-left: 4.5%;
        width: 50vw;
        margin-bottom: 9rem;
    }
    .titleReadFullNews .mt-4 img{
        width: 60% !important;
        height: 44vh;
    }
    .titleReadFullNews .mt-4 p{
        width: 60% !important;
        font-size: 49px;
        margin-top: 13rem !important;
        margin-bottom: 30rem;
    }
    .homeback{
        margin-left: 10% !important;
    }
.emandiNewsSectionn .mx-3{
    margin-right: 5.5rem !important;
    margin-left: 4.5rem !important;
    font-size: 60px !important;
}
.emandiNewsSectionn .p-css{
    font-size: 40px !important;
}
.mt-xl-5 {
    margin-bottom: 4rem;
}
.newEmandiNews .mt-xl-5{
    margin-bottom: 3rem;
}

}
@media only screen and (max-width: 3841px) and (min-width: 2561px){
    .newEmandiNewsViewAll .container .row .box {
        width: 32% !important;
        height: auto !important;
        padding: 71px !important;
    }
    .emandi-news-css .small-box {
        width: 17px;
        height: 98px;
      }
      .newEmandiNewsViewAll {
        margin-left: -2%;
    }
      .emandi-news-css .mx-3{
        margin-right: 4.5rem !important;
        margin-left: 4.5rem !important;
        font-size: 62px !important;
      }
      .emandi-news-css .mx-1{
        font-size: 48px !important;
      }
      .emandi-news-css{
        margin-bottom: 21rem;
      }
      .news-section-css .box img{
        height: 29rem !important;
        margin-bottom: 78px !important;
      }
    //   .newEmandiNewsViewAll .container{
    //     width: 86%;
    //     margin: 0px;
    //     margin-left: 9%;
    //     overflow: hidden;
    //   }
    //   .newEmandiNewsViewAll .container .backbtn{
    //         font-size: 50px;
    //         margin-top: 73px;
    //         margin-left: 0%;
    //         margin-bottom: 66px;
    //   }
      .newEmandiNewsViewAll .mx-3 {
        margin-left: 4rem !important;
    }
    //   .newEmandiNewsViewAll .container .smallBox {
    //     display: flex;
    //     justify-content: space-between;
    //     width: 90%;
    //     margin-left: 0px !important;
    // }
    //   .newEmandiNewsViewAll  .container .smallBox {
    //     margin-left: 1%;
    // }
    // .newEmandiNewsViewAll  .container .smallBox .container-css-1 .yellow{
    //     width: 14px;
    //     height: 107px;
    // }
    // .newEmandiNewsViewAll  .container .smallBox .container-css-1 .p-css-2{
    //     font-size: 55pt !important;
    //     margin-left: 36px !important;
    // }
   
    .newEmandiNewsViewAll  .container .row .box img{
        height: 36rem !important;
    }
    .newEmandiNewsViewAll  .container .row .box .card-body .card-text{
        font-size: 44px !important;
        margin-bottom: 49px;
        margin-top: 52px;
    }
    .newEmandiNewsViewAll  .container .row .box .card-body .fullNews{
        font-size: 38px !important;
    }
    .titleReadFullNews{
        margin-left: 6% !important;
    }
    .titleReadFullNews .mt-4 h4{
        font-size: 57px !important;
        // margin-left: 4.5%;
        width: 53vw;
        margin-bottom: 9rem;
    }
    .titleReadFullNews .mt-4 img{
        width: 60% !important;
        height: 44vh;
    }
    .titleReadFullNews .mt-4 p{
        width: 60% !important;
        font-size: 40px;
        margin-top: 13rem !important;
        margin-bottom: 30rem;
    }
    .homeback{
        margin-left: 6% !important;
    }
}
@media only screen and (max-width: 2732px) and (min-width: 2561px){
    .emandi-news-css .small-box {
        width: 13px;
        height: 67px;
    }
    .newEmandiNewsViewAll .container .row .box {
        width: 32% !important;
        height: auto !important;
        padding: 42px !important;
    }
    .newEmandiNewsViewAll .container .row .box img {
        height: 29rem !important;
    }
    .newEmandiNewsViewAll .container .row .box .card-body .card-text {
        font-size: 34px !important;
        margin-bottom: 32px;
        margin-top: 26px;
    }
    .newEmandiNewsViewAll .container .row .box .card-body .fullNews {
        font-size: 29px !important;
    }
    .emandi-news-css .mx-3 {
        margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
    font-size: 47px !important;
    }
    .emandi-news-css .mx-1 {
        font-size: 33px !important;
    }
    .news-section-css .box img {
        height: 26rem !important;
        margin-bottom: 59px !important;
    }
}
@media only screen and (max-width: 2560px) and (min-width: 1441px){ 
    .emandiTVViewAll {
        font-size: 48px !important;
    }
    
    .newEmandiNewsViewAll {
        margin-left: 2%;
    }
    .newEmandiNewsViewAll .container .container-css-1 .input-box {
        margin-left: 0px !important;
        // margin-right: 2%;
    }
    .newEmandiNewsViewAll .container .row .box {
        width: 32% !important;
        height: auto !important;
        padding: 44px !important;
    }
    .emandi-news-css .small-box {
        width: 12px;
        height: 66px;
    }
    .emandi-news-css .mx-3 {
        margin-right: 2.5rem !important;
        margin-left: 3.5rem !important;
        font-size: 43px !important;
    }
    .emandi-news-css .mx-1 {
        font-size: 30px !important;
    }
    .emandi-news-css {
        margin-bottom: 7rem;
        width: 86% !important;
    }
    .news-section-css .box img {
        height: 23rem !important;
        margin-bottom: 35px !important;
    }
    .news-section-css .card-text {
        font-size: 28px !important;
        margin-bottom: 21px;
    }
    .news-section-css .fullNews {
        font-size: 26px !important;
    }
    // .newEmandiNewsViewAll .container {
    //     width: 89%;
    // }
    // .newEmandiNewsViewAll .container .backbtn{
    //     font-size: 30px;
    //     margin-top: 49px;
    //     margin-bottom: 25px;
    // }
    // .newEmandiNewsViewAll  .container .smallBox {
    //     margin-left: 9% !important;
    //     width: 82%;
    // }
    // .newEmandiNewsViewAll .mx-3 {
    //     margin-left: 2rem !important;
    // }
    .newEmandiNewsViewAll .yellow{
        width: 13px;
    height: 82px !important;
    }
    // .newEmandiNewsViewAll  .container .smallBox .container-css-1 .p-css-2{
    //     font-size: 46pt !important;
    //     margin-left: 28px !important;
    // }
    .newEmandiNewsViewAll  .container .row .box img{
        height: 25rem !important;
    }
    .newEmandiNewsViewAll  .container .row .box .card-body .card-text{
        font-size: 31px !important;
        margin-bottom: 21px;
        margin-top: 20px;
    }
    .newEmandiNewsViewAll  .container .row .box .card-body .fullNews{
        font-size: 28px !important;
    }
    .titleReadFullNews{
        margin-left: 6% !important;
    }
    .titleReadFullNews .mt-4 h4{
        font-size: 47px !important;
        width: 60%;
        margin-bottom: 7rem;
    }
    .titleReadFullNews .mt-4 img{
        width: 60% !important;
        height: 44vh;
    }
    .titleReadFullNews .mt-4 p{
        width: 60% !important;
        font-size: 35px;
        margin-top: 8rem !important;
        margin-bottom: 30rem;
    }
    .homeback{
        margin-left: 6% !important;
    }
}
@media only screen and (max-width: 2300px) and (min-width: 2049px){
    .newEmandiNewsViewAll .container .row .box {
        width: 33% !important;
        margin: 0px;
    }
    .newEmandiNewsViewAll .container .container-css-1 .input-box {
        margin-left: 0px !important;
        // margin-right: 3%;
    }
    .newEmandiNewsViewAll {
        margin-left: 7%;
    }
    // .newEmandiNewsViewAll .container {
    //     width: 100%;
    // }
    .newEmandiNewsViewAll .container .container-css-1 {
        display: flex;
        justify-content: space-between;
    }
    .emandi-news-css .small-box {
        width: 10px;
        height: 70px;
    }
    .emandi-news-css .mx-3 {
        margin-right: 2rem !important;
        margin-left: 3rem !important;
        font-size: 39px !important;
    }
    .emandi-news-css .mx-1 {
        font-size: 27px !important;
    }
    .news-section-css .box img {
        height: 20rem !important;
        margin-bottom: 35px !important;
    }
    .news-section-css .card-text {
        font-size: 24px !important;
        margin-bottom: 21px;
    }
    .news-section-css .fullNews {
        font-size: 24px !important;
    }
}
@media only screen and (max-width: 2048px) and (min-width: 1441px){
    .emandi-news-css {
        margin-bottom: 7rem;
        width: 85% !important;
    }
    .newEmandiNewsViewAll .container .container-css-1 .input-box {
        margin-left: 0px !important;
        // margin-right: 3%;
    }
    // .newEmandiNewsViewAll .container {
    //     width: 93%;
    // }
    .newEmandiNewsViewAll .container .container-css-1 {
        // padding-right: 1%;
    }
    .newEmandiNewsViewAll {
        margin-left: 4%;
    }
    .news-section-css {
        width: 86%;
    }
    .titleReadFullNews .mt-4 img {
        width: 73% !important;
        height: 44vh;
    }
    .titleReadFullNews .mt-4 p {
        width: 72% !important;
        font-size: 35px;
        margin-top: 8rem !important;
        margin-bottom: 30rem;
    }
    .emandiTVViewAll {
        font-size: 49px !important;
    }
    .emandi-news-css .small-box {
        width: 12px;
        height: 82px;
    }
    .emandi-news-css .mx-3 {
        margin-right: 2.5rem !important;
        margin-left: 3.5rem !important;
        font-size: 46px !important;
    }
    .emandi-news-css .mx-1 {
        font-size: 30px !important;
    }
    .news-section-css .box img {
        height: 16rem !important;
    }
    .news-section-css .card-text {
        font-size: 27px !important;
    }
    .news-section-css .fullNews {
        font-size: 23px !important;
    }
    .newEmandiNewsViewAll .container .backbtn{
        font-size: 30px;
    margin-top: 65px;
    margin-bottom: 19px;
    }
    .newEmandiNewsViewAll  .container .smallBox {
        margin-left: 9% !important;
        width: 82%;
    }
    .newEmandiNewsViewAll .container .row .box {
        width: 31% !important;
        height: auto !important;
        padding: 44px !important;
    }
    // .newEmandiNewsViewAll  .container .smallBox .container-css-1 .yellow{
    //     width: 11px;
    //     height: 94px;
    // }
    // .newEmandiNewsViewAll  .container .smallBox .container-css-1 .p-css-2{
    //     font-size: 46pt !important;
    //     margin-left: 28px !important;
    // }
    .newEmandiNewsViewAll  .container .row .box img{
        height: 20rem !important;
    }
    .newEmandiNewsViewAll  .container .row .box .card-body .card-text{
        font-size: 27px !important;
        margin-bottom: 22px;
    }
    .newEmandiNewsViewAll  .container .row .box .card-body .fullNews{
        font-size: 25px !important;
    }
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 9999px !important;
    }
    .titleReadFullNews .mt-4 h4 {
        font-size: 42px !important;
        width: 66%;
        margin-bottom: 4rem;
    }
    .titleReadFullNews .mt-4 p {
        font-size: 31px;
    }
}
@media only screen and (max-width: 1920px) and (min-width: 1441px){
    .titleReadFullNews .mt-4 h4 {
        font-size: 38px !important;
        width: 66%;
        margin-bottom: 4rem;
    }
    .titleReadFullNews .mt-4 img {
        width: 66% !important;
        height: 44vh;
    }
    .titleReadFullNews .mt-4 p {
        width: 64% !important;
    }
    .emandi-news-css .small-box {
        width: 10px;
        height: 47px;
    }
    .emandi-news-css {
        width: 84% !important;
    }
    .emandi-news-css .mx-3{
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 30px !important;
      }
      .emandi-news-css .mx-1{
        font-size: 24px !important;
      }
      .emandi-news-css{
        margin-bottom: 5rem;
      }
      .news-section-css .box img{
        height: 16rem !important;
        margin-bottom: 32px !important;
      }
    //   .newEmandiNewsViewAll .container .backbtn{
    //     font-size: 20px;
    //     margin-top: 52px;
    //     margin-bottom: 20px;
    // }
    .newEmandiNewsViewAll  .container .smallBox {
        margin-left: 9% !important;
        width: 82%;
    }
    .emandiTVViewAll {
        font-size: 45px !important;
    }
    .newEmandiNewsViewAll .yellow {
        width: 8px;
    height: 67px !important;
    }
    .emandiTVViewAll {
        font-size: 38px !important;
    }
    // .newEmandiNewsViewAll  .container .smallBox .container-css-1 .yellow{
    //     width: 8px;
    //     height: 75px;
    // }
    // .newEmandiNewsViewAll  .container .smallBox .container-css-1 .p-css-2{
    //     font-size: 37pt !important;
    //     margin-left: 28px !important;
    // }
    .newEmandiNewsViewAll .container .row .box {
        margin: 0px;
        width: 32% !important;
        padding: 31px !important;
    }
    .newEmandiNewsViewAll  .container .row .box img{
        height: 20rem !important;
    }
    .newEmandiNewsViewAll  .container .row .box .card-body .card-text{
        font-size: 23px !important;
        margin-bottom: 19px;
    }
    .newEmandiNewsViewAll  .container .row .box .card-body .fullNews{
        font-size: 22px !important;
    }
    .newEmandiNews .container .backbtn {
        font-size: 26px;
        margin-top: 34px;
        margin-bottom: 42px;
    }
    .titleReadFullNews .mt-4 p {
        font-size: 25px;
        margin-bottom: 17rem;
    }
    .news-section-css .card-text {
        font-size: 23px !important;
    }
    .news-section-css .fullNews {
        font-size: 21px !important;
    }
}
@media only screen and (max-width: 1822px) and (min-width:1709px){
    .emandi-news-css .small-box {
        width: 10px;
        height: 48px;
    }
    .news-section-css {
        width: 87%;
        margin-left: 6.5%;
    }
    .emandi-news-css {
        width: 85% !important;
    }
    .emandi-news-css .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 29px !important;
    }
    .emandi-news-css .mx-1 {
        font-size: 21px !important;
    }
    .news-section-css .card-text {
        font-size: 21px !important;
    }
    .news-section-css .fullNews {
        font-size: 18px !important;
    }
    .news-section-css .box img {
        height: 15rem !important;
        margin-bottom: 32px !important;
    }
}
@media only screen and (max-width: 1708px) and (min-width:1681px){
    .emandi-news-css .small-box {
        width: 10px;
        height: 46px;
    }
    .newEmandiNewsViewAll .container .row .box img {
        height: 17rem !important;
    }
    .newEmandiNewsViewAll .container .row .box .card-body .fullNews {
        font-size: 19px !important;
    }
    .emandi-news-css {
        width: 86% !important;
    }
    .news-section-css {
        margin-left: 6.5%;
        width: 88%;
    }
    .emandi-news-css .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 29px !important;
    }
    .emandi-news-css .mx-1 {
        font-size: 21px !important;
    }
    .news-section-css .card-text {
        font-size: 19px !important;
    }
    .news-section-css .fullNews {
        font-size: 18px !important;
    }
    .news-section-css .box img {
        height: 15rem !important;
        margin-bottom: 32px !important;
    }
}
@media only screen and (max-width: 1680px) and (min-width: 1441px){
    .titleReadFullNews .mt-4 h4 {
        font-size: 33px !important;
        width: 66%;
        margin-bottom: 4rem;
    }
    .news-section-css {
        margin-left: 6.5%;
    }
    .titleReadFullNews .mt-4 p {
        font-size: 22px;
        margin-bottom: 17rem;
    }
    // .emandiTVViewAll {
    //     font-size: 34px !important;
    // }
    // .newEmandiNewsViewAll .container .backbtn {
    //     margin-top: 36px;
    // }
    .emandi-news-css .small-box {
        width: 9px;
        height: 43px;
    }
    .emandi-news-css .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 27px !important;
    }
    .emandi-news-css .mx-1 {
        font-size: 20px !important;
        margin-left: 2.25rem !important;
    }
    .emandi-news-css {
        width: 86% !important;
    }
    .news-section-css {
        width: 88%;
    }
    // .newEmandiNewsViewAll .container .smallBox .container-css-1 .yellow {
    //     width: 7px;
    //     height: 65px;
    // }
    // .newEmandiNewsViewAll .container .smallBox .container-css-1 .p-css-2 {
    //     font-size: 29pt !important;
    //     margin-left: 20px !important;
    // }
    // .newEmandiNewsViewAll  .container .smallBox .input-box {
    //     width: 26rem;
    // }
    .newEmandiNewsViewAll .container .row .box img {
        height: 15rem !important;
    }
    .newEmandiNewsViewAll .container .row .box .card-body .card-text {
        font-size: 20px !important;
        margin-bottom: 21px;
    }
    .newEmandiNewsViewAll .container .row .box .card-body .fullNews {
        font-size: 17px !important;
    }
    .newEmandiNewsViewAll .container .smallBox .input-box input {
        font-size: 3rem !important;
        margin-bottom: 0px !important;
    }
    .newEmandiNewsViewAll .yellow {
        width: 7px;
        height: 58px !important;
    }
}
@media only screen and (max-width: 1600px) and (min-width: 1441px){ 
    .news-section-css {
        margin-left: 6.5%;
    }
    .titleReadFullNews .mt-4 h4 {
        font-size: 30px !important;
        width: 66%;
        margin-bottom: 4rem;
    }
    .newEmandiNewsViewAll .yellow {
        width: 7px;
        height: 53px !important;
    }
    .emandiTVViewAll {
        font-size: 32px !important;
    }
    .newEmandiNewsViewAll .container .row .box .card-body .card-text {
        font-size: 19px !important;
        margin-bottom: 17px;
        margin-top: 12px;
    }
    .newEmandiNewsViewAll .container .row .box {
        width: 32% !important;
        padding: 25px !important;
    }
    .emandi-news-css {
        margin-bottom: 0rem;
    }
    .emandi-news-css .small-box {
        width: 8px;
        height: 42px;
    }
    .emandi-news-css .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 26px !important;
    }
    .emandi-news-css .mx-1 {
        font-size: 17px !important;
        margin-left: 1.25rem !important;
    }
    .viewAll {
        font-size: 24px !important;
    }
    .news-section-css .box img {
        height: 11rem !important;
        margin-bottom: 18px !important;
    }
}
@media only screen and (max-width: 1440px){
    .emandi-news-css {
        width: 85%;
    }
    // .newEmandiNewsViewAll .container {
    //     width: 90%;
    // }
    // .newEmandiNewsViewAll {
    //     margin-left: 2%;
    // }
    // .news-section-css {
    //     width: 87%;
    // }
    // .newEmandiNewsViewAll .container .backbtn{
    //     font-size: 17px;
    // margin-top: 22px;
    // margin-bottom: 17px;
    // }
    .newEmandiNewsViewAll  .container .smallBox {
        margin-left: 5% !important;
        width: 83%;
    }
    .newEmandiNewsViewAll .yellow {
        width: 7px;
    height: 37px !important;
    }
    .emandiTVViewAll {
        font-size: 28px !important;
    }
    .emandi-news-css .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 23px !important;
    }
    .emandi-news-css .mx-1 {
        font-size: 17px !important;
        margin-left: 1.25rem !important;
    }
    
    // .newEmandiNewsViewAll  .container .smallBox .container-css-1 .yellow{
    //     width: 6px;
    //     height: 64px;
    // }
    // .newEmandiNewsViewAll  .container .smallBox .input-box input {
    //     font-size: 3rem !important;
    //     margin-bottom: 0px !important;
    // }
    // .newEmandiNewsViewAll  .container .smallBox .input-box {
    //     width: 25rem;
    // }
    // .newEmandiNewsViewAll  .container .smallBox .container-css-1 .p-css-2{
    //     font-size: 28pt !important;
    //     margin-left: 17px !important;
    // }
    .newEmandiNewsViewAll  .container .row .box img{
        height: 13rem !important;
    }
    .newEmandiNewsViewAll  .container .row .box .card-body .card-text{
        font-size: 14px !important;
        margin-bottom: 14px;
    }
    .newEmandiNewsViewAll  .container .row .box .card-body .fullNews{
        font-size: 14px !important;
    }
    .newEmandiNewsViewAll .container .row .box {
        width: 32% !important;
    height: auto !important;
    padding: 21px !important;
    margin: 0px;
    }
    .newEmandiNews .container .backbtn {
        margin-bottom: 21px;
        font-size: 16px !important;
        margin-top: 11px !important;
    }
    .titleReadFullNews{
        margin-left: 6% !important;
    }
    .titleReadFullNews .mt-4 h4{
        font-family: 'Metropolis';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        width: 60%;
        margin-bottom: 2rem;
    }
    .titleReadFullNews .mt-4 img {
        width: 66% !important;
        height: 44vh;
    }
    .titleReadFullNews .mt-4 p {
        width: 66% !important;
        font-size: 19px;
        margin-top: 5rem !important;
        margin-bottom: 14rem;
    }
    .homeback{
        margin-left: 6% !important;
    }
}
@media only screen and (max-width: 1366px){
    .emandi-news-css .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 24px !important;
    }
    .emandi-news-css .mx-1 {
        font-size: 17px !important;
    }
    .titleReadFullNews .mt-4 img {
        width: 69% !important;
        height: 44vh;
    }
    .titleReadFullNews .mt-4 p {
        width: 68% !important;
        font-size: 17px;
        margin-top: 5rem !important;
        margin-bottom: 14rem;
    }
    .emandi-news-css {
        font-family: MetropolisRegular;
        margin-top: 6rem !important;
        display: flex;
        justify-content: space-between;
        width: 85%;
    }
    .newEmandiNewsViewAll .container .smallBox {
        margin-left: 1% !important;
        width: 93%;
    }
    .newEmandiNewsViewAll .container .row {
        width: 97%;
        margin-left: -1%;
    }
    .emandiTVViewAll {
        font-size: 31px !important;
    }
    .newEmandiNewsViewAll .yellow {
        width: 5px;
        height: 51px !important;
    }
    .input-box {
        width: 24rem;
        height: 3.5rem;
        margin-bottom: 3rem;
        padding-left: 24px;
    }
    // .newEmandiNewsViewAll .container .container-css-1 .input-box {
    //     margin-left: 0px !important;
    //     margin-right: 6%;
    // }
}
@media only screen and (max-width: 1366px){
    .input-box {
        width: 24rem;
        height: 3.5rem;
        margin-bottom: 3rem;
        padding-left: 24px;
    }
    // .newEmandiNewsViewAll .container {
    //     width: 96%;
    // }
    // .newEmandiNewsViewAll {
    //     margin-left: 5%;
    // }
    .emandiTVViewAll {
        font-size: 26px !important;
    }
    .newEmandiNewsViewAll .yellow , .newEmandiNews .yellow{
        width: 6px;
        height: 35px !important;    
    }
}
@media (max-width: 1280px) {
    .news-section-css {
        margin-left: 6%;
        width: 88%;
    }
    .homeback, .titleReadFullNews{
        margin-left: 8% !important;
    }
    .newEmandiNewsViewAll {
        margin-left: 3%;
    }
    .emandi-news-css {
        width: 85%;
    }
    // .newEmandiNewsViewAll .input-box {
    //     width: 21rem;
    //     height: 4rem;
    //     margin-bottom: 3rem;
    //     padding-left: 12px;
    // }
    .titleReadFullNews .mt-4 h4 {
        width: 64%;
    }
    .newEmandiNewsViewAll .container .row {
        width: 100%;
        margin-left: -1%;
    }
    .titleReadFullNews .mt-4 p {
        width: 68% !important;
        font-size: 16px;
        margin-top: 5rem !important;
        margin-bottom: 14rem;
    }
    .emandi-news-css .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 21px !important;
    }
}
@media (max-width: 1093px) and (min-width: 1025px){
    .news-section-css .box img{
        height: 82px !important;
    }
    .newEmandiNewsViewAll .container .row .box {
        width: 32% !important;
    }
    .newEmandiNewsViewAll .container .row {
        width: 100%;
        margin-left: -1%;
    }
}
@media (max-width: 1024px) {
    .emandi-news-css {
        margin-top: 0rem !important;
        width: 86%;
    }
    .newEmandiNewsViewAll .container .row {
        width: 100%;
        margin-left: -1%;
    }
    .box {
        width: 214px !important;
    }
    .news-section-css {
        margin-left: 6%;
        margin-bottom: 6%;
        width: 88% !important;
    }
    .news-section-css .card-text{
        font-size: 11px !important;
    }
    .news-section-css .fullNews{
        font-size: 9px !important;
    }
    // .newEmandiNewsViewAll .container .backbtn {
    //     font-size: 15px;
    //     margin-top: 12px;
    //     // margin-left: 3%;
    //     margin-bottom: 22px;
    // }
    // .newEmandiNewsViewAll .container .smallBox .container-css-1 .yellow {
    //     width: 4px;
    //     height: 46px;
    // }
    // .newEmandiNewsViewAll .container .smallBox .container-css-1 .p-css-2 {
    //     font-size: 21pt !important;
    //     margin-left: 12px !important;
    // }
    // .newEmandiNewsViewAll .container .smallBox .input-box input {
    //     font-size: 2.5rem !important;
    //     margin-bottom: 0px !important;
    // }
    // .newEmandiNewsViewAll .container .smallBox .input-box {
    //     width: 22rem;
    // }
    // .newEmandiNewsViewAll .container .smallBox {
    //     margin-left: 3% !important;
    //     width: 93%;
    // }
    .newEmandiNewsViewAll .container .row .box {
        width: 33% !important;
        height: auto !important;
        padding: 18px !important;
    }
    .titleReadFullNews .mt-4 h4 {
        font-size: 21px !important;
        width: 68%;
    }
    .titleReadFullNews .mt-4 p {
        font-size: 18px;
    }
    .titleReadFullNews .mt-4 img {
        width: 78% !important;
        height: 44vh;
    }
    .titleReadFullNews .mt-4 p {
        width: 77% !important;
        font-size: 16px;
    }
}
@media screen and (max-width: 991px){
    .emandi-news-css .mx-1 {
        font-size: 15px !important;
    }
}
@media screen and (max-width: 987px){
    .emandi-news-css .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 19px !important;
    }
    .emandi-news-css .mx-1 {
        font-size: 14px !important;
    }
}
@media screen and (max-width: 915px){
    .emandi-news-css .mx-3 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
        font-size: 17px !important;
    }
    .emandi-news-css .mx-1 {
        font-size: 13px !important;
    }
}
@media screen and (max-width: 778px){
    .titleReadFullNews .mt-4 h4 ,.titleReadFullNews .mt-4 img,.titleReadFullNews .mt-4 p{
        width: 100% !important;
    }
    .titleReadFullNews .mt-4 img {
        height: 34vh;
    }
}

