@font-face {
    font-family: MetropolisMedium;
    src: url('../../../assets/Metropolis_Font_family/Metropolis-Medium.otf')
}


.fixed-footer-container{
    display: none;
}

@media screen and (max-width: 620px) {
    .fixed-footer-container{
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        z-index: 2;
        padding: 30px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px -2px 15px -5px rgba(62, 84, 88, 0.075);
    }
    .upgrade-text{
        font-size: 15px;
        font-family: MetropolisMedium;
        text-align: center;
    }
    .download-app-btn{
        background: #04B23D;
        color: #fff;
        font-family: MetropolisMedium ;
        border: none;
        padding: 5px 20px;
        font-size: 17px;
        border-radius: 8px;
    }
}