.cursor{
    cursor: pointer;
}

/* .navbar{
    box-shadow: 2px 0px 12px rgba(92, 138, 152, 0.075);
} */

.navListItem{
    font-size:"12pt";
    margin-right:"16px";
    font-weight:"500"
}
/* .cursor{
    cursor: pointer;
}
.navbar{
    box-shadow: 2px 0px 12px rgba(92, 138, 152, 0.075);}

.navListItem{
    font-size:"12pt";
    margin-right:"16px";
    font-weight:"500"
} */
@font-face {
    font-family: MetropolisRegular;
    src: url('Metropolis-Regular.otf')
}

@font-face {
    font-family: MetropolisMedium;
    src: url('Metropolis-Medium.otf')
}
/* .nav-link{
    font-family: MetropolisMedium;
} */
 
/* ------------------ */
html{
    font-size: 62.5%;
}
/* .headerr{
    font-size: 62.5%;
} */
/* Child CSS */
/* .navbarUl>li>span {
    font-size: 16px; 
} */
.upperHeader{
    height: 9rem;
    width: 100%;
    box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 7%;
    padding-right: 7%;
}
.header-logo {
  height: 8rem;
  /* margin-left: 7.4em; */
  border-radius: 2px;
  /* margin-top: 1em; */
  cursor: pointer;
  min-width: 87px;
}
/* .navbarDiv{
    width: 100%;
    padding-left: 20%;
    height: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
} */
.navbarUl{
    height: 4rem;
    margin: 0px;
    padding: 0px;
    width: 100%;
    display: flex;
    list-style: none;
    /* justify-content: space-around; */
    gap: 4%;
    align-items: center;
    justify-content: flex-end;
}
.navbarUl>li>span{
    display: inline-block;
    font-family: 'MetropolisLight';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    /* line-height: 1.6rem; */
    color: #2F2F2F;
    /* letter-spacing: 1px; */
    cursor: pointer;
    /* width: fit-content; */
}
.upperinnerDiv { 
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.hamContainer {
    display: none;
}
.mob-nav {
        display: none;
    }

.upperinnerDiv .active {
    color: #04B23D !important;
}
/* loader */
#loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  #spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #0DB442;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  .noPAN-popup-content{
    width: 42% !important;
    border-radius: 12px;
    padding-bottom: 1rem !important;
  }
  .noPANPopup-header{
    justify-content: center !important;
    font-size: 18px !important;
    border-bottom: 1px solid #f0f0f0 !important;
  }
  .noPANPopup-para{
    font-size: 14px !important;
    display: flex;
    justify-content: center;
  }
  .noPANPopup-okBtn-conatiner{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 3rem;
  }
  .noPANPopup-okBtn{
    width: 25%;
    border: 2px solid var(--brand-green, #0DB442);
    background: #0DB442;
    color: var(--brand-green, #FFF);
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 16px;
    font-family: MetropolisMedium;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
  .noPANPopup-cancelBtn{
    width: 25%;
    border: 2px solid var(--brand-green, #0DB442);
    background: #FFF;
    color: var(--brand-green, #0DB442);
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 16px;
    font-family: MetropolisMedium;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.16px;
  }
.profileImg{
    cursor: pointer;
}
.profileClick-popup-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
.profileClick-popup-content{
    padding: 25px !important;
    width: fit-content !important;
    position: fixed !important;
    top: 11rem !important;
    right: 9rem !important;
    background-color: white !important;
    border-radius: 12px !important;
}
.ProfileImgPopupCol{
    font-size: 14px;
    text-align: center;
    padding: 10px 16px;
    cursor: pointer;
}
.namepopupCross{
    cursor: pointer;
}
.namePopupPeronImg{
    width: 40px;
    height: 40px;
}
.namePopup-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px !important;
}
.userProfileClick-popup-content{
    width: 30% !important;
    border-radius: 12px;
    padding-bottom: 5rem !important;
}
.namePopup-header{
    padding: 20px !important;
}
.whatYourNameP{
    font-size: 18px;
    margin-top: 25px;
}
.input-submit-container{
    display: flex;
    height: 40px;
    margin-top: 16px;
}
.namePopupInput{
    height: 100%;
    width: 20rem;
    font-size: 16px;
    font-family: MetropolisRegular;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.16px;
    color: black;
    outline: none;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid var(--outline-box, #D3D3D3);
    background: var(--brand-white, #FFF);
    box-shadow: 0px 0px 8px 0px rgba(129, 161, 191, 0.12);
}
.namePopupInput::placeholder{
    color: var(--text-tertiary, #979797);
    font-size: 16px;
    font-family: MetropolisRegular;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.16px;
}
.namePopupSubmit{
    height: 100%;
    width: 9rem;
    color: var(--brand-white, #FFF);
    font-size: 16px;
    font-family: MetropolisRegular;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 8px 8px 0px;
    background: var(--brand-green, #04B23D);
    box-shadow: 0px 0px 8px 0px rgba(129, 161, 191, 0.12);
    cursor: pointer;

}
@font-face {
    font-family: MetropolisRegular;
    src: url('Metropolis-Regular.otf')
}

@font-face {
    font-family: MetropolisMedium;
    src: url('Metropolis-Medium.otf')
}
.nav-link{
    font-family: MetropolisMedium;
}
@media only screen and (max-width: 7680px) and (min-width: 5747px){
    .upperHeader {
        height: 32rem !important;
    }  
    /* .navbarDiv {
        width: 100%;
        padding-left: 22%;
        height: 20rem;
    } */
    .navbarUl>li>span {
        font-size: 4rem;
    }
    .profileImg{
        width: 172px;
    }
}
@media only screen and (max-width: 5746px) and (min-width: 5465px){
    .upperHeader {
        height: 32rem !important;
    }  
    /* .navbarDiv {
        width: 100%;
        padding-left: 22%;
        height: 20rem;
    } */
    .navbarUl>li>span {
        font-size: 4rem;
        letter-spacing: 2.5px;
    }
    .profileImg{
        width: 125px; 
    }
}
@media only screen and (max-width: 5464px) and (min-width: 4099px){
    .upperHeader {
        height: 32rem !important;
    }  
    /* .navbarDiv {
        width: 100%;
        padding-left: 22%;
        height: 20rem;
    } */
    .navbarUl>li>span {
        font-size: 4rem;
        letter-spacing: 2.5px;

    }
    .profileImg{
        width: 125px; 
    }
}
@media only screen and (max-width: 4098px) and (min-width: 3842px){
    .upperHeader {
        height: 29rem !important;
    }  
    /* .navbarDiv {
        width: 100%;
        padding-left: 22%;
        height: 20rem;
    } */
    .navbarUl>li>span {
        font-size: 4.5rem;
        letter-spacing: 2px;
    }
    .profileImg{
        width: 125px; 
    }
    .header-logo {
        height: 11rem;
    }
}
@media only screen and (max-width: 3841px) and (min-width: 2561px){
    .upperHeader{
        height: 28rem;
        padding-left: 7%;
    padding-right: 7%;
    }
    .header-logo{
        height: 10rem;
    }
    /* .navbarDiv{
        height: 21rem;
        padding-left: 21%;
    } */
    .navbarUl>li>span{
        font-size: 4.6rem;
    letter-spacing: 1.5px;
    }
    /* .upperinnerDiv img{
        width: 131px;
    } */
    .navbarUl {
        gap: 3%;
    }
    .profileImg{
        width: 125px; 
    }
}
@media only screen and (max-width: 3200px) and (min-width: 3073px){
    .navbarUl>li>span {
        font-size: 3.6rem;
        letter-spacing: 1.5px;

    }
    .profileImg {
        width: 107px;
    }
    .upperHeader {
        height: 24rem;
    }
}
@media only screen and (max-width: 3051px) and (min-width: 2733px){
    .navbarUl>li>span {
        font-size: 3.6rem;
    }
    .header-logo {
        width: 223px;
    }
    .profileImg {
        width: 104px;
    }
    .upperHeader {
        height: 24rem;
    }
    .header-logo {
        height: 8rem;
    }
}
@media only screen and (max-width: 2732px) and (min-width: 2561px){
    .header-logo {
        width: 206px;
    }
    .profileImg {
        width: 92px;
    }
    .upperHeader {
        height: 22rem;
        padding-left: 8%;
    }
    .navbarUl>li>span {
        font-size: 3.3rem;
    }
    .header-logo {
        height: 8rem;
    }
    /* .navbarDiv {
        height: 17rem;
        padding-left: 23%;
    } */
    
}
@media only screen and (max-width: 2560px) and (min-width: 1921px){
    .navbarUl>li>span {
        font-size: 3.2rem;
        letter-spacing: 1.5px;
    }
    /* .navbarDiv {
        height: 14rem;
    } */
    .upperHeader {
        height: 18rem;
    }
    .header-logo {
        width: 196px;
    }
    .header-logo {
        height: 7rem;
    }
    .navbarUl{
        gap: 3%;
    }
    .profileImg {
        width: 84px;
    }
}
@media only screen and (max-width: 2300px) and (min-width: 2049px){
    .header-logo {
        width: 149px;
        height: 6rem;
    }
    .profileImg {
        width: 80px;
    }
    .navbarUl>li>span {
        font-size: 2.4rem;
        letter-spacing: 1.5px;
    }
}
@media only screen and (max-width: 2048px) and (min-width: 1601px){
    .header-logo {
        width: 172px;
        height: 6rem;
    }
    .profileImg {
        width: 84px;
    }
    .navbarUl>li>span {
        font-size: 2.8rem;
    }
    /* .navbarDiv {
        padding-left: 24%;
    } */
    .navbarUl {
        gap: 3%;
    }
}
@media only screen and (max-width: 1920px) and (min-width: 1441px){
    .header-logo {
        height: 5rem;
        width: 159px;
    }
    .profileImg {
        width: 68px;
    }
    .upperHeader {
        height: 14rem;
    }
    /* .navbarDiv {
        height: 10rem;
    } */
    .navbarUl>li>span {
        font-size: 2.5rem;
        letter-spacing: 1.5px;
    }
}
@media only screen and (max-width: 1680px) and (min-width: 1441px){
    .header-logo {
        width: 144px;
    }
    .navbarUl>li>span {
        font-size: 2.2rem;
        letter-spacing: 1px;
    }
    .profileImg {
        width: 63px;
    }
}
@media only screen and (max-width: 1600px) and (min-width: 1441px){
    .upperHeader {
        height: 12rem;
    }
    .header-logo {
        width: 135px;
        height: 4rem;
    }
    .profileImg {
        width: 56px;
    }
    /* .navbarDiv {
        height: 8rem;
        padding-left: 23%;
    } */
    .navbarUl {
        gap: 3%;
    }
    .navbarUl>li>span {
        font-size: 1.8rem;
    }
}
@media only screen and (max-width: 1440px) and (min-width: 1025px){
    .upperHeader {
        height: 10rem;
    }
    .header-logo {
        height: 4rem;
    }
    .profileImg {
        width: 47px;
    }
    .navbarUl {
        gap: 3%;
    }   
    .navbarUl>li>span {
        font-size: 1.8rem;
        letter-spacing: 0.5px;
    }
}
@media only screen and (max-width: 1366px) and (min-width: 1281px){
    .header-logo {
        height: 3.5rem;
    }
}
@media only screen and (max-width: 1280px) and (min-width: 1025px){
    .navbarUl>li>span {
        font-size: 1.5rem;
    }
    /* .navbarDiv {
        padding-left: 23%;
    } */
}

@media only screen and (max-width: 1024px){
    .header-logo {
        height: 3rem;
    }
    .navbarUl {
        gap: 3%;
    }
}
@media only screen and (max-width:903px) {
    .upperinnerDiv .hamContainer {
        display: block;
        z-index: 4;
        justify-self: right;
    }
    .navbarUl{
        display: none;
    }
    .upperinnerDiv .hamContainer .checkbox {
        position: absolute;
        display: block;
        height: 32px;
        width: 32px;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        user-select: none;
        -webkit-touch-callout: none;
    }
    .upperinnerDiv .hamburger-lines {
        display: block;
        height: 26px;
        width: 32px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .upperinnerDiv .hamburger-lines .line {
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #04B23D;
    }
    .upperinnerDiv .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.4s ease-in-out;
    }
    .upperinnerDiv .hamburger-lines .line2 {
        transition: transform 0.2s ease-in-out;
    }
    .upperinnerDiv .hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.4s ease-in-out;
    }
    .upperinnerDiv .hamContainer input[type="checkbox"]:checked~.hamburger-lines .line1 {
        transform: rotate(45deg);
    }
    .upperinnerDiv .hamContainer input[type="checkbox"]:checked~.hamburger-lines .line2 {
        transform: scaleY(0);
    }
    .upperinnerDiv .hamContainer input[type="checkbox"]:checked~.hamburger-lines .line3 {
        transform: rotate(-45deg);
    }
    .upperinnerDiv .mob-nav {
        position: fixed;
        padding: 120px 0;
        left: 100%;
        display: block;
        transform: translate(0);
        transition: transform 0.5s ease-in-out;
        text-align: center;
        background-color: #2F2F2F;
        min-width: 40vw;
        max-width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        font-family: metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-weight: 500;
        z-index: 3;
        top: 0px;
    }
    .upperinnerDiv .mob-nav>div {
        position: relative;
        transition: all 0.5s ease;
    }
    
    .upperinnerDiv .mob-nav>div>div {
        height: 0;
        overflow: hidden;
        background-color: transparent;
        text-align: center;
        transition: all 0.5s ease;
    }
    .upperinnerDiv .mob-nav>a:last-of-type {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    
    }
    .upperinnerDiv .mob-nav>a:last-of-type>span {
        padding-left: 8px;
    }
    
    a:not([href]):not([class]), a:not([href]):not([class]):hover {
        color: white !important;
    }
    .upperinnerDiv .mob-nav>a{
        display: block;
        color: white;
        text-decoration: none;
        margin: 0px 20px;
        font-size: 16px;
        font-family: MetropolisRegular;
    }
    
    .upperinnerDiv .mob-nav>button {
        padding: 12px 20px;
        height: max-content;
        font-family: metropolis-light, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-weight: 500;
    }
    .show-side-nav {
        transform: translateX(-100%) !important;
    }
}
@media(max-width: 903px) and (max-height: 631px) {
    .upperinnerDiv .mob-nav {
        padding: 20px;
    }
}
@media(max-width: 903px) and (max-height: 496px) {
    .upperinnerDiv .mob-nav {
        display: flex;
        justify-content: space-around;
        padding: 100px 40px;
        width: 100vw;
        max-width: 100vw;
        flex-flow: wrap;
        height: auto;
    }

    .upperinnerDiv .mob-nav>a{
        margin: 10px;
    }
    
}
@media(max-width: 903px) and (max-height: 242px) {
    .upperinnerDiv .mob-nav {
        position: absolute;
    }
}
@media(max-width: 540px) {
    .upperinnerDiv .mob-nav {
        min-width: 50vw;
    }
}
@media(max-width: 345px) and (min-height: 496px) {
    .upperinnerDiv .mob-nav {
        width: 100vw;
    }
}


