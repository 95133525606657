.contact-us-dialog {
    .success-dialog{
 .p-dialog .p-dialog-titlebar-icon span{
        display: block;
    }
    .p-dialog .p-dialog-titlebar-icon{
        display: block;
    }
        .p-dialog{
            position: absolute;
            top: 15vh;
            
        }
        .p-dialog .p-dialog-titlebar-icon{
            color: #BD0019 ;
            border: 3px solid #BD0019;
            border-radius: 50%;
            padding: 4px;
            position: absolute;
            right: 1em !important;
        }
        .custom-dialog .p-dialog-mask .p-dialog .p-dialog-titlebar{
            text-align: center;
            // font-size: 20px;
            font-family: 'Poppins', sans-serif;
            display: flex !important;
            justify-content: center;
            border: none !important;
            border-top-left-radius: 10px !important;
            border-top-right-radius: 10px !important;
            div{
                display: flex;
                img{
                    width: 2em;
                    height: 2em;
                    margin-right: 1em;
                }
            }
          
        }
        .custom-dialog .p-dialog-mask .p-dialog .p-dialog-content {
            // padding: 0 !important;
            font-size: 14px;
            padding: 0px 12px;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
            border-bottom-left-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
            background-color: #ffffff;
            font-family: 'Poppins', sans-serif;
            color: #333333;
        }
        .cashandcarryconfirm-cls{
            text-align: center;
        }
    }
    .custom-dialog .p-dialog .p-component .p-dialog-enter-done{
        width: 20vw !important;
    }
    .custom-dialog .p-dialog-mask .p-dialog .p-dialog-content {
        padding: 0 !important;
        
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: #ffffff;

    }
    .custom-dialog .p-dialog-mask .p-dialog .p-dialog-titlebar{
        // display: none;
        background: linear-gradient(180deg, #DEDEDE 0%, #fff);
        font-weight: bolder;
        border: none;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .titlebar-cls{
            .first-div,.second-div,.third-div{
                align-items: center;
                text-align: center;
                color: #515151;
            }
            .first-div{
                font-size: 25px;
                margin:0 0.8em;
                font-weight: bold;
            }
            .third-div{
                margin: .8em 0;
                font-weight: bold;
                background-color: #f5f9f3;
                padding: 10px
            }
        }
        .phone-icon{
            height: 1em;
            width: 1em;
        margin-top: 1em;
        margin-right: 0.2em;
        }
    }

    .custom-dialog .p-dialog-mask .p-dialog .p-dialog-footer {
        background-color: #eff1f2;
        display: none;

    }
    .p-dialog .p-dialog-titlebar-icon span{
        display: none;
    }

    .custom-dialog .p-dialog-mask .p-dialog .p-dialog-footer>div {
        justify-content: flex-end;
        margin-top     : -60px;
    }
    .p-dialog .p-dialog-titlebar-icon{
        display: none;
    }
    .custom-dialog .p-dialog-titlebar-icons {
        display: block !important;
    }

    .p-dialog-footer {

        .cancel {
            display: none;
        }

        .accept {
            display       : none;
        }
    }

    .p-dialog-content {}

}

.callus-cls {
   
    .phone-icon{
        height: 1em;
        width: 1em;
    margin-top: 1em;
    margin-right: 0.2em;
    }
    .contact-us-scrollbar {
        >div {
            display: flex !important;
            ;
            justify-content: space-around !important;
            ;
        }
    }
    .header-cls{
        .first-div,.second-div,.third-div{
            align-items: center;
            text-align: center;
            color: #515151;
        }
        .first-div{
            font-size: 25px;
            margin: .8em;
            font-weight: bold;
        }
        .third-div{
            margin: .8em 0;
            font-weight: bold;
            background-color: #f5f9f3;
            padding: 10px
        }
    }

    .location-details {
        width: 50%;

        .contact-details-wrapper {
            display: flex;
            margin : 3em 0;

            >p {
                font-size     : 18px;
                margin        : 0px;
                font-family   : sans-serif;
                color         : #122025;
                letter-spacing: 1px;
            }

            img {
                margin-right: 1em;
                padding     : 0;
            }

            .address {
                position: relative;
                bottom  : 2.5em;
            }
        }

    }

    .message-us {
        // width: 50%;

        >p {
            font          : normal normal bold 22px/16px sans-serif;
            letter-spacing: 1px;
            color         : #122025;
        }
         .btns-cls{
             margin: 10px;
            display: flex;
             justify-content: center;
             .cancel{
                background-color: #707070;
             }
             .send{
                background-color: #000000;
             }
        }
        // .inputtext-content-section {
        //     width     : 100%;
        //     margin-top: 2em;

        //     input {
        //         width         : 80%;
        //         border        : none;
        //         border-bottom : 1px solid #707070;
        //         font          : normal normal normal 14px/19px Roboto;
        //         letter-spacing: 0.7px;
        //         color         : #000000;
        //         height        : 2em;
        //     }
        // }

        // .inputarea-content-section {
        //     width     : 100%;
        //     margin-top: 2em;

        //     textarea {
        //         width         : 80%;
        //         border        : none;
        //         border-bottom : 1px solid #707070;
        //         font          : normal normal normal 14px/19px Roboto;
        //         letter-spacing: 0.7px;
        //         color         : #000000;
        //         height        : 5em;
        //         padding-left  : 1em;
        //     }
        // }
        .names-div{
            display: flex ;
            margin-top: 0.9em;
            justify-content: center;
            .inputfield-cls {
                // margin-top: 2em;
                margin-top: 1em;
                box-shadow: 0px 0px 2px 2px #0000001a;
                border: 1px solid #0000001A;
                border-radius: 1px;
                height: 70px;
                margin-right: 1em;
                .input-container {
                    display: -ms-flexbox; /* IE10 */
                    display: flex;
                    width: 100%;
                    .inputtext-content-section .p-inputtext{
                        padding-left: 4px !important;
                    }
                  }
                  
                  .icon {
                      margin-top: 2.5px;
                    font-size: 14px;
                      padding-left: 10px;
                    color: black;
                    text-align: center;
                  }
                  
                 
                p {
                    font-size: 16px;
                    color: #707070;
                    padding-left: 10px;
                    margin: 0.5em 0;
                }
                .inputtext-content-section .p-inputtext{
                    border: none;
                }
        
                .input-cls {
                    .inputtext-content-section .p-inputtext {
                        margin       : 0;
                        width        : 190px;
                        // border-radius: 5px;
                        border       : none !important;
                        
                    }
                }
                
               
            }
        }
        .button-content-section {
            display        : flex;
            justify-content: center;
            margin     : 1em 2em;

            .p-button {
                color         : #ffffff;
                border        : 1px solid #707070;
                padding       : 3px 5px;
                border-radius : 5px;
                letter-spacing: 1px;
                font-size     : 16px;
                box-shadow    : 0px 0px 3px 2px #0000001A;

            }
        }



    }
}
