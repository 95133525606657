.profin-view-dashboard{
    height: 540px;
    overflow-y: auto;
    overflow-x: hidden;
}
.tbody-head {
    font-family: Metropolis-Regular;
    font-weight: 300;
    color: #979797;
    padding: 20px !important;
    font-size: 12px;
    text-align: left;
    vertical-align: top;
}

.tbody-desc {
    font-family: Metropolis-Regular;
    font-weight: 300;
    color: #000;
    padding: 20px !important;
    font-size: 12px;
    text-align: left;
    vertical-align: top;
}
.myTable
{
    width:80%;
    margin-left: 50px;
}
.tableHeight{
    height: 20px !important;
}
.profin-view-dashboard .mid-edit-section{
    background-color:#E0F1E5 !important;
    border:none !important;
}
.reasonText {
    margin-left: 10px;
}


#txtRemarks {
    box-sizing: border-box;
    margin-top: 10px;
    width: 510px;
    height: 155px;
    margin-left: 12px;

    /* Primary Palette/White */

    background: #FFFFFF;
    /* Divider */
    padding: 10px;
    outline-color: #D3D3D3;
    border: 1px solid #D3D3D3;
    border-radius: 8px;

}
