.homePage-cls {
    display: flex;

    .bgText-cls {
        // flex: 1;
        width: 50%;

        .text-cls {
            margin: 4.5em 95px 0px 45px;
            #textHeader {
                font-size: 40px;
                font-weight: bold;
                margin:20px 50px 20px 0px;
                opacity: 0.8;
            }
            .subHeader {
                display: flex;
                font-size: 1.2rem;
                color: #313131;
            }
        }

        .bgImage-cls{
            img{
                position: relative;
                top: 4.5em;
                margin-left: 30px;
                width: 100%;
                height: 28vh;
            }
        }
    }

    .loginPage-cls {
        flex: 1;
    }

}