    @font-face {
    font-family: MetropolisMedium;
    src: url('Metropolis-Medium.otf')
}

@font-face {
    font-family: MetropolisBold;
    src: url('Metropolis-Bold.otf')
}

@font-face {
    font-family: MetropolisRegular;
    src: url('Metropolis-Regular.otf')
} 


@font-face {
    font-family: MetropolisExtraLight;
    src: url('Metropolis-ExtraLight.otf')
} 

@font-face {
    font-family: MetropolisLight;
    src: url('Metropolis-Light.otf')
} 
.origo-text {
    margin-top: 5%;
    font-family: MetropolisMedium !important;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;

    .text1 {
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 24px;
        font-family: MetropolisMedium !important;
        font-weight: 500;
        width: 87%;
        flex-wrap: wrap;
    }

    .text2 {
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: small;
        flex-wrap: wrap;
        text-align: center;
        width: 87%;
    }
    .text-3-css{
        font-family: MetropolisMedium !important;
        font-size: 22px;
        font-weight: 300;
        color: #2F2F2F;
        white-space: nowrap;
    }
    
}

.formBG {
    margin-bottom: 29%;
}

.home-css {
    font-family: MetropolisRegular;

}

.p-css-1 {
    font-family: MetropolisRegular !important;
    font-weight:900;
    font-size: 18px;
    margin-bottom: 2rem;
}

.text1{
    font-family: MetropolisRegular;
    // font-weight: 580 !important;
}


.text2{
    font-family: MetropolisRegular;
    font-weight: 500 !important;

}

.text3 {
    font-family: MetropolisLight !important;
    font-size: 16pt;
    padding-top: 5px;
    // font-weight: 55  0 !important;
}

.p-tag-1-css {
    font-family: MetropolisMedium !important;
    margin-top: 4rem;
}

.form {
    display: flex;
    justify-content: center;
    
    margin-bottom: 22rem;
    margin-left: 0%;
    .formBG {
        /* position: absolute; */
        width: 86%;
        height: 100%;
        left: 0px;
        border-radius: 12px;
        top: 160px;
        display: flex;


        background: #FFFBE1;
        border-radius: 12px;

        .formText {
            font-size: larger;
        }

        .heading {
            color: #04B23D;
        }

        .headText {
            color: grey;
            font-size: 16px !important;
        }

        .benefits {
            display: flex;
            flex-direction: row;
            gap: 29px;
        }

        .row {
            width: 100%;
        }
    }

}

.formCard {
    height: 100% !important;
    width: fit-content;

    .formCardBody {
        width: 407px;
        height: 484px;
        left: 775px;
        top: 2818px;
        margin-top: 50px;

        background: #FDFDFD;
        /* Cards */

        box-shadow: 0px 2px 15px rgba(62, 84, 88, 0.075);
        border-radius: 12px;

        .imageBody{
            display: flex;
            justify-content: center;


            img{
                width: 35%;
            }

        }
        .form-label {
            font-size: medium;
            margin-bottom: 14px;
        }

        .btn {
            width: -webkit-fill-available;
            padding: 10px;
            background: #04B23D;
        }
    }
}


// .img-css-4 {
//     position: absolute;
//     z-index: 10;
//     transform: rotate(360deg);
//     width: 1.57% !important;
//     margin-top: 72px;
//     margin-left: -4px;
//     /* border-radius: 40px; */
//   }
  
//   .btn-css-3 {
//     /* border-radius: 100px; */
//     /* width: 26px;
//     height: 26px; */
//     /* border-radius: 50px !important; */
//     position: relative;   
//   }
  

.home_page-css {
    border-radius: 10px;
    width: 200px;
    height: 470px;
    // border-radius: 50px !important;
    position: relative;   
}

.form_section-css {
    position: absolute;
    z-index: 10;
    // transform: rotate(180deg);
    width: 0.57% !important;
    margin-top: 15px;
    margin-left: 10px;
    border-radius: 40px;
}
.mb-4 {
    margin-bottom: 2rem!important;
}
.scrollbars-wrapper > div{
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  .scrollbars-wrapper > div:nth-child(2){
    display: none;
  }
.origo-text .text1>br{
    display: none;
}
.card-body form .mb-3 input, .card-body form .mb-3 input::placeholder, .card-body form .mb-3 select, .card-body form .mb-3 select::placeholder {
    /* display: -webkit-box; */
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.separater-hr{
    display: none;
}
.form .formBG .p-css-1 {
    display: flex;
}
.formBG .col-lg{
    width: 46%;
}
// .img-css-3 {
//     position: absolute;
//     z-index: 10;
//     transform: rotate(180deg);
//     width: 0.57% !important;
//     margin-top: 15px;
//     margin-left: 10px;
//     border-radius: 40px;
// }
 
// .btn-css-2 {
//     border-radius: 100px;
//     width: 26px;
//     height: 26px;
//     border-radius: 50px !important;
//     position: relative;   
// }



// responsivenesssss-----------------------------
@media only screen and (max-width: 7680px) and (min-width: 5747px){
    .origo-text .text1 {
        font-size: 78px;
    }
    .origo-text .text-3-css {
        font-size: 61px;
    }
    .origo-text .text2{
        font-size: 35pt !important;
        margin-bottom: 15rem !important;
    }
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 100% !important;
    }
    .formBG .col-lg{
        padding-left: 17%;
    }
    .formBG{
        height: 110rem !important;
        margin-bottom: 16% !important;
    }
    .form .formBG .formText{
        font-size: 57px !important;
        margin-top: 10rem;
        margin-bottom: 6rem !important;
    }
    .form .formBG .heading{
        font-size: 68px !important;
        margin-bottom: 3rem;
        margin-top: 5rem;
    }
    .form .formBG .headText {
        color: grey;
        font-size: 42px !important;
    }
    .form .formBG .headText img{
        height: 5rem;
    }
    .headText{
        font-size: 2.875em !important;
    }
    .benefits .mx-5 img{
        height: 47rem !important;
    }
    
}
@media only screen and (max-width: 5746px) and (min-width: 5465px){
    .origo-text .text1 {
        font-size: 78px;
    }
    .origo-text .text-3-css {
        font-size: 61px;
    }
    .origo-text .text2{
        font-size: 35pt !important;
        margin-bottom: 15rem !important;
    }
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 100% !important;
    }
    .formBG .col-lg{
        padding-left: 17%;
    }
    .formBG{
        height: 110rem !important;
        margin-bottom: 16% !important;
    }
    .form .formBG .formText{
        font-size: 57px !important;
        margin-top: 10rem;
        margin-bottom: 6rem !important;
    }
    .form .formBG .heading{
        font-size: 68px !important;
        margin-bottom: 3rem;
        margin-top: 5rem;
    }
    .form .formBG .headText {
        color: grey;
        font-size: 42px !important;
    }
    .form .formBG .headText img{
        height: 5rem;
    }
    .headText{
        font-size: 2.875em !important;
    }
    .benefits .mx-5 img{
        height: 47rem !important;
    }
    
}
@media only screen and (max-width: 5464px) and (min-width: 4099px){
    .origo-text .text1 {
        font-size: 78px;
    }
    .origo-text .text-3-css {
        font-size: 61px;
    }
    .origo-text .text2{
        font-size: 35pt !important;
        margin-bottom: 15rem !important;
    }
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 100% !important;
    }
    .formBG .col-lg{
        padding-left: 17%;
    }
    .formBG{
        height: 110rem !important;
        margin-bottom: 16% !important;
    }
    .form .formBG .formText{
        font-size: 57px !important;
        margin-top: 10rem;
        margin-bottom: 6rem !important;
    }
    .form .formBG .heading{
        font-size: 68px !important;
        margin-bottom: 3rem;
        margin-top: 5rem;
    }
    .form .formBG .headText {
        color: grey;
        font-size: 42px !important;
    }
    .form .formBG .headText img{
        height: 5rem;
    }
    .headText{
        font-size: 2.875em !important;
    }
    .benefits .mx-5 img{
        height: 47rem !important;
    }
    
}
@media only screen and (max-width: 4098px) and (min-width: 3842px){
    .origo-text .text1 {
        font-size: 78px;
    }
    .origo-text .text-3-css {
        font-size: 61px;
    }
    .origo-text .text2{
        font-size: 35pt !important;
        margin-bottom: 15rem !important;
    }
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 100% !important;
    }
    .formBG .col-lg{
        padding-left: 17%;
    }
    .formBG{
        height: 110rem !important;
        margin-bottom: 16% !important;
    }
    .form .formBG .formText{
        font-size: 57px !important;
        margin-top: 10rem;
        margin-bottom: 6rem !important;
    }
    .form .formBG .heading{
        font-size: 68px !important;
        margin-bottom: 3rem;
        margin-top: 5rem;
    }
    .form .formBG .headText {
        color: grey;
        font-size: 42px !important;
    }
    .form .formBG .headText img{
        height: 5rem;
    }
    .headText{
        font-size: 2.875em !important;
    }
    .benefits .mx-5 img{
        height: 47rem !important;
    }
    
}
@media only screen and (max-width: 3841px) and (min-width: 2561px){
    .origo-text .text-3-css{
        font-size:68px;
    }
    .origo-text .text1{
        font-size: 74px;
    }
    .origo-text .text2{
        font-size: 44pt !important;
        margin-bottom: 13rem;
        margin-top: 5rem;
    }
    .form .formBG{
        height: 144rem;
        display: flex;
        justify-content: center;
        padding: 58px;
        margin-bottom: 38rem;
        border-radius: 34px;
    }
    .home_page-css{
        width: 100%;
    }
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
        max-width: 100% !important;
    }
    .form .formBG .formText {
        font-size: 74px !important;
        margin-bottom: 65px !important;
    }
    .form .formBG hr{
        height: 3px;
        margin-bottom: 78px !important;
    }
    .form .formBG .heading {
        color: #04B23D;
        font-size: 78px !important;
        margin-bottom: 40px;
    }
    .form .formBG .headText {
        font-size: 51px !important;
    }
    .form .formBG .headText img{
        width: 46px;
    }
    .form .formBG .benefit{
        margin-top: 82px;
    }
    .form .formBG .benefits .mx-5 img{
        width: 252px;
        margin-left: 102px;
    }
    .p-css-1 {
        margin-bottom: 5rem;
    }
    .formCard .formCardBody {
        width: 122rem;
    }
}
@media only screen and (max-width: 3072px) and (min-width: 2733px){
    .form .formBG .headText {
        font-size: 43px !important;
    }
}
@media only screen and (max-width: 3051px) and (min-width: 2733px){
.form .formBG .headText {
    font-size: 41px !important;
}
}
@media only screen and (max-width: 2732px) and (min-width: 2561px){
    .origo-text .text1 {
        font-size: 48px;
    }
    .origo-text .text2 {
        font-size: 29pt !important;
        margin-bottom: 13rem;
        margin-top: 0rem;
    }
    .form .formBG .formText {
        font-size: 48px !important;
        margin-bottom: 55px !important;
    }
    .form .formBG .heading {
        color: #04B23D;
        font-size: 67px !important;
        margin-bottom: 19px;
    }
    .headText{
        font-size: 2.5rem;
    }
    .form .formBG .headText {
        font-size: 26px !important;
    }
    .form .formBG {
        height: 108rem;
    }
}
@media only screen and (max-width: 2560px) and (min-width: 1441px){
    .form{
        margin-bottom: 32rem;
    }
    .origo-text .text-3-css{
        font-size: 42px;
    }
    .origo-text .text1{
        font-size: 46px;
    }
    .origo-text .text2{
        font-size: 26pt !important;
        margin-bottom: 12rem;
        margin-top: 2rem;
    }
    .form .formBG .formText {
        font-size: 53px !important;
        margin-bottom: 53px !important;
    }
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
        max-width: 100% !important;
    }
    .form .formBG .heading {
        color: #04B23D;
        font-size: 60px !important;
        margin-bottom: 29px;
    }
    .form .formBG hr {
        height: 3px;
        margin-bottom: 55px !important;
    }
    .form .formBG .headText {
        font-size: 33px !important;
    }
    .form .formBG .benefits {
        display: flex;
        margin-top: 46px;
        grid-gap: 29px;
        gap: 29px;
    }
    .form .formBG .benefits .mx-5 img {
        width: 189px;
    }
    .form .formBG {
        height: 105rem;
        padding: 37px;
        margin-bottom: 20rem;
        border-radius: 23px;
    }
    .form .formBG .headText img {
        width: 32px;
    }
    .p-tag-1-css {
        margin-top: 1rem;
    }
}
@media only screen and (max-width: 2300px) and (min-width: 2049px){
    .origo-text .text1 {
        font-size: 39px;
    }
    .origo-text .text2 {
        font-size: 22pt !important;
        margin-bottom: 12rem;
        margin-top: 2rem;
    }
    .form .formBG .formText {
        font-size: 39px !important;
        margin-bottom: 39.7px !important;
    }
    .form .formBG .heading {
        color: #04B23D;
        font-size: 50px !important;
        margin-bottom: 10px;
    }
    .headText{
        font-size: 2rem;
    }
    .form .formBG .headText {
        font-size: 24px;
    }
    .form .formBG {
        height: 90rem;
        padding: 37px;
        margin-bottom: 20rem;
        border-radius: 23px;
    }
}
@media only screen and (max-width: 2048px) and (min-width: 1441px){
    .origo-text .text-3-css{
        font-size: 40px;
    }
    .form .formBG {
        height: 96rem;
        border-radius: 22px;
    }
    .form .formBG .formText {
        font-size: 34px !important;
    }
    .form .formBG .heading {
        font-size: 49px !important;
    }
    .form .formBG .headText {
        font-size: 25px !important;
    }
    .form .formBG .headText img{
        width: 30px;
    }
    .form .formBG .benefits .mx-5 img {
        width: 177px;
    }
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1545px !important;
    }
}
@media only screen and (max-width: 1920px) and (min-width: 1441px){
   .origo-text .text-3-css{
        font-family: MetropolisMedium !important;
        font-size: 31px;
        font-weight: 400;
        color: #2F2F2F;
    }
    .origo-text .text1 {
        font-size: 33px;
    }
    .origo-text .text2{
        font-size: 21pt !important;
    }
    .form .formBG {
        height: 80rem;
        padding: 37px;
        margin-bottom: 13rem;
        border-radius: 18px;
    }
    .form .formBG hr {
        height: 2px !important;
        margin-bottom: 47px !important;
    }
    .form .formBG .benefits {
        display: flex;
        margin-top: 46px;
        grid-gap: 29px;
        gap: 29px;
    }
    .form .formBG .benefits .mx-5 img {
        width: 136px;
        margin-left: 20px;
    }
    .form .formBG .headText img {
        width: 27px;
    }
    .form .formBG .headText {
        font-size: 22px !important;
    }
}
@media only screen and (max-width: 1822px) and (min-width:1709px){
    .form .formBG .formText {
        font-size: 27px !important;
    }
    .form .formBG .heading {
        font-size: 40px !important;
    }
    .headText{
        font-size: 1.3em;
    }
    .form .formBG .headText {
        font-size: 21px;
    }
}
@media only screen and (max-width: 1708px) and (min-width:1681px){
    .form .formBG .formText {
        font-size: 31px !important;
    }
    .form .formBG .heading {
        font-size: 43px !important;
    }
    .headText{
        font-size: 1.4em;
    }
    .form .formBG .headText {
        font-size: 21px !important;
    }
}
@media only screen and (max-width: 1600px) and (min-width: 1441px){
    .origo-text .text1 {
        font-size: 27px;
    }
    .origo-text .text2 {
        font-size: 16pt !important;
    }
    .form .formBG .formText {
        font-size: 29px !important;
        margin-bottom: 31px !important;
    }
    .form .formBG {
        height: 67rem;
        padding: 20px;
        margin-bottom: 13rem;
    }
    .form .formBG hr {
        height: 2px !important;
        margin-bottom: 30px !important;
    }
    .form .formBG .heading {
        font-size: 38px !important;
        margin-bottom: 12px;
    }
    .form .formBG .headText {
        font-size: 20px !important;
    }
    .form .formBG .benefits {
        margin-top: 28px;
    }
    .form .formBG .benefits .mx-5 img {
        width: 141px;
    }
}
@media only screen and (max-width: 1536px) and (min-width: 1519px){
.form .formBG .headText {
    font-size: 19px !important;
}
}
@media only screen and (max-width: 1518px) and (min-width: 1441px){
    .form .formBG .formText {
        font-size: 27px !important;
        margin-bottom: 31px !important;
    }
    .form .formBG .heading {
        font-size: 35px !important;
        margin-bottom: 12px;
    }
    .headText{
        font-size: 16.5px;
    }
    .form .formBG .headText {
        font-size: 18px !important;
    }
}
@media only screen and (max-width: 1440px) and (min-width: 1367px){
    .form .formBG {
        /* position: absolute; */
        width: 82%;
    }
    .form .formBG .benefits .mx-5 img {
        width: 91px;
        margin-left: 38px;
    }
    .form .formBG .headText small {
        font-size: 0.94em !important;
    }
    .form{
        margin-bottom: -6px;
    }
}
@media only screen and (max-width: 1366px){
    .form .formBG {
        /* position: absolute; */
        width: 83%;
    }
    .formBG {
        margin-bottom: 0%;
    }
    .form{
        margin-bottom: 30rem;
    }
}
@media only screen and (max-width: 1280px){
    .form{
        margin-bottom: 28rem;
    }
}
@media screen and (max-width: 1024px) {
    .form .formBG .headText {
        font-size: 12px !important;
    }
    .form .formBG .headText small {
        font-size: 1em !important;
    }
    .upperHeader, .marqueDiv {
        padding-left: 6%;
    }
    .navbarDiv {
        padding-left: 20%;
    }
}
@media screen and (max-width: 992px) {
    .formBG .col-lg{
        width: 54%;
    }
    .formCard .formCardBody {
        width: 322px;
    }
}
@media screen and (max-width: 919px) {
    .formBG .col-lg{
        width: 53%;
    }
}
@media screen and (max-width: 900px){
    .formCard .formCardBody {
        width: 99%;
    }
}
@media screen and (max-width: 893px) {
    .formBG .col-lg .formText{
        font-size: 16px !important;
    }
    .form .formBG .headText {
        font-size: 9px !important;
    }
    .form .formBG .benefits {
        gap: 0px;
    }
    .formBG .col-lg {
        width: 49%;
    }
    .form .formBG .benefits>div:first-child{
        width: 60%;
    }
}
@media screen and (max-width: 827px) {
    .form .formBG .benefits>.mx-5{
        display: none;
    }
    .formBG .col-lg {
        width: 46%;
    }
    .form .formBG .benefits > div:first-child {
        width: 90%;
    }
    .form .formBG .headText {
        font-size: 13px !important;
    }
}
@media screen and (max-width: 823px) {
    .break {
        display: none;
    }
    .separater-hr{
        display: flex;
        justify-content: center;
    }
    .text-4-css {
    flex-basis: 100%;
    text-align: center;
    }
}
@media only screen and (max-width: 782px){
    .formCard .card-body .inner-input {
        width: 154px !important;
        height: 100% !important;
    }
}
@media only screen and (max-width: 775px){
    .form .formBG .headText {
        font-size: 12px !important;
    }
    .form-css-1 {
        padding: 0.6rem 2rem !important;
    }
}
@media only screen and (max-width: 741px){
    .formCard .card-body .inner-input{
        padding: 0.375rem 2rem !important;
    }
}
@media only screen and (max-width: 741px){
    .formCard .card-body .inner-input{
        padding: 0.375rem 0.5rem !important;
        width: 80% !important;
    }
    
}
@media screen and (max-width: 630px) {
    .formBG .col-lg {
        width: 100% !important;
    }
    .formBG .col-lg .formText {
        font-size: 21px !important;
    }
    .formBG .col-lg .heading{
        font-size: 29px;
    }
    .form .formBG .headText {
        font-size: 14px !important;
    }
    .formCard .formCardBody {
        margin-top: 57px;
    }
    .form {
        margin-bottom: 23rem;
    }
    .prefix-1 {
        font-size: 14px !important;
    }
    .formCard .card-body .inner-input {
        padding: 0.375rem 1.5rem !important;
        width: 80% !important;
    }
    .p-tag-1-css {
        font-family: MetropolisMedium !important;
        margin-top: 1rem;
    }
    .formCard {
        margin-right: 4%;
    }
    .home_page-css{
        height: fit-content;
    }
    .formCard{
        margin-bottom: 49px;
    }
}
@media screen and (max-width: 479px) {
.origo-text .text1 {
    font-size: 20px;
}
.origo-text .text2{
    font-size: 10pt !important;
}
.origo-text {
    margin-top: 8%;
}
}
@media screen and (max-width: 426px){
    .form .formBG .headText {
        font-size: 13px !important;
    }
}
@media screen and (max-width: 403px) {
    .formBG .col-lg .formText {
        font-size: 16px !important;
    }
    .form .formBG .headText {
        font-size: 12px !important;
    }
}
@media screen and (max-width: 397px){
.origo-text .text1 {
    font-size: 18px;
}
.origo-text .text2 {
    font-size: 9pt !important;
}
}
@media screen and (max-width: 334px) {
    #exampleInputEmail1, .form-commodity-css{
        padding: 0.375rem 1rem !important;
    }
    .formCard {
        margin-right: 0%;
    }
}
@media screen and (max-width: 263px) {
    .home_page-css{
        height: fit-content !important;
    }
}

