.inputOTP-content-section {
	display: flex;
	margin: 0.5em;

	.inputOPT-label{
		width: 3em;
		label{
			position: relative;
			top: 25%;
		}
	}

	.p-inputtext {
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #d8d8d8;
		border-radius: 7px;
		height: 40px;
		width: 3em;
		box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
		margin: 0.2em;
		text-align: center;
		outline: none;
	}

}
