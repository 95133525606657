@media only screen and (min-width:600px) {

    .update-main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .update-ctn {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }

    .update-multi-ctn {
        margin:20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .label-edit {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        margin-top: 16px;
        font-weight: 600;
    }

    .back-ctn{
        display: flex;
        flex-direction:row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 20px;
    }

}