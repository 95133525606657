@media only screen and (max-width: 400px) {
    .register-Landing-page{
        .container-cls{
            width: 100%;
            // height: 70vh;
        }

        .register-container-cls{
            .container-cls{
                .fields-cls{
                    display: block;
                    padding: 0.5em;
                    .names-div{
                        display: block;
                    }
                }
            }

        }
    }
}