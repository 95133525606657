.emandi-page-cls{
    font-family: 'Poppins', sans-serif;

    .header-cls {
		display: flex;
		margin: 0.5em 1em;
		justify-content: start;
		img {
			width: 2em;
			height: 2em;
			// border: 2px solid bisque;
			border-radius: 50%;
			padding: 4px;
			box-shadow: 1px 1px 6px;
		}
		p {
			margin: 0.5em;
			font-size: 16px;
		}

		.pi-angle-left {
			margin: 0.5em 0em;
			font-size: 16px;
			box-shadow: 0px 0px 20px #00000029;
			border-radius: 50%;
			height: 1.5em;
			width: 1.5em;
			text-align: center;
			line-height: 1.5em;
			cursor: pointer;
		}
	}
    .content-cls{
        .steps-cls{
            margin: 0 2.8em;
            padding: 0.5em
        }
        h3{
            margin: 0 2.2em;
            padding: 0.5em

        }
        .sucess-label{
            margin: 0;
            padding: 0;
        }
        .sucesspage{
            width: 50%;
            margin: 1em;
            margin-left: 4em;
            .continue-cls{
                display: flex;
                margin-top: 1em;
                align-items: center;
                width: 100%;
                font-weight: bold;
                color         : #ffffff;
                border        : 1px solid #0f9d58;
                background-color:#0f9d58;
                padding       : 12px 10px;
                border-radius : 5px;
                letter-spacing: 1px;
                font-size     : 16px;
                cursor: pointer;
                box-shadow    : 0px 0px 3px 2px #0000001A;
                justify-content: center;
        .button-content-section {
            display        : flex;
            justify-content: center;

            .p-button {
                width: 100%;
                font-weight: bold;
                color         : #ffffff;
                border        : 1px solid #0f9d58;
                background-color:#0f9d58;
                border-radius : 5px;
                letter-spacing: 1px;
                font-size     : 16px;

            }
        }
    }
            .sucesspage-fields{
                display: flex;
                border-radius: 10px;
                border: 1px solid lightgray;
                width: 100%;
                justify-content: space-around;
            }
            .confirm-msg-cls{
                font-size: 16px;
            }
            .confirm-msg1-cls{
                font-size: 14px;
                font-weight: bold;
            }
            .label-cls{
                font-weight: bold;
            }
            .pi{
                font-size: 3em;
                color: green;
            }
        }
        .businesscontent-cls{
            display: flex;
            justify-content: space-between;
            margin: 0 3em;
          
            .list-cls{
                background-color: #f5f5f5;
                 padding: 1.5em;
                 margin: 0 1em;
                 border-radius: 8px;
                 height: fit-content;
                .menu-cls{
                    >div{
                        display: flex;
                        align-items: center;
                        .pi{
                            color: #0f9d58;
                            margin: 0 0.5em;
                        }
                    }
                }
            }
            .header{
                display: flex;
                justify-content: space-between;
                >div{
                    .user-name{
                        color: #000000;
                        font-weight: bold;
                        font-size: 14px;
                        padding: 0 10px
                    }
                    p{
                        font-weight: bold;
                        font-size: 20px;
                        span{
                            color: #0f9d58;
                        }
                    }
                  
                }
            }
        }

        .terms-agreeing{
            text-decoration: underline;
            color: #000000;
        }
        .terms-conditions{
            margin-left: 25em;
            text-decoration: underline;
            cursor: pointer;
        }
        .busineesdetailspage{
            margin: 1em;
           
            // .terms-conditions{
            //     // text-align: center;
            //     // text-decoration: underline;
            //     // padding-left: 50px;
            // }
            .busineesdetailspage-fields{
                padding: 1em;
                 box-sizing: border-box;
                border-radius: 10px;
                border: 1px solid lightgray;
                width: 40%;
                .filename-cls{
                    font-size: 12px;
                    cursor: pointer;
                }
                .inputfield-cls {
                    // padding: 1em;
                    p {
                        margin     : 1em 0px;
                        font-size  : 18px;
                        color      : black;
                        font-weight: bold;
                    }
    
                    .input-cls {
                        .inputtext-content-section .p-inputtext {
                            margin       : 0;
                            width        : 100%;
                            height       : 50px;
                            border-radius: 5px;
                            border       : none;
                            box-shadow   : 0px 0px 2px 2px #0000001A;
                        }
                    }
                }
                .radiofield-cls{
                        p {
                            margin     : 1em 0px 0px 0px;
                            font-size  : 18px;
                            color      : black;
                            font-weight: bold;
                            // padding: 0 1em;
                        }

                    .radio-btn-class {
                        // display: flex;
                        // padding: 1em;
                        .selected{
                            font-size   : 14px;
                            margin  : 1em 0;
                            display: flex;
                            justify-content: space-between;
                            height: 50px;
                            align-items: center;
                            padding: 0px 16px;
                            border: 2px solid green;
                            border-radius: 10px;
                        }
                        .p-field-radiobutton {
                            font-size   : 14px;
                            margin  : 1em 0;
                            display: flex;
                            justify-content: space-between;
                            height: 50px;
                            align-items: center;
                            padding: 0px 16px;
                            border: 2px solid lightgray;
                            border-radius: 10px;
                            label{
                                font-weight: bold;
                            }
                           
                            .p-radiobutton {
                                border       : 1px solid black;
                                border-radius: 50%;
                                width        : 14px;
                                height       : 14px;

                                .p-highlight {
                                    background: black;
                                    height    : 12px;
                                    width     : 12px;

                                    .p-radiobutton-icon {
                                        font-size : 0.5em;
                                        margin-top: -.5em;
                                        color     : #fff;
                                    }
                                }
                            }
                        }
                    }
                }
                .continue-btn-enabled {
                    background   :green;
                    color        : #FFFFFF;
                    border       : 1px solid #e43137;
                    padding      : 1em;
                    border-radius: 10px;
                    font-weight  : bold;
                    width: 100%;
                    margin-top: 2em;
                }
                .continue-btn-disabled {
                    background   :gray;
                    color        : #FFFFFF;
                    border       : 1px solid #e43137;
                    padding      : 1em;
                    border-radius: 10px;
                    font-weight  : bold;
                    width: 100%;
                    margin-top: 2em;
                }
                
                .inputFile-cls {
                    label {
                        .inputtext-content-section .p-inputtext.p-disabled {
                            width           : 257px;
                            background-color: #fff;
                            border          : none;
                            border-radius   : 5px;
                            height          : 30px;
                            box-shadow      : 0px 0px 2px 2px #0000001A;
                        }

                        input[type="file"i] {
                            margin-top    : 0px;
                            /* margin-left: -250px; */
                            opacity       : 0;
                            position      : relative;
                            top           : -25px;
                            z-index       : 100;
                        }

                        img {
                            position   : relative;
                            margin-left: 235px;
                            top        : 22px
                        }

                    }

                }
                .head-cls{
                    font-weight: bold;
                }
                .label-cls{
                    font-size: 14px;
                }

            }
           
           
        }
        .cashlimitpage{
            margin: 1em;
.cashlimit-fields{

    // width: 40%;
    .commoditycheck-cls {
        display     : flex;
        margin-top  : -10px;
        margin-right: 2em;

        .label-cls {
            // width: 7em;
            font-size: 12px;
            font-family: sans-serif;
            letter-spacing: 0px;
            color: #00000099;
            opacity: 1;
            margin-top: 1em;
            p {
                font-size     : 12px;
                font-family   : sans-serif;
                letter-spacing: 0px;
                color         : #00000099;
                opacity       : 1;
            }
        }
        .etermConditions{
text-decoration: underline;
cursor: pointer;
        }
        .showCheckbox-cls {
            margin: 7px;

            .p-checkbox {
                align-items  : center;
                border       : 2px solid #000;
                font-size    : 16px;
                font-family  : sans-serif;
                color        : #000000;
                opacity      : 1;
                width        : 16px;
                height       : 16px;
                background   : #fff;
                border-radius: 3px;
            }

            .pi-check {
                font-size : 12px;
                width     : 12px;
                height    : 12px;
                background: #fff;
            }
        }
    }
    .recomended-label{
        font-size:14px;
        padding:2px 4px;
        border-radius:4px;
        background-color: orange;
    }
            .radio-btn-class {
                // display: flex;
                // padding: 1em;
                .selected{
                    font-size   : 14px;
                    margin  : 1em 0;
                    display: flex;
                    justify-content: space-between;
                        height: 80px;
                        align-items: center;
                    padding: 0px 16px;
                    border: 2px solid green;
                    border-radius: 10px;
                }
                .p-field-radiobutton {
                    font-size   : 14px;
                    margin  : 1em 0;
                    display: flex;
                    justify-content: space-between;
                        height: 80px;
                        align-items: center;
                    padding: 0px 16px;
                    border: 2px solid lightgray;
                    border-radius: 10px;
                    label{
                        font-weight: bold;
                    }
                    span{
                        font-weight: 100;
                        font-size: 12px;
                    }
                    .p-radiobutton {
                        border       : 1px solid black;
                        border-radius: 50%;
                        width        : 14px;
                        height       : 14px;

                        .p-highlight {
                            background: black;
                            height    : 12px;
                            width     : 12px;

                            .p-radiobutton-icon {
                                font-size : 0.5em;
                                margin-top: -.5em;
                                color     : #fff;
                            }
                        }
                    }
                }
            }
            .continue-btn-enabled {
                background   :green;
                color        : #FFFFFF;
                border       : 1px solid #e43137;
                padding      : 1em;
                border-radius: 10px;
                font-weight  : bold;
                width: 100%;
                margin-top: 2em;
            }
            .continue-btn-disabled {
                background   :gray;
                color        : #FFFFFF;
                border       : 1px solid #e43137;
                padding      : 1em;
                border-radius: 10px;
                font-weight  : bold;
                width: 100%;
                margin-top: 2em;
            }
        }
        }
        
    }
    .emandiTV-dailog{
		.buy-now-dialog{
			.custom-dialog{
				.p-dialog{

					.p-component{
						.p-dialog-enter-done{
							width: 20vw !important;
						}
					}
				}
				.p-dialog-mask{
					.p-dialog{
						
						.p-dialog-content {
							padding: 0 !important;
							border-bottom-left-radius: 10px;
							border-bottom-right-radius: 10px;
							background-color: #ffffff;
							// background: linear-gradient(180deg, #DEDEDE 0%, #fff);
						}
						.p-dialog-titlebar{
							font-family: 'Poppins', sans-serif;
							font-size: 25px;
							display: flex;
							justify-content:center;
							border: none !important;
							border-top-left-radius: 10px;
							border-top-right-radius: 10px;
							background: linear-gradient(180deg, #DEDEDE 0%, #fff);
							p{
								font-size: 16px;
								margin: 0;
								font-weight: bold;
								text-align: center;
								color: #333333;
								border: none;
								margin-top: 0.25em;
							}

							.p-dialog-titlebar-icons{
								display: none;
							}
						}
						.p-dialog-footer{
							background-color: #eff1f2;
							display: none;
								>div{
									justify-content: flex-end;
									margin-top     : -60px;
								}
						}
					}
				}
			}
			
			.p-dialog-footer {
		
				.cancel {
					display: none;
				}
		
				.accept {
					display       : none;
				}
			}
		.buynow-cls {
				.btns-cls{
                    display: flex;
                    justify-content: center;
					>div{
						margin: 0.5em 0em;
						display: flex;
						justify-content: center;
						padding: 10px;
						.cancel{
						background-color: #707070;
						}
					}
                    .button-content-section{
                        .cancel{
                                 display        : flex;
                                justify-content: center;
                                margin     : 1em 1em;
                                color         : #ffffff;
                                border        : 1px solid #707070;
                                padding       : 4px 10px;
                                border-radius : 5px;
                                letter-spacing: 1px;
                                font-size     : 16px;
                                box-shadow    : 0px 0px 3px 2px #0000001A;
                    } 
                    }
				.button-content-section{
                    .send{
                            display        : flex;
                            justify-content: center;
                            margin     : 1em 1em;
                            color         : #ffffff;
                            border        : 1px solid #707070;
                            padding       : 4px 10px;
                            border-radius : 5px;
                            letter-spacing: 1px;
                            font-size     : 16px;
                            box-shadow    : 0px 0px 3px 2px #0000001A;
                            background-color: #707070;
                    }
                }
			}
		}
		}
	}
    
}

@media only screen and (max-width: 1032px){
    .account-detils .account-detils-scrollbar > div {
        flex-direction: column;
    }
    .account-detils .edit-bank-details {
        width: 95% !important;
    }
}
@media only screen and (max-width: 873px){
    .emandi-page-cls .content-cls .businesscontent-cls{
        flex-direction: column;
        gap: 1rem;
    }
    .emandi-page-cls .content-cls .busineesdetailspage .busineesdetailspage-fields {
        width: 100%;
    }
}
@media only screen and (max-width: 873px){
    #pr_id_2{
        width: 85vw !important;
    }
}
@media only screen and (max-width: 591px){
    .account-detils .edit-bank-details .card .add-withdraw-card .add-new-deposit, .account-detils .edit-bank-details .card .add-withdraw-card .withdraw-deposit {
        flex-direction: column;
    }
    .account-detils .edit-bank-details .card {
        height: 60% !important;
    }  
    .account-detils .edit-bank-details .card .add-withdraw-card {
        height: 100% !important;
    }  
    .account-detils .edit-bank-details .card .inputtext-content-section {
        display: flex;
        flex-direction: column;
        width: 97% !important;
    }
    .account-detils .edit-bank-details .card {
        width: unset;
    }

}
@media only screen and (max-width: 447px){
    .userProfileBlock-cls .userDetails-cls .label-cls p {
        color: #fff;
        font-size: 14px;
    }
    .userProfileBlock-cls .userDetails-cls .logout-cls p {
        color: #E43137;
        font-size: 14px;
    }
    .app-header .eauctionHeader .header-logo {
        height: 3.3em;
        margin-left: 4.4em;
    }
}
@media only screen and (max-width: 415px){
    .emandi-page-cls .content-cls .busineesdetailspage .busineesdetailspage-fields .inputFile-cls label img {
        margin-left: 209px;
    }
    .account-detils .funds-bank .showBankDetails .bankInfo-cls {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: fit-content !important;
    }
}
@media only screen and (max-width: 375px){
    .emandi-page-cls .content-cls .busineesdetailspage .busineesdetailspage-fields .inputfield-cls p {
        font-size: 14px;
    }
    .emandi-page-cls .content-cls .busineesdetailspage .busineesdetailspage-fields .radiofield-cls p {
        font-size: 14px;
    }
    .emandi-page-cls .content-cls .busineesdetailspage .busineesdetailspage-fields .inputFile-cls label img {
        margin-left: 185px;
    }
}
@media only screen and (max-width: 345px){
    .emandi-page-cls .content-cls .busineesdetailspage .busineesdetailspage-fields .inputFile-cls label img {
        margin-left: 160px;
    }
    .account-detils .edit-bank-details .card .add-withdraw-card .add-new-deposit .add-deposit-amount, .account-detils .edit-bank-details .card .add-withdraw-card .withdraw-deposit .add-deposit-amount {
        width: 10em !important;
    }
    .account-detils .edit-bank-details .card .add-withdraw-card .add-new-deposit .withdraw-amount, .account-detils .edit-bank-details .card .add-withdraw-card .withdraw-deposit .withdraw-amount {
        width: 10em !important;
    }
}
@media only screen and (max-width: 317px){
    .emandi-page-cls .content-cls .busineesdetailspage .busineesdetailspage-fields .inputFile-cls label img {
        margin-left: 120px;
    }
}
@media only screen and (max-width: 297px){
    #pr_id_2 {
        width: 98vw !important;
    }
}