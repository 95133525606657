.custom-dialog {

    .p-dialog-mask {
        background-color: rgba(0, 0, 0, 0.4);

        .p-dialog {

            .p-dialog-titlebar {
                border-bottom          : 1px solid #0000004C ;
                background             : #FFFFFF;
                color                  : #000000CC;
                padding                : 1em;
                border-top-right-radius: 3px;
                border-top-left-radius : 3px;
                font-family            : sans-serif;
                padding: 0.5em;

                .p-dialog-titlebar-icons{
                    // display: none;
                }

            }

            .p-dialog-content {
                background: #EFF1F2;
                color     : #0000004C;
                padding   : 0 ;
            }

            .p-dialog-footer {
                border-top                : 0 none;
                background                : #ffffff;
                color                     : #495057;
                padding                   : 1em;
                text-align                : right;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius : 3px;

                >div {
                    display        : flex;
                    justify-content: space-between;

                    .cancel {
                        background-color: transparent;
                        color           : #E43137;
                        border-color    : transparent;
                    }

                    .accept {
                        color        : #ffffff;
                        background   : #E43137;
                        border       : 1px solid #E43137;
                        padding      : 0.1em 1em;
                        font-size    : 1em;
                        transition   : background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
                        border-radius: 3px;
                    }

                }
            }
        }
    }
}