@font-face {
    font-family: MetropolisRegular;
    src: url('./Metropolis-Regular.otf')
}

@font-face {
    font-family: MetropolisMedium;
    src: url('./Metropolis-SemiBold.otf')
}
.eCashHome {
    min-height: 635px;
    background-image: url('../Images/eCash_rect.png');
    display: flex;
    justify-content: center;
    padding-top: 10rem;
    padding-bottom: 8rem;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
}
.greenEcashText{
    font-family: MetropolisMedium;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
}
.text-section {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width:45%;
    gap: 3rem;
}

.instant-cash {
    color: var(--brand-grey, #2F2F2F);
    font-family: MetropolisMedium;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: -0.24px;
}
.explore {
    position: relative;
    color: var(--icon-primary, #757575);
    font-family: MetropolisMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.14px;
    padding-bottom: 12px;
}   
.explore::before{
    content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 63%;
  height: 1px; /* Adjust this value to control the thickness of the border */
  background-color: white;
}
.eCashHome .continue-bottom {
    padding: 11px;
    height: 103px;
    width: 103%;
    background-color: #fff;
    box-shadow: 2px 0px 12px rgba(92, 138, 152, 0.075);
    margin-left: -29px;
    /* font-size: 20px; */
  }
  .coins-section {
    margin-top: 25px;
    margin-left: 50px;
  }
  .carousel-container {
    position: relative;
    width: 84%;
    height: 50px;
    overflow: hidden;
  }
  
  .slide {
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    gap: 1rem;
  }
  
  .slide > span {
    color: var(--brand-grey, #2F2F2F);
    font-family: MetropolisMedium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.5px;
    letter-spacing: -0.18px;
  }
  
  .custom-dots {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    margin-top: 10px;
  }
  
  .custom-dots li {
    margin: 0 5px;
  }
  
  .custom-dots li button {
    width: 8px !important;
    height: 8px !important;
    border-radius: 50% !important;
    background-color: white !important;
    border: none !important;
    outline: none !important;
    cursor: pointer !important;
    padding: 0 !important;
  }
  
  .custom-dots li button:before {
    display: none !important;
  }
  
  .custom-dots li.slick-active button {
    background-color: #FDE96C !important;
    width: 16px !important;
  }
  
  .slick-arrow {
    display: none !important;
  }
  
  