.contact-us-dialog {
    
    .custom-dialog .p-dialog .p-component .p-dialog-enter-done{
        width: 80vw !important;
    }
    .custom-dialog .p-dialog-mask .p-dialog .p-dialog-content {
        padding: 5px 25px;
    }

    .custom-dialog .p-dialog-mask .p-dialog .p-dialog-footer {
        background-color: #eff1f2;

    }

    .custom-dialog .p-dialog-mask .p-dialog .p-dialog-footer>div {
        justify-content: flex-end;
        margin-top     : -60px;
    }

    .custom-dialog .p-dialog-titlebar-icons {
        display: block !important;
    }

    .p-dialog-footer {

        .cancel {
            display: none;
        }

        .accept {
            display       : none;
        }
    }

    .p-dialog-content {}

}

.contact-us {
    padding: 2em !important;
    ;
    display        : flex !important;
    justify-content: space-around;

    .contact-us-scrollbar {
        >div {
            display: flex !important;
            ;
            justify-content: space-around !important;
            ;
        }
    }

    .location-details {
        width: 50%;

        .contact-details-wrapper {
            display: flex;
            margin : 3em 0;

            >p {
                font-size     : 18px;
                margin        : 0px;
                font-family   : sans-serif;
                color         : #122025;
                letter-spacing: 1px;
            }

            img {
                margin-right: 1em;
                padding     : 0;
            }

            .address {
                position: relative;
                bottom  : 2.5em;
            }
        }

    }

    .message-us {
        width: 50%;

        >p {
            font          : normal normal bold 22px/16px sans-serif;
            letter-spacing: 1px;
            color         : #122025;
        }

        .inputtext-content-section {
            width     : 100%;
            margin-top: 2em;

            input {
                width         : 80%;
                border        : none;
                border-bottom : 1px solid #707070;
                font          : normal normal normal 14px/19px Roboto;
                letter-spacing: 0.7px;
                color         : #000000;
                height        : 2em;
            }
        }

        .inputarea-content-section {
            width     : 100%;
            margin-top: 2em;

            textarea {
                width         : 80%;
                border        : none;
                border-bottom : 1px solid #707070;
                font          : normal normal normal 14px/19px Roboto;
                letter-spacing: 0.7px;
                color         : #000000;
                height        : 5em;
                padding-left  : 1em;
            }
        }

        .button-content-section {
            display        : flex;
            justify-content: center;
            margin-top     : 1em;

            .p-button {
                color         : #ffffff;
                background    : #e43137;
                border        : 1px solid #e43137;
                padding       : 3px 5px;
                border-radius : 5px;
                letter-spacing: 1px;
                font-size     : 16px;
                box-shadow    : 0px 0px 3px 2px #0000001A;

            }
        }



    }
}