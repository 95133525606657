@font-face {
  font-family: metropolisLight;
  src: url(Metropolis-Light.otf);
}

@font-face {
  font-family: metropolisSemiBold;
  src: url(Metropolis-SemiBold.otf);
}

@font-face {
  font-family: Metropolis-Regular;
  src: url(Metropolis-Regular.otf);
}

@font-face {
  font-family: metropolisBold;
  src: url(Metropolis-Black.otf);
}

.left-div {
  background-image: url("../../../assets/images/Group86209.png");
  height: 220px;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: 30px;
  float: left;
}

.right-div {
  background-image: url("../../../assets/images/Group86208.png");
  height: 220px;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: center;
  float: right;
}

.add-buyer-button {
  border-radius: 6px;
  color: white;
  background-color: #04b23d;
  border: none;
}

.addbuyer-table {
  width: 50%;
  border-radius: 10px;
  background-color: white;
  transform: translate(38px, -27px);
}

.addbuyer-th {
  width: 30%;
  text-align: left;
  padding-left: 20px;
  font-size: 12px !important;
}

.addbuyer-table input,
.addbuyer-table select {
  font-family: metropolisLight;
  width: 300px;
  font-size: 11px;
  height: 30px;
  outline-color: transparent;
}
.submit-button:hover {
  border-style: none !important;
  border-radius: 5px;
  background-color: #04b23d;
  color: white;
}

.btn-custom,
.btn-custom:hover {
  border-style: none !important;
  margin-right: 15px;
  padding-inline: 16px;
  border-radius: 5px;
  background-color: #04b23d;
  color: white;
  width: 120px;
}

.button-discard {
  margin-right: 15px;
  padding-inline: 16px;
  border-radius: 5px;
  color: #979797;
  border-color: #979797;
  background-color: white;
  border-width: 1.5px;
  pointer-events: none !important;
  width: 120px;
}
#buyerModal .modal-content {
  width: 420px;
}

.successImage {
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.addbuyer-table input::placeholder {
  font-size: 10px;
  font-family: metropolisLight;
}

/* .addbuyer-table select::placeholder{
    font-size: 10px;
    font-family: metropolisLight;
} */

.dropdown-icon {
  background-image: url("../Images/arrow_down_icon.png");
  background-repeat: no-repeat;
  background-position: 270px 4px;
}
