/* .circular-loader {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: white;
    margin: 0 auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .loader-fill {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #04B23D;
    transform: rotate(0deg);
    clip: rect(0, 50px, 100px, 0);
  }
   */


   /* .circular-loader {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto;
  }
  
  .loader-fill {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: transparent;
    border: 6px solid #04B23D;
    border-top-color: transparent;
    transform-origin: center center;
  } */

  /* .circular-loader {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0 auto;
  }
  
  .loader-fill {
    position: absolute;
    width: 100%;
    height: 100%;
  }
   */

   

   .circular-loader {
    position: relative;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin: 0 auto;
  }
  
  .loader-fill {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  