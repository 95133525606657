.load-mask-cls {
    height: 81vh;
    //  position: relative;

    &::before {
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9;
        position: absolute;
        background: rgba(0, 0, 0, 0.06);
    }

    .load-list-item-cls {
        margin: 0.5em;
        width: 0.72em;
        height: 0.72em;    
        display: inline-flex;
        background: #009D45;
    }

    .load-cont-cls {
        z-index: 9;
        width: 9em;
        position: absolute;
        top: calc(50% - 12px);
        left: calc(50% - 12px);
    
        .custom-load-text-cls {
            color: #1D1D1D;
            text-align: center;            
            letter-spacing: 0.05em;
        }
    }
}