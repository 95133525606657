@font-face {
    font-family: MetropolisRegular;
    src: url('./Metropolis-Regular.otf')
}

@font-face {
    font-family: MetropolisMedium;
    src: url('./Metropolis-SemiBold.otf')
}
@font-face {
    font-family: MetropolisBold;
    src: url('./Metropolis-Bold.otf')
}
.ecash-right-container{
    padding: 0rem 5rem !important;
    padding-top: 10rem !important;
    position: relative;
    padding-bottom: 18rem !important;
}
.rightInnerSection{
    width: 70%;
}
.BackContainer{
    display: flex;
    gap: 1rem;
    position: absolute;
    top: 35px;
    cursor: pointer;
}
.back-text{
    color: #545050;
    font-family:  MetropolisMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.ecashProfilePic{
    width: 6rem;
    height: 6rem;
    padding: 0px !important;
}
.profilePic-welcome-support{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 4rem;
    border-bottom: 1px solid #ECECEC;
}
.profilePic-welcome-support-form3Page{
    flex-direction: row !important;
    align-items: center !important;
}
.welcome-text-div , .welcome-text-div-from3Page{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.ecash-text {
    color: #04B23D;
    font-family: Metropolis-Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.welcome {
    color: #2F2F2F;
    font-family: MetropolisBold;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.almostHere{
    color: #2F2F2F;
    font-family: MetropolisMedium;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.support {
    color: var(--brand-grey, #2F2F2F);
    font-family: MetropolisMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.panUpload-div{
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 5rem;
} 
.deatils-section{
    display: flex;
    flex-direction: column;
    gap: 2rem;  
}
.DetailsHeading{
    color: var(--brand-grey, #2F2F2F);
    font-family: MetropolisMedium;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}  
.DetailsHeading>span{
    color: var(--brand-green, #04B23D);
    font-family: Metropolis;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}
.upload-progress-bar{
    display: flex;
    /* gap: 1rem; */
    justify-content: space-between;
}
.stage{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.stageNumber{
    color: #FFF;
    font-family: MetropolisMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 23px;
    background: #04B23D;
    border-radius: 50%;
}
.stageText{
    /* color: #2F2F2F; */
    color: #757575;
    font-family: MetropolisMedium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}
.ecashDetailsInput{
    border-radius: 8px;
    border: 1px solid var(--outline-box, #D3D3D3);
    background: var(--brand-white, #FFF);
    color: #2F2F2F;
    font-family: MetropolisRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    padding: 15px 27px;
    outline: none;
}
.ecashDetailsInput::placeholder{
    color: #2F2F2F;
    font-family: MetropolisRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}
.firmNameEcash{
    color: #757575;
    font-family: MetropolisMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
}
.firmNameEcashFetched{
    color: #04B23D;
}
.firmName-div{
    display: flex;
    gap: 1rem;
    align-items: center;
}
.firmNameLoadingDiv{
    display: flex;
    justify-content: space-between;
}
.bottom-continue-div{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFF;
    box-shadow: 0px -4px 8px 0px rgba(92, 138, 152, 0.08);
    padding: 4rem 5rem;
    flex-direction: column;
    gap: 2rem;
}
.ecashContinueBtn{
    display: flex;
    width: 70%;
    padding: 12px 24px;
    justify-content: center;
    align-items:center;
    border-radius: 8px;
    background: var(--outline-box, #D3D3D3);
    border: none;
    color: #FFF;
    font-family: MetropolisMedium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
}
.ecash-checkbox-text-container{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.agree-ecash-TC-text{
    color: var(--icon-primary, #757575);
    font-family: MetropolisMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}
.TC_text{
    color: #04B23D;
    font-family: MetropolisMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    cursor: pointer;
}
.enabledContinueBtn{
    background-color:#04B23D;
}
.eCashPanNumberError{
    display: none;
    font-size: 14px;
}
.eCashGSTNumberError{
    display: none;
    font-size: 14px;
}
.disabledBg{
    background-color: #F5F5F5;
}
.ecashPanMismatched-consent-modal-content{
    overflow: auto;
    padding: 40px;
    margin-bottom: 5rem;
    height: fit-content;
}
.pan-deatils-checkbox-flex{
    display: flex;
    flex-direction: column;
    gap: 5rem;
}
.dontHaveGstText{
    color: #0DB442;
    font-family: MetropolisMedium;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    cursor: pointer;
}
.isMSMEregistered{
    display: flex;
}
.isRegisteredAsMSME_Span{
    color: var(--text-dark, #2F2F2F);
    font-family: MetropolisMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}
.IsMSMERegistered_div{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.AreUsure-gstContinue-popup-content{
    border-radius: 12px;
    width: 40%;
}
.AreUsure-gstContinue-modal-content{
    padding: 20px 40px;
    padding-top: 7px;
}
.okBtnPopupDiv{
    display: flex;
    justify-content: center;
    gap: 3rem;
}
.okBtnPopupDiv>button{
    border: none;
    border-radius: 8px;
    background: #04B23D;
    color: white;
    font-family: MetropolisMedium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 8px 20px;
}
.cancelBtn{
    background: white !important;
    border: 2px solid #04B23D !important;
    color: #04B23D !important;
}
.bottom-continue-div{
    display: flex;
}
.buttons-inner-div{
    width: 70%;
    display: flex;
    justify-content: space-between;
}
.saveExitBtn{
    border-radius: 8px;
    border: 1px solid var(--brand-green, #0DB442);
    background: var(--brand-white, #FFF);
    padding: 12px 24px;
    color: var(--brand-green, #0DB442);
    font-family: MetropolisMedium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 20rem;
}
.ProceedBtn{
    border-radius: 8px;
    background: #04B23D;
    border: none;
    padding: 12px 24px;
    color: #FFF;
    font-family: MetropolisMedium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 20rem;
}
.documents-partnership-withgst{
    display: flex;
    flex-direction: column;
    gap: 4rem;
}
.selfAttested-percent-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 115%;
}
.self-attested-text , .percentage-text>span{ 
    color: var(--text-grey, #757575);
    font-family: MetropolisMedium;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.percentage-text{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.document-container , .document-container75Lac, .document-container2Cr{
    border-radius: 8px;
    border: 1px solid #D3D3D3;
    background: var(--neutral-white, #FFF);
    padding: 13px 16px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.document-name-upload{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.document-Name-div{
    display: flex;
    gap: 1rem;
}
.document-name{
    color: #2F2F2F;
    font-family: MetropolisRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}
.openDocument-Div{
    display: none;
    flex-direction: column;
    gap: 2rem;
}
.documentinput-div, .documentinput-div>input{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.documentinput-div>input , .documentinput-div>select{
    border-radius: 8px;
    border: 1px solid #ECECEC;
    background: var(--brand-white, #FFF);
    color: #757575;
    font-family: MetropolisMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    outline: none;
    padding: 7px 16px 7px 16px;
    width: 100%;
}
.documentinput-div>input::placeholder{
    color: #757575;
    font-family: MetropolisMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}
.upload-Names-btn-div{
    justify-content: space-between;
    display: flex;
}
.upload-Names-div{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-end;
}
.upload-addMore-Btn-div{
    margin-left: auto;
    display: flex;
    align-items: flex-end;  
}
.document-upload-btn{
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 8px 15px 8px 15px;
    background: #04B23D;
    border: none;
    color: #FFF;
    text-align: center;
    font-family: MetropolisMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.documentname-cross-div{
    display: flex;
    align-items: center;
    color: #008EE2;
    font-family: MetropolisMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.eacsh-partnerWisePanDocumentDiv{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 20px;
    border-bottom: 1px dotted #ECECEC;
}
.PartnerNo{
    color: #2F2F2F;
    font-family: MetropolisRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
}
.addMorePartners-div{
    display: flex;
    justify-content: flex-end;
}
.addMorePartners-text{
    color: #04B23D;
    font-family: MetropolisMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    cursor: pointer;
}
.partner-aadhar-details{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.upload-drag-header{
    display: flex;
    flex-direction: column;
}
.upload-drag-heading{
    color: #2F2F2F;
    text-align: center;
    font-family: MetropolisMedium;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 100% */
    text-transform: uppercase;
    display: flex;
    justify-content: center;
}
.upload-drag-content{
    gap: 5rem;
    align-items: center;
}
.dotted-div{
    border-radius: 12px;
    border: 1px dashed #000;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
    width: 100%;
}
.dragdrop-text{
    color: #2F2F2F;
    text-align: center;
    font-family: MetropolisMedium;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 100% */
    text-transform: capitalize;
}
.browse-text{
    color: #2F2F2F;
    text-align: center;
    font-family: MetropolisRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.size-text{
    color: #1147B2;
    text-align: center;
    font-family: MetropolisRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
}
.saveFileBtn{
    border-radius: 8px;
    background: #04B23D;
    padding: 12px 24px;
    border: none;
    color: #FFF;
    font-family: MetropolisMedium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 26%;
}
.upload-drag-popup-content{
    border-radius: 12px;
}


.documentsNumberDiv{
    border-radius: 4px;
    background: var(--divider, #ECECEC);
    display: flex;
    width: fit-content;
    align-items: center;
    padding: 2px 7px;
}

.save-before-back-alert{
    width: 115%;
    display: flex;
    gap: 1rem;
    border-radius: 8px;
    border: 1px solid rgba(251, 50, 50, 0.44);
    background: #FDE0E0;
    padding: 10px;
}
.save-before-back-alert>span{
    color: #2F2F2F;
    font-family: MetropolisRegular;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}
.reviewDocsDiv{
    background-color: white;
    z-index: 10;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}
.reviewGif{
    width: 100%;
}
.reviewing75LacDocsScreen{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
}
.requestFor75LacInProcess{
    color: #2F2F2F;
    text-align: center;
    font-family: MetropolisMedium;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.green75Lac{
    color: #04B23D;
    font-family: MetropolisMedium;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.textDivFor75LacScreen{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    position: relative;
    padding-bottom: 30px;
}
.textDivFor75LacScreen::after {
    content: "";
    display: block;
    width: 50%; 
    border-bottom: 1px solid #FDE96C;
    position: absolute;
    bottom: 0;
    left: 25%;
  }
.upperTextFor75LacScreen{
    display: flex;
    gap: 1rem;
    align-items: center;
}
.reviewingDocsText{
    color: var(--icon-primary, #757575);
    text-align: center;
    font-family: MetropolisMedium;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}
.lowerTextFor75LacScreen{
    color: #AEAEAE;
    text-align: center;
    font-family: MetropolisMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.14px;
}
.requestIdText{
    color: var(--icon-secondary, #757575);
    font-family: MetropolisMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
}
.wnatToIcrText{
    color: #5B5B5B;
    text-align: center;
    font-family: MetropolisMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; 
}
.limitgreen{
    color: #04B23D;
    font-family: MetropolisMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}
.crDocsHeading{
    color: #000;
    font-family: MetropolisMedium;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
    width: 115%;
    border-bottom: 1px solid #ECECEC;
    padding-bottom: 3rem;
}
.greenLimitcr{
    color: #04B23D;
    font-family: MetropolisMedium;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.uploadHeadingCr{
    display: flex;
    gap: 1rem;
}
.uploadDocsHeading2Cr{
    color: var(--text-color, #2F2F2F);
    font-family: MetropolisMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}
.lowerUploadDocsSectionCr{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.docsdiv2Cr{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.requestFor75LacApproved{
    color: #2F2F2F;
    text-align: center;
    font-family: MetropolisMedium;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 100% */
}
.sanctionedDocsText{
    color: #2F2F2F;
    text-align: center;
    font-family: MetropolisMedium;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36.5px; /* 152.083% */
}
.limitText{
    color: #04B23D;
    font-family: MetropolisMedium;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36.5px;
}
.sorryText{
    color: var(--icon-primary, #757575);
    text-align: center;
    font-family: MetropolisMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 112.5% */
}
.rejectionReason-div{
    border-radius: 8px;
    border: 1px solid #F45944;
    background: #FBE8E8;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 20px;
    width: 95%;
}
.rejectionHeading{
    color: var(--brand-grey, #2F2F2F);
    font-family: MetropolisMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: -0.16px;
}
.rejectionReasons{
    color: var(--brand-grey, #2F2F2F);
    font-family: MetropolisMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.5px; /* 153.571% */
    letter-spacing: -0.14px;
}
.reapplyDiv{
    display: flex;
    gap: 1rem;
}
.reapplyText{
    color: var(--text-dark, #2F2F2F);
    text-align: center;
    font-family: MetropolisMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 112.5% */
    letter-spacing: -0.16px;
}
.sixDocsNames{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: var(--icon-primary, #757575);
    font-family: MetropolisMedium;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.completedItrYears{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.completedItrYearDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.completedItrYearLeft{
    display: flex;
    gap: 1rem;
}
.completedItrYearLeft>span{
    color: #04B23D;
    text-align: center;
    font-family: MetropolisMedium;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    white-space: nowrap;
}
.approvedText75{
    color: var(--brand-grey, #2F2F2F);
    font-family: MetropolisMedium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.5px;        
    letter-spacing: -0.14px;
}
@media only screen and (max-width: 1300px){
    .rightInnerSection {
        width: 85%;
    }
}
@media only screen and (max-width: 1100px){
    .rightInnerSection {
        width: 95%;
    }
}
@media only screen and (max-width: 1000px){
    .rightInnerSection {
        width: 85%;
    }
    .eCashPostReg>.row>.col-md-6:first-child{
        display: none !important;
    }
    .col-md-6{
        width: 100% !important;
    }
}
@media only screen and (max-width: 619px){
    .buttons-inner-div {
        width: 100%;
    }
}