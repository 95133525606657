.personalInfoBlock-cls {
    .personalInfo-cls {
        margin: 2% 5%;

        .personalInfoDetails-cls {
            display       : flex;
            flex-direction: row;
            background    : #0000001A 0% 0% no-repeat padding-box;
            box-shadow    : 0px 3px 6px #0000001A;
            border-radius : 10px;
            opacity       : 1;
            height        : 46vh;
            overflow-x    : auto;

            .p-inputtext {
                margin       : 0;
                outline      : none;
                padding      : .25em;
                font-weight  : normal;
                width        : 250px;
                border       : none;
                background   : #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow   : 0px 2px 5px #0000001A;
                border-radius: 5px;
                opacity      : 1;
                height       : 24px;
            }

            .block1-cls {
                flex  : 1;
                margin: 20px;
                .sendotp{
                    margin: 12px 2px;
                    font-size: 14px;
                    color: #E43137;
                    cursor: pi;
                    cursor: pointer;
                    font-weight: bold;
                    // float: right;
                }
                .resendotp{
                    margin: 12px 2px;
                    font-size: 14px;
                    color: #E43137;
                    cursor: pi;
                    cursor: pointer;
                    font-weight: bold;
                    // float: right;
                }
                .p-button{
                    color: #ffffff;
                    background: #E43137;
                    border: 1px solid #E43137;
                    // padding: 0.5em 0.5em;
                    font-size: 14px;
                    border-radius: 4px;
                    font-weight: bold;
                    height: 30px;
                    width: 250px;
                }
            }

            .block2-cls {
                flex  : 1;
                margin: 20px;
            }

            .block3-cls {
                flex  : 1;
                margin: 20px;

                .inputarea-content-form-cls {

                    .inputarea-label {
                        padding-bottom: 2px;

                        label {
                            color      : #00000099;
                            font-size  : 12px;
                            font-weight: bold;

                        }
                    }

                    .p-inputtext {
                        resize       : none !important;
                        border-radius: 5px;
                        height       : 210px;
                    }
                }
            }
        }

        .personalInfoButtons-cls {
            display   : flex;
            margin-top: 40px;

            .skipButton {
                flex: 1;

                p {
                    font-size  : 11px;
                    font-weight: bold;
                    font-family: sans-serif;
                    color      : #00000099;
                    opacity    : 1;
                }

                .p-button {
                    background   : #FFFFFF;
                    box-shadow   : 0px 3px 6px #D62E3440;
                    border       : 1px solid #E43137;
                    border-radius: 10px;
                    opacity      : 1;
                    width        : 210px;
                    height       : 45px;
                    font-weight  : bold;
                }

                .p-button-text-only .p-button-text {
                    text-align : center;
                    font-size  : 14px;
                    font-weight: bold;
                    font-family: sans-serif;
                    color      : #E43137;
                    opacity    : 1;
                }
            }

            .continueButton {
                .p-button {
                    background   : #E43137;
                    box-shadow   : 0px 3px 6px #D62E3440;
                    border       : 1px solid #E43137;
                    border-radius: 10px;
                    opacity      : 1;
                    width        : 160px;
                    height       : 40px;
                }

                .p-button-text-only .p-button-text {
                    text-align    : center;
                    font-size     : 13px;
                    font-family   : sans-serif;
                    color         : #FFFFFF;
                    opacity       : 1;
                    letter-spacing: 1px;
                    font-weight   : bold;
                }
            }

        }
    }
}