// @import "bourbon";

@import "https://fonts.googleapis.com/css?family=Montserrat:400,700|Raleway:300,400";

/* colors */
$blue: #2F2F2F;
$red: #ff4a53;
$dark: #333;

$accent: $blue;
$accent-inactive: desaturate($accent, 85%);
$secondary: $accent-inactive;

/* tab setting */
$tab-count: 4;
$indicator-width: 50px;
$indicator-height: 4px;

/* breakpoints */
$breakpoints: (
  medium: #{$tab-count * 250px},
  small: #{$tab-count * 150px}
);

/* selectors relative to radio inputs */
$label-selector: "~ ul > li";
$slider-selector: "~ .indicator";
$content-selector: "~ .content > section";

@mixin tabs(
  $label-selector: $label-selector,
  $slider-selector: $slider-selector,
  $content-selector: $content-selector
) {
  @for $i from 1 through $tab-count {
    &:nth-of-type(#{$i}):checked {
      #{$label-selector}:nth-child(#{$i}) {
        @content;
      }

      #{$slider-selector}  {
        transform: translateX(#{100% * ($i - 1)});
        //background-color: green;
        //margin-left: 100px;
      }

      #{$content-selector}:nth-child(#{$i}) {
        display: block;
      }
    }
  }
}

html {
  width: 100%;
  height: 100%;
}

// body {
//   // background: #efefef;
//   color: $dark;
//   font-family: "Raleway";
//   height: 100%;

//   h1 {
//     text-align: center;
//     color: $accent;
//     font-weight: 300;
//     padding: 40px 0 20px 0;
//     margin: 0;
//   }
// }

.--bs-nav-tabs-border-width {
  --bs-nav-tabs-border-width: 0px !important;
}

// .nav {
//   color: 'grey'
// } 
 
// .nav-link {
//   color: 'grey';
// }

// .nav-tabs {
//   --bs-nav-tabs-link-active-bg: 'black'
// }

.tabs {
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  background: white;
  padding-bottom: 80px;
  width: 100%;
//   height: 250px;
  border-radius: 5px;
  background-color: 'black';  
  --bs-nav-tabs-link-active-bg: 'black';

  .navbar-css {
    box-shadow: 'rgb(255 255 255 / 8%)';
  }

  .nav-tabs {
    --bs-nav-tabs-link-active-bg: '#000000';
    border-width: 2px;  
  }

  // .nav-link.active,
  // .nav-item.show .nav-link {
  //   background-color: 'black';

  // }


//   min-width: #{$tab-count * 60px};
  input[name="tab-control"] {
    display: none;
  }
  

  
  // .nav-tabs .nav-links.active {
  //   background-color: 'black', 
  //   // color: 'grey'
  // }
  .content section h2,
  ul li label {
    font-family: "";
    font-weight: bold;
    font-size: 18px;
    color: $accent;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;

    li {
      // box-sizing: border-box;
      // flex: 1;
      // width: #{100% / $tab-count};
      
      label {
        transition: all 0.3s ease-in-out;
        color: $secondary;
        padding: 5px auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        white-space: nowrap;
        -webkit-touch-callout: none;
        // @include user-select(none);
        br {
          display: none;
        }
        svg {
          fill: $secondary;
          height: 1.2em;
          vertical-align: bottom;
          margin-right: 0.2em;
          transition: all 0.2s ease-in-out;
        }

        &:hover,
        &:focus,
        &:active {
          outline: 0;
          color: lighten($secondary, 15%);
          svg {
            fill: lighten($secondary, 15%);
          }
         
        }
      }
    }
  }

  .slider {
     position: relative;
    background-color:lightgray;
    width: 480px;
    transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
    .indicator {
      position: relative;
      width: $indicator-width;
      max-width: 100%;
      transition: all 500ms;
    //   margin: 0 auto;
    //  margin-left:0;
      height: $indicator-height;
      background: rgb(27, 27, 27);
      border-radius: 1px;
    }
  }
 

  .content {
    margin-top: 30px;

    section {
      display: none;
      animation: {
        name: content;
        direction: normal;
        duration: 0.3s;
        timing-function: ease-in-out;
        iteration-count: 1;
      }
      line-height: 1.4;

      h2 {
        color: $accent;
        display: none;
        &::after {
          content: "";
          position: relative;
          display: block;
          width: 30px;
          height: 3px;
          background: $accent;
          margin-top: 5px;
          left: 1px;
        }
      }
    }
  }

  input[name="tab-control"] {
    @include tabs {
      > label {
        cursor: default;
        color: $accent;

        svg {
          fill: $accent;
        }

        @media (max-width: map-get($breakpoints, small)) {
          background: rgba(0, 0, 0, 0.08);
        }
      }
    }
  }

  @keyframes content {
    from {
      opacity: 0;
      transform: translateY(5%);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  @media (max-width: map-get($breakpoints, medium)) {
    ul li label {
      white-space: initial;

      br {
        display: initial;
      }

      svg {
        height: 1.5em;
      }
    }
  }

  @media (max-width: map-get($breakpoints, small)) {
    ul li label {
      padding: 5px;
      border-radius: 5px;

      span {
        display: none;
      }
    }

    .slider {
      display: none;
    }

    .content {
      margin-top: 20px;
      section h2 {
        display: block;
      }
    }
  }
}