@media only screen and (min-width:600px) {
    .main-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 8px;
        padding-bottom: 10px;
    }

    .form-container {
        /* width: 78%; */
        display: flex;
        flex-direction: row;
        text-align: left;
    }

    .singleContainer {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-top: 24px;
    }

    .irrValue {
        margin-top: 6px;
        font-size: 14px;
        color: #000;
        margin-left: 20px;
    }

    .form-label-lender {
        font-size: 12px;
        margin-bottom: 6px;
        font-weight: 600;
    }

    .form-label-create {
        font-size: 12px;
        font-weight: 600;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .submitLender {
        background-color: #04b23d;
        width: 148px;
        height: 32px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 16px;
        border: none;
        color: white;
        font-family: metropolisLight;
        border-radius: 6px;
        float: right;
        font-size: 16px;
        cursor: pointer;
        margin-top: 36px;
    }

    .mutli-container {
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        align-items: flex-start;
    }

    .input-container {
        display: block;
        width: 86%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0.375rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    /* this heigh depend on the lists */
    .modalBackground {
        width: 78vw;
        height: 90vh;
        /* background-color: rgba(200, 200, 200); */
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
    }



    .td-color {
        color: #121212;
    }

    .modalContainer {
        /* width: 500px */
        height: 500px;
        border-radius: 12px;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        display: flex;
        flex-direction: column;
        padding: 25px;
    }

    .modalContainer .title {
        display: inline-block;
        text-align: center;
        margin-top: 10px;
    }

    .titleCloseBtn {
        display: flex;
        justify-content: flex-end;
    }

    .titleCloseBtn button {
        background-color: transparent;
        border: none;
        font-size: 25px;
        cursor: pointer;
    }

    .modalContainer .body {
        flex: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.7rem;
        text-align: center;
    }

    .modalContainer .footer {
        flex: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modalContainer .footer button {
        width: 150px;
        height: 45px;
        margin: 10px;
        border: none;
        background-color: cornflowerblue;
        color: white;
        border-radius: 8px;
        font-size: 20px;
        cursor: pointer;
    }

    #cancelBtn {
        background-color: crimson;
    }

    .pagination-ctn{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .page-item{
        margin: 10px;
        cursor: pointer;
    }
    .single-page{
        font-size: 16px;
    }
}


/* for mobile  */
@media only screen and (max-width:600px) {
    .main-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 8px;
        padding-bottom: 10px;
        overflow: auto;
    }

    .form-container {
        /* width: 78%; */
        display: flex;
        flex-direction: column;
        text-align: left;
        overflow: auto;
        overflow-y: auto;
    }

    .singleContainer {
        display: flex;
        flex-direction: column;
        margin-left: 2px;
        margin-top: 10px;
    }

    .irrValue {
        margin-top: 6px;
        font-size: 14px;
        color: #000;
        margin-left: 20px;
    }

    .form-label-lender {
        font-size: 12px;
        margin-bottom: 6px;
        font-weight: 600;
    }

    .form-label-create {
        font-size: 12px;
        font-weight: 600;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    .submitLender {
        background-color: #04b23d;
        width: 148px;
        height: 32px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 16px;
        border: none;
        color: white;
        font-family: metropolisLight;
        border-radius: 6px;
        float: right;
        font-size: 16px;
        cursor: pointer;
        margin-top: 30px;
    }

    .mutli-container {
        display: flex;
        flex-direction: column;
        margin-top: 2px;
        align-items: flex-start;
    }

    .input-container {
        display: block;
        width: 86%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0.375rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .modalBackground {
        width: 76vw;
        height: 78vh;
        /* background-color: rgba(200, 200, 200); */
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modalContainer {
        /* width: 500px */
        height: 500px;
        border-radius: 12px;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        display: flex;
        flex-direction: column;
        padding: 25px;
    }

    .modalContainer .title {
        display: inline-block;
        text-align: center;
        margin-top: 10px;
    }

    .titleCloseBtn {
        display: flex;
        justify-content: flex-end;
    }

    .titleCloseBtn button {
        background-color: transparent;
        border: none;
        font-size: 25px;
        cursor: pointer;
    }

    .modalContainer .body {
        flex: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.7rem;
        text-align: center;
    }

    .modalContainer .footer {
        flex: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modalContainer .footer button {
        width: 150px;
        height: 45px;
        margin: 10px;
        border: none;
        background-color: cornflowerblue;
        color: white;
        border-radius: 8px;
        font-size: 20px;
        cursor: pointer;
    }

    #cancelBtn {
        background-color: crimson;
    }

    .pagination-ctn{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .page-item{
        margin: 10px;
        cursor: pointer;
    }
    .single-page{
        font-size: 16px;
    }
}