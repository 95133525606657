.profin-dashboard .searchTextBox-2 {
  margin-left: 5px;
  font-family: Metropolis-Regular;
  width: 440px;
  height: 40px;
  border: 1px solid #d3d3d3;
  box-shadow: 2px 0px 12px rgba(92, 138, 152, 0.075);
  border-radius: 8px;
  outline: #d3d3d3;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAYCAYAAAAPtVbGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJDSURBVHgBrVVLbiIxEHV3A2tygvHcILNBgwCF7GY3MydI+gS0xHcH7BAfiayyDNyAnAAiAWKXzg06N+hlxDevLFsi6TaBDk+y/CvXc5WrygbTwHGcZCKRKOx2uzzapWEYSbnlUsPasNvtTtgRMDTK61DisK8xWa1Wdr/f9w4JmZ8IeDwef1YE6Cfb7fY/FP3sdDoG+gusXZMVsIwU50m+Uqn8Y8dYQhbQAQw5KYBy+5A7arUaX6/XDcjeyKVfuIgbJmupQS6Xu6ebEYFpmtftdttlBzCdTv35fD5Kp9MXOPMbS39SqdRwsVi8fZY1pRUc3a1YAEGr1fLYkej1euTaCRqPxWKh7yhI4Ka6nA9OIVDAGzWph0UFcnsoCYTEw1mW1WQRIN+OWhLWXAZIwKxywI9ihQIC5UUOgyRgFubBGpd9A3hLcR4X5oE9dibgkkndHlni6W5wIgmXvRcgoXfAho8xLxaLP1hE4JJXchhwu4quoRS0WQRQnuEsBZAXViVMqXwk+4JMzJOAPBtTjwgbhu0LEmKHwB2GVL/GpxChOFIii3qHWtbXkhA2m01DVlZ+DBFldrVafYCrGzSnio2S74fJfvhPqLLCorGKFGAA4sflcikUkGLKaOTEFeQclcRoInwxb6CwNg+SKJRKJbKqzr4A3R6kNryQx/RBR2ToFMj/gmraX4oceVtftkcQjPYjqVwu3+qItCRRoCOy2BmBT8zNZDKvGJIH8tlsls1ms6ezWqKwbxFgn61A7gN//YCUq/k7IusWomJ+ZjQAAAAASUVORK5CYII=);
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 58px;
  background-position-x: 15px;
  font-family: metropolisLight;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  background-size: 18px;
  line-height: 16px;
  /* background-position-y: -9px; */
  /* padding-left: 40px; */
}

.profin-dashboard .pic {
  float: right;
  width: 50px !important;
  padding-right: 10px;
  margin: 6px;
}

.profin-dashboard .thirdCard-1 {
  width: 240px !important;
  height: 160px;
  margin-right: 7px !important;
  margin-top: 56px !important;
  /* width: 193px; */
  left: 20px;
  top: 0px;
  background: #fff;
  /* Divider */
  border: 1px solid #ececec;
  border-radius: 12px;
  padding: 10px;
  /* display: inline-block; */
  vertical-align: top;
  /* z-index: 8; */

}

.dashboardBg {
  background-image: url('dashboardBg.png');
  min-height: 350px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  padding: 30px;
}

.profin-dashboard .firstCard {
  width: 220px !important;
  height: 160px;
  left: 238px;
  top: 131px;
  background: #fff;
  /* Divider */
  border: 1px solid #ececec;
  border-radius: 12px;
  padding: 20px;
  margin-top: 20px;
  /* display: inline-block; */
  vertical-align: top;
  /* margin-left: 30px; */
}

.profin-dashboard .select-css {
  font-family: Metropolis-Regular;
}

.profin-dashboard .secondCard {
  width: 160px;
  height: 226px;
  left: 238px;
  top: 131px;
  background: #fff;
  margin-left: 30px;

  /* Divider */
  border: 1px solid #ececec;
  border-radius: 12px;
  padding: 10px;
  display: inline-block;
  vertical-align: top;
}

.profin-dashboard .left-box {
  margin-top: 20px !important;
  width: 840px !important;
  margin-left: 30px !important;
}

.profin-dashboard .dashed-css {
  border: 2.5px;
  border-style: dashed;
  color: white;
}

.profin-dashboard .viewAllLink-2 {
  font-family: metropolisSemiBold;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height */
  color: #04b23d;
  margin-top: 10px;
  margin-left: 25px;
  float: right;
}

.profin-dashboard .viewAll-1 :hover {
  cursor: pointer;

}

.profin-dashboard .viewAll-1 {
  display: flex;
  margin-top: 30px;

}

.profin-dashboard .pagination-section {
  font-family: metropolisRegular;
  margin-left: 35px;
  margin-top: 20px;
  margin-right: 75px;
  display: flex;
}

.profin-dashboard .pagination-content-active {
  color: #04B23D;
  font-size: 15px;
  font-family: metropolisRegular;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 5px;
  text-transform: capitalize;
  cursor: pointer;
}

.profin-dashboard .pagination-content {
  color: var(--text-tertiary, #979797);
  margin: 5px;
  font-size: 15px;
  font-family: metropolisRegular;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}

.profin-dashboard .pagination-right {
  width: 70%;
  margin-left: 580px;
  float: right;
}

.profin-dashboard .pagination {
  justify-content: flex-end;
}

.profin-dashboard .page-num {
  width: 6px;
  height: 18px;

  /* Button/Small */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 10pt !important;
  line-height: 18px;
  /* identical to box height, or 150% */


  /* Primary Palette/White */

  color: #FFFFFF;
  background-color: #04B23D !important;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.buyer-list {
  color: #000;
  font-size: 18px;
  font-family: Metropolis-Regular;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 10px;
  margin-left: 80px;
}

.calender-content {
  margin-left: 300px;
  width: 18% !important;
  border: 1px solid rgb(222, 222, 222);
  border-radius: 5px;
  margin-left: 15px;
  height: 57%;
  display: flex;
}

.sort-by-1 {
  color: var(--text-tertiary, #979797);
  font-size: 14px;
  font-family: Metropolis-Regular;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.profin-dashboard .img-date {
  width: 10% !important;
  height: 55%;
  /* margin: 11px !important; */
  padding-bottom: 2px !important;
}

.profin-dashboard .p-inputtext {
  padding-top: 10px;
}

.text-header {
  background: #04B23D;
}

.table-bordered {
  border-color: #f2f2f2;
}

.rectangle-section {
  display: flex;
  flex-direction: row;
  width: 150px;
  flex-shrink: 0;
  margin-left: 20px;
  color: white;
  padding-top: 25px;
  padding-left: 33px;
  border-radius: 16px 16px 0px 0px;
}

.text-table {}

.tbody-content {
  padding: 20px !important;
  font-family: Metropolis-Regular;
  font-weight: 600;
  color: var(--text-dark, #2F2F2F);
  font-size: 12px;
}

.profin-dashboard .statusIncomplete {
  color: #F5D405;
  background: white;
  border: 1px solid #F5D405;
  border-radius: 8px;
  height: auto;
  font-family: metropolisLight;
  text-transform: uppercase;
  font-size: 9pt;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.profin-dashboard .statusRejected{
  color: #F11C1C;
  background: white;
  border: 1px solid #F11C1C;
  border-radius: 8px;
  height: auto;
  font-family: metropolisLight;
  text-transform: uppercase;
  font-size: 9pt;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.profin-dashboard .statusBtnunderProcess {
  background: rgba(242, 123, 14, 0.13);
  border: 1px solid #f27b0e;
  color: #f27b0e;

  border-radius: 8px;
  height: auto;
  font-family: metropolisLight;
  text-transform: uppercase;
  font-size: 9pt;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.profin-dashboard .statusBtn {
  background: rgba(13, 180, 66, 0.1);
  border: 1px solid #0A8330;
  color: #0A8330;
  border-radius: 8px;
  height: auto;
  font-family: metropolisLight;
  text-transform: uppercase;
  font-size: 9pt;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.profin-dashboard .tableCard {
  max-width: 100% !important;
  background: #ffffff;
  /* margin-top: 20px; */
  border-radius: 12px;
  padding: 30px;
  margin-left: 30px;

}

.table-width {
  width: 100% !important;
}

.tableCard > table td{
  vertical-align: middle;
}

.tableCard > table th{
  vertical-align: middle;
  color:white;
}

.border-input :focus {
  outline: none !important;
}