.custom-carousel{
    font-family: 'Poppins', sans-serif;

    .p-carousel{
        .p-carousel-header{
            width: 50%;
            h5{
                margin: 0.5em;
            }
        }
    }

    .p-carousel-content{
        
        .p-carousel-container{

            .p-button{
                background: none;
                border: none;
            }

            .p-carousel-items-content{

                .p-carousel-items-container{

                    .p-carousel-item{
                        // height: 24vh !important;
                        border: 1px solid var(--surface-d);
                        border-radius: 3px;
                        // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
                        overflow: hidden;
                        // flex: 0 0 33.333333333333336% !important;

                        .card-view{
                            height: 100%;
                            max-width: 273px;
                            font-family: 'Poppins', sans-serif !important;
                        }

                        .product-item{
                            border: 2px solid lightgray;
                            width: 20em;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            height: 100%;
                            cursor: default;
                            background-image: repeating-linear-gradient(45deg, #f0f5ff, #cad5e8);
                            margin-right: 0.5em;
                            border-radius: 33px;
                            background-size:cover;
                            background-position:center;
                            background-repeat:no-repeat;
                            .top-warpper{
                                display: flex;
                                // justify-content: space-between;

                                .image-wrapper{
                                    // width: 20%;

                                    .commodity-image{
                                        border: 1px solid black;
                                        height: 62px;
                                        margin: 0.5em 0.5em;
                                        background: #FFFFFF 0% 0% no-repeat padding-box;
                                        border: 1px solid #8A8A8A;
                                        border-radius: 0px 25px 25px 25px;
                                        width: 62px;
                                        // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

                                        img{
                                            width: 100%;
                                            height: 100%;
                                            border-radius: 0px 25px 25px 25px;
                                        }
                                    }
                                }

                                .commodity-info{
                                    padding-top: 0.5em;
                                    text-transform: capitalize;

                                    p{
                                        margin: 0;
                                        
                                    }

                                    .commodity-name{
                                        font-size: 15px;
                                        font-family: 'Poppins', sans-serif !important;
                                    }

                                    .commodity-price{
                                        font-size: 12px;
                                    }

                                    .bid-now{
                                        background: #FFD900 0% 0% no-repeat padding-box;
                                        border-radius: 5px;
                                        width: 6em;
                                        height: 30px;
                                        color: #000000;
                                        text-align: center;
                                        font: normal normal 600 14px/21px Poppins;
                                        line-height: 30px;
                                        font-size: 14px;
                                        margin-top: 12px;
                                        cursor: pointer;
                                    }
                                }

                                .commodity-qty{
                                    color: #FF4848;
                                    padding-top: 0.5em;
                                    padding-right: 0.3em;

                                    p{
                                        text-align: right;
                                        margin: 0;
                                        font-size: 12px;
                                    }
                                    .bid-now{
                                        background: #FFD900 0% 0% no-repeat padding-box;
                                        border-radius: 5px;
                                        width: 6em;
                                        height: 30px;
                                        color: #000000;
                                        text-align: center;
                                        font: normal normal 600 14px/21px Poppins;
                                        line-height: 30px;
                                        font-size: 14px;
                                        // margin-top: 4.5em;
                                        // margin-bottom: 10px;
                                        cursor: pointer;
                                    }
                                }
                            }

                            .bottom-wrapper{
                                display: flex;
                                justify-content: space-between;
                                margin: 0 1em;
                                color: #FFFFFF;
                                font-weight: bold;
                                padding: 0px 0em 0px 0em;
                                font-family: 'Poppins', sans-serif !important;
                                text-shadow: 2px 2px 6px #000000;
                                margin-bottom: 1em;
                                p{
                                    margin: 0;
                                    font-size: 14px;
                            font-family: 'Poppins', sans-serif !important;
                                }
                            .location-cls{
                                // width: 39%;
                            }
                                .closes-in{
                                    display: flex;
                                    font-weight: normal;
                                    font-size: 12px;
                                    .close-label{
                                        font-size: 11px;
                                        display: flex;
                                        margin: auto;
                                        .label{
                                            position: relative;
                                            right: -3.5em
                                        }   
                                        >div{
                                            // margin-left: 0.25em;
                                        }
                                    }
                                }
                            }
                            .header-wrapper{
                                display: flex;
                                justify-content: space-between;
                                color: #FFFFFF;
                                text-shadow: 2px 2px 6px #000000;
                                // margin: 0.5em;
                                height: 5.9em;
                                .commodity-name{
                                    font-size: 15px;
                                    font-size: 20px;
                                    font-family: 'Poppins', sans-serif !important;
                                }
                                .commodity-details{
                                    text-align: right;
                                    // background-color: rgba(118,107,89,255);
                                    // border-radius: 8px;
                                    // height: 3.3em;
                                    // margin-top: 8px;
                                    // opacity: 0.9;
                                    padding: 4px 4px 4px 2px;
                                    // width: 9em;
                                    text-shadow: 2px 2px 6px #000000;

                                    .price{
                                        margin-top: 2em;
                                    }
                                }
                                
                            }
                            .commodity-qty{
                                display: flex;
                                justify-content: center;
                                .bid-now{
                                    background: #FFD900 0% 0% no-repeat padding-box;
                                    border-radius: 5px;
                                    width: 6em;
                                    height: 30px;
                                    color: #000000;
                                    text-align: center;
                                    font: normal normal 600 14px/21px Poppins;
                                    line-height: 30px;
                                    font-size: 14px;
                                    // margin-top: 12px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                   
            }
        }

        .p-carousel-dots-container{
            margin: 0;
            .p-carousel-dot-item{
                margin-right: 0.5rem;
                margin-bottom: 0.5rem;
                
                .p-link{
                    background-color: #ced4da;
                    width: 2rem;
                    height: 0.5rem;
                    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
                    border-radius: 0;

                    .p-carousel-dot-icon{
                        display: none;
                    }
                }
            }

            .p-highlight{
                .p-link{
                    background-color: #6366F1;
                    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
                }
            }

            display: none;
            
        }
    }
}