.radio-content-section {
    flex:1;
    .radio-label{
            align-items: center;
            display: flex;
            height: 40px;
            margin-bottom: -4px;
        .required-cls{
            p{
              color:red;
              font-size: 14px;
              margin-left: 0px;
              
            }
        }
        .label-cls{
            font-size: 14px;
            font-family: sans-serif;
            margin-left: 3px;
            color:#00000099;
            margin-top: -8px;
        }
    }
    .radio-button-cls{
        display: flex;
        flex-direction: row;
        //margin-left: 5px;
        margin-right: 60px;
    }
  }

  .radio-button-cls {
    display: flex;
    flex-direction: row;
  }

  .userStatus-radio-cls {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;

    input {
      width: 15px;
      height: 15px;
      margin-top: -1px;
      margin-right: 8px;
    }

    label {
      font-size: 13px;
      font-family: sans-serif;
      color: #050e11;
    }
  }


  
