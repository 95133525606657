.stepper-wrapper-horizontal {
    display: flex;
    padding: 0.5em;
    width  : 100%;
    margin: 50px 0px 0px 40px;

    .step-wrapper {
        // width          : 22%;
        display        : flex;
        flex-direction : row;
        align-items    : center;
        justify-content: center;
        position       : relative;
        font-size      : 15px;
        margin-right   : 130px;
        padding        : 0px 20px;
    }

    .step-number {
        border-radius   : 50%;
        background-color: #838383;
        border          : 1px solid grey;
        width           : 20px;
        height          : 20px;
        padding         : 3px;
        text-align      : center;
    }

    .divider-line {
        height          : 1px;
        background-color: #bdbdbd;
        position        : absolute;
        top             : 45%;
        left            : 11em;
        //width:20%
    }

    .step-wrapper-active {
        background      : #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow      : 0px 2px 5px #00000029;
        border-radius   : 10px;
        opacity         : 1;
        // width        : 12%;
        font-size       : 15px;
        margin-right    : 130px;
        padding         : 0px 20px;
    }

    .divider-line-2 {
        width: 18em
    }

    .divider-line-3 {
        width      : 20%;
        margin-left: 30%;
    }

    .divider-line-4 {
        width: 70%
    }

    .divider-line-5 {
        width: 60%
    }
}



.step-number-selected {
    // background: purple;
    border: 1px solid #bdbdbd;
    color : #fff;

}

.step-number-disabled {
    border: 1px solid #838383
}

.step-description-active {
    font-weight: bold;
    color      : #7DB851;
}

.step-description {
    padding  : 0.6em;
    font-size: 14px;
}