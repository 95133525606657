.userProfileBlock-cls {
    display    : flex;
    align-items: center;
    margin-top: -0.5em;

    .image-cls {
        margin-right: 10px;
        cursor: pointer;

        img {
            width : 35px;
            height: 35px;
        }
    }

    .userDetails-cls {

        .label-cls {
            cursor: pointer;
            p {
                color      : #fff;
                font-size  : 18px;
                font-family: sans-serif;
                text-align : right;
            }
        }

        .logout-cls {
            p {
                color      : #E43137;
                font-size  : 17px;
                font-family: sans-serif;
                text-align : right;
                margin-top : -5px;
                cursor     : pointer;
            }
        }

    }
}